import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyFlagableConfigurations from './useModifyFlagableConfigurations'

import { apiClient } from '~/api/ApiClient'
import { selectCurrentScope, selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { EScope } from '~/types/enums/ECommonEnum'
import { IFlagableConfiguration } from '~/types/models/IFlagableConfiguration'

const useQueryFlagableConfigurations = (
  params = {},
  options?: Partial<UseQueryOptions<IFlagableConfiguration[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)
  const currentScope: EScope = useSelector(selectCurrentScope)

  const {
    addFlagableConfiguration,
    updateFlagableConfiguration,
    removeFlagableConfiguration,
  } = useModifyFlagableConfigurations({})

  const { data, isLoading, isFetched } = useQuery({
    queryKey: [
      'flagableConfigurations',
      sessionUser?.id,
      buildGetUrl(apiClient.flagableConfigurations.endpoint, params),
      currentScope,
    ],
    async queryFn() {
      const response = await apiClient.flagableConfigurations.get()
      return response.flagableConfigurations
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 600000, // 10 minute
    ...options,
  })

  const flagableConfigurationsData = useMemo(() => data || [], [data])

  return {
    flagableConfigurationsData,
    isFlagableConfigurationLoading: isLoading,
    isFlagableConfigurationFetched: isFetched,
    addFlagableConfiguration,
    updateFlagableConfiguration,
    removeFlagableConfiguration,
  }
}

export default useQueryFlagableConfigurations

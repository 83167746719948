import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyRewardSettings from './useModifyRewardSettings'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IRewardSetting } from '~/types/models/IRewardSetting'

const useQueryRewardSettings = (
  params = {},
  options?: Partial<UseQueryOptions<IRewardSetting[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'rewardSettings',
      sessionUser?.id,
      buildGetUrl(apiClient.rewardSettings.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.rewardSettings.get()
      return response.rewardSettings
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // 1 min
    ...options,
  })

  const rewardSettingsData = useMemo(() => data || [], [data])

  const rewardSettingOptions = useMemo(
    () =>
      rewardSettingsData.map(({ id, name, provider }) => ({
        value: id,
        label: `${name} - ${provider}`,
      })),
    [rewardSettingsData],
  )

  const { addRewardSetting, updateRewardSetting, removeRewardSetting } =
    useModifyRewardSettings(params)

  return {
    rewardSettingsData,
    isLoadingRewardSettings: isLoading,
    rewardSettingOptions,
    addRewardSetting,
    updateRewardSetting,
    removeRewardSetting,
  }
}

export default useQueryRewardSettings

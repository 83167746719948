import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IGetProductsParams, IProduct } from '~/types/models/IProduct'

const useModifyTaxes = (params: IGetProductsParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'products',
      sessionUser?.id,
      buildGetUrl(apiClient.products.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addProduct = useCallback(
    (data: IProduct) => {
      queryClient.setQueryData<IProduct[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [...oldData, data]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateProduct = useCallback(
    (id: number, data: IProduct) => {
      queryClient.setQueryData<IProduct[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeProduct = useCallback(
    (id: number) => {
      queryClient.setQueryData<IProduct[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addProduct,
    updateProduct,
    removeProduct,
  }
}

export default useModifyTaxes

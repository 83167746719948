import {
  DragEvent,
  MouseEvent,
  PropsWithChildren,
  RefObject,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { RTTableBodyCellValue } from '../RTTableBodyCellValue'
import Skeleton from 'react-loading-skeleton'

import type {
  RTCell,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'
import { isCellEditable, openEditingCell } from '../../../utils/cell.utils'
import { getCommonMRTCellStyles } from '../../../utils/style.utils'
import { parseFromValuesOrFunc } from '../../../utils/utils'
// import { RTCopyButton } from "../buttons/RTCopyButton";
import { RTEditCellField } from '../../inputs/RTEditCellField'

import './styles.scss'
import clsx from 'clsx'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import { ToolTipOverlay } from '~/components/shared/ToolTipOverlay'

export interface IRTTableBodyCellProps<TData extends IReusableTableRowData>
  extends PropsWithChildren {
  cell: RTCell<TData>
  numRows?: number
  rowRef: RefObject<HTMLTableRowElement>
  staticColumnIndex?: number
  staticRowIndex: number
  table: ReusableTableInstance<TData>
}

const RTTableBodyCell = <TData extends IReusableTableRowData>({
  cell,
  numRows,
  rowRef,
  staticColumnIndex,
  staticRowIndex,
  table,
  ...rest
}: IRTTableBodyCellProps<TData>) => {
  const {
    getState,
    options: {
      columnResizeDirection,
      columnResizeMode,
      createDisplayMode,
      editDisplayMode,
      enableCellActions,
      enableClickToCopy,
      enableColumnOrdering,
      enableColumnPinning,
      layoutMode,
      getCellClassName,
    },
    setHoveredColumn,
  } = table
  const {
    actionCell,
    columnSizingInfo,
    creatingRow,
    density,
    draggingColumn,
    draggingRow,
    editingCell,
    editingRow,
    hoveredColumn,
    hoveredRow,
    isLoading,
    showSkeletons,
  } = getState()
  const { column, row } = cell
  const { columnDef } = column
  const { columnDefType, className } = columnDef

  const args = { cell, column, row, table }
  const tableCellProps = {
    ...args,
    ...rest,
  }

  const cellClassName =
    typeof getCellClassName === 'function'
      ? getCellClassName({
          row,
          table,
          cell,
        })
      : ''

  const [skeletonWidth, setSkeletonWidth] = useState(100)
  useEffect(() => {
    if ((!isLoading && !showSkeletons) || skeletonWidth !== 100) return
    const size = column.getSize()
    setSkeletonWidth(
      columnDefType === 'display'
        ? size / 2
        : Math.round(Math.random() * (size - size / 3) + size / 3),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, showSkeletons])

  const draggingBorderColor = 'var(--ion-color-concord)'

  const cellPropTypes =
    typeof columnDef.cellProps === 'function'
      ? columnDef.cellProps({
          cell,
          column,
          row,
          table,
        })
      : {}

  const hasError = Boolean(cellPropTypes.error)

  const draggingBorders = useMemo(() => {
    const isDraggingColumn = draggingColumn?.id === column.id
    const isHoveredColumn = hoveredColumn?.id === column.id
    const isDraggingRow = draggingRow?.id === row.id
    const isHoveredRow = hoveredRow?.id === row.id
    const isFirstColumn = column.getIsFirstColumn()
    const isLastColumn = column.getIsLastColumn()
    const isLastRow = numRows && staticRowIndex === numRows - 1
    const isResizingColumn = columnSizingInfo.isResizingColumn === column.id
    const showResizeBorder = isResizingColumn && columnResizeMode === 'onChange'

    const borderStyle = showResizeBorder
      ? `2px solid ${draggingBorderColor}`
      : isDraggingColumn || isDraggingRow
      ? '1px dashed #e5e5e5'
      : isHoveredColumn || isHoveredRow || isResizingColumn
      ? `2px dashed ${draggingBorderColor}`
      : undefined

    if (showResizeBorder) {
      return columnResizeDirection === 'ltr'
        ? { borderRight: borderStyle }
        : { borderLeft: borderStyle }
    }

    return borderStyle
      ? {
          borderBottom:
            isDraggingRow || isHoveredRow || (isLastRow && !isResizingColumn)
              ? borderStyle
              : undefined,
          borderLeft:
            isDraggingColumn ||
            isHoveredColumn ||
            ((isDraggingRow || isHoveredRow) && isFirstColumn)
              ? borderStyle
              : undefined,
          borderRight:
            isDraggingColumn ||
            isHoveredColumn ||
            ((isDraggingRow || isHoveredRow) && isLastColumn)
              ? borderStyle
              : undefined,
          borderTop: isDraggingRow || isHoveredRow ? borderStyle : undefined,
        }
      : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnSizingInfo.isResizingColumn,
    draggingColumn,
    draggingRow,
    hoveredColumn,
    hoveredRow,
    staticRowIndex,
  ])

  const isColumnPinned =
    enableColumnPinning &&
    columnDef.columnDefType !== 'group' &&
    column.getIsPinned()

  const isEditable = isCellEditable({ cell, table })

  const isEditing =
    isEditable &&
    !['custom', 'modal'].includes(editDisplayMode as string) &&
    (editDisplayMode === 'table' ||
      editingRow?.id === row.id ||
      editingCell?.id === cell.id) &&
    !row.getIsGrouped()

  const isCreating =
    isEditable && createDisplayMode === 'row' && creatingRow?.id === row.id

  const showClickToCopyButton =
    (parseFromValuesOrFunc(enableClickToCopy, cell) === true ||
      parseFromValuesOrFunc(columnDef.enableClickToCopy, cell) === true) &&
    !['context-menu', false].includes(
      // @ts-ignore
      parseFromValuesOrFunc(columnDef.enableClickToCopy, cell),
    )

  const isRightClickable = parseFromValuesOrFunc(enableCellActions, cell)

  const cellValueProps = {
    cell,
    table,
  }

  const handleDoubleClick = () => {
    openEditingCell({ cell, table })
  }

  const handleDragEnter = () => {
    // if (enableGrouping && hoveredColumn?.id === 'drop-zone') {
    //   setHoveredColumn(null)
    // }
    if (enableColumnOrdering && draggingColumn) {
      setHoveredColumn(columnDef.enableColumnOrdering !== false ? column : null)
    }
  }

  const handleDragOver = (e: DragEvent) => {
    if (columnDef.enableColumnOrdering !== false) {
      e.preventDefault()
    }
  }

  const handleContextMenu = (e: MouseEvent<HTMLTableCellElement>) => {
    if (isRightClickable) {
      e.preventDefault()
      table.setActionCell(cell)
      table.refs.actionCellRef.current = e.currentTarget
    }
  }

  const isPinned = column?.getIsPinned()

  const canEdit =
    (editDisplayMode === 'cell' && isEditable) ||
    (editDisplayMode === 'table' && (isCreating || isEditing))

  return (
    <ToolTipOverlay
      content={cellPropTypes.error}
      placement='left'
      allowToShow={hasError}
    >
      <td
        // align={theme.direction === "rtl" ? "right" : "left"}
        data-index={staticColumnIndex}
        data-pinned={!!isColumnPinned || undefined}
        {...(tableCellProps as any)}
        onContextMenu={handleContextMenu}
        onDoubleClick={handleDoubleClick}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        className={clsx(
          'RTTableBodyCell__container',
          columnDef.align,
          className,
          cellClassName,
          {
            canEdit,
            hasError,
          },
        )}
        style={{
          alignItems: layoutMode?.startsWith('grid') ? 'center' : undefined,
          cursor:
            isRightClickable &&
            actionCell?.column?.id !== ERTDisplayColumnId.actions
              ? 'context-menu'
              : isEditable && editDisplayMode === 'cell'
              ? 'pointer'
              : 'inherit',
          outline:
            actionCell?.id === cell.id &&
            actionCell?.column?.id !== ERTDisplayColumnId.actions
              ? '1px solid #e5e5e5'
              : undefined,
          outlineOffset: '-1px',
          overflow: 'hidden',
          padding:
            density === 'compact'
              ? columnDefType === 'display'
                ? '0 0.5rem'
                : '0.5rem'
              : density === 'comfortable'
              ? columnDefType === 'display'
                ? '0.5rem 0.75rem'
                : '1rem'
              : columnDefType === 'display'
              ? '1rem 1.25rem'
              : '1.5rem',

          boxShadow: column
            ? isPinned === 'left' && column.getIsLastColumn(isPinned)
              ? '-4px 0 4px -4px #e5e5e5 inset'
              : isPinned === 'right' && column.getIsFirstColumn(isPinned)
              ? '4px 0 4px -4px #e5e5e5 inset'
              : undefined
            : undefined,

          textOverflow: columnDefType !== 'display' ? 'ellipsis' : undefined,
          whiteSpace:
            row.getIsPinned() || density === 'compact' ? 'nowrap' : 'normal',
          ...getCommonMRTCellStyles({
            column,
            table,
            tableCellProps,
          }),
          ...draggingBorders,
        }}
      >
        {tableCellProps.children ?? (
          <>
            {cell.getIsPlaceholder() ? (
              columnDef.PlaceholderCell?.({ cell, column, row, table }) ?? null
            ) : showSkeletons !== false && (isLoading || showSkeletons) ? (
              <Skeleton height={20} width={skeletonWidth} />
            ) : columnDefType === 'display' &&
              ([
                ERTDisplayColumnId.expand,
                ERTDisplayColumnId.numbers,
                ERTDisplayColumnId.select,
              ].includes(column.id as ERTDisplayColumnId) ||
                !row.getIsGrouped()) ? (
              columnDef.Cell?.({
                cell,
                column,
                renderedCellValue: cell.renderValue() as any,
                row,
                rowRef,
                staticColumnIndex,
                staticRowIndex,
                table,
              })
            ) : isCreating || isEditing ? (
              <RTEditCellField cell={cell} table={table} />
            ) : showClickToCopyButton &&
              columnDef.enableClickToCopy !== false ? (
              // <RTCopyButton cell={cell} table={table}>
              //   <RTTableBodyCellValue {...cellValueProps} />
              // </RTCopyButton>
              <RTTableBodyCellValue {...cellValueProps} />
            ) : (
              <RTTableBodyCellValue {...cellValueProps} />
            )}
            {cell.getIsGrouped() && !columnDef.GroupedCell && (
              <> ({row.subRows?.length})</>
            )}
          </>
        )}
      </td>
    </ToolTipOverlay>
  )
}

export const MemoRTTableBodyCell = memo(
  RTTableBodyCell,
  (prev, next) => next.cell === prev.cell,
) as typeof RTTableBodyCell

export default RTTableBodyCell

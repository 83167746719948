const buildObjWithValue = (path, value = '') => {
  const paths = path.split('.')
  return paths.reduceRight(
    (acc, item, index) => ({
      [item]: index === paths.length - 1 ? value : acc,
    }),
    {},
  )
}

export default buildObjWithValue

import {
  ClockIcon,
  ColorPaletteIcon,
  DeleteIcon,
  DriverCard,
  DropdownWithCustomChildren,
  LocationIcon,
  ToolTipOverlay,
  TruckIcon,
  UserPlusIcon,
} from '~/components/shared'

import type { INewStartTime } from '../StartTimeForm'
import { ICommonOption } from '~/types/models/ICommonModel'
import { Button, ButtonGroup } from 'react-bootstrap'
import { useQueryTerminals, useQueryUsers } from '~/hooks/useQueryData'
import { START_TIME_COLOR_OPTIONS } from '~/utils/constants'
import { useCallback, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from '~/components/fleet/StartTimes/helpers'
import { IDriverFleet } from '~/types/models/IDriverFleet'

import './styles.scss'
import { NoteButton } from '~/components/fleet/StartTimes/StartTimesTable/NoteButton'
import { produce } from 'immer'
import { useMeasure } from 'react-use'
import buildFullName from '~/utils/buildFullName'

export interface ITimeSlotCardProps {
  startTime: INewStartTime
  timeSlotOptions: ICommonOption[]
  startTimesSelected: string[]
  assignedDriverFleetIds: number[]
  onUpdateTimeSlot: (
    newValue: any,
    time: string,
    field: keyof INewStartTime,
  ) => void
  onDeleteStartTime: (startTime: INewStartTime) => () => void
  onAssignDriver: (time: string, driverFleetId: number) => void
  onChangeDriverNotes: (
    startTime: INewStartTime,
    driverFleetId: number,
    notes: string,
  ) => void
}

function TimeSlotCard(props: ITimeSlotCardProps) {
  const {
    startTime,
    timeSlotOptions,
    startTimesSelected,
    assignedDriverFleetIds,
    onUpdateTimeSlot,
    onDeleteStartTime,
    onAssignDriver,
    onChangeDriverNotes,
  } = props

  const [measureRef, { width }] = useMeasure()

  const isSmallSection = useMemo(() => width <= 536, [width])

  const { buyerTerminalOptions, sellerTerminalOptions, findTerminalById } =
    useQueryTerminals()

  const { driverFleetUsers } = useQueryUsers()

  const driverFleetOptions = useMemo(
    () =>
      driverFleetUsers.map(({ driverFleet, person }) => ({
        value: driverFleet?.id,
        label: buildFullName(person),
      })),
    [driverFleetUsers],
  )

  const findColorByValue = useCallback((value: string | undefined) => {
    const find = START_TIME_COLOR_OPTIONS.find(({ value: v }) => v === value)
    return find
  }, [])

  const [droppingParams, drop] = useDrop<any>(
    () =>
      ({
        accept: ItemTypes.DRIVER,
        drop: ({ driverFleet }: { driverFleet: IDriverFleet }) => {
          onAssignDriver(startTime.time, driverFleet.id)
        },
        collect: (monitor: any) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
      } as any),
    [],
  )

  const border = useMemo(() => {
    const { canDrop, isOver } = droppingParams as any
    const isActive = canDrop && isOver
    if (isActive) {
      return '1px dashed #2dd36f'
    }
    if (canDrop) {
      return '1px dashed var(--ion-color-success)'
    }

    return undefined
  }, [droppingParams])

  return (
    <div
      key={startTime.time}
      style={{
        border,
        // background: startTime.color
      }}
      className='TimeSlotCard__container'
      ref={node => {
        drop(node)
        measureRef(node as any)
      }}
    >
      <div className='leftColorBadge' style={{ background: startTime.color }} />
      <div style={{ marginLeft: '12px' }}>
        <ButtonGroup style={{ marginRight: 8 }}>
          <DropdownWithCustomChildren
            options={timeSlotOptions}
            className='StartTimeForm__dropdown timeCreateButton'
            onChange={(event, { value }) => {
              onUpdateTimeSlot(value, startTime.time, 'time')
            }}
            isOptionDisabled={option =>
              startTimesSelected.includes(option.value)
            }
          >
            <Button
              variant='primary'
              className='StartTimeForm__button'
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <ClockIcon color='white' />
              <span className='StartTimeForm__buttonLabel'>
                {startTime.time}
              </span>
            </Button>
          </DropdownWithCustomChildren>

          <DropdownWithCustomChildren
            options={START_TIME_COLOR_OPTIONS}
            className='StartTimeForm__dropdown'
            onChange={(event, { value }) => {
              onUpdateTimeSlot(value, startTime.time, 'color')
            }}
            value={startTime.color}
          >
            <Button
              variant='secondary'
              className='StartTimeForm__button'
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                background: startTime.color,
                borderColor: startTime.color,
              }}
            >
              <ColorPaletteIcon color='white' />
              <span className='StartTimeForm__buttonLabel'>
                {findColorByValue(startTime.color)?.label}
              </span>
            </Button>
          </DropdownWithCustomChildren>
        </ButtonGroup>

        <ButtonGroup style={{ marginRight: 8 }}>
          <DropdownWithCustomChildren
            options={driverFleetOptions}
            className='StartTimeForm__dropdown'
            isOptionDisabled={opt => assignedDriverFleetIds.includes(opt.value)}
            onChange={(event, { value }) => {
              const newDriverFleets = produce(startTime.driverFleets, draft => {
                value.forEach((id: number) => {
                  const find = draft.find(df => df.id === id)
                  if (!find) {
                    draft.push({
                      id,
                      notes: '',
                    })
                  }
                })
              })
              onUpdateTimeSlot(newDriverFleets, startTime.time, 'driverFleets')
            }}
            value={startTime.driverFleets.map(({ id }) => id)}
            isMulti
          >
            <Button className='StartTimeForm__button'>
              <UserPlusIcon color='white' />
            </Button>
          </DropdownWithCustomChildren>
        </ButtonGroup>

        <ButtonGroup style={{ marginTop: isSmallSection ? 8 : 0 }}>
          <NoteButton
            note={startTime.notes}
            onSave={notes => {
              onUpdateTimeSlot(notes, startTime.time, 'notes')
            }}
          />
          <DropdownWithCustomChildren
            options={buyerTerminalOptions}
            className='StartTimeForm__dropdown'
            onChange={(event, { value }) => {
              onUpdateTimeSlot(value, startTime.time, 'terminalId')
            }}
            value={startTime.terminalId}
          >
            <ToolTipOverlay
              placement='top'
              content='Clockin and Truck Pickup Location'
            >
              <Button
                variant='secondary'
                style={{
                  height: 29,
                  borderRadius: 0,
                }}
              >
                <TruckIcon color='white' />
                <span className='StartTimeForm__buttonLabel'>
                  {findTerminalById(startTime.terminalId as number)?.name ||
                    'Truck pickup terminal'}
                </span>
              </Button>
            </ToolTipOverlay>
          </DropdownWithCustomChildren>

          <DropdownWithCustomChildren
            options={sellerTerminalOptions}
            className='StartTimeForm__dropdown'
            onChange={(event, { value }) => {
              onUpdateTimeSlot(value, startTime.time, 'sellerTerminalId')
            }}
            value={startTime.sellerTerminalId}
          >
            <ToolTipOverlay
              placement='top'
              content='Seller Terminal for first Load Pickup'
            >
              <Button
                variant='secondary'
                className='StartTimeForm__button'
                style={{
                  height: 29,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <LocationIcon color='white' />
                <span className='StartTimeForm__buttonLabel'>
                  {findTerminalById(startTime.sellerTerminalId as number)
                    ?.name || 'First load terminal'}
                </span>
              </Button>
            </ToolTipOverlay>
          </DropdownWithCustomChildren>
        </ButtonGroup>

        <Button
          variant='danger'
          style={{
            marginLeft: 8,
            height: 29,
            marginTop: isSmallSection ? 8 : 0,
          }}
          onClick={onDeleteStartTime(startTime)}
        >
          <DeleteIcon color='white' />
        </Button>
      </div>
      {startTime.driverFleets.length > 0 && (
        <div style={{ marginLeft: 12, marginTop: 12 }}>
          {startTime.driverFleets.map((driverFleet, index) => (
            <div
              key={driverFleet.id}
              style={{
                marginRight: 12,
                marginBottom: 8,
                display: 'inline-block',
              }}
            >
              <DriverCard
                driverFleetId={driverFleet.id}
                isDraggable={false}
                isCloseable
                isHiddenStatusTime
                isHiddenNotesButton={false}
                isShowingDriverName
                isDriverLeft
                index={index + 1}
                extraIndexTooltipContent={
                  <div>
                    <span>
                      Count:{' '}
                      {assignedDriverFleetIds.indexOf(driverFleet.id) + 1}
                    </span>{' '}
                    / <span>{assignedDriverFleetIds.length}</span>
                  </div>
                }
                indexBadgeStyle={{
                  backgroundColor: startTime.color,
                  color: 'white',
                }}
                onChangeNotes={(_df, notes) => {
                  onChangeDriverNotes(startTime, driverFleet.id, notes)
                }}
                onClose={() => {
                  onUpdateTimeSlot(
                    produce(startTime.driverFleets, draft => {
                      draft.splice(index, 1)
                    }),
                    startTime.time,
                    'driverFleets',
                  )
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default TimeSlotCard

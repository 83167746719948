import React, { useCallback, useMemo, useRef } from 'react'

import { Form } from 'react-bootstrap'
import { ConcordFormLayout } from '~/components/shared'
import ReactSelect, { ActionMeta, PropsValue, SingleValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import _ from 'lodash'

import type {
  IConcordFormDropdownOption,
  IConcordFormDropdownProps,
} from './type'

import './styles.scss'

function ConcordFormDropdownV2<T extends IConcordFormDropdownOption>(
  props: IConcordFormDropdownProps<T>,
) {
  const {
    options,
    onChange,
    label,
    error,
    value,
    name,
    className,
    isLoading,
    isDisabled,
    isRequired,
    getOptionLabel = ({ label }) => label || '',
    isHiddenCreateIcon,
    onClickCreateIcon,
    isOptionDisabled,
    placeholder = '',
    extraIcons,
    hint,
    isReadOnly,
    isMulti,
    formatOptionLabel,
    isCreatable,
    ...dropdownProps
  } = props

  const inputRef = useRef()

  // eslint-disable-next-line @typescript-eslint/ban-types
  const selectedOption = useMemo<T | {} | T['value']>(() => {
    try {
      if (_.isPlainObject(value)) {
        return value
      }

      if (_.isNil(value)) {
        return null
      }

      if (_.isNumber(value) || _.isString(value) || _.isBoolean(value)) {
        if (options.length === 0) {
          return {
            label: value,
            value,
          }
        }
        const result = options.find(option => option.value === value) || {
          value,
          label: value,
        }
        return result
      }
      if (_.isArray(value)) {
        return value.map(val => {
          const result = options.find(option => option.value === val) || {
            value: val,
            label: val,
          }
          return result
        })
      }

      return {}
    } catch (error) {
      console.log('error', error)
      return {}
    }
  }, [options, value])

  const handleSelectOption = useCallback(
    (params: SingleValue<T>, event: ActionMeta<T>) => {
      const { name } = event
      onChange &&
        onChange(event, {
          value: isMulti
            ? (params as any).map(({ value }: any) => value)
            : params?.value,
          name,
          selectedOption: params as T,
        } as any)
    },
    [isMulti, onChange],
  )

  const renderError = useMemo(() => {
    if (error) {
      return <Form.Text>{error}</Form.Text>
    }

    return null
  }, [error])

  const isDisabledStyles = useMemo(() => {
    if (isDisabled) {
      return {
        opacity: 0.3,
        userSelect: 'none',
        pointerEvents: 'none',
      }
    }
    if (isReadOnly) {
      return {
        userSelect: 'none',
        pointerEvents: 'none',
        borderColor: '#f4f4f4',
      }
    }
    return {}
  }, [isDisabled, isReadOnly])

  const SelectComponent = isCreatable ? CreatableSelect : ReactSelect

  return (
    <ConcordFormLayout
      label={label}
      error={error}
      className={className}
      isDisabled={isLoading}
      isRequired={isRequired}
      isLoading={isLoading}
      extraIcons={extraIcons}
      isHiddenCreateIcon={isHiddenCreateIcon}
      onClickCreateIcon={onClickCreateIcon}
      hint={hint}
    >
      <SelectComponent
        {...dropdownProps}
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        options={options}
        menuPortalTarget={document.body}
        menuPosition='fixed'
        classNamePrefix='ConcordFormDropdownV2'
        isMulti={isMulti as any}
        ref={inputRef as any}
        name={name}
        value={selectedOption as PropsValue<T>}
        onChange={handleSelectOption}
        isOptionDisabled={isOptionDisabled as any}
        formatOptionLabel={formatOptionLabel}
        styles={{
          control: provided => ({
            ...provided,
            borderColor: error ? 'red !important' : provided.borderColor,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(isDisabledStyles as any),
          }),
          menuPortal: provided => ({
            ...provided,
            zIndex: 999999,
          }),
          indicatorsContainer: provided => ({
            ...provided,
            display: isReadOnly ? 'none' : 'flex',
          }),
          option: (provided, state: any) => {
            return {
              ...provided,
              backgroundColor: state.data.__isNew__
                ? 'var(--ion-color-primary)'
                : undefined,
              color: state.data.__isNew__ ? 'white' : 'black',
              '&:hover': {
                backgroundColor: '#e5e5e5',
                color: 'black',
              },
            }
          },
          ...props.styles,
        }}
      />
      {renderError as JSX.Element}
    </ConcordFormLayout>
  )
}

export default ConcordFormDropdownV2

import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { IGetOffdaysParams, IOffday } from '~/types/models/IOffday'

const useModifyOffdays = (params: IGetOffdaysParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'offdays',
      sessionUser?.id,
      buildGetUrl(apiClient.offdays.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addOffday = useCallback(
    (data: IOffday) => {
      queryClient.setQueryData<IOffday[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [...oldData, data]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateOffday = useCallback(
    (id: number, data: IOffday) => {
      queryClient.setQueryData<IOffday[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeOffday = useCallback(
    (id: number) => {
      queryClient.setQueryData<IOffday[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return { addOffday, updateOffday, removeOffday }
}

export default useModifyOffdays

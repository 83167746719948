import { useWindowSize } from 'react-use'

import { isMobileApp, isMobileWebApp } from '~/utils/getCurrentPlatform'
import { MOBILE_BREAKPOINT } from '~/utils/constants'

export const useIsMobile = () => {
  const windowSize = useWindowSize()

  return (
    windowSize.width <= MOBILE_BREAKPOINT || isMobileApp() || isMobileWebApp()
  )
}

import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Unless, When } from 'react-if'
import Skeleton from 'react-loading-skeleton'
import { IonIcon } from '@ionic/react'

import type { IToggleSectionProps } from './type'

import './styles.scss'
import { caretDown, caretForward } from 'ionicons/icons'
import { Badge } from 'react-bootstrap'
import clsx from 'clsx'

function ToggleSection(props: PropsWithChildren<IToggleSectionProps>) {
  const {
    children,
    label,
    value,
    isLoading,
    badges = [],
    iconButtons = [],
    onChange,
    isOpenByDefault,
    className,
  } = props

  const [isShow, setIsShow] = useState(false)

  const isOpen = useMemo(() => {
    if (typeof value === 'boolean') {
      return value
    }
    return isShow
  }, [isShow, value])

  const renderIconButtons = useMemo(
    () =>
      iconButtons
        .filter(({ isHidden }) => !isHidden)
        .map((iconProps, index) => (
          <span
            className='ToggleSection__icon icon'
            onClick={iconProps.onClick}
            key={index}
          >
            <IonIcon
              color={iconProps.color}
              icon={iconProps.icon}
              size={iconProps.size}
            />
          </span>
        )),
    [iconButtons],
  )

  const renderBadges = useMemo(
    () =>
      badges
        .filter(({ isHidden }) => !isHidden)
        .map((badgeProps, index) => (
          <Badge key={index} bg={badgeProps.color} style={{ marginLeft: 4 }}>
            {badgeProps.label}
          </Badge>
        )),
    [badges],
  )

  const onToggleShowHide = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const nextValue = !isOpen
      setIsShow(nextValue)
      onChange && onChange(event, nextValue)
    },
    [isOpen, onChange],
  )

  useEffect(() => {
    if (typeof isOpenByDefault === 'boolean') {
      setIsShow(isOpenByDefault)
    }
  }, [isOpenByDefault])

  return (
    <div className={clsx('ToggleSection__container', className)}>
      <div className='headerToggleContainer'>
        <div className='label'>
          <span className='labelIcon'>
            <IonIcon icon={isOpen ? caretDown : caretForward} />
          </span>
          <span className='clickable' onClick={onToggleShowHide}>
            {label}
          </span>
          {renderIconButtons}
          {renderBadges}
        </div>
      </div>
      <When condition={isOpen}>
        <div className='bodyContainer'>
          <Unless condition={isLoading}>{children}</Unless>
        </div>
        <When condition={isLoading}>
          <Skeleton height={14} width='100%' className='mb-2' />
          <Skeleton height={14} width='100%' className='mb-2' />
          <Skeleton height={14} width='100%' className='mb-2' />
          <Skeleton height={14} width='100%' className='mb-2' />
        </When>
      </When>
    </div>
  )
}
export default ToggleSection

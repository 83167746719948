import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { CheckMarkIcon, CloseIcon } from '~/components/shared'

import './styles.scss'

interface TextToggleInputProps {
  value: string
  label?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: (...event: any[]) => void
  inputProps?: HTMLInputElement
}

export const TextToggleInput = ({
  value,
  label,
  handleSubmit,
  ...inputProps
}: TextToggleInputProps) => {
  const [show, setShow] = useState<boolean>(false)
  const [newValue, setNewValue] = useState<string>(value)
  const [loading, setLoading] = useState<boolean>(false)

  const revert = () => {
    if (value) setShow(false)
    setNewValue(value)
  }

  const submit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setLoading(true)
    try {
      handleSubmit(newValue)
      setShow(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!value) setShow(true)
    else setShow(false)
  }, [value])

  return (
    <div>
      {!show && (
        <div
          onClick={event => {
            event.stopPropagation()
            setShow(!show)
          }}
        >
          {label}
          {value}
        </div>
      )}
      {show && (
        <div className='d-flex flex-row gap-1 align-items-center ms-0'>
          <input
            value={newValue}
            onChange={e => setNewValue(e.target.value)}
            className='cell-input'
            onClick={event => event.stopPropagation()}
            {...inputProps}
          />

          {loading && <Spinner animation='border' />}
          {!loading && (
            <div className='d-flex flex-column'>
              {!(value === newValue) && (
                <button
                  onClick={submit}
                  className='bg-transparent'
                  onBlur={revert}
                >
                  <CheckMarkIcon color='#2dd36f' />
                </button>
              )}
              {value && (
                <button
                  onClick={event => {
                    event.stopPropagation()
                    revert()
                  }}
                  className='bg-transparent'
                >
                  <CloseIcon color='red' />
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

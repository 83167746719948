import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useUpdateEffect } from 'react-use'

import _ from 'lodash'
import {
  selectTruckFleetsFullList,
  selectDriverFleetsList,
} from '~/redux/selectors'

import { ITruckInfoBoxProps } from './type'
import { ITruckFleet } from '~/types/models/ITruckFleet'
import { IDriverFleet } from '~/types/models/IDriverFleet'

const useTruckInfoBox = (props: ITruckInfoBoxProps) => {
  const { truck, onClick } = props

  const [isOpenInfoBox, setIsOpenInfoBox] = useState(
    props.isOpenInfoBox || false,
  )
  const [isShowDriverInfo, setIsShowDriverInfo] = useState(false)

  const truckFleets: ITruckFleet[] = useSelector(selectTruckFleetsFullList)
  const driverFleets: IDriverFleet[] = useSelector(selectDriverFleetsList)

  const truckLocation = useMemo(() => {
    if (truck.location) {
      return new google.maps.LatLng(
        Number(truck.location.lat),
        Number(truck.location.lng),
      )
    }

    return null
  }, [truck.location])

  const truckDetails = useMemo(() => {
    const details = [truck?.year, truck?.make, truck?.model, truck?.bed]

    return details.filter(value => value).join(' ')
  }, [truck?.bed, truck?.make, truck?.model, truck?.year])

  const driverFleetOfTruck = useMemo(() => {
    const truckFleet = truckFleets.find(({ truckId }) => truck.id === truckId)
    const driverFleet = driverFleets.find(
      ({ currentTruckFleetId, defaultTruckFleetId }) =>
        truckFleet?.id === defaultTruckFleetId ||
        truckFleet?.id === currentTruckFleetId,
    )

    if (driverFleet) {
      return {
        ...driverFleet,
        loadId: 93413,
      }
    }

    return driverFleet || null
  }, [driverFleets, truck.id, truckFleets])

  const fields = useMemo(
    () => [
      {
        label: 'Name',
        name: 'name',
      },
      {
        label: 'Address',
        name: 'location.fullAddress',
      },
      {
        label: 'Axle weight',
        name: 'axleWeight',
      },
      {
        label: 'Bed type',
        name: 'bedType',
      },
      {
        label: 'Empty weight',
        name: 'emptyWeight',
      },
      {
        label: 'GPS interval',
        name: 'gpsInterval',
      },
      {
        label: 'Height',
        name: 'height',
      },
      {
        label: 'License plate',
        name: 'licensePlate',
      },
      {
        label: 'Max qty',
        name: 'maxQty',
      },
      {
        label: 'Total weight',
        name: 'totalWeight',
      },
      {
        label: 'Vehicle uid',
        name: 'vehicleUid',
      },
      {
        label: 'Vin',
        name: 'vin',
      },
      {
        label: 'Weight allowance',
        name: 'weightAllowance',
      },
    ],
    [],
  )

  const renderTruckInfo = useMemo(
    () =>
      fields
        .filter(({ name }) => _.get(truck, name))
        .map(({ label, name }) => (
          <div key={name} style={{ marginTop: 4 }}>
            <span style={{ fontWeight: 600 }}>{label}: </span>
            <span>{_.get(truck, name)}</span>
          </div>
        )),
    [fields, truck],
  )

  const onOpenTruckInfo = useCallback(() => {
    setIsOpenInfoBox(prev => !prev)
  }, [])

  const onCloseTruckInfo = useCallback(() => {
    setIsOpenInfoBox(false)
  }, [])

  const onToggleDriverInfo = useCallback(() => {
    setIsShowDriverInfo(prev => !prev)
  }, [])

  useUpdateEffect(() => {
    if (isOpenInfoBox) {
      onClick &&
        onClick({
          driverFleet: driverFleetOfTruck,
          truck,
          isOpenInfoBox: true,
        })
    } else {
      onClick &&
        onClick({
          driverFleet: null,
          truck: null,
          isOpenInfoBox: false,
        })
    }
  }, [isOpenInfoBox])

  return {
    truck,
    truckLocation,
    isOpenInfoBox,
    renderTruckInfo,
    truckDetails,
    driverFleetOfTruck,
    isShowDriverInfo,
    onOpenTruckInfo,
    onCloseTruckInfo,
    onToggleDriverInfo,
  }
}

export default useTruckInfoBox

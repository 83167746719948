import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFormatDateToTz } from '~/hooks/useFormatDateToTz'

import moment from 'moment'
import {
  selectSellerTerminalOptions,
  selectCommonFleetOptions,
} from '~/redux/selectors'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { apiClient } from '~/api/ApiClient'

import type { IScheduleLoad } from '~/types/models/IScheduleLoad'
import type { ITerminal } from '~/types/models/ITerminal'
import type { ICommonOption } from '~/types/models/ICommonModel'
import type { IScheduleLoadsTableProps } from './type'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { IRTColumnDef, RTCell } from '../ReusableTable'

const useScheduleLoadsTable = (props: IScheduleLoadsTableProps) => {
  const {
    scheduleLoads,
    tableHeight,
    state,
    extraColumns = [],
    afterUpdate,
    enableTopToolbar,
    manualFiltering,
    filterOptions,
    onColumnFiltersChange,
  } = props

  const sellerTerminalOptions: ICommonOption[] = useSelector(
    selectSellerTerminalOptions,
  )
  const fleetOptions: ICommonOption[] = useSelector(selectCommonFleetOptions)

  const { getDateFromISODateWithTz } = useFormatDateToTz()

  const columns = useMemo<IRTColumnDef<IScheduleLoad>[]>(
    () => [
      {
        header: 'Qty',
        accessorKey: 'qty',
        minSize: 80,
        maxSize: 80,
      },
      {
        header: 'Ticket time',
        accessorKey: 'ticketTime',
        minSize: 120,
        maxSize: 120,
        enableEditing: true,
        editVariant: EFieldType.time,
      },
      {
        header: 'Start terminal',
        accessorKey: 'beginSellerTerminalId',
        enableEditing: true,
        editSelectOptions: sellerTerminalOptions,
        editVariant: EFieldType.singleSelect,
        editDropdownFieldProps() {
          return {
            formatOptionLabel: (option: ITerminal) => option.name,
          }
        },
      },
      {
        header: 'At job',
        accessorKey: 'arriveBuyerTime',
        enableEditing: true,
        editVariant: EFieldType.time,
      },
      {
        header: 'Back',
        accessorKey: 'arriveSellerTime',
        enableEditing: true,
        editVariant: EFieldType.time,
      },
      {
        header: 'Return Terminal',
        accessorKey: 'returnSellerTerminalId',
        enableEditing: true,
        editSelectOptions: sellerTerminalOptions,
        editVariant: EFieldType.singleSelect,
        editDropdownFieldProps() {
          return {
            formatOptionLabel: (option: ITerminal) => option.name,
          }
        },
      },
      {
        header: 'Total',
        accessorKey: 'totalTime',
        maxSize: 100,
        minSize: 100,
        Cell({ row }) {
          const { ticketTime, arriveSellerTime } = row.original
          const d1 = new Date(ticketTime).getTime()
          const d2 = new Date(arriveSellerTime).getTime()
          const minutes = Math.floor((d2 - d1) / 1000 / 60)
          return <div>{minutes} mins</div>
        },
      },
      {
        header: 'Fleet',
        accessorKey: 'fleetId',
        maxSize: 250,
        minSize: 250,
        enableEditing: true,
        editVariant: EFieldType.singleSelect,
        editSelectOptions: fleetOptions,
      },
      ...extraColumns,
    ],
    [extraColumns, fleetOptions, sellerTerminalOptions],
  )

  const timeDifference = useCallback(
    (
      startTimeString: string,
      endTimeString: string,
    ): { hours: number; minutes: number } => {
      const startTime = moment(getDateFromISODateWithTz(startTimeString))
        .format('HH:mm')
        .split(':')
      const endTime = endTimeString.split(':')

      const startTimeHours = Number(startTime[0])
      const startTimeMinutes = Number(startTime[1])

      const endTimeHours = Number(endTime[0])
      const endTimeMinutes = Number(endTime[1])

      const diffHours = endTimeHours - startTimeHours
      const diffMinutes = endTimeMinutes - startTimeMinutes

      return { hours: diffHours, minutes: diffMinutes }
    },
    [getDateFromISODateWithTz],
  )

  const onCellEditEnd = useCallback(
    async (value: any, cell: RTCell<IScheduleLoad>) => {
      const type = cell.column.columnDef.editVariant
      const { column, row } = cell
      const columnField = column.id
      const rowId = row.original.id
      const originalValue = (cell.row.original as any)[columnField]

      if (type === EFieldType.time) {
        const [hours, minutes] = value.split(':')
        value = moment(originalValue)
          .set({
            hours,
            minutes,
          })
          .toISOString()
      }
      const payload = {
        [columnField]: value,
      }
      if (columnField === 'ticketTime') {
        const { hours, minutes } = timeDifference(originalValue, value)
        delete payload.ticketTime

        payload.arriveBuyerTime = moment(row.original.arriveBuyerTime)
          .add({ hours, minutes })
          .toISOString()
      }
      const { errors, ...response } = await apiClient.scheduleLoads.update(
        rowId,
        {
          scheduleLoad: payload,
        },
      )
      if ((errors as string[]).length > 0) {
        toast.error(toastMessages.updateError)
      } else {
        toast.success(toastMessages.updateSuccess)
        afterUpdate && afterUpdate(response)
      }
    },
    [afterUpdate, timeDifference],
  )

  return {
    columns,
    onCellEditEnd,
    state,
    scheduleLoads,
    tableHeight,
    enableTopToolbar,
    manualFiltering,
    filterOptions,
    onColumnFiltersChange,
  }
}

export default useScheduleLoadsTable

import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQueryUserGroups } from '~/hooks/useQueryData'

import { ConcordFormStructure } from '~/components/shared'

import { FORM_FIELD_TYPE, REGEX_PHONE_NUMBERS } from '~/utils/constants'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'

const UserForm = props => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const [isLoading, setIsLoading] = useState(false)

  const currentCompany = useSelector(selectMyCurrentCompany)

  const { userGroupOptions } = useQueryUserGroups()

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const defaultValues = useMemo(
    () => ({
      email: '',
      phoneNumber: '',
      color: '',
      userGroupId: null,
      password: '',
      passwordConfirmation: '',
      userAccess: {
        userGroupId: null,
      },
    }),
    [],
  )

  const fields = useMemo(
    () => [
      {
        name: 'email',
        label: 'Email',
        autoComplete: false,
        isRequired: true,
        size: 6,
      },
      {
        name: 'phoneNumber',
        label: 'Phone Number',
        autoComplete: false,
        size: 6,
      },
      {
        name: 'userAccess.userGroupId',
        label: 'User Group',
        type: FORM_FIELD_TYPE.singleSelect,
        options: userGroupOptions,
        isRequired: true,
        size: 6,
      },
      {
        name: 'color',
        label: 'Color',
        type: FORM_FIELD_TYPE.color,
        size: 6,
      },
      {
        name: 'password',
        label: 'Password',
        autoComplete: 'new-password',
        isRequired: !isUpdating,
        type: FORM_FIELD_TYPE.password,
      },
      {
        name: 'passwordConfirmation',
        label: 'Password Confirmation',
        autoComplete: 'new-password',
        isRequired: !isUpdating,
        type: FORM_FIELD_TYPE.password,
      },
    ],
    [isUpdating, userGroupOptions],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        email: Yup.string().required('Email is required').email(),
        phoneNumber: Yup.string().test(
          'phoneNumber-regex',
          'Phone number is invalid',
          val => {
            if (val) {
              return REGEX_PHONE_NUMBERS.test(val)
            }

            return true
          },
        ),
        password: Yup.lazy(() => {
          if (isUpdating) {
            return Yup.string().test(
              'max-password',
              'Password is too short (minimum is 8 characters)',
              password => {
                if (password) {
                  return password.length >= 8
                }

                return true
              },
            )
          }

          return Yup.string()
            .required()
            .min(8, 'Password is too short (minimum is 8 characters)')
            .required('Password is required!')
        }),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Passwords must match',
        ),
      }),
    [isUpdating],
  )

  const createCompanyUser = useCallback(
    async formValues => {
      const { user } = await apiClient.user.create(formValues)
      if (user.errors?.length) {
        toast.error(user.errors[0])
      } else {
        const { userAccess, errors } = await apiClient.userAccesses.create({
          companyId: currentCompany.id,
          status: 'Active',
          default: 'Yes',
          lastUsed: 'Yes',
          userGroupId: formValues.userAccess.userGroupId,
          userId: user.id,
        })
        if (errors?.length) {
          toast.error(errors[0])
        } else {
          const newUser = {
            ...user,
            userAccess,
          }
          afterCreate && afterCreate(newUser)
        }
      }
    },
    [afterCreate, currentCompany.id],
  )

  const updateCompanyUser = useCallback(
    async formValues => {
      const {
        userAccess,
        email,
        phoneNumber,
        password,
        passwordConfirmation,
        color,
      } = formValues
      const { userGroupId, id: userAccessId } = userAccess
      const payload = {
        email,
        phoneNumber,
        color,
      }
      if (isUpdating && password) {
        payload.password = password
        payload.passwordConfirmation = passwordConfirmation
      }
      const [updateUserResp, updateUserAccessResp] = await Promise.all([
        apiClient.users.update(formData?.id, { user: payload }),
        apiClient.userAccesses.update(userAccessId, { userGroupId }),
      ])
      if (updateUserResp.errors?.length > 0) {
        toast.error(updateUserResp.errors[0])
      } else {
        const updatedUser = {
          ...updateUserResp.user,
          userAccess: updateUserAccessResp.userAccess,
        }
        afterUpdate && afterUpdate(updatedUser)
        toast.success(toastMessages.updateSuccess)
      }
    },
    [afterUpdate, isUpdating, formData?.id],
  )

  const onSubmit = useCallback(
    async formValues => {
      setIsLoading(true)
      try {
        if (isUpdating) {
          await updateCompanyUser(formValues)
        } else {
          await createCompanyUser(formValues)
        }
      } catch (error) {
        const { errors } = error
        if (errors?.fullMessages?.length > 0) {
          toast.error(errors?.fullMessages[0])
        } else {
          toast.error('Error happens!')
        }
      } finally {
        setIsLoading(false)
      }
    },
    [createCompanyUser, isUpdating, updateCompanyUser],
  )

  return (
    <ConcordFormStructure
      {...formProps}
      formData={formData}
      isLoading={isLoading}
      onSubmit={onSubmit}
      fields={fields}
      schema={schema}
      defaultValues={defaultValues}
      submitText={isUpdating ? 'Update' : 'Create'}
    />
  )
}

export default UserForm

import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'
import {
  IGetProductTaxOverridesParams,
  IProductTaxOverride,
} from '~/types/models/IProductTaxOverride'

const useModifyProductTaxOverrides = (
  params: Partial<IGetProductTaxOverridesParams> = {},
) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'productTaxOverrides',
      sessionUser?.id,
      buildGetUrl(apiClient.productTaxOverrides.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addProductTaxOverride = useCallback(
    (data: IProductTaxOverride) => {
      queryClient.setQueryData<IProductTaxOverride[] | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [...oldData, data]
          }
          return [data]
        },
      )
    },
    [queryClient, queryKey],
  )

  const updateProductTaxOverride = useCallback(
    (id: number, data: IProductTaxOverride) => {
      queryClient.setQueryData<IProductTaxOverride[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(item => item.id === id)
              if (draft[index]) {
                draft[index] = data
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const removeProductTaxOverride = useCallback(
    (id: number) => {
      queryClient.setQueryData<IProductTaxOverride[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(item => item.id === id)
              if (draft[index]) {
                draft.splice(index, 1)
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addProductTaxOverride,
    updateProductTaxOverride,
    removeProductTaxOverride,
  }
}

export default useModifyProductTaxOverrides

import React from 'react'
import { useCompanyAvatar } from './useCompanyAvatar'

import { IonImg, IonPopover } from '@ionic/react'
import { ToolTipOverlay } from '~/components/shared'

import clsx from 'clsx'
import { EScope } from '~/types/enums/ECommonEnum'

import type { ICompanyAvatarProps } from './type'

import './styles.scss'

const CompanyAvatar: React.FC<ICompanyAvatarProps> = props => {
  const {
    company,
    className,
    tooltipMessage,
    tooltipProps,
    showImagePopup,
    ...containerProps
  } = props

  const { companyAvatarRendered, showPopup, handleCloseImagePopup } =
    useCompanyAvatar(props)

  return (
    <>
      <ToolTipOverlay content={tooltipMessage} {...tooltipProps}>
        <div
          {...containerProps}
          className={clsx('CompanyAvatar__container', className)}
        >
          {companyAvatarRendered}
        </div>
      </ToolTipOverlay>
      {showImagePopup && (
        <IonPopover
          mode='ios'
          className='imagePreviewPopup'
          isOpen={showPopup}
          onDidDismiss={handleCloseImagePopup}
        >
          <IonImg alt='popover' src={company?.image} />
        </IonPopover>
      )}
    </>
  )
}

CompanyAvatar.defaultProps = {
  tooltipMessage: '',
  companyType: EScope.buyer,
  showImagePopup: false,
  width: 35,
  height: 35,
  company: {
    value: null,
    label: '',
    image: '',
    name: '',
  },
}

export default React.memo(CompanyAvatar)

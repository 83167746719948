import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyPayment from './useModifyPayment'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IGetPaymentsParams, IPayment } from '~/types/models/IPayment'

const useQueryPayments = (
  query: IGetPaymentsParams,
  options?: Partial<UseQueryOptions<IPayment[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { addPayment, updatePayment, removePayment } = useModifyPayment(query)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['payments', sessionUser?.id, buildGetUrl('/payments', query)],
    async queryFn() {
      const response = await apiClient.payments.get(query)
      return response.payments
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // one minute
    ...options,
  })

  const paymentsData = useMemo(() => data || [], [data])

  return {
    paymentsData,
    isLoadingPaymentsData: isLoading,
    refetchPaymentsData: refetch,
    addPayment,
    updatePayment,
    removePayment,
  }
}

export default useQueryPayments

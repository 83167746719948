import moment from 'moment'

function isDateInRange(date, startTime, endTime) {
  const momentDate = moment(date)
  const momentStartTime = moment(date).set({
    hours: startTime.split(':')[0],
    minutes: startTime.split(':')[1],
  })

  const momentEndTime = moment(date).set({
    hours: endTime.split(':')[0],
    minutes: endTime.split(':')[1],
  })

  // Check if the date is between the start time and end time, inclusive
  return momentDate.isBetween(momentStartTime, momentEndTime, null, '[]')
}

export default isDateInRange

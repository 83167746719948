import type {
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'

import { usePagination } from 'pagination-react-js'

import './styles.scss'
import clsx from 'clsx'
import { useEffect } from 'react'
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  DoubleArrowBackIcon,
  DoubleArrowForwardIcon,
} from '~/components/shared'

const defaultRowsPerPage = [5, 10, 24, 50, 100]

export interface IRTTablePaginationProps<
  TData extends IReusableTableRowData,
> extends Partial<{
    SelectProps?: any
    disabled?: boolean
    rowsPerPageOptions?: { label: string; value: number }[] | number[]
    showRowsPerPage?: boolean
  }> {
  table: ReusableTableInstance<TData>
  showFirstButton?: boolean
  showLastButton?: boolean
}

const PaginationItem = ({
  children,
  label,
  isActive,
  onClick,
  isDisabled,
  rel,
  isThreedot,
}: any) => {
  return (
    <li
      className={clsx('RTTablePagination__paginationItem', {
        isActive,
        isDisabled,
        isThreedot,
      })}
      aria-current={isActive ?? 'page'}
      aria-label={label}
      rel={rel}
      onClick={onClick}
    >
      {children}
    </li>
  )
}

const RTTablePagination = <TData extends IReusableTableRowData>({
  table,
  ...rest
}: IRTTablePaginationProps<TData>) => {
  const isMobile = false

  const {
    pagination: { pageIndex = 0, pageSize = 50 },
  } = table.getState()

  const { data, showPaginationPages, showPerPageOptions, rowCount } =
    table.options

  const { pageNumbers, setActivePage, setRecordsPerPage } = usePagination({
    activePage: 1,
    recordsPerPage: 5,
    totalRecordsLength: typeof rowCount === 'number' ? rowCount : data.length,
    offset: 2,
    navCustomPageSteps: { prev: 2, next: 3 },
    permanentFirstNumber: true,
    permanentLastNumber: true,
  })

  const totalRowCount = table.getRowCount()
  const lastRowIndex = Math.min(pageIndex * pageSize + pageSize, totalRowCount)

  const {
    SelectProps = {},
    disabled = false,
    rowsPerPageOptions = defaultRowsPerPage,
  } = rest ?? {}

  const disableBack = pageIndex <= 0 || disabled
  const disableNext = lastRowIndex >= totalRowCount || disabled

  if (isMobile && SelectProps?.native !== false) {
    SelectProps.native = true
  }

  const onMovePageTo = (page: number | boolean) => () => {
    if (typeof page === 'number') {
      table.setPageIndex(page - 1)
    }
  }

  useEffect(() => {
    setActivePage(pageIndex + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex])

  useEffect(() => {
    setRecordsPerPage(pageSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  return (
    <div className='RTTablePagination__container'>
      <div />
      {showPaginationPages && (
        <ul className='RTTablePagination__paginationList'>
          <PaginationItem
            label={`Goto first page ${pageNumbers.firstPage}`}
            rel='first'
            isDisabled={disableBack}
            onClick={onMovePageTo(pageNumbers.firstPage)}
          >
            <DoubleArrowBackIcon />
          </PaginationItem>

          <PaginationItem
            label={`Goto previous page ${pageNumbers.previousPage}`}
            rel='prev'
            isDisabled={disableBack}
            onClick={onMovePageTo(pageNumbers.previousPage)}
          >
            <ArrowBackIcon />
          </PaginationItem>

          <PaginationItem
            label={`Goto first page ${pageNumbers.firstPage}`}
            isActive={pageNumbers.firstPage === pageNumbers.activePage}
            onClick={onMovePageTo(pageNumbers.firstPage)}
          >
            {pageNumbers.firstPage}
          </PaginationItem>

          {pageNumbers.customPreviousPage && (
            <PaginationItem
              label={`Goto page ${pageNumbers.customPreviousPage}`}
              isThreedot
            >
              &middot;&middot;&middot;
            </PaginationItem>
          )}

          {pageNumbers.navigation.map(navigationNumber => {
            const isFirstOrLastPage =
              navigationNumber === pageNumbers.firstPage ||
              navigationNumber === pageNumbers.lastPage

            return isFirstOrLastPage ? null : (
              <PaginationItem
                label={`Goto page ${navigationNumber}`}
                key={navigationNumber}
                isActive={navigationNumber === pageNumbers.activePage}
                onClick={onMovePageTo(navigationNumber)}
              >
                {navigationNumber}
              </PaginationItem>
            )
          })}

          {pageNumbers.customNextPage && (
            <PaginationItem
              isThreedot
              label={`Goto page ${pageNumbers.customNextPage}`}
            >
              &middot;&middot;&middot;
            </PaginationItem>
          )}

          {pageNumbers.firstPage !== pageNumbers.lastPage && (
            <PaginationItem
              label={`Goto last page ${pageNumbers.lastPage}`}
              isActive={pageNumbers.lastPage === pageNumbers.activePage}
              onClick={onMovePageTo(pageNumbers.lastPage)}
            >
              {pageNumbers.lastPage}
            </PaginationItem>
          )}

          <PaginationItem
            label={`Goto next page ${pageNumbers.nextPage}`}
            rel='next'
            isDisabled={disableNext}
            onClick={onMovePageTo(pageNumbers.nextPage)}
          >
            <ArrowForwardIcon />
          </PaginationItem>

          <PaginationItem
            label={`Goto last page ${pageNumbers.lastPage}`}
            rel='last'
            isDisabled={disableNext}
            onClick={onMovePageTo(pageNumbers.lastPage)}
          >
            <DoubleArrowForwardIcon />
          </PaginationItem>
        </ul>
      )}

      {showPerPageOptions && (
        <div className='RTTablePagination__perPage'>
          <label>Rows per page</label>
          <select
            value={pageSize}
            onChange={(event: any) => {
              table.setPageSize(+(event.target.value as any))
            }}
          >
            {(rowsPerPageOptions as number[]).map(opt => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  )
}

export default RTTablePagination

import { useCallback, useMemo } from 'react'
import { useTruckFormProvider } from '~/contexts'

import type { ITruckNumberSectionProps } from './type'
import { useQueryTruckFleets } from '~/hooks/useQueryData'

const useTruckNumberSection = (props: ITruckNumberSectionProps) => {
  const { truckId } = props

  const { truckFleetsData } = useQueryTruckFleets()

  const { onOpenTruckForm } = useTruckFormProvider()

  const truckFleet = useMemo(
    () => truckFleetsData.find(tf => tf.truckId === truckId),
    [truckFleetsData, truckId],
  )

  const truck = truckFleet?.truck

  const onRightClick = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      if (truck) {
        event.preventDefault()
        onOpenTruckForm(
          {
            truck,
            truckFleet: truckFleet || null,
          },
          {
            defaultTab: 'truckFleet',
          },
        )
      }
    },
    [onOpenTruckForm, truck, truckFleet],
  )

  return {
    truck,
    onRightClick,
  }
}

export default useTruckNumberSection

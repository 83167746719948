import { createAsyncThunk } from '@reduxjs/toolkit'
import {locationSlice} from '~/redux/reducers/data/locations'
import terminalsUiSlice from '~/redux/reducers/ui/terminals'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'

export const updateLocations = payload => dispatch =>
  dispatch(locationSlice.actions.updateLocations(payload))

export const updateLocation = payload => dispatch =>
  dispatch(locationSlice.actions.updateLocation(payload))

export const fetchMyTerminalLocations = createAsyncThunk(
  'TERMINAL_SET_MY_TERMINAL_LOCATIONS',
  async ({ locationableId }, { dispatch }) => {
    dispatch(terminalsUiSlice.actions.updateUi({ isLoading: true }))
    try {
      const { locations } = await apiClient.locations.get({
        filters: {
          locationableId,
          locationableType: 'Terminal',
        },
      })
      dispatch(locationSlice.actions.initializeLocations(locations))
    } catch (error) {
      console.log('error', error)
      toast.error('An error has occured.')
    } finally {
      dispatch(terminalsUiSlice.actions.updateUi({ isLoading: false }))
    }
  },
)

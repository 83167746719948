import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyBundleItems from './useModifyBundleItems'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type {
  IBundleItem,
  IGetBundleItemsParams,
} from '~/types/models/IBundleItem'

const useQueryBundleItems = (
  params: IGetBundleItemsParams = {},
  options?: Partial<UseQueryOptions<IBundleItem[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'bundleItems',
      sessionUser?.id,
      buildGetUrl('/bundle_items', params),
    ],
    async queryFn() {
      const response = await apiClient.bundleItems.get(params)
      return response.bundleItems
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // 1 min
    ...options,
  })

  const bundleItemsData = useMemo(() => data || [], [data])

  const { addBundleItem, updateBundleItem, removeBundleItem } =
    useModifyBundleItems(params)

  return {
    bundleItemsData,
    isLoadingBundleItems: isLoading,
    refetchBundleItems: refetch,
    addBundleItem,
    updateBundleItem,
    removeBundleItem,
  }
}

export default useQueryBundleItems

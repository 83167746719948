import { useQueryLoad } from '~/hooks/useQueryData'
import { useCallback, useMemo, useState } from 'react'

import { When } from 'react-if'
import { TerminalMarker1, MapDirection } from '~/components/shared'

import moment from 'moment'
import {
  arrowDownCircleOutline,
  arrowUpCircleOutline,
  checkmarkCircleOutline,
} from 'ionicons/icons'
import { isInLoadStatus } from '~/utils/loadUtils'

import { IUseDeliveryRoutesProps } from './type'
import { IGetDirectionOnMapResponse } from '~/utils/getDirectionOnMap'
import { ITerminal } from '~/types/models/ITerminal'
import _ from 'lodash'

const useDeliveryRoutes = (props: IUseDeliveryRoutesProps) => {
  const { loadId, truck, isShow } = props

  const [dropoffDirection, setDropoffDirection] =
    useState<IGetDirectionOnMapResponse | null>(null)
  const [pickupDirection, setPickupDirection] =
    useState<IGetDirectionOnMapResponse | null>(null)
  const [returnDirection, setReturnDirection] =
    useState<IGetDirectionOnMapResponse | null>(null)

  const {
    loadData,
    loadBuyerTerminal,
    loadSellerTerminal,
    loadReturnTerminal,
    buyerTerminalLocation,
    sellerTerminalLocation,
    returnTerminalLocation,
    breadcrumbLoadsData,
  } = useQueryLoad(loadId as number)

  const calculateTravelTime = useCallback((travelTime: number) => {
    if (travelTime) {
      const result = Math.ceil(travelTime / 60)
      return result
    }
    return null
  }, [])

  const calculateDistance = useCallback((distance: number) => {
    if (distance) {
      const result = distance / 1609.34
      return Number(result.toFixed(2))
    }
    return null
  }, [])

  const getExtraInfos = useCallback(
    (info: {
      travelTime: number | null
      distance: number | null
      eta: string | null
    }) => {
      const list = [
        {
          label: 'Travel time',
          value: `${info.travelTime} mins`,
          isHidden: !info.travelTime,
        },
        {
          label: 'ETA',
          value: info.eta,
          isHidden: !info.eta,
        },
        {
          label: 'Distance',
          value: `${info.distance} mi`,
          isHidden: !info.distance,
        },
      ]

      return list
        .filter(({ isHidden }) => !isHidden)
        .map(({ label, value }) => (
          <div key={label} style={{ marginTop: 4 }}>
            <span>{label}: </span>
            <span>{value}</span>
          </div>
        ))
    },
    [],
  )

  const pickupInfobox = useMemo(() => {
    const travelTime = calculateTravelTime(
      pickupDirection?.travelTime as number,
    )
    const distance = calculateDistance(pickupDirection?.distance as number)
    const eta = travelTime
      ? moment().add({ minute: travelTime }).format('HH:mm')
      : null

    return {
      travelTime,
      distance,
      eta,
    }
  }, [
    calculateDistance,
    calculateTravelTime,
    pickupDirection?.distance,
    pickupDirection?.travelTime,
  ])

  const dropoffInfoBox = useMemo(() => {
    const travelTime = calculateTravelTime(
      dropoffDirection?.travelTime as number,
    )
    const distance = calculateDistance(dropoffDirection?.distance as number)
    const eta = travelTime
      ? moment()
          .add({ minute: travelTime + (pickupInfobox?.travelTime as number) })
          .format('HH:mm')
      : null

    return {
      travelTime,
      distance,
      eta,
    }
  }, [
    calculateDistance,
    calculateTravelTime,
    dropoffDirection?.distance,
    dropoffDirection?.travelTime,
    pickupInfobox?.travelTime,
  ])

  const returnTerminalInfoBox = useMemo(() => {
    const travelTime = calculateTravelTime(
      returnDirection?.travelTime as number,
    )
    const distance = calculateDistance(returnDirection?.distance as number)
    const eta = travelTime
      ? moment()
          .add({
            minute:
              travelTime +
              (pickupInfobox.travelTime as number) +
              (dropoffInfoBox.travelTime as number),
          })
          .format('HH:mm')
      : null

    return {
      travelTime,
      distance,
      eta,
    }
  }, [
    calculateDistance,
    calculateTravelTime,
    dropoffInfoBox.travelTime,
    pickupInfobox.travelTime,
    returnDirection?.distance,
    returnDirection?.travelTime,
  ])

  const truckLocation = useMemo(() => {
    if (truck?.location) {
      return new window.google.maps.LatLng(
        Number(truck.location.lat),
        Number(truck.location.lng),
      )
    }
    return null
  }, [truck?.location])

  const breadcrumbWaypoints = useMemo(() => {
    if (breadcrumbLoadsData.length <= 1) {
      return null
    }
    const firstData = _.first(breadcrumbLoadsData)
    const lastData = _.last(breadcrumbLoadsData)
    const waypoints = breadcrumbLoadsData
      .filter(({ id }) => ![firstData?.id, lastData?.id].includes(id))
      .map(({ lat, lng }) => ({
        location: {
          lat: Number(lat || 0),
          lng: Number(lng || 0),
        },
      }))
    const startingPoint = new google.maps.LatLng(
      Number(firstData?.lat || 0),
      Number(firstData?.lng || 0),
    )
    const endingPoint = new google.maps.LatLng(
      Number(lastData?.lat || 0),
      Number(lastData?.lng || 0),
    )

    return {
      from: startingPoint,
      to: endingPoint,
      waypoints,
    }
  }, [breadcrumbLoadsData])

  const renderDeliveryRoutes = useMemo(() => {
    if (isShow) {
      return (
        <>
          <When
            condition={Boolean(
              truckLocation && sellerTerminalLocation && loadBuyerTerminal,
            )}
          >
            <TerminalMarker1
              terminal={loadSellerTerminal as ITerminal}
              icon={arrowUpCircleOutline}
              color='black'
              extraInfo={getExtraInfos(pickupInfobox)}
              isShowInfoBox
              title='Pickup'
            />

            <MapDirection
              from={truckLocation as google.maps.LatLng}
              to={sellerTerminalLocation as google.maps.LatLng}
              isHidden={!isInLoadStatus('to_seller', loadData?.status)}
              color='black'
              onChange={direction => {
                setPickupDirection(direction)
              }}
            />
          </When>

          <When
            condition={Boolean(
              sellerTerminalLocation &&
                buyerTerminalLocation &&
                loadBuyerTerminal,
            )}
          >
            <TerminalMarker1
              terminal={loadBuyerTerminal as ITerminal}
              icon={arrowDownCircleOutline}
              color='blue'
              extraInfo={getExtraInfos(dropoffInfoBox)}
              isShowInfoBox
              title='Dropoff'
            />

            <MapDirection
              from={sellerTerminalLocation as google.maps.LatLng}
              to={buyerTerminalLocation as google.maps.LatLng}
              isHidden={
                isInLoadStatus('at_buyer', loadData?.status) ||
                isInLoadStatus('deliver_complete', loadData?.status)
              }
              color='#1090ff'
              onChange={direction => {
                setDropoffDirection(direction)
              }}
            />
          </When>

          <When condition={Boolean(breadcrumbWaypoints)}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <MapDirection {...(breadcrumbWaypoints as any)} color='#787878' />
          </When>

          <When
            condition={Boolean(
              buyerTerminalLocation &&
                returnTerminalLocation &&
                loadBuyerTerminal,
            )}
          >
            <TerminalMarker1
              terminal={loadReturnTerminal as ITerminal}
              icon={checkmarkCircleOutline}
              color='#45ad45'
              extraInfo={getExtraInfos(returnTerminalInfoBox)}
              isShowInfoBox
              title='Return'
            />
            <MapDirection
              from={buyerTerminalLocation as google.maps.LatLng}
              to={returnTerminalLocation as google.maps.LatLng}
              isHidden={isInLoadStatus('deliver_complete', loadData?.status)}
              color='rgb(85, 210, 85)'
              onChange={direction => {
                setReturnDirection(direction)
              }}
            />
          </When>
        </>
      )
    }

    return null
  }, [
    breadcrumbWaypoints,
    buyerTerminalLocation,
    dropoffInfoBox,
    getExtraInfos,
    isShow,
    loadBuyerTerminal,
    loadData?.status,
    loadReturnTerminal,
    loadSellerTerminal,
    pickupInfobox,
    returnTerminalInfoBox,
    returnTerminalLocation,
    sellerTerminalLocation,
    truckLocation,
  ])

  const boundingDeliveryRoute = useMemo(() => {
    const bounding: google.maps.LatLng[] = []
    if (truckLocation) {
      bounding.push(truckLocation)
    }
    if (sellerTerminalLocation) {
      bounding.push(sellerTerminalLocation)
    }
    if (buyerTerminalLocation) {
      bounding.push(buyerTerminalLocation)
    }
    if (returnTerminalLocation) {
      bounding.push(returnTerminalLocation)
    }

    return bounding
  }, [
    buyerTerminalLocation,
    returnTerminalLocation,
    sellerTerminalLocation,
    truckLocation,
  ])

  return {
    loadData,
    loadBuyerTerminal,
    loadSellerTerminal,
    loadReturnTerminal,
    buyerTerminalLocation,
    sellerTerminalLocation,
    returnTerminalLocation,
    truckLocation,
    returnTerminalInfoBox,
    pickupInfobox,
    dropoffInfoBox,
    renderDeliveryRoutes,
    boundingDeliveryRoute,
    breadcrumbLoadsData,
    setDropoffDirection,
    setPickupDirection,
    setReturnDirection,
    getExtraInfos,
  }
}

export default useDeliveryRoutes

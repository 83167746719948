import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyRewards from './useModifyRewards'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IGetRewardsParams, IReward } from '~/types/models/IReward'

const useQueryRewards = (
  params: IGetRewardsParams,
  options?: Partial<UseQueryOptions<IReward[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'rewards',
      sessionUser?.id,
      buildGetUrl(apiClient.rewards.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.rewards.get(params)
      return response.rewards
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // 1 min
    ...options,
  })

  const rewardsData = useMemo(() => data || [], [data])

  const { addReward, updateReward, removeReward } = useModifyRewards(params)

  return {
    rewardsData,
    isLoadingRewards: isLoading,
    addReward,
    updateReward,
    removeReward,
    refetchRewards: refetch,
  }
}

export default useQueryRewards

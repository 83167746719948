import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyBankAccounts from './useModifyBankAccounts'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { IBankAccount } from '~/types/models/IBankAccount'

const useQueryBankAccounts = (
  params = {},
  options?: Partial<UseQueryOptions<IBankAccount[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'bankAccounts',
      sessionUser?.id,
      buildGetUrl(apiClient.bankAccounts.endpoint, params),
    ],
    async queryFn() {
      const res = await apiClient.bankAccounts.get()
      return res.bankAccounts
    },
    enabled: Boolean(sessionUser?.id),
    ...options,
  })

  const { addBankAccount, updateBankAccount, removeBankAccount } =
    useModifyBankAccounts(params)

  const bankAccountsData = useMemo(() => data || [], [data])

  return {
    bankAccountsData,
    isLoadingBankAccounts: isLoading,
    addBankAccount,
    updateBankAccount,
    removeBankAccount,
  }
}

export default useQueryBankAccounts

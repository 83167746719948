import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type {
  ICheckUsageSummariesGetParams,
  ICheckUsageSummariesGetResponse,
} from '~/types/models/ICheck'
import { buildGetUrl } from '~/utils/buildUrl'

const useQueryCheckUsageSummaries = (
  params: ICheckUsageSummariesGetParams = {},
  options?: Partial<UseQueryOptions<ICheckUsageSummariesGetResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'checkUsageSummaries',
      sessionUser?.id,
      buildGetUrl('/check_usage_summaries', params),
    ],
    queryFn() {
      return apiClient.check.getUsageSummaries(params)
    },
    enabled: Boolean(sessionUser?.id),
    ...options,
  })

  const usageSummaries = data?.result || []

  return {
    usageSummaries,
    isCheckUsageSummariesLoading: isLoading,
  }
}

export default useQueryCheckUsageSummaries

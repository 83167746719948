import { useEffect, memo, useState, useCallback } from 'react'

import SearchField from 'react-search-field'
import { Badge } from 'react-bootstrap'
import { IonIcon, IonText } from '@ionic/react'

import clsx from 'clsx'
import { closeCircle } from 'ionicons/icons'

import '~/styles/searchbar.scss'
import './ContainerSearchBar.scss'

const ContainerSearchBar = props => {
  const {
    searchBarPlaceholder,
    searchBarValue,
    onSearchBarChange,
    searchBarParent,
    searchBarSize = null,
    className,
    onFocus,
    containerClassName,
    countBadge,
    showBadge,
    onClick,
    ...inputProps
  } = props

  const [searchValue, setSearchValue] = useState('')

  // On component mount, I have to set width to be able to adjust it based on input length
  // keyPressHandler focuses the input on '/' button press and also prevents '/' from being entered automatically in the input
  // inputSizeHandler will add a certain 'rem' length to the input based on the text entered/

  useEffect(() => {
    const searchFieldInput = document.querySelector(
      `div.${searchBarParent} input`,
    )

    // if (searchFieldInput) {
    //   searchFieldInput.style.width = searchBarSize
    //     ? `${searchBarSize}rem`
    //     : '16rem'
    //   searchFieldInput.type = 'search'
    // }

    const keyPressHandler = e => {
      const currentActiveElement = document.activeElement
      if (
        `${e.code}` == 'Slash' &&
        currentActiveElement.tagName.toLowerCase() != 'input'
      ) {
        e.preventDefault()
        searchFieldInput.focus()
      }
    }

    // const inputSizeHandler = () => {
    //   if (!searchFieldInput) return

    //   if (searchFieldInput.value.length >= 20) {
    //     searchFieldInput.style.width =
    //       16 + (searchFieldInput.value.length - 20) / 1.1 + 'rem'
    //   } else {
    //     searchFieldInput.style.width = searchBarSize
    //       ? `${searchBarSize}rem`
    //       : '16rem'
    //   }
    // }

    document.addEventListener('keydown', keyPressHandler)
    // document.addEventListener('input', inputSizeHandler)

    return () => {
      document.removeEventListener('keydown', keyPressHandler)
      // document.removeEventListener('input', inputSizeHandler)
    }
  }, [searchBarParent, searchBarSize])

  // if input has text, change the className
  const onInputChange = value => {
    setSearchValue(value)
    onSearchBarChange && onSearchBarChange(value)
  }

  const onClearInput = useCallback(() => {
    onSearchBarChange && onSearchBarChange('')
    setSearchValue('')
  }, [onSearchBarChange])

  useEffect(() => {
    setSearchValue(searchBarValue || '')
  }, [searchBarValue])

  return (
    <div
      onClick={onClick}
      onPointerDown={onFocus}
      className={clsx('ContainerSearchBar__container', containerClassName)}
    >
      <div className='ContainerSearchBar__searchBar'>
        <SearchField
          placeholder={searchBarPlaceholder}
          classNames={clsx(searchBarParent, {
            'searchBar-component-has-text': searchValue?.length > 0,
            'searchBar-component': searchValue?.length === 0,
          })}
          searchText={searchValue}
          onChange={onInputChange}
          {...inputProps}
        />
        {searchValue && (
          <IonText
            className='icon ContainerSearchBar__clearIcon'
            color='medium'
            onClick={onClearInput}
          >
            <IonIcon icon={closeCircle} />
          </IonText>
        )}
      </div>
      {showBadge && countBadge !== 0 && (
        <Badge className='ContainerSearchBar__badge' bg='danger'>
          {countBadge}
        </Badge>
      )}
    </div>
  )
}

// ContainerSearchBar.propTypes = {
//   searchBarPlaceholder: PropTypes.string,
//   searchBarValue: PropTypes.string,
//   onSearchBarChange: PropTypes.func,
//   className: PropTypes.string,
//   containerClassName: PropTypes.string,
//   countBadge: PropTypes.number,
//   showBadge: PropTypes.bool,
//   onClick: PropTypes.func,
// }

export default memo(ContainerSearchBar)

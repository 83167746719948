import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'
import { produce } from 'immer'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetWorkPayTypesResponse,
  IWorkPayType,
} from '~/types/models/IWorkPayType'

const useModifyWorkPayTypes = () => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = ['workPayTypes', sessionUser?.id]

  const updateWorkPayType = (id: number, data: Partial<IWorkPayType>) => {
    queryClient.setQueryData<IGetWorkPayTypesResponse | undefined>(
      queryKey,
      oldData =>
        produce(oldData, draft => {
          if (draft?.workPayTypes) {
            const index = draft.workPayTypes.findIndex(item => item.id === id)
            if (index !== -1) {
              draft.workPayTypes[index] = {
                ...draft.workPayTypes[index],
                ...data,
              }
            }
          }
        }),
    )
  }

  const deleteWorkPayType = (id: number) => {
    queryClient.setQueryData<IGetWorkPayTypesResponse | undefined>(
      queryKey,
      oldData =>
        produce(oldData, draft => {
          if (draft?.workPayTypes) {
            const index = draft.workPayTypes.findIndex(item => item.id === id)
            if (index !== -1) {
              draft.workPayTypes.splice(index, 1)
            }
          }
        }),
    )
  }

  const addWorkPayType = (data: IWorkPayType) => {
    queryClient.setQueryData<IGetWorkPayTypesResponse | undefined>(
      queryKey,
      oldData =>
        produce(oldData, draft => {
          if (draft?.workPayTypes) {
            draft.workPayTypes.push(data)
          }
        }),
    )
  }

  return { updateWorkPayType, deleteWorkPayType, addWorkPayType }
}

export default useModifyWorkPayTypes

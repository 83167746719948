import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { CommonDialogV2 } from '~/components/shared'
import LoadFormContext from './LoadFormContext'
import LoadForm from '~/components/order/LoadForm'
import { ILoadFormProviderSettingProps, ILoadFormProviderValues } from './type'
import { When } from 'react-if'
import { selectDriverFleets } from '~/redux/selectors'
import { IDriverFleet } from '~/types/models/IDriverFleet'

function LoadFormProvider(props: PropsWithChildren) {
  const { children } = props

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [header, setHeader] = useState<string>('New Load')

  const [settingProps, setSettingProps] =
    useState<ILoadFormProviderSettingProps>({
      driverFleetId: null,
      defaultSchedule: null,
    })

  const driverFleetObjects: Record<number, IDriverFleet> =
    useSelector(selectDriverFleets)

  const driverFleet = useMemo(() => {
    if (settingProps.driverFleetId) {
      return driverFleetObjects[settingProps.driverFleetId]
    }

    return null
  }, [driverFleetObjects, settingProps.driverFleetId])

  const onOpenLoadFormDialog = useCallback(
    (settingProps: ILoadFormProviderSettingProps) => {
      setSettingProps(settingProps)
      setIsOpenForm(true)
    },
    [],
  )

  const onCloseLoadFormDialog = useCallback(() => {
    setSettingProps({
      driverFleetId: null,
      defaultSchedule: null,
    })
    setIsOpenForm(false)
  }, [])

  const providerValue = useMemo<ILoadFormProviderValues>(
    () => ({
      onOpenLoadFormDialog,
      onCloseLoadFormDialog,
    }),
    [onCloseLoadFormDialog, onOpenLoadFormDialog],
  )

  return (
    <>
      <LoadFormContext.Provider value={providerValue}>
        {children}
      </LoadFormContext.Provider>
      <CommonDialogV2
        isOpen={isOpenForm}
        onClose={onCloseLoadFormDialog}
        isHiddenOkButton
        title={header}
        size='lg'
      >
        <When condition={Boolean(driverFleet)}>
          <LoadForm
            defaultSchedule={settingProps.defaultSchedule}
            driverFleet={driverFleet}
            setShow={() => {
              onCloseLoadFormDialog()
            }}
            onTogglePrintLoad={setHeader}
            isHiddenHeader
          />
        </When>
      </CommonDialogV2>
    </>
  )
}

export default LoadFormProvider

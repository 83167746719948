/*global google*/
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import GeofenceMapView from '../GeofenceMapView'
import AddressTextBox from '../AddressTextBox'
import { apiClient } from '~/api/ApiClient'
import { updateTerminal } from '~/redux/actions/CommonData/terminalsActions'
import { updateLocation } from '~/redux/actions/locationActions'
import Loading from '../Loading'
import buildFullAddress from '~/utils/buildFullAddress'
import { CloseIcon } from '~/components/shared'

const LOADING = {
  UPDATE: 'update',
  CREATE: 'create',
  NONE: '',
}
export const EditTerminalSection = props => {
  const { terminal, onComplete, onCreateLocation, onClickClose } = props

  const { location, geofence, id, coordinates, address: addressObj } = terminal

  const dispatch = useDispatch()
  const [address, setAddress] = useState(
    addressObj?.fullAddress || buildFullAddress(addressObj),
  )
  const [coordinate, setCoordinate] = useState(coordinates)
  const [latLng, setLatLng] = useState({
    lat: parseFloat(location?.lat),
    lng: parseFloat(location?.lng),
  })
  const [loading, setLoading] = useState(LOADING.NONE)

  const update = async () => {
    setLoading(LOADING.UPDATE)
    try {
      const coordinateParams = coordinate.map(c => ({
        ...c,
      }))

      const locationRes = await apiClient.locations.update(location.id, {
        lat: latLng.lat,
        lng: latLng.lng,
        address_attributes: {
          //id: address.id,
          full_address: address,
          address_types: ['location'],
        },
      })

      dispatch(updateLocation(locationRes))
      const coordinates = await apiClient.geofences.update(geofence.id, {
        geofence: {
          coordinatesAttributes: coordinateParams,
        },
      })
      dispatch(
        updateTerminal({
          ...terminal,
          location: locationRes,
          coordinates: coordinates,
        }),
      )
      toast.success('Location updated successfully!')
      onComplete && onComplete()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(LOADING.NONE)
    }
  }

  const create = async () => {
    setLoading(LOADING.CREATE)
    try {
      const locationRes = await apiClient.locations.create(location.id, {
        lat: address.lat,
        lng: address.lng,
        locationableType: 'Terminal',
        locationableId: id,
        address_attributes: {
          // id: address.id,
          fullAddress: address,
          address_types: ['location'],
        },
      })
      dispatch(updateLocation(locationRes))

      const coordinateParams = coordinate.map(c => ({
        ...c,
        coordinateable_id: geofence.id, //can this come from locationRes?
      }))
      await apiClient.geofences.update(geofence.id, {
        geofence: {
          coordinatesAttributes: coordinateParams,
        },
      })

      toast.success('Location created successfully!')
      onCreateLocation && onCreateLocation(locationRes)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(LOADING.NONE)
    }
  }

  const onSelectPlace = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(values => {
        setAddress(address)
        setLatLng({
          lat: values.lat,
          lng: values.lng,
        })
      })
  }

  const fetchNewAddress = () => {
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          setAddress(results[0].formatted_address)
        }
      }
    })
  }

  const showFetchAddressButton = () => {
    return (
      latLng?.lat !== parseFloat(location?.lat) ||
      latLng?.lng !== parseFloat(location?.lng)
    )
  }

  return (
    <div className='mb-2'>
      <div className='d-flex flex-row fs-3 mb-2 gap-2'>
        <AddressTextBox
          value={address}
          onChange={setAddress}
          onSelect={onSelectPlace}
          className={'border'}
        />
        <Button variant='danger' onClick={onClickClose}>
          <CloseIcon />
        </Button>
      </div>

      <div>
        <GeofenceMapView
          currentLocation={latLng}
          zoom={14}
          points={coordinate}
          onPointerChange={setLatLng}
          onChange={setCoordinate}
        />
      </div>
      <div className='mt-1 d-flex gap-2'>
        <Button
          onClick={() => setCoordinate([])}
          className=' '
          variant='danger'
        >
          Clear Geofence
        </Button>

        {showFetchAddressButton() && (
          <Button onClick={() => fetchNewAddress()}>Fetch New Address</Button>
        )}

        <div className='ms-auto'>
          <Button
            disabled={
              location?.fullAddress === address &&
              _.isEqual(coordinate, coordinates)
            }
            onClick={() => update()}
            className='me-2'
          >
            {loading === LOADING.UPDATE && <Loading />}
            Update
          </Button>

          {!_.isEmpty(geofence) && (
            <Button
              disabled={location?.fullAddress === address}
              onClick={() => create()}
            >
              {loading === LOADING.CREATE && <Loading />}
              New
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

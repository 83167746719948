import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { IGroup, IGetGroupsParams } from '~/types/models/IGroup'

const useModifyGroups = (_params: Partial<IGetGroupsParams> = {}) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(() => ['groups', sessionUser?.id], [sessionUser?.id])

  const addGroup = useCallback(
    (data: IGroup) => {
      queryClient.setQueryData<IGroup[]>(queryKey, oldData => {
        if (oldData) {
          return [...oldData, data]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateGroup = useCallback(
    (id: number, data: IGroup) => {
      queryClient.setQueryData<(oldData: IGroup[]) => IGroup[] | undefined>(
        queryKey,
        (oldData: IGroup[]) =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex((g: IGroup) => g.id === id)
              draft[index] = data
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const removeGroup = useCallback(
    (id: number) => {
      queryClient.setQueryData<IGroup[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )
  return {
    addGroup,
    updateGroup,
    removeGroup,
  }
}

export default useModifyGroups

import { Badge, Button } from 'react-bootstrap'
import Select from 'react-select'
import { useCallback, useEffect, useMemo } from 'react'
import { useController } from 'react-hook-form'
import { ExtrasFormRangesFieldArray } from './ExtrasFormRangesFieldArray'
import { useQuerySellerProducts } from '~/hooks/useQueryData'
import { EOrderType, EQtyType } from '~/types/enums/ESellerProduct'
import _ from 'lodash'
import { DeleteIcon } from '~/components/shared'

const selectErrorStyle = {
  control: base => ({
    ...base,
    borderColor: 'red',
  }),
  placeholder: base => ({
    ...base,
    color: 'red',
  }),
}

export const ExtrasFormRow = ({
  index,
  control,
  remove,
  buyerId,
  sellerId,
  prefix,
  setValue,
}) => {
  const fieldsName = useMemo(
    () => `${prefix}orderExtrasAttributes[${index}].orderExtraRangesAttributes`,
    [prefix, index],
  )

  const extraOrderTypes = useMemo(() => [EOrderType.extra, EOrderType.both], [])

  const { sellerProductsObj, sellerProductOptions, isLoadingSellerProducts } =
    useQuerySellerProducts(
      {
        sellerId: [sellerId],
        filters: {
          joinsSellerProduct: true,
          orderTypes: extraOrderTypes,
        },
      },
      { enabled: Boolean(buyerId && sellerId) },
    )

  const onSelectSellerProduct = useCallback(
    ({ _field, value }) => {
      const name = `${prefix}orderExtrasAttributes[${index}]`
      setValue(name, {
        sellerProductId: value,
        orderExtraRangesAttributes: [
          { qty: null, startLoad: 1, endLoad: null },
        ],
      })
    },
    [index, prefix, setValue],
  )

  const {
    field: { onBlur, value },
    fieldState: { error },
  } = useController({
    name: `${prefix}orderExtrasAttributes[${index}].sellerProductId`,
    control,
  })

  const qtyType = useMemo(
    () => sellerProductsObj[value]?.qtyType,
    [value, sellerProductsObj],
  )

  const qtyTypeValue = _.startCase(Object.values(EQtyType)[qtyType])

  const isPerOrder = useMemo(() => qtyType === EQtyType.per_order, [qtyType])

  useEffect(() => {
    if (isPerOrder) {
      setValue(
        `${prefix}orderExtrasAttributes[${index}].${prefix}orderExtraRangesAttributes`,
        [{ qty: null, startLoad: 1, endLoad: 1 }],
      )
    }
  }, [isPerOrder, index, prefix, setValue])

  const selectDisplay = error && selectErrorStyle

  const selectedValue = useMemo(() => {
    if (sellerProductOptions) {
      return sellerProductOptions.find(option => option.value === value)
    }
    return null
  }, [sellerProductOptions, value])

  return (
    <div>
      <div className='d-flex pb-1 align-items-center'>
        <Select
          className='d-flex'
          options={sellerProductOptions}
          placeholder='Select product'
          components={{
            // ...selectComponents,
            // MenuList: CreateButton(onClickCreateProduct),
            // Option: EditOption(onClickEditSellerProduct),
            IndicatorSeparator: null,
          }}
          styles={selectDisplay}
          onBlur={onBlur}
          isSearchable
          isDisabled={!sellerId}
          onChange={e => onSelectSellerProduct(e.value)}
          value={selectedValue}
          isLoading={isLoadingSellerProducts}
        />
        {qtyTypeValue && (
          <Badge pill className='d-flex ms-1'>
            {qtyTypeValue}
          </Badge>
        )}

        <Button
          onClick={() => remove(index)}
          variant='danger'
          className='ms-auto'
        >
          <DeleteIcon color='#fff' />
        </Button>
      </div>
      {fieldsName && (
        <ExtrasFormRangesFieldArray
          name={fieldsName}
          sellerProductId={value}
          control={control}
          hideRange={isPerOrder}
          setValue={setValue}
        />
      )}
    </div>
  )
}

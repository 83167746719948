import { useCallback, useMemo, useState } from 'react'
import {
  useQueryBundleItems,
  useQueryBundles,
  useQuerySellerTerminalProducts,
} from '~/hooks/useQueryData'

import { BundleBadge } from '../BundleBadge'

import { apiClient } from '~/api/ApiClient'

import type { IBundleFormWithBadgesProps } from './type'
import type { IBundleItem } from '~/types/models/IBundleItem'
import type { IBundle } from '~/types/models/IBundle'
import type { ISellerTerminalProduct } from '~/types/models/ISellerTerminalProduct'

const useBundleFormWithBadges = (props: IBundleFormWithBadgesProps) => {
  const { sellerProductId } = props

  const [isCreatingBundle, setIsCreatingBundle] = useState(false)
  const [sellerTerminalProdState, setSellerTerminalProdState] = useState({
    isOpen: false,
    formData: undefined as ISellerTerminalProduct | undefined,
  })

  const { bundlesData, addBundle, updateBundle, removeBundle } =
    useQueryBundles({
      filters: {
        sellerProductId,
      },
    })

  const bundleId = useMemo(() => bundlesData.map(({ id }) => id), [bundlesData])

  const {
    bundleItemsData,
    refetchBundleItems,
    addBundleItem,
    updateBundleItem,
    removeBundleItem,
  } = useQueryBundleItems(
    {
      filters: {
        bundleId,
      },
    },
    { enabled: bundleId.length > 0 },
  )

  const {
    sellerTerminalProductsData,
    refecthSellerTerminalProducts,
    addSellerTerminalProduct,
    updateSellerTerminalProduct,
    removeSellerTerminalProduct,
  } = useQuerySellerTerminalProducts({
    filters: {
      sellerProductId,
    },
  })

  const onOpenCreateForm = useCallback(() => {
    setIsCreatingBundle(true)
  }, [])

  const onOpenSellerTerProdCreateForm = useCallback(() => {
    setSellerTerminalProdState({
      isOpen: true,
      formData: undefined,
    })
  }, [])

  const onCloseForm = useCallback(() => {
    setIsCreatingBundle(false)
  }, [])

  const onCloseSellerTerProdForm = useCallback(() => {
    setSellerTerminalProdState({
      isOpen: false,
      formData: undefined,
    })
  }, [])

  const afterCreateBundle = useCallback(
    (newBundle: IBundle) => {
      addBundle(newBundle)
      onCloseForm()
    },
    [addBundle, onCloseForm],
  )

  const afterUpdateBundle = useCallback(
    (bundleUpdated: IBundle) => {
      updateBundle(bundleUpdated.id, bundleUpdated)
      onCloseForm()
    },
    [onCloseForm, updateBundle],
  )

  const afterCreateSellerTerProd = useCallback(
    (newItem: ISellerTerminalProduct) => {
      addSellerTerminalProduct(newItem)
      onCloseSellerTerProdForm()
    },
    [addSellerTerminalProduct, onCloseSellerTerProdForm],
  )

  const afterUpdateSellerTerProd = useCallback(
    (itemUpdated: ISellerTerminalProduct) => {
      updateSellerTerminalProduct(itemUpdated.id, itemUpdated)
      onCloseSellerTerProdForm()
    },
    [onCloseSellerTerProdForm, updateSellerTerminalProduct],
  )

  const onRemoveBundle = useCallback(
    (bundle: IBundle) => {
      apiClient.bundles.delete(bundle.id)
      removeBundle(bundle.id)
    },
    [removeBundle],
  )

  const afterCreateBundleItem = useCallback(
    (newItem: IBundleItem) => {
      addBundleItem(newItem)
    },
    [addBundleItem],
  )

  const afterUpdateBundleItem = useCallback(
    (newItem: IBundleItem) => {
      updateBundleItem(newItem.id, newItem)
    },
    [updateBundleItem],
  )

  const onRemoveBundleItem = useCallback(
    (bundleItem: IBundleItem) => {
      apiClient.bundleItems.delete(bundleItem.id)
      removeBundleItem(bundleItem.id)
    },
    [removeBundleItem],
  )

  const onRemoveSellerTerminalProduct = useCallback(
    (bundleItem: ISellerTerminalProduct) => {
      apiClient.sellerTerminalProducts.delete(bundleItem.id)
      removeSellerTerminalProduct(bundleItem.id)
    },
    [removeSellerTerminalProduct],
  )

  const renderBundleBadges = useMemo(
    () =>
      bundlesData.map(bundle => {
        const bundleItemsFiltered = bundleItemsData.filter(
          ({ bundleId }) => bundleId === bundle.id,
        )
        const terminalProdFiltered = sellerTerminalProductsData.filter(
          ({ bundleId }) => bundleId === bundle.id,
        )

        return (
          <BundleBadge
            key={bundle.id}
            bundleData={bundle}
            bundleItemsData={bundleItemsFiltered}
            sellerProductId={sellerProductId}
            sellerTerminalProductsData={terminalProdFiltered}
            onRemove={onRemoveBundle}
            afterChangeRanking={() => {
              refetchBundleItems()
            }}
            onRemoveBundleItem={onRemoveBundleItem}
            onRemoveSellerTerminalProduct={onRemoveSellerTerminalProduct}
            afterCreateSellerTerminalProduct={afterCreateSellerTerProd}
            afterCreateBundleItem={afterCreateBundleItem}
            afterUpdateBundleItem={afterUpdateBundleItem}
            afterUpdateBundle={afterUpdateBundle}
            afterCreateBundle={afterCreateBundle}
            afterDrag={() => {
              refecthSellerTerminalProducts()
            }}
          />
        )
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      bundlesData,
      bundleItemsData,
      sellerTerminalProductsData,
      sellerProductId,
      onRemoveBundle,
      onRemoveBundleItem,
      onRemoveSellerTerminalProduct,
      afterCreateSellerTerProd,
      afterCreateBundleItem,
      afterUpdateBundleItem,
      afterUpdateBundle,
      afterCreateBundle,
      refetchBundleItems,
    ],
  )

  const renderSellerTerminalProductsBadges = useMemo(
    () => sellerTerminalProductsData.map(prod => <div key={prod.id}>1</div>),
    [sellerTerminalProductsData],
  )

  return {
    isCreatingBundle,
    sellerProductId,
    sellerTerminalProdState,
    renderBundleBadges,
    renderSellerTerminalProductsBadges,
    onOpenCreateForm,
    onCloseForm,
    afterCreateBundle,
    afterUpdateBundle,
    onCloseSellerTerProdForm,
    onOpenSellerTerProdCreateForm,
    afterCreateSellerTerProd,
    afterUpdateSellerTerProd,
  }
}

export default useBundleFormWithBadges

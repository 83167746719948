import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyProducts from './useModifyProducts'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IGetProductsParams, IProduct } from '~/types/models/IProduct'

const useQueryProducts = (
  params: IGetProductsParams = {},
  options?: Partial<UseQueryOptions<IProduct[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'products',
      sessionUser?.id,
      buildGetUrl(apiClient.products.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.products.get(params)
      return response.products
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // 1 min
    ...options,
  })

  const productsData = useMemo(() => data || [], [data])

  const productOptions = useMemo(
    () =>
      productsData.map(({ code, name, id }) => ({
        label: `${code} - ${name}`,
        value: id,
      })),
    [productsData],
  )

  const { addProduct, updateProduct, removeProduct } = useModifyProducts(params)

  return {
    productsData,
    isLoadingProducts: isLoading,
    productOptions,
    refecthProducts: refetch,
    addProduct,
    updateProduct,
    removeProduct,
  }
}

export default useQueryProducts

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useInitializeReduxTerminals } from '~/hooks/useInitializeRedux/stores'

import { CheckComponentsForWorker } from '../CheckComponentsForWorker'
import NotFoundPage from '~/containers/NotFoundPage'

import { selectWorkerDetails } from '~/redux/selectors'

import { IWorker } from '~/types/models/IWorker'

function DriverPayrolls() {
  useInitializeReduxTerminals()

  const workerDetails: IWorker = useSelector(selectWorkerDetails)

  const checkUid = useMemo(
    () => workerDetails.checkUid,
    [workerDetails.checkUid],
  )

  if (checkUid) {
    return <CheckComponentsForWorker workerUid={checkUid} />
  }

  return (
    <NotFoundPage
      message={
        <div>
          <div>Your company has not set up data for payrolls yet.</div>
          <div>
            <span>Please contact your company for more details</span>
          </div>
        </div>
      }
      onGoBack={() => {
        window.location.reload()
      }}
      buttonText='Reload Page'
    />
  )
}

export default DriverPayrolls

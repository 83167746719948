import useScheduleLoadsTable from './useScheduleLoadsTable'

import { ReusableTable } from '~/components/shared'

import type { IScheduleLoadsTableProps } from './type'

import './styles.scss'

function ScheduleLoadsTable(props: IScheduleLoadsTableProps) {
  const {
    columns,
    scheduleLoads,
    tableHeight,
    state,
    onCellEditEnd,
    enableTopToolbar,
    manualFiltering,
    filterOptions,
    onColumnFiltersChange,
  } = useScheduleLoadsTable(props)

  return (
    <ReusableTable
      data={scheduleLoads}
      columns={columns}
      onCellEditEnd={onCellEditEnd}
      tableHeight={tableHeight || 400}
      state={state}
      enableTopToolbar={enableTopToolbar}
      manualFiltering={manualFiltering}
      filterOptions={filterOptions}
      onColumnFiltersChange={onColumnFiltersChange}
    />
  )
}
export default ScheduleLoadsTable

import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser, selectMyCurrentCompany } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { ICommonOption } from '~/types/models/ICommonModel'
import type { ICompany } from '~/types/models/ICompany'
import { IGetUomsResponse } from '~/types/models/IUoms'

const useQueryUoms = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  params = {},
  options: UseQueryOptions<IGetUomsResponse> = {},
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { data, isLoading } = useQuery({
    queryKey: ['uoms', sessionUser?.id],
    queryFn: () => apiClient.uoms.get(),
    staleTime: 3600000, // 1 hour
    ...options,
  })

  const uomsData = useMemo(() => data?.uoms || [], [data?.uoms])

  const uomsObj = useMemo(
    () => Object.fromEntries(uomsData.map(uom => [uom.id, uom])),
    [uomsData],
  )

  const currentUom = useMemo(
    () => uomsData.find(({ id }) => id === currentCompany.uomId),
    [currentCompany.uomId, uomsData],
  )

  const uomOptions = useMemo<ICommonOption[]>(() => {
    const options = uomsData.map(({ id, name, code }) => ({
      value: id,
      label: `${code} - ${name}`,
    }))

    return options
  }, [uomsData])

  return {
    uomsData,
    uomsObj,
    uomOptions,
    currentUom,
    isLoadingUoms: isLoading,
  }
}

export default useQueryUoms

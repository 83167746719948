import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useQueryBuyerSellerProducts,
  useQuerySellerProducts,
} from '~/hooks/useQueryData'
import { useConfirmationProvider } from '~/contexts'

import {
  CompanyInfo,
  DropdownWithCustomChildren,
  IConcordFormOnChangeParams,
  ToolTipOverlay,
  CompanyAvatar,
  IRTColumnDef,
  RTRow,
  RTCell,
  GraphIcon,
  CartIcon,
} from '~/components/shared'

import {
  selectCompanies,
  selectAllTerminals,
  selectCommonFleetOptions,
  selectTerminalOptions,
  selectBuyerSellers,
} from '~/redux/selectors'

import { ISchedule, IScheduleFormData } from '~/types/models/ISchedule'
import { ICompany } from '~/types/models/ICompany'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import { ITerminal } from '~/types/models/ITerminal'
import { IonIcon, IonText } from '@ionic/react'
import { ICommonOption } from '~/types/models/ICommonModel'
import { ActionMeta } from 'react-select'
import { apiClient } from '~/api/ApiClient'
import { ENoteableType } from '~/types/enums/ENote'
import { ISchedulesTableProps } from './type'
import { IScheduleLoad } from '~/types/models/IScheduleLoad'
import { INote } from '~/types/models/INote'
import { produce } from 'immer'
import { ILoad } from '~/types/models/ILoad'
import {
  CustomLdsQtyField,
  ICustomLdsQtyFieldOnChangeParams,
} from './CustomLdsQtyField'
import { countMinutesFromTimeToNow, getMinDate } from '~/utils/getDay'
import { useDeepCompareEffect } from 'react-use'
import { IOrderFormData } from '~/types/models/IOrder'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import _ from 'lodash'
import { IBuyerSeller } from '~/types/models/IBuyerSeller'
import { IBuyerSellerProduct } from '~/types/models/IBuyerSellerProduct'
import { Badge, Popover } from 'react-bootstrap'
import { ORDER_STATUSES } from '~/constants/orders/constants'
import { defaultStatuses } from '~/components/fleet/constants'
import { components } from 'react-select'
import { DateTime } from 'luxon'
import useQueryOrderExtras from '~/hooks/useQueryData/useQueryOrderExtras/useQueryOrderExtras'
import { useDateTimeFormat } from '~/hooks/useFormatDateToTz'
import Tooltip from 'rc-tooltip'

import clsx from 'clsx'
import './styles.scss'

const useSchedulesTable = (props: ISchedulesTableProps) => {
  const {
    scheduleLoads,
    schedules,
    afterUpdateOrder,
    afterUpdateSchedule,
    tableHeight,
    filterOptions,
    state,
    enableTopToolbar,
    manualFiltering,
    toolbarProps,
    onColumnFiltersChange,
    afterUpdateScheduleLoad,
    renderDetailPanel,
    onSortingChange,
  } = props

  const [lineGraphState, setLineGraphState] = useState({
    isOpen: false,
    orderIds: [] as number[],
    scheduleIds: undefined as number[] | undefined,
  })
  const [notesData, setNotesData] = useState<INote[]>([])
  const [loadsData, setLoadsData] = useState<ILoad[]>([])
  const [selectedSchedule, setSelectedSchedule] =
    useState<ISchedule | null>(null)
  const [isOpenOrderForm, setIsOpenOrderForm] = useState(false)
  const [defaultTab, setDefaultTab] =
    useState<string | number | undefined>(undefined)
  const [orderExtrasDialog, setOrderExtrasDialog] = useState({
    isOpen: false,
    orderId: undefined as number | undefined,
  })

  const companyObjects: Record<number, ICompany> = useSelector(selectCompanies)
  const terminalObjects: Record<number, ITerminal> =
    useSelector(selectAllTerminals)
  const terminalOptions: ICommonOption<{ companyId: number }>[] = useSelector(
    selectTerminalOptions,
  )
  const fleetOptions: ICommonOption[] = useSelector(selectCommonFleetOptions)
  const buyerSellerObjects = useSelector(selectBuyerSellers)

  const { getTimeFormat } = useDateTimeFormat()

  const buyerSellersData = useMemo<IBuyerSeller[]>(
    () => Object.values(buyerSellerObjects || {}),
    [buyerSellerObjects],
  )

  const currentBuyerIds = useMemo(() => {
    const ids = schedules.map(({ order }) => order.buyerId)
    return _.uniq(ids)
  }, [schedules])

  const currentSellerIds = useMemo(() => {
    const ids = schedules.map(({ order }) => order.sellerId)
    return _.uniq(ids)
  }, [schedules])

  const orderIds = useMemo(
    () => schedules.map(({ order }) => order.id),
    [schedules],
  )

  const {
    findBuyerSellerProductById,
    createProductOptionsByBuyerSeller,
    findSellerProductById,
  } = useQueryBuyerSellerProducts(
    {
      buyerId: currentBuyerIds,
      sellerId: currentSellerIds,
      filters: {
        joinsSellerProduct: true,
        orderTypes: [0, 2],
      },
    },
    {
      enabled: Boolean(currentBuyerIds.length && currentSellerIds.length),
    },
  )

  const { orderExtras } = useQueryOrderExtras(
    {
      filters: {
        orderId: orderIds,
      },
    },
    { enabled: Boolean(orderIds.length > 0) },
  )

  const spIds = useMemo(
    () => _.uniq(orderExtras.map(({ sellerProductId }) => sellerProductId)),
    [orderExtras],
  )

  const { sellerProductsObj } = useQuerySellerProducts(
    { filters: { id: spIds } },
    { enabled: Boolean(spIds) },
  )

  const { confirmation } = useConfirmationProvider()

  const isDifferentTerminal = useCallback((schedule: ISchedule) => {
    const { nextLoads } = schedule
    if (nextLoads && nextLoads?.length > 0) {
      const { beginSellerTerminalId } = nextLoads[0]
      return schedule.sellerTerminalId !== beginSellerTerminalId
    }

    return false
  }, [])

  const onChangeDropdownFields = useCallback(
    (scheduleId: number, field: keyof ISchedule) =>
      async (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        event: ActionMeta<any>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        { value }: IConcordFormOnChangeParams<any>,
      ) => {
        const response = await apiClient.schedules.update(scheduleId, {
          schedule: {
            [field]: value,
          },
        })
        console.log('response', response)
      },
    [],
  )

  const cellTimeClassName = useCallback((minutes: number) => {
    // negative number
    if (Math.sign(minutes) === -1) {
      const positiveNum = Math.abs(minutes)
      if (positiveNum >= 60) {
        return 'moreThanOneHourLate'
      }
      if (positiveNum >= 30) {
        return 'moreThan30MinutesLate'
      }
      if (positiveNum >= 15) {
        return 'moreThan15MinutesLate'
      }
      if (positiveNum >= 5) {
        return 'moreThan5MinutesLate'
      }
    }

    if (minutes > 10) {
      return 'earlier10Minutes'
    }

    return 'between10MinsEarlyTo5MinutesLate'
  }, [])

  const getBackgroundBasedOnTime = useCallback(
    (field: string, { nextLoads }: ISchedule) => {
      if (nextLoads) {
        const basedOnField =
          field === 'nextArrivalTime' ? 'arriveBuyerTime' : 'ticketTime'
        const nextLoad: IScheduleLoad = getMinDate(nextLoads || [])
        if (nextLoad) {
          const value = nextLoad[basedOnField]
          const minutesFromNow = countMinutesFromTimeToNow(value)
          return cellTimeClassName(minutesFromNow)
        }
        return ''
      }
      return ''
    },
    [cellTimeClassName],
  )

  const getCellClassName = useCallback(
    ({ cell, row }: { cell: RTCell<ISchedule>; row: RTRow<ISchedule> }) => {
      const field = cell.column.id
      const rowData = row.original
      if (field === 'sellerTerminalId' && isDifferentTerminal(rowData)) {
        return 'bg-light-red'
      }

      if (field && ['nextArrivalTime', 'nextTicketTime'].includes(field)) {
        return getBackgroundBasedOnTime(field, rowData)
      }
    },
    [isDifferentTerminal, getBackgroundBasedOnTime],
  )

  const onCellEditEnd = useCallback(
    async (value: any, cell: RTCell<ISchedule>) => {
      const { column, row } = cell
      const columnField = column.id
      const rowId = row.original.id
      try {
        const response = await apiClient.schedules.update(Number(rowId), {
          schedule: {
            [columnField]: value,
          },
        })
        afterUpdateSchedule && afterUpdateSchedule(response)
      } catch (error) {
        console.log('error', error)
      }
    },
    [afterUpdateSchedule],
  )

  const afterCreateNote = useCallback((note: INote) => {
    setNotesData(prev => [...prev, note])
  }, [])

  const afterUpdateNote = useCallback((note: INote) => {
    setNotesData(prev =>
      produce(prev, draft => {
        const index = draft.findIndex(item => note.id === item.id)
        if (index !== -1) {
          draft[index] = {
            ...draft[index],
            ...note,
          }
        }
      }),
    )
  }, [])

  const afterDeleteNote = useCallback((note: INote) => {
    setNotesData(prev =>
      produce(prev, draft => {
        const index = draft.findIndex(item => note.id === item.id)
        if (index !== -1) {
          draft.splice(index, 1)
        }
      }),
    )
  }, [])

  const onOpenScheduleLineGraph = useCallback(
    (rowData: ISchedule, scheduleIds?: number[]) => () => {
      setLineGraphState({
        isOpen: true,
        orderIds: [rowData.orderId],
        scheduleIds,
      })
    },
    [],
  )

  const onCloseScheduleLineGraph = useCallback(() => {
    setLineGraphState({
      isOpen: false,
      orderIds: [],
      scheduleIds: undefined,
    })
  }, [])

  const onChangeLdsQtyField = useCallback(
    (schedule: ISchedule) =>
      async (params: ICustomLdsQtyFieldOnChangeParams) => {
        const response = await apiClient.schedules.update(schedule.id, {
          schedule: {
            lds: params.lds || 0,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            qty: (params.qty || 0) as any,
          },
        })
        afterUpdateSchedule && afterUpdateSchedule(response)
      },
    [afterUpdateSchedule],
  )

  const onOpenOrderForm = useCallback(
    (schedule: ISchedule, defaultTab?: number | string) => () => {
      setIsOpenOrderForm(true)
      setSelectedSchedule(schedule)
      if (defaultTab !== undefined) {
        setDefaultTab(defaultTab)
      }
    },
    [],
  )

  const onCloseOrderForm = useCallback(() => {
    setIsOpenOrderForm(false)
    setSelectedSchedule(null)
    setDefaultTab(undefined)
  }, [])

  const onCloseOrderExtrasDialog = useCallback(() => {
    setOrderExtrasDialog({
      isOpen: false,
      orderId: undefined,
    })
  }, [])

  const updateOrder = useCallback(
    async (orderId: number, payload: Partial<IOrderFormData>) => {
      const { order, errors } = await apiClient.orders.update(orderId, {
        order: payload,
      })
      if ((errors as string[]).length > 0) {
        toast.error(toastMessages.createError)
      } else {
        return order
      }
    },
    [],
  )

  const updateSchedule = useCallback(
    async (scheduleId: number, payload: Partial<IScheduleFormData>) => {
      const { errors, ...schedule } = await apiClient.schedules.update(
        scheduleId,
        {
          schedule: payload,
        },
      )
      if ((errors as string[]).length > 0) {
        toast.error(toastMessages.createError)
      } else {
        return schedule
      }
    },
    [],
  )

  const onChangeBuyerSellerProduct = useCallback(
    (schedule: ISchedule) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (event: any, { value }: IConcordFormOnChangeParams<any>) => {
        if (
          !schedule.buyerSellerProductId &&
          schedule.order.buyerSellerProductId
        ) {
          // show confirm
          const result = await confirmation({
            message: 'Should it be set on this schedule only or the order?',
            className: 'SchedulesTable__confirmation',
            buttons: [
              {
                action: 'schedule',
                text: 'Update Schedule only',
                color: 'primary',
              },
              {
                action: 'order',
                text: 'Update Order',
                color: 'warning',
              },
            ],
          })

          if (result === 'order') {
            const newOrder = await updateOrder(schedule.order.id, {
              buyerSellerProductId: value,
            })
            if (newOrder) {
              const updatedSchedule = { ...schedule, order: newOrder }
              afterUpdateSchedule && afterUpdateSchedule(updatedSchedule)
            }
          } else {
            const newSchedule = await updateSchedule(schedule.id, {
              buyerSellerProductId: value,
            })
            afterUpdateSchedule &&
              afterUpdateSchedule({ ...schedule, ...newSchedule })
          }
        } else {
          const newSchedule = await updateSchedule(schedule.id, {
            buyerSellerProductId: value,
          })
          afterUpdateSchedule &&
            afterUpdateSchedule({ ...schedule, ...newSchedule })
        }
      },
    [afterUpdateSchedule, confirmation, updateOrder, updateSchedule],
  )

  const getBuyerSellerProductTextColor = useCallback((schedule: ISchedule) => {
    if (schedule.buyerSellerProductId) {
      return 'dark'
    }
    if (schedule.order.buyerSellerProductId) {
      return 'medium'
    }

    return 'concord'
  }, [])

  const renderBuyerSellerProductCell = useCallback(
    (schedule: ISchedule) => {
      const productId =
        schedule.buyerSellerProductId || schedule.order.buyerSellerProductId

      const color = getBuyerSellerProductTextColor(schedule)

      const buyerSellerProduct =
        findBuyerSellerProductById(productId as number) ||
        findSellerProductById(productId as number)
      let text = 'Select product'
      let tooltip = ''

      if (buyerSellerProduct) {
        if ((buyerSellerProduct as IBuyerSellerProduct).sellerProductCode) {
          text = (buyerSellerProduct as IBuyerSellerProduct)
            .sellerProductCode as string
        } else {
          text = buyerSellerProduct.code || 'Unknown'
        }
      }

      if (
        !schedule.buyerSellerProductId &&
        schedule.order.buyerSellerProductId
      ) {
        tooltip = 'Product is set on order'
      }

      return (
        <ToolTipOverlay content={tooltip} placement='top'>
          <IonText color={color}>{text}</IonText>
        </ToolTipOverlay>
      )
    },
    [
      findBuyerSellerProductById,
      findSellerProductById,
      getBuyerSellerProductTextColor,
    ],
  )

  const getTime = useCallback((time: string) => {
    return Number(time)
  }, [])

  const onChangeScheduleStatus = useCallback(
    (cell: RTCell<ISchedule>) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (event: ActionMeta<any>, { value }: IConcordFormOnChangeParams<any>) => {
        onCellEditEnd(value, cell)
      },
    [onCellEditEnd],
  )

  const renderExtrasCol = useCallback(
    (id: number) => {
      const items = orderExtras.filter(({ orderId }) => orderId === id)

      const scheduleExtras = items.filter(({ scheduleId }) => scheduleId)
      const orderExtrasFound = items.filter(({ scheduleId }) => !scheduleId)

      return (
        <div>
          <div
            style={{
              padding: 8,
              border: '1px solid var(--bs-dark)',
              borderRadius: 8,
              marginBottom: 8,
            }}
          >
            <p className='mb-0' style={{ fontWeight: 600 }}>
              Order Extras: <Badge>{orderExtrasFound.length}</Badge>
            </p>
            <ul>
              {orderExtrasFound.map(({ sellerProductId }) => (
                <li key={`schEx${sellerProductId}`}>
                  {sellerProductsObj[sellerProductId]?.code} -{' '}
                  {sellerProductsObj[sellerProductId]?.name}
                </li>
              ))}
            </ul>
          </div>
          <div
            style={{
              padding: 8,
              border: '1px solid var(--bs-dark)',
              borderRadius: 8,
            }}
          >
            <p className='mb-0' style={{ fontWeight: 600 }}>
              Schedule Extras: <Badge>{scheduleExtras.length}</Badge>
            </p>
            <ul>
              {scheduleExtras.map(({ sellerProductId }) => (
                <li key={`schEx${sellerProductId}`}>
                  {sellerProductsObj[sellerProductId]?.code} -{' '}
                  {sellerProductsObj[sellerProductId]?.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )
    },
    [orderExtras, sellerProductsObj],
  )

  const columns = useMemo<IRTColumnDef<ISchedule>[]>(
    () => [
      {
        header: '#',
        accessorKey: 'order.num',
        maxSize: 70,
        // hide: isHiddenOrderNumColumn,
        Cell({ cell, row }) {
          const cellData = cell.getValue<string>()
          const rowData = row.original
          const str = renderExtrasCol(rowData.orderId)

          return (
            <div>
              <div>
                <ToolTipOverlay content='Order #' placement='top'>
                  <span
                    className='hyperLink'
                    onClick={onOpenOrderForm(rowData)}
                  >
                    {cellData}
                  </span>
                </ToolTipOverlay>
                &nbsp;-&nbsp;
                <ToolTipOverlay content='Schedule #' placement='top'>
                  <span
                    className='hyperLink'
                    onClick={onOpenOrderForm(
                      rowData,
                      rowData.scheduleNumber as string,
                    )}
                  >
                    {rowData.scheduleNumber || '-'}
                  </span>
                </ToolTipOverlay>
              </div>

              <span
                className='icon'
                onClick={onOpenScheduleLineGraph(rowData, [rowData.id])}
              >
                <GraphIcon />
              </span>

              <Tooltip
                placement='top'
                overlay={
                  <Popover className='SchedulesTable__popoverExtras'>
                    <Popover.Body>{str}</Popover.Body>
                  </Popover>
                }
                destroyTooltipOnHide
                overlayClassName='SchedulesTable__overlayExtras'
              >
                <span
                  className='icon'
                  onClick={() => {
                    setOrderExtrasDialog({
                      isOpen: true,
                      orderId: row.original.orderId,
                    })
                  }}
                >
                  <CartIcon color='var(--bs-dark)' />
                </span>
              </Tooltip>
            </div>
          )
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        minSize: 125,
        maxSize: 125,
        className: 'SchedulesTable__cell',
        Cell({ cell }) {
          const cellData = cell.getValue<string>()
          return (
            <DropdownWithCustomChildren
              options={defaultStatuses}
              value={cellData}
              onChange={onChangeScheduleStatus(cell)}
              components={{
                Option: props => {
                  const { data } = props
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const dataOpt = (ORDER_STATUSES as any)[data.value]
                  return (
                    <div
                      className={clsx(
                        'SchedulesTable__statusDropdown',
                        `bg-${dataOpt.color}`,
                        {
                          'text-light': dataOpt.color === 'dark',
                        },
                      )}
                    >
                      <span
                        style={{
                          verticalAlign: 'middle',
                          marginRight: 4,
                          fontSize: 18,
                          display: 'inline-block',
                          marginTop: 3,
                        }}
                      >
                        <IonIcon icon={dataOpt?.icon} />
                      </span>
                      <components.Option {...props} />
                    </div>
                  )
                },
              }}
            >
              <Badge
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                bg={(ORDER_STATUSES as any)[cellData]?.color}
                style={{ fontSize: 12, verticalAlign: 'middle' }}
              >
                <span
                  style={{
                    verticalAlign: 'middle',
                    marginRight: 4,
                    fontSize: 18,
                    display: 'inline-block',
                    marginTop: 3,
                  }}
                >
                  <IonIcon
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    icon={(ORDER_STATUSES as any)[cellData]?.icon}
                  />
                </span>
                <span style={{ verticalAlign: 'middle' }}>{cellData}</span>
              </Badge>
            </DropdownWithCustomChildren>
          )
        },
      },
      {
        header: 'Buyer / Terminal',
        id: 'buyerId',
        accessorFn: rowData => companyObjects[rowData.order.buyerId]?.name,
        Cell({ row }) {
          const rowData = row.original

          const buyer = companyObjects[rowData.order.buyerId]
          const terminal = terminalObjects[rowData.order.buyerTerminalId]

          return (
            <div className='d-flex flex-row align-items-center'>
              <CompanyAvatar
                company={{
                  value: buyer?.id,
                  label: buyer?.name,
                  image: buyer?.logo,
                  code: buyer?.code,
                }}
                companyType={EScope.buyer}
              />
              &nbsp;{terminal?.name}
            </div>
          )
        },
      },
      {
        header: 'Seller / Terminal',
        id: 'sellerId',
        accessorFn: rowData => companyObjects[rowData.order.sellerId]?.name,
        className: 'SchedulesTable__cell',
        Cell({ row }) {
          const rowData = row.original

          const seller = companyObjects[rowData.order.sellerId]

          const terminal = terminalObjects[rowData.sellerTerminalId]
          const filteredSellerTerminal = terminalOptions.filter(
            ({ companyId }) => companyId === rowData.order.sellerId,
          )
          if (isDifferentTerminal(rowData) && rowData.nextLoads) {
            const { nextLoads } = rowData
            const { beginSellerTerminalId } = nextLoads[0]
            const loadTerminal = terminalObjects[beginSellerTerminalId]
            const loadTerminalCompany = companyObjects[loadTerminal?.companyId]
            const terminalCompany = companyObjects[terminal?.companyId]

            return (
              <>
                <CompanyInfo
                  searchableGoogle={false}
                  company={{
                    value: seller?.id,
                    label: seller?.name,
                    name: seller?.name,
                    image: seller?.logo,
                    code: seller?.code,
                  }}
                  companyType={EScope.seller}
                />
                <DropdownWithCustomChildren
                  options={filteredSellerTerminal}
                  onChange={onChangeDropdownFields(
                    rowData.id,
                    'sellerTerminalId',
                  )}
                >
                  <div className='sellerTerminalCell'>
                    <div className='item'>
                      <div className='text'>Default:</div>
                      <CompanyInfo
                        width={25}
                        height={25}
                        company={{
                          value: terminal?.id,
                          label: terminal?.name,
                          name: terminal?.name,
                          code: terminalCompany?.code,
                          image: terminalCompany?.logo,
                        }}
                        hideSubtitle
                        hideAnchor
                      />
                    </div>

                    <div className='item'>
                      <div className='text'>Next LD:</div>
                      <CompanyInfo
                        style={{ marginTop: 4 }}
                        width={25}
                        height={25}
                        company={{
                          value: loadTerminalCompany?.id,
                          label: loadTerminal.name,
                          name: loadTerminal.name,
                          code: loadTerminalCompany?.code,
                          image: loadTerminalCompany?.logo,
                        }}
                        hideSubtitle
                        hideAnchor
                      />
                    </div>
                  </div>
                </DropdownWithCustomChildren>
              </>
            )
          }

          return (
            <div
              className='d-flex flex-row align-items-center'
              style={{ width: '100%', height: '100%' }}
            >
              <CompanyAvatar
                company={{
                  value: seller?.id,
                  label: seller?.name,
                  image: seller?.logo,
                  code: seller?.code,
                }}
                companyType={EScope.seller}
              />

              <DropdownWithCustomChildren
                options={filteredSellerTerminal}
                onChange={onChangeDropdownFields(
                  rowData.id,
                  'sellerTerminalId',
                )}
              >
                {terminal?.name}
              </DropdownWithCustomChildren>
            </div>
          )
        },
      },
      {
        header: 'Fleet',
        id: 'fleetId',
        accessorFn: rowData => companyObjects[rowData.fleetId]?.name,
        maxSize: 100,
        className: 'SchedulesTable__cell',
        Cell({ row }) {
          const rowData = row.original

          const company = companyObjects[rowData.fleetId]
          return (
            <DropdownWithCustomChildren
              options={fleetOptions}
              onChange={onChangeDropdownFields(rowData.id, 'fleetId')}
            >
              <CompanyAvatar
                company={{
                  value: company?.id,
                  label: company?.name,
                  name: company?.name,
                  image: company?.logo,
                  code: company?.code,
                }}
                companyType={EScope.fleet}
                tooltipMessage={company?.name}
              />
              &nbsp;{company?.code}
            </DropdownWithCustomChildren>
          )
        },
      },
      {
        header: 'Product',
        accessorKey: 'buyerSellerProductId',
        className: 'SchedulesTable__cell',
        Cell({ cell, row }) {
          const cellData = cell.getValue<number>()
          const rowData = row.original

          const value = cellData || rowData.order.buyerSellerProductId
          const { order } = rowData
          const buyerSellerData = buyerSellersData.find(
            ({ buyerId, sellerId }) =>
              order.sellerId === sellerId && order.buyerId === buyerId,
          )
          const options = createProductOptionsByBuyerSeller(
            buyerSellerData?.id as number,
            order.sellerId,
          )

          return (
            <DropdownWithCustomChildren
              options={options}
              onChange={onChangeBuyerSellerProduct(rowData)}
              value={value}
            >
              {renderBuyerSellerProductCell(rowData)}
            </DropdownWithCustomChildren>
          )
        },
      },
      {
        header: 'Date',
        id: 'date',
        accessorFn: rowData =>
          DateTime.fromISO(rowData.startTime).toFormat('MMM-d'),
        size: 80,
      },
      {
        header: 'Start time',
        id: 'startTime',
        accessorFn: rowData => getTimeFormat(rowData.startTime),
        size: 120,
      },
      {
        header: 'Next Arrival',
        id: 'nextArrivalTime',
        accessorFn: rowData =>
          getMinDate(rowData.nextLoads || [], 'arriveBuyerTime')
            ?.arriveBuyerTime,
        maxSize: 120,
        Cell({ cell }) {
          const cellValue = cell.getValue<string>()

          if (cellValue) {
            const minutesFromNow = countMinutesFromTimeToNow(cellValue)

            return (
              <ToolTipOverlay content={`${minutesFromNow} min`} placement='top'>
                <span>{getTimeFormat(cellValue)}</span>
              </ToolTipOverlay>
            )
          }
          return ''
        },
      },
      {
        header: 'Next Ticket',
        id: 'nextTicketTime',
        accessorFn: rowData =>
          getMinDate(rowData.nextLoads || [], 'ticketTime')?.ticketTime,
        maxSize: 120,
        Cell({ cell }) {
          const cellValue = cell.getValue<string>()

          if (cellValue) {
            const minutesFromNow = countMinutesFromTimeToNow(cellValue)

            return (
              <ToolTipOverlay
                content={`${getTimeFormat(cellValue)}`}
                placement='top'
              >
                <span>{`${minutesFromNow} min`}</span>
              </ToolTipOverlay>
            )
          }
          return ''
        },
      },
      {
        header: 'Travel Time',
        accessorKey: 'routeTravelTime',
        maxSize: 120,
        Cell: ({ row }) => {
          const rowData = row.original

          const { nextLoads = [] } = rowData
          if (nextLoads.length === 0) {
            return null
          }

          const {
            loadTime,
            ticketToLoadTime,
            travelTimeToBuyer,
            travelTimeToSeller,
          } = nextLoads[0]

          return (
            <ToolTipOverlay
              className='SchedulesTable__tooltip'
              content={
                <ul>
                  <li>
                    <span>Ticket to Load: </span>
                    <span>{getTime(ticketToLoadTime)} min</span>
                  </li>
                  <li>
                    <span>Loading: </span>
                    <span>{getTime(loadTime)} min</span>
                  </li>
                  <li>
                    <span>Travel to Seller: </span>
                    <span>{getTime(travelTimeToSeller)} min</span>
                  </li>
                </ul>
              }
              placement='top'
            >
              <span>{getTime(travelTimeToBuyer)} min</span>
            </ToolTipOverlay>
          )
        },
      },
      {
        header: 'Qty / LDs',
        id: 'ldsOrQty',
        accessorFn: rowData => rowData.qty,
        className: 'SchedulesTable__cell',
        Cell({ row }) {
          const rowData = row.original

          const { plus, sellerTerminalId } = rowData
          return (
            <CustomLdsQtyField
              sellerTerminalId={sellerTerminalId}
              plus={plus}
              qty={rowData.qty}
              qtyCounter={rowData.qtyCounter}
              lds={rowData.lds}
              ldsCounter={rowData.ldsCounter}
              onChange={onChangeLdsQtyField(rowData)}
            />
          )
        },
      },
      {
        header: 'Spacing',
        accessorKey: 'spacing',
        enableEditing: true,
        maxSize: 100,
        editVariant: EFieldType.number,
      },
      {
        header: 'LD Size',
        accessorKey: 'loadSize',
        enableEditing: true,
        size: 100,
        editVariant: EFieldType.number,
      },
    ],
    [
      onOpenOrderForm,
      onChangeScheduleStatus,
      companyObjects,
      terminalObjects,
      terminalOptions,
      isDifferentTerminal,
      onChangeDropdownFields,
      fleetOptions,
      buyerSellersData,
      createProductOptionsByBuyerSeller,
      onChangeBuyerSellerProduct,
      renderBuyerSellerProductCell,
      getTimeFormat,
      getTime,
      onChangeLdsQtyField,
      renderExtrasCol,
      onOpenScheduleLineGraph,
    ],
  )

  const fetchScheduleNotes = useCallback(async () => {
    if (schedules.length > 0) {
      const scheduleIds = schedules.map(({ id }) => id)
      const response = await apiClient.notes.get({
        filters: {
          noteableId: scheduleIds,
          noteableType: ENoteableType.Schedule,
        },
      })
      setNotesData(response.notes)
    }
  }, [schedules])

  const fetchLoads = useCallback(async () => {
    if (schedules.length > 0) {
      const scheduleIds = schedules.map(({ id }) => id)
      const response = await apiClient.loads.get({
        filters: {
          scheduleId: scheduleIds,
        },
      })
      setLoadsData(response.loads)
    }
  }, [schedules])

  useDeepCompareEffect(() => {
    fetchLoads()
    fetchScheduleNotes()
  }, [schedules])

  return {
    columns,
    lineGraphState,
    notesData,
    loadsData,
    selectedSchedule,
    isOpenOrderForm,
    defaultTab,
    tableHeight,
    filterOptions,
    state,
    scheduleLoads,
    schedules,
    afterCreateNote,
    afterUpdateNote,
    afterDeleteNote,
    getCellClassName,
    onCellEditEnd,
    onCloseScheduleLineGraph,
    onCloseOrderForm,
    onCloseOrderExtrasDialog,
    afterUpdateOrder,
    onOpenScheduleLineGraph,
    afterUpdateScheduleLoad,
    enableTopToolbar,
    manualFiltering,
    toolbarProps,
    onColumnFiltersChange,
    renderDetailPanel,
    onSortingChange,
    orderExtrasDialog,
  }
}

export default useSchedulesTable

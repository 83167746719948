import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  useQueryDriversNew,
  useQueryTerminals,
  useQueryTruckFleets,
  useQueryWorkPayTypes,
} from '~/hooks/useQueryData'

import _ from 'lodash'

import { IRTColumnDef, CompanyInfo, RTCell } from '~/components/shared'
import { selectMyCurrentCompany } from '~/redux/selectors'
import {
  CHECK_STATUS_COLOR,
  WORKER_PAYMENT_METHOD_PREFERENCES,
  WORKER_STATUSES,
  WORKER_TAX_TYPES,
  WORKER_TYPES,
} from '~/utils/constants'
import { LicenseCell } from './LicenseCell'
import { Badge } from 'react-bootstrap'
import { IUserData } from '~/hooks/useQueryData/useQueryUsers/useQueryUsers'
import buildFullName from '~/utils/buildFullName'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import { IUpdateDriverPayload } from '~/types/models/IDriver'

export interface IUseTableWorkerColumnsProps {
  updateDriverData: (
    rowData: IUserData,
    payload: IUpdateDriverPayload['driver'],
  ) => void
}

const useTableWorkerColumns = (props: IUseTableWorkerColumnsProps) => {
  const { updateDriverData } = props

  const currentCompany = useSelector(selectMyCurrentCompany)

  const { stateOptions } = useQueryDriversNew()

  const { workPayTypeOptions } = useQueryWorkPayTypes()
  const {
    currentTruckFleetOptions,
    assignedCurrentTruckFleetOptions,
    defaultTruckFleetOptions,
    assignedDefaultTruckFleetOptions,
  } = useQueryTruckFleets()

  const { terminalOptions } = useQueryTerminals({
    filters: {
      companyId: currentCompany.id,
    },
  })

  const workerTypeOptions = useMemo(
    () =>
      Object.values(WORKER_TYPES).map(workerType => ({
        value: workerType,
        label: _.startCase(workerType),
      })),
    [],
  )

  const workerStatusOptions = useMemo(
    () =>
      Object.values(WORKER_STATUSES).map(wst => ({
        value: wst,
        label: _.startCase(wst),
      })),
    [],
  )

  const workerTaxTypeOptions = useMemo(
    () =>
      Object.values(WORKER_TAX_TYPES).map(wtt => ({
        value: wtt,
        label: _.startCase(wtt),
      })),
    [],
  )

  const workerPaymentMethodPreferenceOptions = useMemo(
    () =>
      Object.values(WORKER_PAYMENT_METHOD_PREFERENCES).map(preference => ({
        value: preference,
        label: _.startCase(preference),
      })),
    [],
  )

  const updateDriver = useCallback(
    (cell: RTCell<IUserData, unknown>) => (object: any) => {
      const { id, ...payload } = object
      updateDriverData(cell.row.original, payload)
    },
    [updateDriverData],
  )

  const columns = useMemo<IRTColumnDef<IUserData>[]>(
    () => [
      {
        id: 'fullName',
        header: 'Driver',
        minSize: 240,
        accessorFn: row => buildFullName(row.person),
        Cell({ row }) {
          const user = row.original
          return (
            <CompanyInfo
              companyType={EScope.driver}
              hideAnchor
              hideSubtitle
              searchableGoogle={false}
              company={{
                value: user.id,
                name: buildFullName(user.person),
                label: buildFullName(user.person),
                code: buildFullName(user.person),
              }}
            />
          )
        },
      },
      {
        accessorFn: row =>
          [row.email, row.phoneNumber].filter(text => text).join(' - '),
        id: 'contactInfo',
        header: 'Contact Info',
        minSize: 250,
        Cell({ row }) {
          const userData = row.original
          return (
            <div>
              <div>
                <span>Email: </span>
                <a href={`mailto:${userData.email}`}>{userData.email}</a>
              </div>
              <div>
                <span>Phone: </span>
                <a href={`tel:${userData.phoneNumber}`}>
                  {userData.phoneNumber}
                </a>
              </div>
            </div>
          )
        },
      },
      {
        header: 'Work Pay Type',
        accessorKey: 'worker.workPayTypeId',
        enableEditing: true,
        maxSize: 140,
        editSelectOptions: workPayTypeOptions,
        editVariant: EFieldType.singleSelect,
      },
      {
        header: 'Onboard Status',
        accessorKey: 'worker.checkStatus',
        maxSize: 150,
        Cell({ cell }) {
          const cellValue = cell.getValue<string | null>()
          if (cellValue) {
            const color = (CHECK_STATUS_COLOR as any)[cellValue]
            return (
              <Badge bg={color} style={{ fontSize: 11, marginLeft: 4 }}>
                {_.startCase(cellValue)}
              </Badge>
            )
          }
          return ''
        },
      },
      {
        header: 'Status',
        accessorKey: 'worker.status',
        enableEditing: true,
        maxSize: 120,
        editSelectOptions: workerStatusOptions,
        editVariant: EFieldType.singleSelect,
      },
      {
        accessorKey: 'driverFleet.currentTruckFleetId',
        header: 'Current Truck',
        enableEditing: true,
        maxSize: 140,
        editSelectOptions: currentTruckFleetOptions as any,
        editVariant: EFieldType.singleSelect,
        editDropdownFieldProps({ cell }) {
          const cellValue = cell.getValue<number | null>()
          const opt = assignedCurrentTruckFleetOptions.options.find(
            ({ value }) => value === cellValue,
          )
          let label = ''
          if (opt) {
            label = opt.label.split(' - ')[0]
          }
          return {
            value: {
              value: opt?.value,
              label,
            },
          }
        },
      },
      {
        accessorKey: 'driverFleet.defaultTruckFleetId',
        header: 'Default Truck',
        enableEditing: true,
        maxSize: 140,
        editSelectOptions: defaultTruckFleetOptions as any,
        editVariant: EFieldType.singleSelect,
        editDropdownFieldProps({ cell }) {
          const cellValue = cell.getValue<number | null>()
          const opt = assignedDefaultTruckFleetOptions.options.find(
            ({ value }) => value === cellValue,
          )
          let label = ''
          if (opt) {
            label = opt.label.split(' - ')[0]
          }
          return {
            value: {
              value: opt?.value,
              label,
            },
          }
        },
      },
      {
        accessorKey: 'person.dob',
        header: 'DOB',
        maxSize: 120,
        enableEditing: true,
        editVariant: EFieldType.date,
      },
      {
        accessorKey: 'driverFleet.color',
        header: 'Color',
        maxSize: 100,
        enableEditing: true,
        editVariant: EFieldType.color,
      },
      {
        accessorKey: 'worker.workerType',
        header: 'Worker Type',
        enableEditing: true,
        maxSize: 140,
        editVariant: EFieldType.singleSelect,
        editSelectOptions: workerTypeOptions,
      },
      {
        id: 'worker.taxType',
        header: 'Tax Type',
        maxSize: 140,
        accessorFn: row => _.startCase(row.worker?.taxType),
        enableEditing({ original }) {
          return !original.worker?.checkUid
        },
        editVariant: EFieldType.singleSelect,
        editSelectOptions: workerTaxTypeOptions,
      },
      {
        accessorKey: 'worker.paymentMethodPreference',
        header: 'Payment Method Preference',
        enableEditing: true,
        size: 240,
        editVariant: EFieldType.singleSelect,
        editSelectOptions: workerPaymentMethodPreferenceOptions,
      },
      {
        accessorKey: 'driver.licenseNumber',
        header: 'License',
        maxSize: 180,
        Cell: ({ row, cell }) => {
          return (
            <LicenseCell
              rowData={row.original}
              stateOptions={stateOptions}
              updateDriver={updateDriver(cell)}
            />
          )
        },
      },
      {
        accessorKey: 'worker.startDate',
        header: 'Start Date',
        enableEditing: true,
        maxSize: 140,
        editVariant: EFieldType.date,
      },
      {
        accessorKey: 'worker.endDate',
        header: 'End Date',
        enableEditing: true,
        maxSize: 140,
        editVariant: EFieldType.date,
      },
      {
        accessorKey: 'worker.title',
        header: 'Title',
        enableEditing: true,
      },
      {
        accessorKey: 'worker.terminalId',
        header: 'Primary Workplace',
        enableEditing: true,
        editVariant: EFieldType.singleSelect,
        editSelectOptions: terminalOptions,
      },
      {
        accessorKey: 'driverFleet.rank',
        header: 'Rank',
        maxSize: 80,
        enableEditing: true,
        editVariant: EFieldType.number,
      },
      {
        accessorKey: 'driverFleet.group',
        header: 'Driver Group',
        maxSize: 130,
      },
    ],
    [
      workPayTypeOptions,
      workerStatusOptions,
      currentTruckFleetOptions,
      defaultTruckFleetOptions,
      workerTypeOptions,
      workerTaxTypeOptions,
      workerPaymentMethodPreferenceOptions,
      terminalOptions,
      assignedCurrentTruckFleetOptions.options,
      assignedDefaultTruckFleetOptions.options,
      stateOptions,
      updateDriver,
    ],
  )

  return { columns }
}

export default useTableWorkerColumns

import { useState } from 'react'
import { DropDownSelect } from '~/components/shared'

const ClickToDropDownSelect = props => {
  const {
    options,
    value,
    onClose,
    onOpen,
    onSelect,
    className,
    children,
    clickable = true,
    style,
    ...dropdownProps
  } = props

  const [show, setShow] = useState(false)

  const openShow = e => {
    setShow(true)
    onOpen && onOpen(e)
  }

  const closeShow = e => {
    setShow(false)
    onClose && onClose(e)
  }

  const toggleShow = e => {
    show ? closeShow(e) : openShow(e)
  }

  const onChange = item => {
    setShow(false)
    onSelect && item.value !== value && onSelect(item.value)
  }

  return (
    <div
      className={className}
      onClick={e => {
        if (clickable) {
          toggleShow(e)
        }
      }}
    >
      {children}

      {show && (
        <DropDownSelect
          showMenu={show}
          selectOptions={options}
          value={value}
          onChange={onChange}
          onCloseDropdown={closeShow}
          {...dropdownProps}
        />
      )}
    </div>
  )
}

// ClickToDropDownSelect.propTypes = {
//   options: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string,
//       value: PropTypes.number,
//     }),
//   ),
//   value: PropTypes.number,
//   onSelect: PropTypes.func,
//   className: PropTypes.string,
//   children: PropTypes.node,
// }

export default ClickToDropDownSelect

import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'

import { selectSessionUser } from '~/redux/selectors'
import { NOT_SET_OPTION } from '~/utils/constants'

import type { ICommonOption } from '~/types/models/ICommonModel'
import type { IUser } from '~/types/models/IUser'
import useModifyWorkPayTypes from './useModifyWorkPayTypes'
import { IGetWorkPayTypesResponse } from '~/types/models/IWorkPayType'

const useQueryWorkPayTypes = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  params = {},
  options: UseQueryOptions<IGetWorkPayTypesResponse> = {},
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['workPayTypes', sessionUser?.id],
    queryFn: () => apiClient.workPayTypes.get(),
    ...options,
  })

  const { addWorkPayType, updateWorkPayType, deleteWorkPayType } =
    useModifyWorkPayTypes()

  const workPayTypes = useMemo(
    () => data?.workPayTypes || [],
    [data?.workPayTypes],
  )

  const workPayTypeOptions = useMemo<ICommonOption[]>(() => {
    const options = workPayTypes.map(({ id, name }) => ({
      value: id,
      label: name,
    }))

    return [NOT_SET_OPTION, ...options]
  }, [workPayTypes])

  return {
    workPayTypes,
    workPayTypeOptions,
    isLoadingWorkPayTypes: isLoading,
    addWorkPayType,
    updateWorkPayType,
    deleteWorkPayType,
  }
}

export default useQueryWorkPayTypes

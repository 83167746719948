import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import useFuzzy from '~/hooks/useFuzzy'

import {
  IonList,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react'
import { CompanyAvatar, CommonDialog } from '~/components/shared'
import { sessionService } from 'redux-react-session'
import {
  selectSessionUser,
  selectCurrentDriverFleet,
  selectDriverFleetsList,
} from '~/redux/selectors'
import { toast } from 'react-toastify'

import './DriverFleetModal.scss'

const DriverFleetModal = ({ showModal, dismissModal }) => {
  const sessionUser = useSelector(selectSessionUser)
  const driverFleets = useSelector(selectDriverFleetsList)
  const currentDriverFleet = useSelector(selectCurrentDriverFleet)
  const [selectedDriverFleetId, setSelectedDriverFleetId] = useState(null)
  const [loading, setLoading] = useState(false)

  const {
    seachedList: searchedDriverFleets,
    searchValue,
    onSearch,
  } = useFuzzy(driverFleets, {
    keys: ['fleet.name', 'fleet.code'],
  })

  const onChangeFleet = useCallback(async () => {
    setLoading(true)
    try {
      const findDriverFleet = driverFleets.find(
        ({ id }) => id === selectedDriverFleetId,
      )
      if (findDriverFleet) {
        await sessionService.saveUser({
          ...sessionUser,
          currentFleet: findDriverFleet.fleet,
        })
      }
      window.location.reload()
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }, [driverFleets, selectedDriverFleetId, sessionUser])

  useEffect(() => {
    if (currentDriverFleet?.id) {
      setSelectedDriverFleetId(currentDriverFleet?.id)
    }
  }, [currentDriverFleet?.id])

  return (
    <CommonDialog
      className='DriverFleetModal__root'
      open={showModal}
      onClose={dismissModal}
      title="Driver's Fleet"
      onOk={onChangeFleet}
      okText='Submit'
      headerProps={{
        mode: 'ios',
      }}
      okProps={{
        loading,
      }}
    >
      <IonList className='DriverFleetModal__list'>
        <IonSearchbar
          value={searchValue}
          onIonChange={e => onSearch(e.detail.value)}
        />

        <IonRadioGroup
          value={selectedDriverFleetId}
          onIonChange={e => {
            setSelectedDriverFleetId(e.detail.value)
          }}
        >
          {searchedDriverFleets &&
            searchedDriverFleets.map(({ person, id }) => (
              <IonItem key={id}>
                <IonRadio slot='start' value={id} />
                <CompanyAvatar
                  company={{
                    // image: person?.url,
                    name: person?.initials,
                    value: id,
                  }}
                />
                <IonLabel className='ms-2'>{person?.firstName}</IonLabel>
              </IonItem>
            ))}
        </IonRadioGroup>
      </IonList>
      {/* <Button
        expand='full'
        onClick={onChangeFleet}
        label='Submit'
        loading={loading}
      /> */}
    </CommonDialog>
  )
}

export default DriverFleetModal

export const testBuyers = [
  {
    label: 'JP Noonan',
    value: 23,
  },
  {
    label: 'Blue Rock Agg',
    value: 1,
  },
  {
    label: 'Ciment Quebec Inc.',
    value: 11,
  },
  {
    label: 'Norlite',
    value: 17,
  },
  {
    label: 'JP Noonan',
    value: 23,
  },
  {
    label: 'Blue Rock Agg',
    value: 1,
  },
  {
    label: 'Ciment Quebec Inc.',
    value: 11,
  },
  {
    label: 'JP Noonan',
    value: 23,
  },
  {
    label: 'Blue Rock Agg',
    value: 1,
  },
  {
    label: 'Ciment Quebec Inc.',
    value: 11,
  },
  {
    label: 'JP Noonan',
    value: 23,
  },
  {
    label: 'Blue Rock Agg',
    value: 1,
  },
  {
    label: 'Ciment Quebec Inc.',
    value: 11,
  },
  {
    label: 'JP Noonan',
    value: 23,
  },
  {
    label: 'Blue Rock Agg',
    value: 1,
  },
  {
    label: 'Ciment Quebec Inc.',
    value: 11,
  },
  {
    label: 'JP Noonan',
    value: 23,
  },
  {
    label: 'Blue Rock Agg',
    value: 1,
  },
  {
    label: 'JP Noonan',
    value: 23,
  },
  {
    label: 'Blue Rock Agg',
    value: 1,
  },
  {
    label: 'Ciment Quebec Inc.',
    value: 11,
  },
  {
    label: 'JP Noonan',
    value: 23,
  },
  {
    label: 'Blue Rock Agg',
    value: 1,
  },
  {
    label: 'JP Noonan',
    value: 23,
  },
  {
    label: 'Blue Rock Agg',
    value: 1,
  },
  {
    label: 'Ciment Quebec Inc.',
    value: 11,
  },
  {
    label: 'JP Noonan',
    value: 23,
  },
  {
    label: 'Blue Rock Agg',
    value: 1,
  },
]

export const testTerminals = [
  {
    label: 'Charlestown',
    value: 15,
    code: 'asa3',
  },
  {
    label: 'Austin',
    value: 932,
    code: 'asa2',
  },
  {
    label: 'Los Angeles',
    value: 256,
    code: 'asa1',
  },
]

export const testBSPs = [
  {
    label: 'GROUND SLAG',
    value: 15,
  },
  {
    label: 'Freight',
    value: 26,
  },
  {
    label: 'Fly Ash',
    value: 18,
  },
]

//return tomorrow's formatted date as default for form
const getTomorrowDate = () => {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  const month =
    tomorrow.getMonth() < 8
      ? '0' + (tomorrow.getMonth() + 1)
      : tomorrow.getMonth() + 1
  const day =
    tomorrow.getDate() < 10 ? '0' + tomorrow.getDate() : tomorrow.getDate()

  return `${tomorrow.getFullYear()}-${month}-${day}`
}

export const defaultForm = {
  seller: null,
  buyer: null,
  fleet: null,
  buyerTerminal: null,
  sellerTerminal: null,
  buyerSellerProduct: {},
  date: getTomorrowDate(),
  startTime: '12:00',
  spacing: 20,
  qty: 0,
  loadSize: 0,
  status: 'Requested',
  basedOnLds: false,
  lds: 0,
}

export const defaultStatuses = [
  {
    label: 'Requested',
    value: 'Requested',
  },
  {
    label: 'Will Call',
    value: 'Will Call',
  },
  {
    label: 'Confirmed',
    value: 'Confirmed',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Hold',
    value: 'Hold',
  },
  {
    label: 'Complete',
    value: 'Complete',
  },
  {
    label: 'Canceled',
    value: 'Canceled',
  },
]

export const plusFilterOptions = [
  {
    label: 'Must have a plus LD',
    value: 'true'
  },
  {
    label: 'Must not have a plus LD',
    value: 'false'
  },
  {
    label: 'All',
    value: 'null'
  }
]


export const colorOptions = [
  { label: 'Red', value: 'red' },
  { label: 'Blue', value: 'blue' },
  { label: 'Green', value: 'green' },
  { label: 'Yellow', value: 'yellow' },
  { label: 'Purple', value: 'purple' },
  { label: 'Orange', value: 'orange' },
  { label: 'Black', value: 'black' },
  { label: 'White', value: 'white' },
]

export const CURRENTSCOPE = {
  SELLER: 'seller',
  BUYER: 'buyer',
  FLEET: 'fleet',
}

import { useCallback, useMemo, useState } from 'react'

import TruckInfoBox from '../TruckInfoBox/TruckInfoBox'

import type { ITruckInfoBoxListProps } from './type'
import type { ITruckInfoBoxOnClickParams } from '../TruckInfoBox/type'
import type { ITruck } from '~/types/models/ITruck'
import type { IDriverFleet } from '~/types/models/IDriverFleet'

const useTruckInfoBoxList = (props: ITruckInfoBoxListProps) => {
  const { trucks, onClickTruck } = props

  const [selectedSchedule, setSelectedSchedule] = useState({
    isOpen: false,
    truck: null as ITruck | null,
    driverFleet: null as IDriverFleet | null,
  })

  const selectedTruck = useMemo(
    () => selectedSchedule.truck,
    [selectedSchedule.truck],
  )

  const onClickTruckInfo = useCallback(
    (params: ITruckInfoBoxOnClickParams) => {
      const { driverFleet, isOpenInfoBox, truck } = params
      setSelectedSchedule({
        isOpen: isOpenInfoBox,
        driverFleet,
        truck,
      })
      onClickTruck && onClickTruck(params)
    },
    [onClickTruck],
  )

  const renderTrucks = useMemo(() => {
    if (selectedTruck) {
      return (
        <TruckInfoBox
          truck={selectedTruck}
          onClick={onClickTruckInfo}
          isOpenInfoBox
        />
      )
    }
    return trucks.map(truck => (
      <TruckInfoBox key={truck.id} truck={truck} onClick={onClickTruckInfo} />
    ))
  }, [onClickTruckInfo, selectedTruck, trucks])

  return { renderTrucks, selectedSchedule }
}

export default useTruckInfoBoxList

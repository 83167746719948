import { useCallback, useMemo, useRef, useState } from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import SplitPane from 'react-split-pane'
import { useIsMobile } from '~/hooks/useIsMobile'

import './styles.scss'
import { Alert, Button, ButtonGroup } from 'react-bootstrap'
import {
  CalendarIcon,
  ClockPlusIcon,
  CloseIcon,
  CommonDialogV2,
  DropdownWithCustomChildren,
  GraphIcon,
  LineChart,
  LocationIcon,
  ScheduleIcon,
  ToolTipOverlay,
  TruckIcon,
} from '~/components/shared'
import {
  useQueryOffdays,
  useQueryScheduleLoads,
  useQueryTerminals,
  useQueryUsers,
} from '~/hooks/useQueryData'
import { Calendar } from 'react-date-range'
import moment from 'moment'
import {
  START_TIME_INTERVAL_OPTIONS,
  START_TIME_SHORTCUT_OPTIONS,
} from '~/utils/constants'
import { ICommonOption } from '~/types/models/ICommonModel'
import { colord } from 'colord'
import clsx from 'clsx'
import { produce } from 'immer'
import _ from 'lodash'
import { DriverFleetList } from './DriverFleetList'
import { TimeSlotCard } from './TimeSlotCard'
import { NoteButton } from '~/components/fleet/StartTimes/StartTimesTable/NoteButton'
import { apiClient } from '~/api/ApiClient'
import { IStartTime } from '~/types/models/IStartTime'
import { toast } from 'react-toastify'
import { calculateDriversNeededByScheduleId } from '~/utils/calculateDriversNeeded'
import { IonItem, IonLabel, IonList, IonPopover } from '@ionic/react'
import { ERoundingOption, EScope } from '~/types/enums/ECommonEnum'
import { useConfirmationProvider } from '~/contexts'
import { IScheduleLoad } from '~/types/models/IScheduleLoad'
import { selectCurrentScope } from '~/redux/selectors'
import { useSelector } from 'react-redux'
import { useMeasure } from 'react-use'

function randomHexColor() {
  const randomColor = Math.floor(Math.random() * 16777215)
  return '#' + randomColor.toString(16).padStart(6, '0') // Ensure it always has 6 digits
}

export interface INewStartTime {
  color: string
  driverFleets: {
    id: number
    notes?: string
  }[]
  notes?: string
  terminalId?: number
  sellerTerminalId?: number
  time: string // HH:mm
}

export interface IStartTimeFormProps {
  afterCreateStartTimes?: (newStartTimes: IStartTime[]) => void
}

function StartTimeForm(props: IStartTimeFormProps) {
  const { afterCreateStartTimes } = props

  const isMobile = useIsMobile()

  const [buyerTerminalIdSelected, setBuyerTerminalIdSelected] =
    useState<undefined | number>(undefined)
  const [sellerTerminalIdSelected, setSellerTerminalIdSelected] =
    useState<undefined | number>(undefined)
  const [isOpenCalendar, setIsOpenCalendar] = useState(false)
  const [date, setDate] = useState<Date | undefined>(new Date())
  const [interval, setInterval] = useState<number>(10)
  const [newStartTimes, setNewStartTimes] = useState<INewStartTime[]>([])
  const [notesValues, setNotesValues] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [backendError, setBackendError] = useState('')
  const [isChartShown, setIsChartShown] = useState(false)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [roundingInterval, setRoundingInterval] = useState(10)
  const [roundingOption, setRoundingOption] = useState(ERoundingOption.nearest)

  const popoverRef = useRef<any>(null)
  const currentScope: EScope = useSelector(selectCurrentScope)

  const hasDriverFleet = useMemo(
    () => newStartTimes.every(startTime => startTime.driverFleets.length > 0),
    [newStartTimes],
  )

  const [measureRef, { width }] = useMeasure()

  const isSmallSection = useMemo(() => width <= 536, [width])

  const { confirmation } = useConfirmationProvider()

  const newStartTimesSorted = useMemo(() => {
    return _.orderBy(newStartTimes, [
      item => moment(item.time, 'H:mm').toDate(),
    ])
  }, [newStartTimes])

  const { sellerTerminalOptions, companyTerminalOptions, findTerminalById } =
    useQueryTerminals()

  const terminalsOptions = [
    {
      label: 'My Terminals',
      options: companyTerminalOptions,
    },
    {
      label: 'Seller Terminals',
      options: currentScope === EScope.seller ? [] : sellerTerminalOptions,
    },
  ]

  const { driverFleetUsers } = useQueryUsers()

  const { scheduleLoadsData } = useQueryScheduleLoads(
    {
      filters: {
        ticketTime: {
          startDate: moment(date).startOf('day').toISOString(),
          endDate: moment(date).endOf('day').toISOString(),
        },
        beginSellerTerminalId: buyerTerminalIdSelected,
      },
    },
    { enabled: Boolean(buyerTerminalIdSelected) },
  )

  const { findOffDayByWorkerId } = useQueryOffdays({
    filters: {
      startDate: {
        startDate: moment(date).startOf('day').toISOString(),
        endDate: moment(date).endOf('day').toISOString(),
      },
    },
  })

  const labelsInChart = useMemo(() => {
    const ticketTimeData = scheduleLoadsData.map(({ ticketTime }) => ticketTime)
    const sorted = ticketTimeData.sort((a, b) => {
      const dateA: any = new Date(a)
      const dateB: any = new Date(b)

      return dateA - dateB
    })
    return _.uniq(sorted)
    // .map(time => moment(time).format('HH:mm'))
  }, [scheduleLoadsData])

  const roundingOptions = useMemo(() => {
    const options = [
      {
        label: 'Closest Slot',
        value: ERoundingOption.nearest,
      },
      {
        label: 'Next Upcoming Slot',
        value: ERoundingOption.forceUp,
      },
      {
        label: 'Closest Earlier Slot',
        value: ERoundingOption.forceDown,
      },
    ]
    return options.map(({ label, value }) => (
      <option key={value} value={value} selected={roundingOption === value}>
        {label}
      </option>
    ))
  }, [roundingOption])

  const dataOnXAxisOfChart = useMemo(() => {
    const driversNeeded: any =
      calculateDriversNeededByScheduleId(scheduleLoadsData)
    const dataset: any = []
    Object.keys(driversNeeded).forEach(scheduleId => {
      const calculatedTime = driversNeeded[scheduleId]
      const data: any = []
      labelsInChart.forEach(label => {
        let numberOfDrivers = 0
        if (calculatedTime[label]) {
          numberOfDrivers = calculatedTime[label]
        }
        data.push(numberOfDrivers)
      })
      const color = randomHexColor()
      dataset.push({
        data,
        backgroundColor: color,
        borderColor: color,
      })
    })
    return dataset
  }, [labelsInChart, scheduleLoadsData])

  const intervalOptions = useMemo(() => {
    const options = [
      {
        label: '1 min',
        value: 1,
      },
      {
        label: '2 min',
        value: 2,
      },
      {
        label: '5 min',
        value: 5,
      },
      {
        label: '10 min',
        value: 10,
      },
      {
        label: '15 min',
        value: 15,
      },
      {
        label: '30 min',
        value: 30,
      },
      {
        label: '60 min',
        value: 60,
      },
    ]
    return options.map(({ label, value }) => (
      <option key={value} value={value} selected={roundingInterval === value}>
        {label}
      </option>
    ))
  }, [roundingInterval])

  const allDriverFleetIds = useMemo(
    () =>
      newStartTimesSorted.flatMap(({ driverFleets }) =>
        driverFleets.map(({ id }) => id),
      ),
    [newStartTimesSorted],
  )

  const startTimesSelected = useMemo(
    () => newStartTimes.map(({ time }) => time),
    [newStartTimes],
  )

  const dateLabel = useMemo(() => {
    if (!date) return 'Select Date'
    return moment(date).format('ddd, MMM-D')
  }, [date])

  const buyerTerminal = useMemo(
    () => findTerminalById(buyerTerminalIdSelected),
    [buyerTerminalIdSelected, findTerminalById],
  )

  const sellerTerminal = useMemo(
    () => findTerminalById(sellerTerminalIdSelected),
    [findTerminalById, sellerTerminalIdSelected],
  )

  const onOpenSetupStartTime = (event: any) => {
    console.log('event', event)
    if (popoverRef.current) {
      popoverRef.current.event = event
      setPopoverOpen(true)
    }
  }

  const onCloseSetupStartTime = () => {
    setPopoverOpen(false)
  }

  const timeSlotOptions = useMemo(() => {
    const arr: ICommonOption[] = []
    const spacing = interval || 60
    for (let i = 0; i < 1440 / spacing; i++) {
      arr.push({
        label: moment(date)
          .startOf('day')
          .add(i * spacing, 'minutes')
          .format('H:mm'),
        value: moment(date)
          .startOf('day')
          .add(i * spacing, 'minutes')
          .format('H:mm'),
      })
    }
    return arr
  }, [date, interval])

  const onCloseCalendar = () => setIsOpenCalendar(false)
  const onOpenCalendar = () => setIsOpenCalendar(true)

  const onAddTimeSlot = useCallback(
    (timeSlot: string, color?: string) => {
      setNewStartTimes(prev => [
        ...prev,
        {
          color: color || 'var(--ion-color-medium)',
          driverFleets: [],
          notes: '',
          terminalId: buyerTerminalIdSelected,
          sellerTerminalId: sellerTerminalIdSelected,
          time: timeSlot,
        },
      ])
    },
    [buyerTerminalIdSelected, sellerTerminalIdSelected],
  )

  const onUpdateTimeSlot = useCallback(
    (newValue: any, time: string, field: keyof INewStartTime) => {
      setNewStartTimes(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ time: t }) => t === time)
          if (index !== -1) {
            ;(draft[index] as any)[field] = newValue
          }
        }),
      )
    },
    [setNewStartTimes],
  )

  const onDeleteStartTime = useCallback(
    (startTime: INewStartTime) => () => {
      setNewStartTimes(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ time: t }) => t === startTime.time)
          if (index !== -1) {
            draft.splice(index, 1)
          }
        }),
      )
    },
    [setNewStartTimes],
  )

  const onAssignDriver = useCallback(
    (time: string, driverFleetId: number) => {
      setNewStartTimes(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ time: t }) => t === time)
          if (index !== -1) {
            draft[index].driverFleets.push({
              id: driverFleetId,
              notes: '',
            })
          }
        }),
      )
    },
    [setNewStartTimes],
  )

  const onChangeDriverNotes = useCallback(
    (startTime: INewStartTime, driverFleetId: number, notes: string) => {
      setNewStartTimes(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ time: t }) => t === startTime.time)
          if (index !== -1) {
            const driverFleet = draft[index].driverFleets.find(
              ({ id }) => id === driverFleetId,
            )
            if (driverFleet) {
              driverFleet.notes = notes
            }
          }
        }),
      )
    },
    [setNewStartTimes],
  )

  const onSubmitAssignments = useCallback(async () => {
    setIsSubmitting(true)
    setBackendError('')
    try {
      const payload: any = []

      newStartTimes.forEach(item => {
        const [hours, minutes] = item.time.split(':')
        const startTime = moment(date)
          .set({
            hours: Number(hours),
            minutes: Number(minutes),
            seconds: 0,
          })
          .toISOString()
        item.driverFleets.forEach(driverFleet => {
          const userFound = driverFleetUsers.find(
            user => driverFleet?.id === user.driverFleet?.id,
          )
          payload.push({
            color: item.color,
            driverId: userFound?.driver?.id,
            driverFleetId: driverFleet.id,
            fleetId: userFound?.driverFleet?.fleetId,
            startTime,
            terminalId: item.terminalId || buyerTerminalIdSelected,
            sellerTerminalId: item.sellerTerminalId || sellerTerminalIdSelected,
            notes: driverFleet.notes || item.notes || notesValues,
            workerId: userFound?.worker?.id,
          })
        })
      })
      const response = await apiClient.startTimes.create(payload)
      afterCreateStartTimes && afterCreateStartTimes(response.startTimes)
      setNewStartTimes([])
      setNotesValues('')
      setBuyerTerminalIdSelected(undefined)
      setSellerTerminalIdSelected(undefined)
      setInterval(10)
      toast.success('Start times created successfully!')
    } catch (error) {
      console.log('error', error)
      setBackendError('An error has occurred')
    } finally {
      setIsSubmitting(false)
    }
  }, [
    afterCreateStartTimes,
    buyerTerminalIdSelected,
    date,
    driverFleetUsers,
    newStartTimes,
    notesValues,
    sellerTerminalIdSelected,
  ])

  const mergeDriverSchedule = useCallback((assigments: any) => {
    const mergedSchedule: any = []

    assigments.forEach((item: any) => {
      const existingItem = mergedSchedule.find(
        (mergedItem: any) => mergedItem.time === item.time,
      )

      if (existingItem) {
        existingItem.driverFleets = [
          ...new Set(existingItem.driverFleets.concat(item.driverFleets)),
        ]
      } else {
        mergedSchedule.push({
          ...item,
          time: item.time,
          driverFleets: [...item.driverFleets],
        })
      }
    })

    return mergedSchedule
  }, [])

  const sortDriverFleetsByRank = useMemo(() => {
    const driverFleetsInTerminalSelected = driverFleetUsers.filter(
      ({ worker }) => worker?.terminalId === buyerTerminalIdSelected,
    )
    const otherDriverFleets = driverFleetUsers.filter(
      ({ worker }) => worker?.terminalId !== buyerTerminalIdSelected,
    )
    const sortedDriverFleetsInTerminal = _.orderBy(
      driverFleetsInTerminalSelected,
      ['rank'],
      ['asc'],
    )
    return [...sortedDriverFleetsInTerminal, ...otherDriverFleets]
  }, [buyerTerminalIdSelected, driverFleetUsers])

  const driverFleetIds = useMemo(
    () =>
      sortDriverFleetsByRank
        .filter(({ worker }) => !findOffDayByWorkerId(worker?.id as any))
        .map(({ driverFleet }) => driverFleet?.id as number),
    [findOffDayByWorkerId, sortDriverFleetsByRank],
  )

  function findNextItem(arr: any, currentItems: any) {
    const result = _.difference(arr, currentItems)
    return result[0]
  }

  const assignDrivers = useCallback(
    (scheduleLoads: IScheduleLoad[], driverIds: number[]) => {
      const driverAssigned: any = []
      const assignments: any = {}

      scheduleLoads.forEach(({ ticketTime }) => {
        const nextDriverId = findNextItem(driverIds, driverAssigned)
        if (assignments[ticketTime]) {
          assignments[ticketTime].push(nextDriverId)
        } else {
          assignments[ticketTime] = [nextDriverId]
        }
        driverAssigned.push(nextDriverId)
      })
      return assignments
    },
    [],
  )

  const roundTime = useCallback(
    (roundingOption: ERoundingOption, interval: number, isoDate: string) => {
      if (roundingOption === ERoundingOption.exact) {
        return isoDate
      }
      const time = new Date(isoDate)
      const roundedTime = new Date(time) // Create a copy of the original time

      switch (roundingOption) {
        case ERoundingOption.nearest:
          roundedTime.setMinutes(
            Math.round(time.getMinutes() / interval) * interval,
          )
          break
        case ERoundingOption.forceUp:
          roundedTime.setMinutes(
            Math.ceil(time.getMinutes() / interval) * interval,
          )
          break
        case ERoundingOption.forceDown:
          roundedTime.setMinutes(
            Math.floor(time.getMinutes() / interval) * interval,
          )
          break
        default:
          return 'Invalid rounding option'
      }

      return roundedTime.toISOString()
    },
    [],
  )

  const onSetupStartTimeData = useCallback(
    (roundedOption: ERoundingOption) => async () => {
      if (!buyerTerminalIdSelected) {
        await confirmation({
          message: 'Select Truck pickup terminal',
          buttons: [
            {
              text: 'Ok',
              action: 'Ok',
            },
          ],
        })
        return
      }
      const assignments = assignDrivers(scheduleLoadsData, driverFleetIds)

      const result: any = []

      Object.keys(assignments).forEach(time => {
        const timeRounded = roundTime(roundedOption, roundingInterval, time)
        const timeslot: any = {
          time: moment(timeRounded).format('H:mm'),
          driverFleets: assignments[time],
          color: 'var(--ion-color-medium)',
        }

        if (buyerTerminalIdSelected) {
          timeslot.terminalId = buyerTerminalIdSelected
        }

        if (sellerTerminalIdSelected) {
          timeslot.sellerTerminalId = sellerTerminalIdSelected
        }

        result.push(timeslot)
      })
      const uniqTime = mergeDriverSchedule(result)
      const timeWithDriverNote = uniqTime.map((item: any) => ({
        ...item,
        driverFleets: item.driverFleets.map((id: number) => ({
          id,
          notes: '',
        })),
      }))
      setNewStartTimes(timeWithDriverNote)
      onCloseSetupStartTime()
    },
    [
      assignDrivers,
      buyerTerminalIdSelected,
      confirmation,
      driverFleetIds,
      mergeDriverSchedule,
      roundTime,
      roundingInterval,
      scheduleLoadsData,
      sellerTerminalIdSelected,
    ],
  )

  const onSaveRounding = useCallback(
    (event: any) => {
      event.preventDefault()
      onSetupStartTimeData(roundingOption)()
    },
    [onSetupStartTimeData, roundingOption],
  )

  const onChangeRoundingOpt = useCallback((event: any) => {
    const value = event.target.value
    setRoundingOption(value)
  }, [])

  const onChangeRoundingInterval = useCallback((event: any) => {
    const value = event.target.value
    setRoundingInterval(value)
  }, [])

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {/* @ts-expect-error splitpane props broken*/}
        <SplitPane
          style={{ position: 'static' }}
          defaultSize='70%'
          minSize={200}
          split={isMobile ? 'horizontal' : 'vertical'}
          className='StartTimeForm__splitPaneContainer'
        >
          <div
            className='StartTimeForm__createForm'
            style={{
              width: isMobile ? '100%' : undefined,
              marginRight: isMobile ? 0 : 8,
            }}
            ref={measureRef as any}
          >
            {backendError && (
              <Alert
                variant='danger'
                style={{ fontSize: 13, margin: '0 8px 8px' }}
              >
                {backendError}
              </Alert>
            )}

            <div className='StartTimeForm__header'>
              <div
                style={{
                  marginBottom: 8,
                  display: isSmallSection ? 'block' : 'flex',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Button
                    style={{ fontSize: 14, marginRight: 8 }}
                    className={clsx({
                      isDisabled:
                        isSubmitting ||
                        newStartTimes.length === 0 ||
                        !hasDriverFleet,
                    })}
                    onClick={onSubmitAssignments}
                  >
                    Submit Driver Assignments
                  </Button>

                  {scheduleLoadsData.length > 0 && (
                    <ButtonGroup style={{ marginRight: 8 }}>
                      <Button
                        variant={isChartShown ? 'danger' : 'warning'}
                        onClick={() => {
                          setIsChartShown(prev => !prev)
                        }}
                      >
                        {isChartShown ? (
                          <CloseIcon color='white' />
                        ) : (
                          <GraphIcon />
                        )}
                      </Button>

                      <ToolTipOverlay
                        placement='top'
                        content='Setup Start Times'
                      >
                        <Button
                          variant='warning'
                          onClick={onOpenSetupStartTime}
                          id='setup-start-time'
                        >
                          <ScheduleIcon />
                        </Button>
                      </ToolTipOverlay>
                    </ButtonGroup>
                  )}
                </div>

                <div style={{ marginTop: isSmallSection ? 8 : 0 }}>
                  {START_TIME_SHORTCUT_OPTIONS.map(option => (
                    <span
                      key={option.value}
                      className={clsx(
                        'StartTimeForm__shortcutBadge clickable',
                        {
                          isDisabled: startTimesSelected.includes(option.value),
                        },
                      )}
                      style={{
                        background: option.color,
                        color: colord(option.color).isDark()
                          ? 'white'
                          : 'black',
                      }}
                      onClick={() => {
                        onAddTimeSlot(option.value, option.color)
                      }}
                    >
                      {option.label}
                    </span>
                  ))}
                </div>
              </div>

              {isChartShown && (
                <div style={{ marginBottom: 8 }}>
                  <LineChart
                    labels={labelsInChart.map(time =>
                      moment(time).format('H:mm'),
                    )}
                    datasets={dataOnXAxisOfChart}
                    isStacked
                  />
                </div>
              )}

              <div
                style={{
                  display: isSmallSection ? 'block' : 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', marginRight: 8 }}>
                  <NoteButton
                    note={notesValues}
                    onSave={setNotesValues}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  />

                  <DropdownWithCustomChildren
                    options={terminalsOptions}
                    className='StartTimeForm__dropdown'
                    onChange={(event, { value }) => {
                      setBuyerTerminalIdSelected(value)
                    }}
                    value={buyerTerminalIdSelected}
                  >
                    <ToolTipOverlay
                      placement='top'
                      content='Clockin and Truck Pickup Location'
                    >
                      <Button
                        variant='secondary'
                        className='StartTimeForm__button'
                        style={{
                          borderRadius: 0,
                        }}
                      >
                        <TruckIcon color='white' />
                        <span className='StartTimeForm__buttonLabel'>
                          {buyerTerminal?.name || 'Truck pickup terminal'}
                        </span>
                      </Button>
                    </ToolTipOverlay>
                  </DropdownWithCustomChildren>

                  <DropdownWithCustomChildren
                    options={terminalsOptions}
                    className='StartTimeForm__dropdown'
                    onChange={(event, { value }) => {
                      setSellerTerminalIdSelected(value)
                    }}
                    value={sellerTerminalIdSelected}
                  >
                    <ToolTipOverlay
                      placement='top'
                      content='Seller Terminal for first Load Pickup'
                    >
                      <Button
                        variant='secondary'
                        className='StartTimeForm__button'
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                      >
                        <LocationIcon color='white' />
                        <span className='StartTimeForm__buttonLabel'>
                          {sellerTerminal?.name || 'First load terminal'}
                        </span>
                      </Button>
                    </ToolTipOverlay>
                  </DropdownWithCustomChildren>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: isSmallSection ? 8 : 0,
                  }}
                >
                  <ButtonGroup>
                    <Button variant='secondary' onClick={onOpenCalendar}>
                      <CalendarIcon color='white' />
                      <span className='StartTimeForm__buttonLabel'>
                        {dateLabel}
                      </span>
                    </Button>
                    <DropdownWithCustomChildren
                      options={timeSlotOptions}
                      className='StartTimeForm__dropdown timeCreateButton'
                      onChange={(event, { value }) => {
                        onAddTimeSlot(value)
                      }}
                      isOptionDisabled={option =>
                        startTimesSelected.includes(option.value)
                      }
                    >
                      <ToolTipOverlay placement='top' content='Add Start Time'>
                        <Button
                          variant='secondary'
                          className='StartTimeForm__button'
                          style={{
                            borderRadius: 0,
                          }}
                        >
                          <ClockPlusIcon color='white' />
                        </Button>
                      </ToolTipOverlay>
                    </DropdownWithCustomChildren>
                    <Button variant='secondary'>
                      <select
                        style={{
                          color: 'white',
                          background: 'transparent',
                          outline: 'none',
                          fontSize: '12px',
                          verticalAlign: 'middle',
                          border: 'none',
                          marginLeft: '4px',
                        }}
                        value={interval}
                        onChange={event => {
                          setInterval(Number(event.target.value))
                        }}
                      >
                        {START_TIME_INTERVAL_OPTIONS.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>

            <div className='StartTimeForm__driversContainer'>
              {newStartTimesSorted.map(startTime => (
                <TimeSlotCard
                  key={startTime.time}
                  startTime={startTime}
                  timeSlotOptions={timeSlotOptions}
                  onUpdateTimeSlot={onUpdateTimeSlot}
                  onDeleteStartTime={onDeleteStartTime}
                  startTimesSelected={startTimesSelected}
                  onAssignDriver={onAssignDriver}
                  onChangeDriverNotes={onChangeDriverNotes}
                  assignedDriverFleetIds={allDriverFleetIds}
                />
              ))}
            </div>
          </div>
          {!isMobile && (
            <div>
              <DriverFleetList
                assignedDriverFleetIds={allDriverFleetIds}
                isBannerShown={newStartTimes.length <= 0}
                isListDisabled={newStartTimes.length <= 0}
                bannerText='Please add start times (e.g. 7:00 am) before selecting a driver'
              />
            </div>
          )}
        </SplitPane>
      </DndProvider>
      <CommonDialogV2
        isOpen={isOpenCalendar}
        onClose={onCloseCalendar}
        isHiddenOkButton
        okText='Clear'
        title='Select Date'
        className='StartTimeForm__calendarDialog'
      >
        <Calendar
          date={date}
          onChange={date => {
            setDate(date)
            onCloseCalendar()
          }}
        />
      </CommonDialogV2>

      <IonPopover
        ref={popoverRef}
        isOpen={popoverOpen}
        onDidDismiss={onCloseSetupStartTime}
        mode='ios'
        className='StartTimeForm__popover'
      >
        <IonList>
          <IonItem
            className='clickable'
            onClick={onSetupStartTimeData(ERoundingOption.exact)}
          >
            <IonLabel>Exact Time</IonLabel>
          </IonItem>
          <IonItem>
            <form
              className='CreateStartTimeForm__roundingForm'
              onSubmit={onSaveRounding}
            >
              <div className='fieldsContainer'>
                <select
                  onChange={onChangeRoundingOpt}
                  required
                  value={roundingOption}
                  style={{ width: '70%' }}
                >
                  {roundingOptions}
                </select>

                <select
                  onChange={onChangeRoundingInterval}
                  required
                  value={roundingInterval}
                  style={{ width: '30%' }}
                >
                  {intervalOptions}
                </select>
              </div>
              <Button type='submit'>Save</Button>
            </form>
          </IonItem>
        </IonList>
      </IonPopover>
    </>
  )
}

export default StartTimeForm

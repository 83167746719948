import { useState, useMemo, useCallback, useEffect } from 'react'
import { useEffectOnce } from 'react-use'

import { Tab } from 'react-bootstrap'
import { IonBadge } from '@ionic/react'

import type { IUseCommonTabProps } from './type'
import _ from 'lodash'
import clsx from 'clsx'

export const useCommonTabs = (props: IUseCommonTabProps) => {
  const { tabs, currentTab, onChangeTab, defaultTab } = props

  const [eventKey, setEventKey] = useState<string | number>('')

  const existentTabs = useMemo(
    () => tabs.filter(({ isHidden }) => isHidden !== true),
    [tabs],
  )

  const renderTabs = useCallback(() => {
    return existentTabs.map(
      ({ name, label, render, isDisabled, errors = [] }) => (
        <Tab
          eventKey={name}
          disabled={isDisabled}
          title={
            <div
              className={clsx('CommonTab__labelTabContainer', { isDisabled })}
            >
              <span>{label}</span>
              {errors.length > 0 && (
                <IonBadge color='danger'>{errors.length}</IonBadge>
              )}
            </div>
          }
          key={name}
        >
          {render()}
        </Tab>
      ),
    )
  }, [existentTabs])

  const onSelectTab = useCallback(
    (eventKey: string | null, event: React.SyntheticEvent<unknown>) => {
      event.stopPropagation()
      setEventKey(eventKey as string)
      onChangeTab && onChangeTab(eventKey as string)
    },
    [onChangeTab],
  )

  useEffectOnce(() => {
    if (existentTabs.length > 0) {
      if (defaultTab) {
        const index = existentTabs.findIndex(({ name }) => name === defaultTab)
        if (index !== -1) {
          setEventKey(existentTabs[index].name)
          onChangeTab && onChangeTab(existentTabs[index].name)
        } else {
          setEventKey(existentTabs[0].name)
          onChangeTab && onChangeTab(existentTabs[0].name)
        }
      } else {
        setEventKey(existentTabs[0].name)
        onChangeTab && onChangeTab(existentTabs[0].name)
      }
    }
  })

  useEffect(() => {
    if (!_.isNil(currentTab)) {
      setEventKey(currentTab)
    }
  }, [currentTab])

  return {
    existentTabs,
    eventKey,
    renderTabs,
    onSelectTab,
  }
}

import React, { useCallback, useMemo } from 'react'

import { Modal } from 'react-bootstrap'
import { IonButtons, IonProgressBar } from '@ionic/react'
import { Button } from '~/components/shared'

import { closeCircleOutline } from 'ionicons/icons'
import clsx from 'clsx'

import type { ICommonDialogProps } from './type'

import './styles.scss'
import { Unless } from 'react-if'
import { isMobileApp } from '~/utils/getCurrentPlatform'

const CommonDialog = React.forwardRef<unknown, ICommonDialogProps>(
  (props, ref) => {
    const {
      isOpen,
      onClose,
      isLoading,
      onOk,
      children,
      title,
      okText = 'Submit',
      isHiddenCloseButton = false,
      isHiddenOkButton = false,
      isHiddenHeader = false,
      className,
      okButtonProps,
      extraButtons = [],
      ...modalProps
    } = props

    const isMobileAppScreen = isMobileApp()

    const handleClose = useCallback(() => {
      if (isLoading) {
        return
      }
      onClose && onClose()
    }, [isLoading, onClose])

    const renderOkButton = useCallback(() => {
      if (isHiddenOkButton) {
        return
      }

      return (
        <Button
          fill='solid'
          onClick={onOk}
          isDisabled={isLoading}
          {...okButtonProps}
        >
          {okText}
        </Button>
      )
    }, [isHiddenOkButton, isLoading, okButtonProps, okText, onOk])

    const renderCloseButton = useCallback(() => {
      if (isHiddenCloseButton) {
        return
      }

      return (
        <Button
          icon={closeCircleOutline}
          color='danger'
          fill='solid'
          onClick={handleClose}
          isDisabled={isLoading}
        />
      )
    }, [handleClose, isHiddenCloseButton, isLoading])

    const renderExtraButtons = useMemo(
      () =>
        extraButtons
          .filter(({ isHidden }) => !isHidden)
          .map((buttonProps, index) => <Button {...buttonProps} key={index} />),
      [extraButtons],
    )

    return (
      <Modal
        {...modalProps}
        show={isOpen}
        centered
        className={clsx('CommonDialog__container', className)}
        onHide={handleClose}
      >
        {isLoading && <IonProgressBar type='indeterminate' />}
        <Unless condition={isHiddenHeader}>
          <Modal.Header
            className={clsx('headerContainer', { isMobileAppScreen })}
          >
            <div className='headerTitle'>{title}</div>
            <IonButtons>
              {renderExtraButtons}
              {renderOkButton()}
              {renderCloseButton()}
            </IonButtons>
          </Modal.Header>
        </Unless>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <Modal.Body ref={ref as any}>{children}</Modal.Body>
      </Modal>
    )
  },
)

export default CommonDialog

import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectCurrentScope, selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import useModifyStartTimes from './useModifyStartTimes'
import { IGetStartTimesParams, IStartTime } from '~/types/models/IStartTime'
import { EScope } from '~/types/enums/ECommonEnum'
import _ from 'lodash'
import moment from 'moment'

const setSeenAtStartTimes = async (startTimes: IStartTime[]) => {
  const unSeenStartTimeIds = _(startTimes)
    .filter(({ seenAt }) => !seenAt)
    .map('id')
    .value()
  let updatedStartTimesSeenAt: IStartTime[] = []
  if (unSeenStartTimeIds.length > 0) {
    const updateSeenAtResponse = await apiClient.startTime.updateSeenAt({
      startTimeIds: unSeenStartTimeIds,
      seenAt: moment().toISOString(),
    })
    updatedStartTimesSeenAt = updateSeenAtResponse.startTimes || []
  }
  return _.uniqBy([...updatedStartTimesSeenAt, ...startTimes], 'id')
}

const useQueryStartTimes = (
  params: Partial<IGetStartTimesParams>,
  options?: Partial<UseQueryOptions<IStartTime[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)
  const scope: EScope = useSelector(selectCurrentScope)

  const { addStartTime, updateStartTime, removeStartTime } =
    useModifyStartTimes(params)

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey: [
      'startTimes',
      sessionUser?.id,
      buildGetUrl(apiClient.startTimes.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.startTimes.get(params)

      if (scope === EScope.driverFleet) {
        // driver fleet
        const startTimesResponse = await setSeenAtStartTimes(
          response.startTimes,
        )
        return startTimesResponse
      }

      return response.startTimes
    },
    enabled: Boolean(sessionUser?.id),
    ...options,
  })

  const startTimesData = useMemo(() => data || [], [data])

  return {
    startTimesData,
    isStartTimesLoading: isLoading,
    isStartTimesFetched: isFetched,
    refetchStartTimesData: refetch,
    addStartTime,
    updateStartTime,
    removeStartTime,
  }
}

export default useQueryStartTimes

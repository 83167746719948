import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'

import { selectSessionUser } from '~/redux/selectors'
import { ICommonOption } from '~/types/models/ICommonModel'
import { IUser } from '~/types/models/IUser'
import { MAP_PRODUCT_GROUP_ORDER_TYPE } from '~/utils/constants'

const useQueryProductGroups = () => {
  const sessionUser: IUser = useSelector(selectSessionUser)

  const { data = [], isLoading } = useQuery({
    queryKey: ['productGroups', sessionUser.id],
    enabled: Boolean(sessionUser.id),
    staleTime: 60000, // one minute
    async queryFn() {
      const response = await apiClient.productGroups.get()
      return response.map(item => {
        return {
          ...item,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          orderType: (MAP_PRODUCT_GROUP_ORDER_TYPE as any)[item.orderType],
        }
      })
    },
  })

  const productGroupOptions = useMemo<ICommonOption[]>(
    () =>
      data.map(({ code, name, id }) => ({
        label: `${code} - ${name}`,
        value: id,
      })),
    [data],
  )

  return {
    productGroups: data,
    productGroupOptions,
    isLoadingProductGroups: isLoading,
  }
}

export default useQueryProductGroups

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQuery, UseQueryOptions } from 'react-query'
import useModifyCheckNetPaySplits from './useModifyCheckNetPaySplits'

import { selectDriverDetails } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'

import type { IDriverFleet } from '~/types/models/IDriverFleet'
import type { ICheckNetPaySplit } from '~/types/models/ICheck'

const useQueryCheckNetPaySplits = (
  options?: Partial<UseQueryOptions<ICheckNetPaySplit[]>>,
) => {
  const driverDetails: IDriverFleet | null = useSelector(selectDriverDetails)

  const workerUid = driverDetails?.worker.checkUid

  const { addCheckNetPaySplits } = useModifyCheckNetPaySplits()

  const { data, isLoading } = useQuery({
    queryKey: ['checkNetPaySplits', workerUid],
    async queryFn() {
      const response = await apiClient.check.getNetPaySplits({
        workerUid: workerUid as string,
      })
      return response.results
    },
    enabled: Boolean(workerUid),
    refetchOnWindowFocus: false,
    staleTime: 30000, // 5 minutes
    ...options,
  })

  const checkNetPaySplitsData = useMemo(() => data || [], [data])

  return {
    checkNetPaySplitsData,
    isLoadingCheckNetPaySplitsData: isLoading,
    addCheckNetPaySplits,
  }
}

export default useQueryCheckNetPaySplits

import { useRouter } from '~/hooks/useRouter'

import { RTEditActionButtons } from './RTEditActionButtons'
import {
  RTCell,
  RTRow,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../types'
import { useMemo } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { ToolTipOverlay } from '~/components/shared/ToolTipOverlay'

export interface IRTToggleRowActionMenuButtonProps<
  TData extends IReusableTableRowData,
> {
  cell: RTCell<TData>
  row: RTRow<TData>
  staticRowIndex?: number
  table: ReusableTableInstance<TData>
}

export const RTToggleRowActionMenuButton = <
  TData extends IReusableTableRowData,
>({
  cell,
  row,
  staticRowIndex,
  table,
}: IRTToggleRowActionMenuButtonProps<TData>) => {
  const {
    getState,
    options: { createDisplayMode, editDisplayMode, renderRowActions },
  } = table

  const { creatingRow, editingRow } = getState()

  const router = useRouter()

  const isCreating = creatingRow?.id === row.id
  const isEditing = editingRow?.id === row.id

  const showEditActionButtons =
    (isCreating && createDisplayMode === 'row') ||
    (isEditing && editDisplayMode === 'row')

  const rowActions = useMemo(() => {
    if (typeof renderRowActions === 'function') {
      const buttonProps = renderRowActions({ cell, row, staticRowIndex, table })

      const buttonElements = buttonProps.map((btn, index) => {
        if (btn.isHidden) {
          return null
        }
        return (
          <ToolTipOverlay key={index} {...btn.tooltipProps}>
            {typeof btn.render === 'function' ? (
              btn.render()
            ) : (
              <Button
                variant={btn.color || 'secondary'}
                style={{ fontSize: 13 }}
                onClick={event => {
                  btn.onClick && btn.onClick(event)
                  if (btn.href) {
                    event.preventDefault()
                    router.push(btn.href)
                  }
                }}
                disabled={btn.isDisabled}
                className={btn.className}
                href={btn.href}
              >
                {btn.label && <span>{btn.label}</span>}
                {btn.icon}
              </Button>
            )}
          </ToolTipOverlay>
        )
      })

      return <ButtonGroup>{buttonElements}</ButtonGroup>
    }

    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cell, renderRowActions, row, staticRowIndex, table])

  return (
    <>
      {renderRowActions && !showEditActionButtons ? (
        rowActions
      ) : showEditActionButtons ? (
        <RTEditActionButtons row={row} table={table} />
      ) : null}
    </>
  )
}

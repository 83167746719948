import { useEffect, useMemo, useRef, useState } from 'react'
import {
  CheckMarkIcon,
  CloseIcon,
  ConcordFormStructure,
  InfoIcon,
  ToolTipOverlay,
} from '~/components/shared'
import { EFieldType, EScope, EUserProvider } from '~/types/enums/ECommonEnum'

import * as Yup from 'yup'
import _ from 'lodash'
import { useRouter } from '~/hooks/useRouter'
import { useSelector, useDispatch } from 'react-redux'
import { selectSessionUser } from '~/redux/selectors'
import { Alert, Badge } from 'react-bootstrap'
import { REGEX_PHONE_NUMBERS } from '~/utils/constants'
import { apiClient } from '~/api/ApiClient'
import { login } from '~/redux/actions/sessionActions'

const UserForm = ({ handleBack, afterCreate, isLoading: isLoadingProp }) => {
  const router = useRouter()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allowedUserType = [EScope.driver, EScope.company]

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')
  const [userProvider, setUserProvider] = useState(EUserProvider.email)

  const userTypeQs = router.query.type

  const dispatch = useDispatch()
  const currentUser = useSelector(selectSessionUser)

  const formRef = useRef()

  const createUserAndLogin = async formValues => {
    const payload = _.pick(formValues, ['password', 'personAttributes'])
    payload.uid = formValues[userProvider]
    payload.provider = _.snakeCase(userProvider)
    payload.phoneNumber = formValues.phone || undefined
    payload.email = formValues.email || undefined
    if (allowedUserType.includes(userTypeQs)) {
      payload.userType = userTypeQs
    }
    setIsLoading(true)
    setBackendError('')
    try {
      const response = await apiClient.users.signUp({
        user: _.pick(payload, [
          'email',
          'password',
          'phoneNumber',
          'provider',
          'personAttributes',
        ]),
      })
      await dispatch(
        login(
          {
            uid: payload.uid,
            password: payload.password,
          },
          () => {
            afterCreate(response, {
              type: formValues.userType,
              password: payload.password,
              uid: payload.uid,
            })
            setIsLoading(false)
          },
        ),
      )
    } catch (error) {
      const message = _.get(error, 'errors.fullMessages[0]')
      setBackendError(message)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async formValues => {
    if (currentUser?.id) {
      afterCreate(currentUser, {
        type: formValues.userType,
        password: formValues.password,
        uid: formValues.email,
      })
    } else {
      createUserAndLogin(formValues)
    }
  }

  const onChangeUserProvider = userProvider => () => {
    setUserProvider(userProvider)
  }

  const fields = useMemo(
    () => [
      {
        label: (
          <>
            <span>Email</span>
            <Badge
              bg={
                userProvider === EUserProvider.email ? 'primary' : 'secondary'
              }
              style={{ margin: '0 4px' }}
              className='clickable'
              onClick={onChangeUserProvider(EUserProvider.email)}
            >
              {userProvider === EUserProvider.email ? (
                <CheckMarkIcon style={{ marginRight: 4 }} color='white' />
              ) : (
                <CloseIcon style={{ marginRight: 4 }} color='white' />
              )}
              <span>Username</span>
            </Badge>
            {userProvider === EUserProvider.email && (
              <ToolTipOverlay
                placement='top'
                content='Use email and password to sign in'
              >
                <span>
                  <InfoIcon color='var(--ion-color-medium)' />
                </span>
              </ToolTipOverlay>
            )}
          </>
        ),
        name: 'email',
        isRequired: EUserProvider.email === userProvider,
        autoFocus: true,
      },
      {
        label: (
          <>
            <span>Phone Number</span>
            <Badge
              bg={
                userProvider === EUserProvider.phoneNumber
                  ? 'primary'
                  : 'secondary'
              }
              style={{ margin: '0 4px' }}
              className='clickable'
              onClick={onChangeUserProvider(EUserProvider.phoneNumber)}
            >
              {userProvider === EUserProvider.phoneNumber ? (
                <CheckMarkIcon style={{ marginRight: 4 }} color='white' />
              ) : (
                <CloseIcon style={{ marginRight: 4 }} color='white' />
              )}
              <span>Username</span>
            </Badge>
            {userProvider === EUserProvider.phoneNumber && (
              <ToolTipOverlay
                placement='top'
                content='Use phone number and password to sign in'
              >
                <span>
                  <InfoIcon color='var(--ion-color-medium)' />
                </span>
              </ToolTipOverlay>
            )}
          </>
        ),
        name: 'phone',
        isRequired: EUserProvider.phoneNumber === userProvider,
        autoFocus: true,
        isPhoneNumber: true,
        type: EFieldType.number,
      },
      {
        label: 'Password',
        name: 'password',
        type: EFieldType.password,
        isRequired: true,
        isHidden: Boolean(currentUser?.id),
      },
      {
        label: 'First Name',
        name: 'personAttributes.firstName',
        isRequired: true,
      },
      {
        label: 'Last Name',
        name: 'personAttributes.lastName',
        isRequired: true,
      },
      {
        label: 'Date of Birth',
        name: 'personAttributes.dob',
        type: EFieldType.date,
      },
      {
        label: 'Type',
        name: 'userType',
        isRequired: true,
        isHidden: allowedUserType.includes(userTypeQs),
        type: EFieldType.radio,
        options: [
          {
            label: 'Driver',
            value: EScope.driver,
          },
          {
            label: 'Company',
            value: EScope.company,
          },
        ],
      },
    ],
    [allowedUserType, currentUser?.id, userProvider, userTypeQs],
  )

  const schema = Yup.object({
    email:
      userProvider === EUserProvider.email
        ? Yup.string().required('Email is required').email('Invalid email')
        : Yup.string(),
    phone:
      userProvider === EUserProvider.phoneNumber
        ? Yup.string().test('phone-is-required', 'Phone is invalid', val => {
            return REGEX_PHONE_NUMBERS.test(val)
          })
        : Yup.string().test('phone-is-required', 'Phone is invalid', val => {
            if (val) {
              return REGEX_PHONE_NUMBERS.test(val)
            }
            return true
          }),
    password: Yup.lazy(() => {
      if (currentUser?.id) {
        return Yup.string()
      }
      return Yup.string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .required('Password is required')
    }),
    personAttributes: Yup.object({
      lastName: Yup.string().required('Last name is required'),
      firstName: Yup.string().required('First name is required'),
    }),
  })

  const defaultValues = useMemo(
    () => ({
      email: '',
      password: '',
      personAttributes: {
        lastName: '',
        firstName: '',
      },
      userType: EScope.company,
    }),
    [],
  )

  useEffect(() => {
    if (currentUser?.id) {
      formRef.current?.setValue(
        'personAttributes.firstName',
        currentUser?.person?.firstName,
      )
      formRef.current?.setValue(
        'personAttributes.lastName',
        currentUser?.person?.lastName,
      )
      formRef.current?.setValue('email', currentUser?.email)
    }
  }, [
    currentUser?.email,
    currentUser?.id,
    currentUser?.person?.firstName,
    currentUser?.person?.lastName,
  ])

  return (
    <div>
      {backendError && (
        <Alert variant='danger' style={{ fontSize: 13, margin: '8px 0' }}>
          {backendError}
        </Alert>
      )}
      <ConcordFormStructure
        schema={schema}
        fields={fields}
        defaultValues={defaultValues}
        submitText='Next'
        cancelText='Back'
        onSubmit={onSubmit}
        isLoading={isLoading || isLoadingProp}
        onCancel={handleBack}
        ref={formRef}
        isHiddenCancelButton={Boolean(currentUser?.id)}
      />
    </div>
  )
}

export default UserForm

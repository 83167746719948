import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { isStartWithEmp } from '~/utils/constants'

import type { IUser } from '~/types/models/IUser'
import type { IGetCheckSetupCompanyResponse } from '~/types/models/ICheck'

const useQueryCheckEmployeeDetails = (
  checkUid: string,
  options?: Partial<UseQueryOptions<IGetCheckSetupCompanyResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['checkEmployee', sessionUser?.id, checkUid],
    queryFn() {
      return apiClient.check.getWorkerByUid(checkUid)
    },
    enabled: Boolean(sessionUser?.id && checkUid && isStartWithEmp(checkUid)),
    staleTime: 600000, // 10 minutes
    ...options,
  })

  return {
    checkEmployeeDetails: data,
    refetchCheckEmployeeDetails: refetch,
    isLoadingCheckEmployee: isLoading,
  }
}

export default useQueryCheckEmployeeDetails

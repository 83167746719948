import React, { useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  IonSearchbar,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonButton,
} from '@ionic/react'
import { Accordion, Button } from 'react-bootstrap'

import {
  selectDriverFleetsList,
  selectTruckFleetsFullList,
} from '~/redux/selectors'
import { isMobileApp } from '~/utils/getCurrentPlatform'
import { useDrivers } from '~/hooks/useDrivers'

import '@ionic/core/css/core.css'
import '@ionic/core/css/ionic.bundle.css'
import '~/styles/home.scss'
import { CloseIcon, ToolTipOverlay } from '~/components/shared'
import buildFullName from '~/utils/buildFullName'

const AssignTruck = props => {
  const { currentTruckFleetId, driverFleetId, setShow } = props
  const [search, setSearch] = useState('')
  const [truckFleetId, setTruckFleetId] = useState(currentTruckFleetId)

  const truckFleets = useSelector(selectTruckFleetsFullList)
  const driverFleets = useSelector(selectDriverFleetsList)

  const { updateDriverFleet } = useDrivers()

  const onChange = useCallback(e => setSearch(e.target.value), [])

  const handleAddTruck = useCallback(() => {
    updateDriverFleet({
      id: driverFleetId,
      currentTruckFleetId: truckFleetId || null,
    })

    setShow(false)
  }, [updateDriverFleet, driverFleetId, truckFleetId, setShow])

  const driverFullName = useMemo(() => {
    const { person } = driverFleets.find(df => df.id === driverFleetId) || {}

    return buildFullName(person)
  }, [driverFleets, driverFleetId])

  const driversTruckFleet = useMemo(() => {
    const driverFleet = driverFleets.find(df => df.id === driverFleetId)
    return truckFleets.find(tf => tf.id === driverFleet?.currentTruckFleetId)
  }, [driverFleets, driverFleetId, truckFleets])

  const filteredTruckFleets = useMemo(
    () =>
      truckFleets.filter(truckFleet => {
        const query = search.toLowerCase()
        const valueall = ' + ' + truckFleet.truck.name

        return valueall.toLowerCase().indexOf(query) >= 0
      }),
    [truckFleets, search],
  )

  const getSortedDrivers = useCallback(
    assigned => (
      <div>
        {filteredTruckFleets &&
          filteredTruckFleets.map(truckFleet => {
            const {
              id,
              truck: { name },
            } = truckFleet

            const foundDriverFleet = driverFleets.find(
              df => df.currentTruckFleetId == id,
            )

            if (
              (!assigned && foundDriverFleet) ||
              (assigned && !foundDriverFleet)
            )
              return null

            return (
              <IonItem
                color={truckFleetId && truckFleetId === id ? 'primary' : null}
                key={name}
                button
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  setTruckFleetId(id)
                }}
              >
                <IonLabel>
                  {assigned
                    ? `${name} - ${foundDriverFleet?.person?.firstName}`
                    : name}
                </IonLabel>
              </IonItem>
            )
          })}
      </div>
    ),
    [filteredTruckFleets, driverFleets, truckFleetId],
  )

  const assignedTrucks = useMemo(
    () => getSortedDrivers(true),
    [getSortedDrivers],
  )

  const availableTrucks = useMemo(
    () => getSortedDrivers(false),
    [getSortedDrivers],
  )

  const renderUnassignTruck = useMemo(
    () =>
      driversTruckFleet && (
        <ToolTipOverlay
          content={`Remove truck ${driversTruckFleet?.truck?.name} from ${driverFullName}`}
        >
          <Button
            variant='danger'
            className='ms-2'
            onClick={() =>
              updateDriverFleet({
                id: driverFleetId,
                currentTruckFleetId: null,
              })
            }
          >
            <CloseIcon />
          </Button>
        </ToolTipOverlay>
      ),
    [driversTruckFleet, driverFullName, updateDriverFleet, driverFleetId],
  )

  return (
    <div>
      <IonHeader>
        <IonItem
          expand='block'
          lines='none'
          style={{ marginTop: isMobileApp() ? 48 : 0 }}
        >
          <IonLabel
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {driverFullName}
            {driversTruckFleet?.truck?.name
              ? ` - Truck ${driversTruckFleet.truck.name}`
              : ' - Select a Truck...'}
            {renderUnassignTruck}
          </IonLabel>
          <button style={{ background: 'none' }} onClick={() => setShow(false)}>
            <CloseIcon />
          </button>
        </IonItem>
        <IonSearchbar
          style={{ marginLeft: 0, position: 'sticky', top: '0' }}
          value={search}
          onIonChange={onChange}
        />
      </IonHeader>
      <div>
        <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
          <Accordion.Item eventKey='0'>
            <Accordion.Header style={{ fontWeight: 'bold' }}>
              <h2>Available</h2>
            </Accordion.Header>
            <Accordion.Body style={{ maxHeight: 200, overflow: 'auto' }}>
              <IonList>{availableTrucks}</IonList>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey='1'>
            <Accordion.Header style={{ fontWeight: 'bold' }}>
              <h2>Assigned</h2>
            </Accordion.Header>
            <Accordion.Body style={{ maxHeight: 200, overflow: 'auto' }}>
              <IonList>{assignedTrucks}</IonList>
            </Accordion.Body>
          </Accordion.Item>
          {/* {truckFleetId && (
            <IonItem
              color={truckFleetId === null ? 'danger' : ''}
              button
              onClick={() => setTruckFleetId(null)}
              //disabled={assignedTruck !== null}
            >
              <IonLabel>
                Remove truck {truckFleets[truckFleetId]?.truck?.name} from{' '}
                {driverFullName}
              </IonLabel>
            </IonItem>
          )} */}
        </Accordion>
      </div>
      <IonButton expand='block' color='primary' onClick={handleAddTruck}>
        Assign Truck
      </IonButton>
    </div>
  )
}

export default AssignTruck

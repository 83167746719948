import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQueryCheckSignatories } from '~/hooks/useQueryData'

import { selectSessionUser, selectMyCurrentCompany } from '~/redux/selectors'
import buildFullName from '~/utils/buildFullName'
import {
  SIGNER_MYSELF_VALUE,
  SIGNER_OTHER_USER_OPTION,
} from '~/utils/constants'
// import { EFieldType } from '~/types/enums/ECommonEnum'
import * as Yup from 'yup'

import type {
  ICheckSignatoryFormProps,
  ICheckSignatoryFormValues,
} from './type'
import type { ICommonOption } from '~/types/models/ICommonModel'
import type {
  IConcordFormField,
  IConcordFormOnChangeParams,
} from '~/components/shared'
import type { IUser } from '~/types/models/IUser'
import type { ActionMeta } from 'react-select'
import type { ICompany } from '~/types/models/ICompany'
import { ICreateCheckSignatoryPayload } from '~/types/models/ICheck'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { useDeepCompareEffect } from 'react-use'

const useCheckSignatoryForm = (props: ICheckSignatoryFormProps) => {
  const { selectedCheckUid, onSubmit, onChange } = props

  const currentUser: IUser = useSelector(selectSessionUser)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const [signerType, setSignerType] = useState(SIGNER_MYSELF_VALUE)
  const [selectedUserAccessId, setSelectedUserAccessId] =
    useState<null | number>(null)
  const [isShowBanner, setIsShowBanner] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const {
    checkSignatoriesData,
    checkSignatoryOptions,
    userOptions,
    addCheckSignatory,
    isLoadingCheckSignatoriesData,
    usersData,
    updatePerson,
    updateUser,
    updateWorker,
  } = useQueryCheckSignatories({
    filters: {
      companyId: currentCompany.id,
    },
  })

  const selectedSignatory = useMemo(() => {
    if (selectedUserAccessId) {
      return checkSignatoriesData.find(
        ({ userAccessId }) => userAccessId === selectedUserAccessId,
      )
    }
    return null
  }, [checkSignatoriesData, selectedUserAccessId])

  const hasSelectedSignatory = useMemo(
    () => Boolean(selectedSignatory),
    [selectedSignatory],
  )

  const selectedUser = useMemo(() => {
    if (selectedUserAccessId) {
      return usersData.find(
        ({ userAccess }) => userAccess.id === selectedUserAccessId,
      )
    }
    return null
  }, [selectedUserAccessId, usersData])

  const selectedSignatoryOption = useMemo(() => {
    if (selectedUserAccessId) {
      return userOptions.find(
        ({ userAccessId }) => userAccessId === selectedUserAccessId,
      )
    }
    return null
  }, [selectedUserAccessId, userOptions])

  const DESIGNATE_SIGNER_TYPE_OPTIONS = useMemo<ICommonOption[]>(
    () => [
      {
        value: SIGNER_MYSELF_VALUE,
        label: `Me - ${buildFullName(currentUser.person)}`,
      },
      SIGNER_OTHER_USER_OPTION,
    ],
    [currentUser.person],
  )

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'First Name',
        name: 'firstName',
        isRequired: true,
        size: 4,
        isDisabled: hasSelectedSignatory,
      },
      {
        label: 'Middle Name',
        name: 'middleName',
        size: 4,
        isDisabled: hasSelectedSignatory,
      },
      {
        label: 'Last Name',
        name: 'lastName',
        isRequired: true,
        size: 4,
        isDisabled: hasSelectedSignatory,
      },
      {
        label: 'Signer Title',
        name: 'title',
        placeholder: 'Ex: CEO',
        isRequired: true,
        size: 6,
        isDisabled: hasSelectedSignatory,
      },

      {
        label: 'Email',
        name: 'email',
        isRequired: true,
        size: 6,
        isDisabled: hasSelectedSignatory,
      },
    ],
    [hasSelectedSignatory],
  )

  const schema = Yup.object({
    firstName: Yup.string().nullable().required('This field is required!'),
    lastName: Yup.string().nullable().required('This field is required!'),
    title: Yup.string().nullable().required('This field is required!'),
    email: Yup.string().required('This field is required!'),
  })

  const onSelectSignerType = useCallback(
    (
      event: ActionMeta<ICommonOption>,
      { value }: IConcordFormOnChangeParams<ICommonOption>,
    ) => {
      setSignerType(value as string)
    },
    [],
  )

  const onSelectOtherSigner = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      event: ActionMeta<any>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { selectedOption }: IConcordFormOnChangeParams<any>,
    ) => {
      setSelectedUserAccessId(selectedOption.userAccessId)
    },
    [],
  )

  const handleSubmit = useCallback(
    (formValues: ICheckSignatoryFormValues) => {
      onSubmit(formValues)
    },
    [onSubmit],
  )

  const updateUserData = useCallback(
    (formValues: ICheckSignatoryFormValues) => {
      if (selectedUser) {
        const { worker, person } = selectedUser

        updateUser(selectedUser.id, {
          email: formValues.email,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const apis: Promise<any>[] = [
          apiClient.users.update(selectedUser.id, {
            user: {
              email: formValues.email,
            },
          }),
        ]

        if (person) {
          const data = {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            middleName: formValues.middleName,
          }
          updatePerson(person.id, data)
          apis.push(
            apiClient.people.update(person.id, {
              person: data,
            }),
          )
        }

        if (worker) {
          updateWorker(worker.id, {
            title: formValues.title,
          })
          apis.push(
            apiClient.workers.update(worker.id, {
              title: formValues.title,
            }),
          )
        }
        Promise.all(apis)
      }
    },
    [selectedUser, updatePerson, updateUser, updateWorker],
  )

  const onSubmitForm = useCallback(
    async (formValues: ICheckSignatoryFormValues) => {
      setIsShowBanner(true)
      if (hasSelectedSignatory && selectedSignatory) {
        handleSubmit(selectedSignatory)
      } else {
        updateUserData(formValues)
        const payload: ICreateCheckSignatoryPayload = {
          companyId: currentCompany.id,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          middleName: formValues.middleName,
          title: formValues.title,
          email: formValues.email,
          userAccessId: selectedUserAccessId as number,
          jurisdiction: formValues.jurisdiction,
          federalOnly: formValues.federalOnly,
        }

        setIsLoading(true)
        try {
          const response = await apiClient.check.createSignatory(payload)
          addCheckSignatory(response)
          handleSubmit(response)
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.createError)
        } finally {
          setIsLoading(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      addCheckSignatory,
      currentCompany.id,
      handleSubmit,
      hasSelectedSignatory,
      selectedSignatory,
      selectedUserAccessId,
    ],
  )

  const onToggleForm = useCallback(() => {
    setIsShowBanner(prev => !prev)
  }, [])

  useEffect(() => {
    if (signerType === SIGNER_MYSELF_VALUE) {
      setSelectedUserAccessId(currentUser.userAccess?.id)
    }
  }, [currentUser.userAccess?.id, signerType])

  useEffect(() => {
    if (selectedSignatory) {
      formRef.current?.setValue('firstName', selectedSignatory?.firstName || '')
      formRef.current?.setValue(
        'middleName',
        selectedSignatory?.middleName || '',
      )
      formRef.current?.setValue('lastName', selectedSignatory?.lastName || '')
      formRef.current?.setValue('title', selectedSignatory?.title || '')
      formRef.current?.setValue('email', selectedSignatory?.email || '')
      setIsShowBanner(true)
    } else if (selectedUser) {
      const { person, worker } = selectedUser
      formRef.current?.setValue('firstName', person?.firstName || '')
      formRef.current?.setValue('middleName', person?.middleName || '')
      formRef.current?.setValue('lastName', person?.lastName || '')
      formRef.current?.setValue('title', worker?.title || '')
      formRef.current?.setValue('email', selectedUser?.email || '')
      setIsShowBanner(false)
    }
  }, [selectedSignatory, selectedUser])

  useDeepCompareEffect(() => {
    onChange && onChange(selectedSignatory)
  }, [selectedSignatory])

  useEffect(() => {
    if (selectedCheckUid) {
      // find checksignatory by check uid
      const findObj = checkSignatoriesData.find(
        ({ checkUid }) => selectedCheckUid === checkUid,
      )
      if (findObj) {
        if (findObj.userAccessId === currentUser.userAccess?.id) {
          setSignerType(SIGNER_MYSELF_VALUE)
        } else {
          setSignerType(SIGNER_OTHER_USER_OPTION.value)
        }
        setSelectedUserAccessId(findObj.userAccessId)
      } else {
        setSelectedUserAccessId(null)
      }
    }
  }, [selectedCheckUid, checkSignatoriesData, currentUser.userAccess?.id])

  return {
    DESIGNATE_SIGNER_TYPE_OPTIONS,
    signerType,
    checkSignatoryOptions,
    fields,
    schema,
    isLoading,
    formRef,
    onSelectSignerType,
    onSubmitForm,
    onSelectOtherSigner,
    isLoadingCheckSignatoriesData,
    selectedSignatory,
    selectedSignatoryOption,
    isShowBanner,
    onToggleForm,
  }
}

export default useCheckSignatoryForm

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQueryWorkers } from '~/hooks/useQueryData'

import { IonText } from '@ionic/react'
import { CompanyAvatar } from '~/components/shared'

import _ from 'lodash'
import { selectUserGroups } from '~/redux/selectors'
import { USER_STATUSES } from '~/utils/constants'
import { EFieldType } from '~/types/enums/ECommonEnum'
import buildFullName from '~/utils/buildFullName'

const useTableUsersCompanyColumns = () => {
  const userGroups = useSelector(selectUserGroups)

  const { findWorkerByUserAccessId } = useQueryWorkers()

  const getUserGroup = useCallback(
    userGroupId => {
      if (_.size(userGroups) > 0 && _.isNumber(userGroupId)) {
        return userGroups[userGroupId] || {}
      }

      return {}
    },
    [userGroups],
  )

  const columns = useMemo(
    () => [
      {
        header: 'Profile',
        id: 'profile',
        size: 300,
        accessorFn(rowData) {
          const worker = findWorkerByUserAccessId(rowData.userAccess.id)
          return buildFullName(worker?.person)
        },
        Cell({ row, renderedCellValue }) {
          return (
            <div className='CompanyUserSubTab__profileContainer'>
              <CompanyAvatar
                company={{
                  value: row.original.id,
                  name: renderedCellValue,
                }}
              />
              <div className='CompanyUserSubTab__profileInfo'>
                <span>{renderedCellValue}</span>
                <span>
                  <a href={`mailto:${row.original.email}`}>
                    {row.original.email}
                  </a>
                </span>
              </div>
            </div>
          )
        },
      },
      {
        header: 'Phone',
        accessorKey: 'phoneNumber',
        size: 130,
        enableEditing: true,
        editVariant: EFieldType.number,
        editNumberFieldProps: () => ({
          format: '+1 (###) ### ####',
          allowEmptyFormatting: true,
          mask: '_',
        }),
      },
      {
        header: 'User Group',
        id: 'userAccess.userGroupId',
        accessorFn(row) {
          return getUserGroup(row.userAccess.userGroupId)?.name
        },
        Cell({ renderedCellValue }) {
          return renderedCellValue
        },
      },
      {
        header: 'Status',
        accessorKey: 'userAccess.status',
        size: 80,
        Cell({ cell }) {
          return (
            <IonText color={USER_STATUSES[cell.getValue()]}>
              {cell.getValue()}
            </IonText>
          )
        },
      },
      {
        header: 'Default',
        accessorKey: 'userAccess.default',
        size: 100,
      },
      {
        header: 'Title',
        id: 'title',
        size: 80,
        enableEditing: row => {
          const worker = findWorkerByUserAccessId(row.original.userAccess?.id)

          return worker?.id
        },
        accessorFn(rowData) {
          const { userAccess } = rowData
          const worker = findWorkerByUserAccessId(userAccess.id)

          if (worker) {
            return worker.title
          }

          return ''
        },
      },
      {
        header: 'Disallowed Scopes',
        accessorKey: 'userAccess.disallowedScopes',
        Cell({ cell }) {
          return (cell.getValue() || []).join(', ')
        },
      },
    ],
    [findWorkerByUserAccessId, getUserGroup],
  )

  return { columns }
}

export default useTableUsersCompanyColumns

import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { IGetAppliedForIdsResponse } from '~/types/models/ICheck'

const useQueryCheckAppliedForIds = (
  options?: Partial<UseQueryOptions<IGetAppliedForIdsResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['appliedForIds', sessionUser?.id],
    queryFn() {
      return apiClient.check.appliedForIds()
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // one minute
    ...options,
  })

  const checkAppliedForIds = useMemo(() => data?.results || [], [data?.results])

  return {
    checkAppliedForIds,
    refetchCheckAppliedForIds: refetch,
    isLoadingCheckAppliedForIds: isLoading,
  }
}

export default useQueryCheckAppliedForIds

import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyCalculations from './useModifyCalculations'

import { apiClient } from '~/api/ApiClient'
import { selectMyCurrentCompany, selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { ICalculation } from '~/types/models/ICalculation'
import { ICompany } from '~/types/models/ICompany'

const useQueryCalculations = (
  params = {},
  options?: Partial<UseQueryOptions<ICalculation[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { data, isLoading } = useQuery({
    queryKey: [
      'calculations',
      sessionUser?.id,
      buildGetUrl(apiClient.calculations.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.calculations.get({
        ...params,
        filters: {
          companyId: currentCompany.id,
        },
      })
      return response.calculations
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // 1 min
    retry: false,
    ...options,
  })

  const calculationsData = useMemo(() => data || [], [data])

  const calculationOptions = useMemo(
    () =>
      calculationsData.map(({ id, name, formula }) => ({
        value: id,
        label: `${name} - ${formula}`,
      })),
    [calculationsData],
  )

  const { addCalculation, updateCalculation, removeCalculation } =
    useModifyCalculations(params)

  return {
    calculationsData,
    isLoadingCalculations: isLoading,
    calculationOptions,
    addCalculation,
    updateCalculation,
    removeCalculation,
  }
}

export default useQueryCalculations

import { useMemo } from 'react'

import { ConcordFormStructure } from '~/components/shared'

import { FORM_FIELD_TYPE } from '~/utils/constants'
import { personSchema } from './schemas'

function PersonTab(props) {
  const defaultValues = useMemo(
    () => ({
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      initials: '',
      dob: null,
    }),
    [],
  )

  const fields = useMemo(
    () => [
      {
        name: 'firstName',
        label: 'First Name',
        isRequired: true,
      },
      {
        name: 'middleName',
        label: 'Middle Name',
      },
      {
        name: 'lastName',
        label: 'Last Name',
        isRequired: true,
      },
      {
        name: 'suffix',
        label: 'Suffix',
      },
      {
        name: 'initials',
        label: 'Initials',
      },
      {
        name: 'dob',
        label: 'Date of birth',
        type: FORM_FIELD_TYPE.date,
      },
    ],
    [],
  )

  return (
    <ConcordFormStructure
      fields={fields}
      submitText='Next'
      schema={personSchema}
      {...props}
      defaultValues={props.defaultValues || defaultValues}
    />
  )
}

PersonTab.propTypes = {}

export default PersonTab

import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import { selectAllTerminals } from '~/redux/selectors'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CompanyAvatar } from '../shared'
import { EScope } from '~/types/enums/ECommonEnum'

export const useNextLoadsTable = ({
  projectedMinutesShift,
  sellerTerminalId,
  qty,
}) => {
  const terminals = useSelector(selectAllTerminals)

  const COLUMN_NAMES = [
    'ticketTime',
    'loadStartTime',
    'loadEndTime',
    'leaveSellerTime',
    'leaveBuyerTime',
    'arriveSellerTime',
  ]

  const columns = useMemo(
    () => [
      {
        header: '',
        accessorKey: 'index',
        minSize: 90,
        maxSize: 90,
        Cell: ({ row }) => {
          if (row.index === 0)
            return <div style={{ fontWeight: 'bold' }}>Scheduled</div>
          return <div style={{ fontWeight: 'bold' }}>This Load</div>
        },
      },
      {
        header: 'Qty',
        accessorKey: 'qty',
        minSize: 80,
        maxSize: 80,
        Cell: ({ row, cell }) => {
          const cellData = cell.getValue()
          const rowIndex = row.index
          if (rowIndex === 0) {
            let valueCell = cellData
            if (parseInt(valueCell) == cellData) {
              return <div>{parseInt(valueCell)}</div>
            }
            return <div>{cellData}</div>
          }
          let valueQty = qty
          if (parseInt(valueQty) == qty) {
            return <div>{parseInt(valueQty)}</div>
          }
          return <div>{qty}</div>
        },
      },
      {
        header: 'Begin Terminal',
        accessorKey: 'beginSellerTerminalId',
        Cell: ({ row, cell }) => {
          const cellData = cell.getValue()
          const rowIndex = row.index
          let terminalData =
            rowIndex === 0
              ? {
                  ...terminals[cellData],
                  image: terminals[cellData]?.logo,
                }
              : {
                  ...terminals[sellerTerminalId],
                  image: terminals[sellerTerminalId]?.logo,
                }
          return (
            <OverlayTrigger
              placement='top'
              overlay={tooltipProps => (
                <Tooltip id='button-tooltip test' {...tooltipProps}>
                  {terminalData?.name}
                </Tooltip>
              )}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CompanyAvatar
                  companyType={EScope.buyer}
                  showImagePopup={false}
                  company={terminalData}
                  style={{ marginRight: 4 }}
                  width={24}
                  height={24}
                />
                {terminalData?.code}
              </div>
            </OverlayTrigger>
          )
        },
      },
      {
        header: 'Ticket Time',
        accessorKey: 'ticketTime',
        Cell: ({ cell }) => {
          const cellData = cell.getValue()

          const time = DateTime.fromISO(cellData).toFormat('H:mm')
          return (
            <div>
              {time}
              <Badge
                style={{ marginLeft: 4 }}
                bg={projectedMinutesShift > 0 ? 'danger' : 'success'}
              >
                {projectedMinutesShift} min
              </Badge>
            </div>
          )
        },
      },
      {
        header: 'Load Start Time',
        accessorKey: 'loadStartTime',
        minSize: 130,
        maxSize: 130,
        Cell: ({ cell }) => {
          const cellData = cell.getValue()

          const time = DateTime.fromISO(cellData).toFormat('H:mm')
          return <div>{time}</div>
        },
      },
      {
        header: 'Load End Time',
        accessorKey: 'loadEndTime',
        minSize: 120,
        maxSize: 120,
        Cell: ({ cell }) => {
          const cellData = cell.getValue()

          const time = DateTime.fromISO(cellData).toFormat('H:mm')
          return <div>{time}</div>
        },
      },
      {
        header: 'Leave Seller Time',
        accessorKey: 'leaveSellerTime',
        minSize: 135,
        maxSize: 135,
        Cell: ({ cell }) => {
          const cellData = cell.getValue()

          const time = DateTime.fromISO(cellData).toFormat('H:mm')
          return <div>{time}</div>
        },
      },
      {
        header: 'Leave Buyer Time',
        accessorKey: 'leaveBuyerTime',
        minSize: 135,
        maxSize: 135,
        Cell: ({ cell }) => {
          const cellData = cell.getValue()

          const time = DateTime.fromISO(cellData).toFormat('H:mm')
          return <div>{time}</div>
        },
      },
      {
        header: 'Arrive Seller Time',
        accessorKey: 'arriveSellerTime',
        minSize: 140,
        maxSize: 140,
        Cell: ({ cell }) => {
          const cellData = cell.getValue()

          const time = DateTime.fromISO(cellData).toFormat('H:mm')
          return <div>{time}</div>
        },
      },
      {
        header: 'Return Terminal',
        accessorKey: 'returnSellerTerminalId',
        Cell: ({ cell }) => {
          const cellData = cell.getValue()

          return (
            <OverlayTrigger
              placement='top'
              overlay={tooltipProps => (
                <Tooltip id='button-tooltip test' {...tooltipProps}>
                  {terminals[cellData]?.name}
                </Tooltip>
              )}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CompanyAvatar
                  companyType={EScope.buyer}
                  showImagePopup={false}
                  company={{
                    ...terminals[cellData],
                    image: terminals[cellData]?.logo,
                  }}
                  style={{ marginRight: 4 }}
                  width={24}
                  height={24}
                />
                {terminals[cellData]?.code}
              </div>
            </OverlayTrigger>
          )
        },
      },
    ],
    [projectedMinutesShift, terminals, sellerTerminalId, qty],
  )
  return { columns, COLUMN_NAMES }
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useConfirmationProvider } from '~/contexts'

import { Badge } from 'react-bootstrap'
import { IonIcon, IonSpinner } from '@ionic/react'

import {
  addOutline,
  arrowUndo,
  checkmark,
  chevronDown,
  chevronUp,
  close,
  closeOutline,
  layers,
  location,
  mapOutline,
} from 'ionicons/icons'

import type { IBundleBadgeProps } from './type'
import {
  DropdownWithCustomChildren,
  GoogleMap,
  TerminalMarker1,
  ToolTipOverlay,
  AutoWidthInput,
} from '~/components/shared'

import './styles.scss'
import { EStatus, EYesNo } from '~/types/enums/ECommonEnum'
import { useQueryTerminals } from '~/hooks/useQueryData'
import { IBundleItem } from '~/types/models/IBundleItem'
import { ISellerTerminalProduct } from '~/types/models/ISellerTerminalProduct'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { BundleItemCard } from '../BundleItemCard'
import { produce } from 'immer'
import { Unless, When } from 'react-if'
import { useDeepCompareEffect } from 'react-use'
import clsx from 'clsx'
import { ITerminal } from '~/types/models/ITerminal'
import { IBundle } from '~/types/models/IBundle'
import { SellerTerminalProdCard } from '../SellerTerminalProdCard'
import { useDrop } from 'react-dnd'

function BundleBadge(props: IBundleBadgeProps) {
  const {
    bundleData,
    bundleItemsData,
    sellerTerminalProductsData,
    sellerProductId,
    onRemove,
    onRemoveBundleItem,
    onRemoveSellerTerminalProduct,
    afterCreateSellerTerminalProduct,
    afterChangeRanking,
    afterCreateBundleItem,
    afterUpdateBundleItem,
    afterUpdateBundle,
    afterCreateBundle,
    afterDrag,
  } = props

  const { confirmation } = useConfirmationProvider()

  const [isOpenBundleItems, setIsOpenBundleItems] = useState(false)
  const [isCreatingTerminalProd, setIsCreatingTerminalProd] = useState(false)
  const [isShowTerminalProducts, setIsShowTerminalProducts] = useState(false)
  const [bundleItemsCloned, setBundleItemsCloned] = useState<IBundleItem[]>([])
  const [wasChangedRanking, setWasChangedRanking] = useState(false)
  const [isChangingRank, setIsChangingRank] = useState(false)
  const [terminalIdSelected, setTerminalIdSelected] =
    useState<number | null>(null)
  const [isShowingBundleInputs, setIsShowingBundleInputs] = useState(false)
  const [bundleFormValues, setBundleFormValues] = useState({
    name: '',
    num: null as number | null,
  })
  const [isLoadingBundleForm, setIsLoadingBundleForm] = useState(false)
  const [error, setError] = useState('')

  const [isCreatingItem, setIsCreatingItem] = useState(false)

  const [{ canDrop, isOver, item }, drop] = useDrop(() => ({
    accept: 'BUNDLE_CARD',
    drop: () => ({ bundle: bundleData }),
    collect: monitor => {
      const item: ISellerTerminalProduct = monitor.getItem()
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        item,
      }
    },
  }))

  const style = useMemo(() => {
    if (item && item.bundleId === bundleData?.id) {
      return {}
    }
    const isActive = canDrop && isOver

    if (isActive) {
      return {
        borderColor: 'var(--ion-color-success)',
        boxShadow: 'rgba(9,199,41, 0.2) 0px 2px 8px 0px',
      }
    }

    if (canDrop) {
      return {
        borderColor: 'var(--ion-color-concord)',
        boxShadow: 'rgb(6, 59, 226, 0.2) 0px 2px 8px 0px',
      }
    }

    return {}
  }, [bundleData?.id, canDrop, isOver, item])

  const { terminalOptions, terminalsData } = useQueryTerminals()

  const existentTerminalIds = sellerTerminalProductsData.map(
    ({ terminalId }) => terminalId,
  )

  const findTerminal = useCallback(
    (terminalId: number) => terminalsData.find(({ id }) => id === terminalId),
    [terminalsData],
  )

  const terminalSelected = useMemo(() => {
    if (terminalIdSelected) {
      return findTerminal(terminalIdSelected)
    }
    return undefined
  }, [findTerminal, terminalIdSelected])

  const terminalLocation = useMemo<google.maps.LatLng>(() => {
    if (terminalSelected) {
      return new google.maps.LatLng(
        Number(terminalSelected.location?.lat || 0),
        Number(terminalSelected.location?.lng || 0),
      )
    }
    return new google.maps.LatLng(0, 0)
  }, [terminalSelected])

  const getTerminalLabel = useCallback((terminal: ITerminal | undefined) => {
    if (terminal) {
      return `${terminal.code} - ${terminal.name}`
    }
    return ''
  }, [])

  const findHighestRank = (objects: IBundleItem[]) => {
    if (objects.length === 0) {
      return 0
    }

    let highestRank: number | undefined = undefined

    for (const obj of objects) {
      if (obj.rank !== undefined) {
        if (highestRank === undefined || obj.rank > highestRank) {
          highestRank = obj.rank
        }
      }
    }

    if (highestRank === undefined) {
      return 0
    }

    return highestRank
  }

  const highestBundleItemRank = findHighestRank(bundleItemsData) + 1

  const onToggleBundleInputs = useCallback(() => {
    setIsShowingBundleInputs(prev => !prev)
  }, [])

  const createBundle = useCallback(async () => {
    setIsLoadingBundleForm(true)
    try {
      const { errors, ...response } = await apiClient.bundles.create({
        bundle: {
          sellerProductId,
          num: bundleFormValues.num,
          name: bundleFormValues.name,
        },
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreateBundle && afterCreateBundle(response)
        toast.success(toastMessages.createSuccess)
        onToggleBundleInputs()
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.createError)
    } finally {
      setIsLoadingBundleForm(false)
    }
  }, [
    afterCreateBundle,
    bundleFormValues.name,
    bundleFormValues.num,
    onToggleBundleInputs,
    sellerProductId,
  ])

  const updateBundle = useCallback(async () => {
    setIsLoadingBundleForm(true)
    try {
      if (bundleData?.id) {
        const { errors, ...response } = await apiClient.bundles.update(
          bundleData.id,
          {
            bundle: {
              name: bundleFormValues.name,
              num: bundleFormValues.num,
            },
          },
        )
        if (errors.length) {
          setError(errors[0])
        } else {
          onToggleBundleInputs()
          afterUpdateBundle && afterUpdateBundle(response)
        }
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    } finally {
      setIsLoadingBundleForm(false)
    }
  }, [
    afterUpdateBundle,
    bundleData?.id,
    bundleFormValues.name,
    bundleFormValues.num,
    onToggleBundleInputs,
  ])

  const onSubmitBundleForm = useCallback(() => {
    setError('')
    if (bundleData?.id) {
      updateBundle()
    } else {
      createBundle()
    }
  }, [bundleData?.id, createBundle, updateBundle])

  const onChangeBundleInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      const { name, value } = event.target
      setBundleFormValues(prev => ({
        ...prev,
        [name]: value,
      }))
    },
    [],
  )

  const onClickTerminal = useCallback(
    (terminalId: number) => () => {
      setTerminalIdSelected(prev => {
        if (prev === terminalId) {
          return null
        }
        return terminalId
      })
      setIsShowTerminalProducts(true)
    },
    [],
  )

  const renderTerminalBadges = useMemo(
    () =>
      sellerTerminalProductsData.map(({ id, terminalId }) => {
        const terminal = findTerminal(terminalId)

        return (
          <React.Fragment key={id}>
            <ToolTipOverlay
              content={getTerminalLabel(terminal)}
              placement='top'
            >
              <span
                className={clsx('locationIcon', {
                  selected: terminalId === terminalIdSelected,
                })}
                onClick={onClickTerminal(terminalId)}
              >
                <IonIcon icon={location} />
              </span>
            </ToolTipOverlay>
          </React.Fragment>
        )
      }),
    [
      findTerminal,
      getTerminalLabel,
      onClickTerminal,
      sellerTerminalProductsData,
      terminalIdSelected,
    ],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClickRemoveBundleItem = async (
    bundleItem: IBundleItem | undefined,
  ) => {
    if (bundleItem) {
      const result = await confirmation({
        message: 'Are you sure you want to remove this item?',
      })
      if (result === EYesNo.Yes) {
        onRemoveBundleItem && onRemoveBundleItem(bundleItem)
      }
    } else {
      setIsCreatingItem(false)
    }
  }

  const renderBundleItems = useMemo(
    () =>
      bundleItemsCloned.map((item, index) => (
        <BundleItemCard
          key={item.id}
          bundleItem={item}
          index={index}
          sellerProductId={sellerProductId}
          onRemove={onClickRemoveBundleItem}
          canDrag={bundleItemsCloned.length > 1}
          onDrag={(dragIndex: number, hoverIndex: number) => {
            const newBundleItems = produce(bundleItemsCloned, draft => {
              const [movedCard] = draft.splice(dragIndex, 1)
              draft.splice(hoverIndex, 0, movedCard)
            })
            setBundleItemsCloned(newBundleItems)
            setWasChangedRanking(true)
          }}
          afterUpdate={afterUpdateBundleItem}
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bundleData, bundleItemsCloned],
  )

  const onRevertRanking = useCallback(() => {
    setBundleItemsCloned(bundleItemsData)
    setWasChangedRanking(false)
  }, [bundleItemsData])

  const onSaveRanking = useCallback(async () => {
    setIsChangingRank(true)
    try {
      const ids = bundleItemsCloned.map(({ id }) => id)
      await apiClient.rerank.rerank({
        modelName: 'BundleItem',
        rankings: ids,
        field: 'rank',
        update: false,
      })
      afterChangeRanking && afterChangeRanking()
      setWasChangedRanking(false)
    } catch (error) {
      toast.error(toastMessages.serverError)
    } finally {
      setIsChangingRank(false)
    }
  }, [afterChangeRanking, bundleItemsCloned])

  const renderBundleItemsSection = useMemo(() => {
    if (isOpenBundleItems) {
      return (
        <div className='bundleItems'>
          <div className='bundleItemLabel'>
            <span style={{ marginRight: 6 }}>Bundle Items:</span>
            <ToolTipOverlay content='Create' placement='top'>
              <Badge
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsCreatingItem(prev => !prev)
                }}
              >
                <IonIcon icon={addOutline} style={{ fontSize: 12 }} />
              </Badge>
            </ToolTipOverlay>

            <When condition={wasChangedRanking}>
              <ToolTipOverlay content='Save changes' placement='top'>
                <Badge
                  bg='success'
                  style={{ marginLeft: 4, cursor: 'pointer' }}
                  onClick={onSaveRanking}
                  className={clsx({ isDisabled: isChangingRank })}
                >
                  {isChangingRank ? (
                    <IonSpinner name='dots' style={{ width: 10, height: 10 }} />
                  ) : (
                    <IonIcon icon={checkmark} style={{ fontSize: 12 }} />
                  )}
                </Badge>
              </ToolTipOverlay>
              <ToolTipOverlay content='Revert changes' placement='top'>
                <Badge
                  bg='warning'
                  style={{ marginLeft: 4, cursor: 'pointer' }}
                  onClick={onRevertRanking}
                >
                  <IonIcon icon={arrowUndo} style={{ fontSize: 12 }} />
                </Badge>
              </ToolTipOverlay>
            </When>
          </div>

          <div>
            {renderBundleItems}

            {isCreatingItem && (
              <BundleItemCard
                index={-1}
                defaultRank={highestBundleItemRank}
                sellerProductId={sellerProductId}
                bundleId={bundleData?.id}
                onRemove={onClickRemoveBundleItem}
                canDrag={bundleItemsCloned.length > 1}
                onDrag={(dragIndex: number, hoverIndex: number) => {
                  const newBundleItems = produce(bundleItemsCloned, draft => {
                    const [movedCard] = draft.splice(dragIndex, 1)
                    draft.splice(hoverIndex, 0, movedCard)
                  })
                  setBundleItemsCloned(newBundleItems)
                  setWasChangedRanking(true)
                }}
                afterUpdate={afterUpdateBundleItem}
                afterCreate={item => {
                  setIsCreatingItem(false)
                  afterCreateBundleItem && afterCreateBundleItem(item)
                }}
              />
            )}
          </div>
        </div>
      )
    }
  }, [
    afterCreateBundleItem,
    afterUpdateBundleItem,
    bundleItemsCloned,
    isChangingRank,
    isOpenBundleItems,
    onClickRemoveBundleItem,
    onRevertRanking,
    onSaveRanking,
    renderBundleItems,
    sellerProductId,
    wasChangedRanking,
    isCreatingItem,
    highestBundleItemRank,
    bundleData?.id,
  ])

  const onClickRemove = useCallback(async () => {
    const result = await confirmation({
      message: 'Are you sure you want to remove this item?',
    })
    if (result === EYesNo.Yes) {
      onRemove && onRemove(bundleData as IBundle)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickRemoveTerminalProd = useCallback(
    (terminalProd: ISellerTerminalProduct) => async () => {
      const result = await confirmation({
        message: 'Are you sure you want to remove this item?',
      })
      if (result === EYesNo.Yes) {
        onRemoveSellerTerminalProduct &&
          onRemoveSellerTerminalProduct(terminalProd)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const onSelectTerminal = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (event: any, { selectedOption }: any) => {
      setIsCreatingTerminalProd(true)
      try {
        const { errors, ...response } =
          await apiClient.sellerTerminalProducts.create({
            sellerTerminalProduct: {
              bundleId: bundleData?.id as number,
              sellerProductId: sellerProductId,
              terminalId: selectedOption.value,
              status: EStatus.Active,
            },
          })
        if (errors.length > 0) {
          toast.error(errors[0])
        } else {
          afterCreateSellerTerminalProduct &&
            afterCreateSellerTerminalProduct(response)
          setTerminalIdSelected(response.terminalId)
          toast.success(toastMessages.createSuccess)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        setIsCreatingTerminalProd(false)
      }
    },
    [afterCreateSellerTerminalProduct, bundleData?.id, sellerProductId],
  )

  const onToggleShowHideBundleItems = useCallback(() => {
    setIsOpenBundleItems(prev => !prev)
  }, [])

  const onToggleShowHideTerminalProducts = useCallback(() => {
    setIsShowTerminalProducts(prev => !prev)
  }, [])

  useDeepCompareEffect(() => {
    setBundleItemsCloned(bundleItemsData)
  }, [bundleItemsData])

  useEffect(() => {
    setBundleFormValues({
      name: bundleData?.name || '',
      num: bundleData?.num || null,
    })

    if (!bundleData?.id) {
      setIsShowingBundleInputs(true)
    }
  }, [bundleData])

  return (
    <div className='BundleBadge__container' ref={drop} style={style}>
      {isLoadingBundleForm && (
        <div className='loadingSpinner'>
          <IonSpinner name='dots' />
        </div>
      )}

      <Badge bg='secondary' className='badgeItem'>
        <Unless condition={isShowingBundleInputs}>
          <span onClick={onToggleBundleInputs}>
            <span>#{bundleFormValues.num}</span>
            {bundleFormValues.name && <span> - {bundleFormValues.name}</span>}
          </span>
        </Unless>

        <When condition={isShowingBundleInputs}>
          <AutoWidthInput
            autoFocus
            className='autoWidthInput'
            placeholder='Num'
            type='number'
            value={bundleFormValues.num || ''}
            name='num'
            onChange={onChangeBundleInput}
          />{' '}
          -{' '}
          <AutoWidthInput
            className='autoWidthInput'
            placeholder='Name'
            name='name'
            value={bundleFormValues.name}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={onChangeBundleInput}
          />
          <span className='submitBundleIcon' onClick={onSubmitBundleForm}>
            <IonIcon icon={checkmark} color='success' />
          </span>
          {bundleData?.id && (
            <span className='closeBundleInputs' onClick={onToggleBundleInputs}>
              <IonIcon icon={closeOutline} color='danger' />
            </span>
          )}
        </When>
      </Badge>

      <When condition={Boolean(bundleData?.id)}>
        <ToolTipOverlay content='Terminals' placement='top'>
          <Badge
            bg={isShowTerminalProducts ? 'info' : 'secondary'}
            className='badgeItem icon'
            onClick={onToggleShowHideTerminalProducts}
          >
            <IonIcon icon={mapOutline} />
            <IonIcon
              style={{ marginLeft: 4 }}
              icon={isShowTerminalProducts ? chevronUp : chevronDown}
            />
          </Badge>
        </ToolTipOverlay>

        <ToolTipOverlay content='Bundle Items' placement='top'>
          <Badge
            bg={isOpenBundleItems ? 'success' : 'secondary'}
            className='badgeItem icon'
            onClick={onToggleShowHideBundleItems}
          >
            <IonIcon icon={layers} />
            <IonIcon
              style={{ marginLeft: 4 }}
              icon={isOpenBundleItems ? chevronUp : chevronDown}
            />
          </Badge>
        </ToolTipOverlay>

        <ToolTipOverlay content='Remove this Bundle' placement='top'>
          <Badge bg='danger' className='badgeItem icon' onClick={onClickRemove}>
            <IonIcon icon={close} />
          </Badge>
        </ToolTipOverlay>

        {renderTerminalBadges}
      </When>

      {error && <span className='error'>{error}</span>}

      {/* <div className='removeIcon icon' onClick={onClickRemove}>
        <IonIcon icon={close} />
      </div> */}

      {isShowTerminalProducts && (
        <>
          <div className='terminalSection'>
            <div className='dropdownLabel'>Seller Terminal Products: </div>
            <DropdownWithCustomChildren
              options={terminalOptions}
              onChange={onSelectTerminal}
              className='terminalDropdown'
              isLoading={isCreatingTerminalProd}
              isOptionDisabled={opt => existentTerminalIds.includes(opt.value)}
            >
              <Badge>
                {isCreatingTerminalProd ? (
                  <IonSpinner name='dots' style={{ width: 10, height: 10 }} />
                ) : (
                  <IonIcon icon={addOutline} />
                )}
              </Badge>
            </DropdownWithCustomChildren>
          </div>
          <div className='terminalsList'>
            {sellerTerminalProductsData.map(terminalProd => {
              return (
                <SellerTerminalProdCard
                  key={terminalProd.id}
                  sellerTerminalProduct={terminalProd}
                  isSelected={terminalIdSelected === terminalProd.terminalId}
                  onClick={({ terminalId }) => {
                    onClickTerminal(terminalId)()
                  }}
                  onRemove={terminal => {
                    onClickRemoveTerminalProd(terminal)
                  }}
                  afterDrag={afterDrag}
                />
              )
            })}
          </div>

          {terminalSelected && (
            <div className='mapContainer'>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              <GoogleMap defaultCenter={terminalLocation as any} zoom={15}>
                <TerminalMarker1 terminal={terminalSelected} />
              </GoogleMap>
            </div>
          )}
        </>
      )}

      {renderBundleItemsSection}
    </div>
  )
}

export default BundleBadge

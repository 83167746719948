import { useMemo, useState } from 'react'
import { useQueryUoms } from '~/hooks/useQueryData'

import { FloatingInput } from '../FloatingForm/Input'
import Select from 'react-select'

import './QtyCombined.scss'

const QtyCombinedInput = ({ control, index, onBlurQty }) => {
  const { currentUom } = useQueryUoms()

  const [input, setInput] = useState('qty')

  const options = useMemo(
    () => [
      { value: 'qty', label: currentUom ? `Qty (${currentUom.code})` : 'Qty' },
      { value: 'loads', label: 'Loads' },
    ],
    [currentUom],
  )

  const selectedOption = useMemo(
    () => options.find(({ value }) => value === input),
    [input, options],
  )

  const inputName = useMemo(
    () => `schedulesAttributes[${index}].${input}`,
    [index, input],
  )

  return (
    <div className='qty-loads-input'>
      <FloatingInput
        className='qty'
        control={control}
        inputType='number'
        name={inputName}
        label={selectedOption.label}
        handleBlur={onBlurQty}
      />

      <Select
        className='select'
        options={options}
        onChange={selectedValue => {
          setInput(selectedValue.value)
        }}
        value={selectedOption}
        styles={{
          control: provided => ({
            ...provided,
            borderLeft: 0,
            borderRadius: '0px 1px 1px 0px',
            height: '41px',
            minHeight: '41px',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
        }}
      />
    </div>
  )
}

export default QtyCombinedInput

import React, { useCallback, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { NoteIcon } from '~/components/shared'
import Tooltip from 'rc-tooltip'

import './styles.scss'

export interface INoteButtonProps {
  canEdit?: boolean
  note?: string
  onSave?: (note: string) => void
  style?: React.CSSProperties
}

function NoteButton(props: INoteButtonProps) {
  const { note, canEdit = true, onSave, style } = props

  const [noteValue, setNoteValue] = useState('')
  const [isVisible, setIsVisible] = useState(false)

  const handleChangeNotes = useCallback((event: any) => {
    setNoteValue(event.target.value)
  }, [])

  const onClickSave = () => {
    onSave && onSave(noteValue)
    setIsVisible(false)
  }

  useEffect(() => {
    setNoteValue(note || '')
  }, [note])

  return (
    <Tooltip
      placement='right'
      overlayClassName='NoteButton__tooltip'
      visible={isVisible}
      onVisibleChange={setIsVisible}
      overlay={
        <Form>
          <Form.Group>
            <Form.Control
              as='textarea'
              rows={3}
              value={noteValue}
              onChange={handleChangeNotes}
            />
          </Form.Group>
          {canEdit && <Button onClick={onClickSave}>Save</Button>}
        </Form>
      }
      trigger='click'
      destroyTooltipOnHide
    >
      <Button variant='secondary' style={{ position: 'relative', ...style }}>
        <NoteIcon color='white' />
        {noteValue.length > 0 && <div className='NoteButton__dot' />}
      </Button>
    </Tooltip>
  )
}

export default NoteButton

import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { ENoteableType } from '~/types/enums/ENote'
import _ from 'lodash'

import type { IUser } from '~/types/models/IUser'
import type { ILoad } from '~/types/models/ILoad'
import type { INote } from '~/types/models/INote'
import type { IUseQuerySchedulesData, IUseQuerySchedulesParams } from './type'
import useModifySchedules from './useModifySchedules'
import { ISchedule } from '~/types/models/ISchedule'
import { useQueryUoms } from '../useQueryUoms'
import { useQueryTerminals } from '../useQueryTerminals'

const useQuerySchedules = (
  params: IUseQuerySchedulesParams & { id?: number },
  options?: Partial<UseQueryOptions<IUseQuerySchedulesData>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { currentUom } = useQueryUoms()
  const { findTerminalById } = useQueryTerminals()

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey: [
      'schedules',
      sessionUser?.id,
      buildGetUrl(apiClient.schedules.endpoint, params),
    ],
    async queryFn() {
      let schedules: ISchedule[] = []
      if (params?.id) {
        const response = await apiClient.schedules.getById(params.id)
        schedules = [response]
      } else {
        const response = await apiClient.schedules.get(
          _.omit(params, ['hasLoads', 'hasNotes']),
        )
        schedules = response.schedules
      }

      let loadsData: ILoad[] = []
      let notesData: INote[] = []

      if (params.hasLoads && schedules.length > 0) {
        const loadsRes = await apiClient.loads.get({
          filters: {
            scheduleId: schedules.map(({ id }) => id),
          },
          perPage: 1000,
        })
        loadsData = loadsRes.loads
      }

      if (params.hasNotes && schedules.length > 0) {
        const notesRes = await apiClient.notes.get({
          filters: {
            noteableId: schedules.map(({ id }) => id),
            noteableType: ENoteableType.Schedule,
          },
        })
        notesData = notesRes.notes
      }

      return schedules.map(schedule => {
        const loads = loadsData.filter(
          ({ scheduleId }) => schedule.id === scheduleId,
        )
        const notes = notesData.filter(
          ({ noteableId }) => schedule.id === noteableId,
        )

        return {
          ...schedule,
          loads,
          notes,
        }
      })
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // one minute
    ...options,
  })

  const {
    addScheduleNote,
    updateScheduleNote,
    deleteScheduleNote,
    updateSchedule,
    addLoadToSchedule,
    addSchedule,
  } = useModifySchedules(params)

  const schedulesData = data || []

  const schedulesDataHavingLoads = schedulesData.filter(
    ({ loads }) => (loads || []).length > 0,
  )

  const formatScheduleLabel = (schedule: ISchedule | undefined) => {
    if (schedule) {
      const sellerTerminal = findTerminalById(schedule.sellerTerminalId)
      const scheduleNumberLabel = `#${schedule.scheduleNumber}`
      const sellerTerminalLabel = sellerTerminal
        ? `Seller Terminal: ${sellerTerminal.code} - ${sellerTerminal.name}`
        : null
      const qtyLabel = `${schedule.qtyCounter} / ${schedule.qty} ${
        currentUom?.code || ''
      }`
      const spacingLabel = `${schedule.spacing} mins`
      const label = [
        scheduleNumberLabel,
        sellerTerminalLabel,
        qtyLabel,
        spacingLabel,
      ]
        .filter(label => label)
        .join(', ')

      return label
    }
    return ''
  }

  const scheduleOptions = schedulesData.map(schedule => {
    const label = formatScheduleLabel(schedule)

    return {
      value: schedule.id,
      label,
    }
  })

  const findScheduleById = (id: number) =>
    schedulesData.find(item => item.id === id)

  return {
    schedulesData,
    schedulesDataHavingLoads,
    isLoadingSchedulesData: isLoading,
    isSchedulesDataFetched: isFetched,
    refetchSchedulesData: refetch,
    addScheduleNote,
    updateScheduleNote,
    deleteScheduleNote,
    updateSchedule,
    addLoadToSchedule,
    addSchedule,
    findScheduleById,
    scheduleOptions,
    formatScheduleLabel,
  }
}

export default useQuerySchedules

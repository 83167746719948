import { ISellerTerminalProdCardProps } from './type'

import './styles.scss'
import clsx from 'clsx'
import { Badge } from 'react-bootstrap'
import { IonIcon, IonSpinner } from '@ionic/react'
import { close } from 'ionicons/icons'
import { useQueryTerminals } from '~/hooks/useQueryData'
import { useCallback, useMemo, useState } from 'react'
import { ITerminal } from '~/types/models/ITerminal'
import { useDrag } from 'react-dnd'
import { ISellerTerminalProduct } from '~/types/models/ISellerTerminalProduct'
import { IBundle } from '~/types/models/IBundle'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'

function SellerTerminalProdCard(props: ISellerTerminalProdCardProps) {
  const { isSelected, sellerTerminalProduct, onClick, onRemove, afterDrag } =
    props

  const { terminalsData } = useQueryTerminals()

  const [isLoading, setIsLoading] = useState(false)

  const removeAndAddSellerTerminalProd = useCallback(
    async (item: ISellerTerminalProduct, bundle: IBundle) => {
      if (!bundle) {
        toast.error('Bundle is not found!')
      }
      setIsLoading(true)
      try {
        const response = await apiClient.sellerTerminalProducts.update(
          item.id,
          {
            sellerTerminalProduct: {
              bundleId: bundle.id,
            },
          },
        )
        if (response.errors.length) {
          toast.error(response.errors[0])
        } else {
          afterDrag && afterDrag(response)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [afterDrag],
  )

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'BUNDLE_CARD',
    item: sellerTerminalProduct,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<{ bundle: IBundle }>()
      if (item && dropResult?.bundle?.id !== sellerTerminalProduct.bundleId) {
        console.log('dropResult?.bundle', dropResult?.bundle)
        removeAndAddSellerTerminalProd(item, dropResult?.bundle as IBundle)
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))

  const getTerminalLabel = useCallback((terminal: ITerminal | undefined) => {
    if (terminal) {
      return `${terminal.code} - ${terminal.name}`
    }
    return ''
  }, [])

  const terminal = useMemo(
    () =>
      terminalsData.find(({ id }) => id === sellerTerminalProduct.terminalId),
    [sellerTerminalProduct.terminalId, terminalsData],
  )
  const opacity = isDragging ? 0.4 : 1

  return (
    <div
      className={clsx('SellerTerminalProdCard__container', {
        isSelected,
        isDisabled: isLoading,
      })}
      onClick={() => {
        onClick && onClick(sellerTerminalProduct)
      }}
      ref={drag}
      style={{ opacity }}
    >
      {isLoading && (
        <IonSpinner
          style={{
            verticalAlign: 'middle',
            width: 20,
            height: 10,
            marginRight: 4,
          }}
          name='dots'
        />
      )}

      <span>{getTerminalLabel(terminal)}</span>
      <Badge
        style={{ marginLeft: 4 }}
        bg='danger'
        className='icon'
        onClick={() => {
          onRemove && onRemove(sellerTerminalProduct)
        }}
      >
        <IonIcon icon={close} />
      </Badge>
    </div>
  )
}

export default SellerTerminalProdCard

import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyProductGlCodes from './useModifyProductGlCodes'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetProductGlCodesParams,
  IProductGlCode,
} from '~/types/models/IProductGlCode'
import { buildGetUrl } from '~/utils/buildUrl'

const useQueryProductGlCodes = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  params: Partial<IGetProductGlCodesParams> = {},
  options?: Partial<UseQueryOptions<IProductGlCode[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'productGlCodes',
      sessionUser?.id,
      buildGetUrl(apiClient.productGlCodes.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.productGlCodes.get(params)
      return response.productGlCodes
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // 1 min
    ...options,
  })

  const productGlCodesData = useMemo(() => data || [], [data])

  const { addProductGlCode, updateProductGlCode, removeProductGlCode } =
    useModifyProductGlCodes(params)

  return {
    productGlCodesData,
    isLoadingProductGlCodes: isLoading,
    addProductGlCode,
    updateProductGlCode,
    removeProductGlCode,
  }
}

export default useQueryProductGlCodes

import { useMemo } from 'react'
import { useQueryUsers } from '~/hooks/useQueryData'
import useFuzzy from '~/hooks/useFuzzy'
import { ContainerSearchBar, DriverCard } from '~/components/shared'
import { ItemTypes } from '~/components/fleet/StartTimes/helpers'
import Skeleton from 'react-loading-skeleton'

import './styles.scss'
import { Alert } from 'react-bootstrap'
import clsx from 'clsx'
export interface IDriverFleetListProps {
  assignedDriverFleetIds: number[]
  isBannerShown?: boolean
  bannerText?: string
  isListDisabled?: boolean
}

function DriverFleetList(props: IDriverFleetListProps) {
  const { assignedDriverFleetIds, isBannerShown, bannerText, isListDisabled } =
    props

  const { driverFleetUsers, isLoadingUsersData } = useQueryUsers()

  const availableDriverFleets = useMemo(
    () =>
      driverFleetUsers.filter(
        ({ driverFleet }) =>
          !assignedDriverFleetIds.includes(driverFleet?.id as number),
      ),
    [assignedDriverFleetIds, driverFleetUsers],
  )

  const {
    searchValue,
    onSearch,
    seachedList: searchedDriverList,
  } = useFuzzy(availableDriverFleets, {
    keys: ['person.firstName', 'person.lastName'],
  })

  return (
    <div className='DriverFleetList__container'>
      <div className='headerContainer'>
        <ContainerSearchBar
          searchBarParent='searchBar'
          searchBarPlaceholder='Search Drivers'
          searchBarValue={searchValue}
          onSearchBarChange={onSearch}
        />
      </div>

      <div className='driverFleetList'>
        {isBannerShown && (
          <Alert style={{ marginBottom: 8, fontSize: 13 }} variant='danger'>
            {bannerText}
          </Alert>
        )}
        {isLoadingUsersData && (
          <div>
            <Skeleton height={14} width='100%' className='item' />
            <Skeleton height={14} width='100%' className='item' />
            <Skeleton height={14} width='100%' className='item' />
            <Skeleton height={14} width='100%' className='item' />
          </div>
        )}

        {searchedDriverList.map(user => (
          <div
            key={user.id}
            style={{ marginBottom: 8 }}
            className={clsx({ isDisabled: isListDisabled })}
          >
            <DriverCard
              driverFleetId={user.driverFleet?.id as number}
              dragType={ItemTypes.DRIVER}
              isShowingDriverName
              isHiddenStatusTime
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default DriverFleetList

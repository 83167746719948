import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyLoad from './useModifyLoad'
import { useQueryBreadcrumbLoads } from '../useQueryBreadcrumbLoads'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser, selectAllTerminals } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { ILoad } from '~/types/models/ILoad'
import type { ITerminal } from '~/types/models/ITerminal'
import { toast } from 'react-toastify'

const useQueryLoad = (
  loadId: number,
  options?: Partial<UseQueryOptions<ILoad | undefined>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)
  const terminalObjects: Record<number, ITerminal> =
    useSelector(selectAllTerminals)

  const { updateLoadData } = useModifyLoad(loadId)

  const { data, isLoading, isFetched } = useQuery({
    queryKey: ['load', sessionUser?.id, loadId],
    async queryFn() {
      const response = await apiClient.loads.getById(loadId)
      if (response.id) {
        return response
      }
      toast.error('Load Is Not Found')
      return undefined
    },
    enabled: Boolean(sessionUser?.id && loadId),
    staleTime: 60000, // one minute
    ...options,
  })

  const { breadcrumbLoadsData } = useQueryBreadcrumbLoads(loadId)

  const loadBuyerTerminal = useMemo(() => {
    if (data?.buyerTerminalId) {
      return terminalObjects[data.buyerTerminalId]
    }
    return null
  }, [data?.buyerTerminalId, terminalObjects])

  const loadSellerTerminal = useMemo(() => {
    if (data?.sellerTerminalId) {
      return terminalObjects[data.sellerTerminalId]
    }
    return null
  }, [data?.sellerTerminalId, terminalObjects])

  const loadReturnTerminal = useMemo(() => {
    if (data?.returnTerminalId) {
      return terminalObjects[data.returnTerminalId]
    }
    return null
  }, [data?.returnTerminalId, terminalObjects])

  const returnTerminalLocation = useMemo(() => {
    if (
      loadReturnTerminal &&
      loadReturnTerminal?.id !== loadSellerTerminal?.id &&
      loadReturnTerminal.location
    ) {
      return new window.google.maps.LatLng(
        Number(loadReturnTerminal.location.lat),
        Number(loadReturnTerminal.location.lng),
      )
    }
    return null
  }, [loadReturnTerminal, loadSellerTerminal?.id])

  const buyerTerminalLocation = useMemo(() => {
    if (loadBuyerTerminal?.location) {
      return new window.google.maps.LatLng(
        Number(loadBuyerTerminal?.location.lat),
        Number(loadBuyerTerminal?.location.lng),
      )
    }
    return null
  }, [loadBuyerTerminal?.location])

  // Seller Terminal
  const sellerTerminalLocation = useMemo(() => {
    if (loadSellerTerminal?.location) {
      return new window.google.maps.LatLng(
        Number(loadSellerTerminal.location.lat),
        Number(loadSellerTerminal.location.lng),
      )
    }
    return null
  }, [loadSellerTerminal])

  return {
    loadData: data,
    isLoadingLoadData: isLoading,
    loadBuyerTerminal,
    loadSellerTerminal,
    loadReturnTerminal,
    buyerTerminalLocation,
    sellerTerminalLocation,
    returnTerminalLocation,
    breadcrumbLoadsData,
    updateLoadData,
    isLoadDataFetched: isFetched,
  }
}

export default useQueryLoad

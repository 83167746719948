import PropTypes from 'prop-types'

export const getObjectKeyFromColumn = (docType, columnName, keyType) => {
  const mapping = {
    Load: LOADS_COLUMN_TO_KEY,
    Invoice: INVOICES_COLUMN_TO_KEY,
    'Bill Line': BILL_LINES_COLUMN_TO_KEY,
  }

  const key = mapping[docType][columnName][keyType]
  return key
}

const LOADS_COLUMN_TO_KEY = {
  ticket_num: { nameKey: 'num', codeKey: null },
  date: { nameKey: 'date', codeKey: null },
  qty: { nameKey: 'qty', codeKey: null },
  sellerBuyer: {
    nameKey: 'sellerName',
    codeKey: 'sellerCode',
    idKey: 'sellerId',
  },
  seller_terminal: {
    nameKey: 'sellerTerminalName',
    codeKey: 'sellerTerminalCode',
    idKey: 'sellerTerminalId',
  },
  buyer_terminal: {
    nameKey: 'buyerTerminalName',
    codeKey: 'buyerTerminalCode',
    idKey: 'buyerTerminalId',
  },
  buyer_seller_product: {
    nameKey: 'productName',
    codeKey: 'productCode',
    idKey: 'productId',
  },
  fleet: { nameKey: 'fleetName', codeKey: 'fleetCode', idKey: 'fleetId' },
}

const INVOICES_COLUMN_TO_KEY = {
  num: { nameKey: 'num', codeKey: null },
  date: { nameKey: 'date', codeKey: null },
  seller_id: {
    nameKey: 'sellerName',
    codeKey: 'sellerCode',
    idKey: 'sellerId',
  },
  buyer_id: { nameKey: 'buyerName', codeKey: 'buyerCode', idKey: 'buyerId' },
  buyer_terminal: {
    nameKey: 'buyerTerminalName',
    codeKey: 'buyerTerminalCode',
    idKey: 'buyerTerminalId',
  },
  total: { nameKey: 'total', codeKey: null },
  qty: { nameKey: 'qty', codeKey: null },
  end_date: { nameKey: 'endDate', codeKey: null },
  due_date: { nameKey: 'dueDate', codeKey: null },
  //below don't have invoice table columns
  // seller_terminal: {
  //   nameKey: 'sellerTerminalName',
  //   codeKey: 'sellerTerminalCode',
  // },
  // price: { nameKey: 'price', codeKey: null },
  // load_date: { nameKey: 'date', codeKey: null },
  // doc_total: { nameKey: 'docTotal', codeKey: null },
  // sub: { nameKey: 'sub', codeKey: null },
  // tax: { nameKey: 'tax', codeKey: null },
  // parsed_payment_terms: { nameKey: 'parsedPaymentTerms', codeKey: null },
  // credits: { nameKey: 'credits', codeKey: null },
  // balance: { nameKey: 'balance', codeKey: null },
  // parsed_discount: { nameKey: 'parsedDiscounts', codeKey: null },
  // discount_amount: { nameKey: 'discount', codeKey: null },
  // freight: { nameKey: 'freight', codeKey: null },
  // late_fees: { nameKey: 'lateFees', codeKey: null },
}

const BILL_LINES_COLUMN_TO_KEY = {
  num: { nameKey: 'num', codeKey: null },
  line_date: { nameKey: 'date', codeKey: null },
  product: {
    nameKey: 'productName',
    codeKey: 'productCode',
    idKey: 'productId',
  },
  qty: { nameKey: 'qty', codeKey: null },
  price: { nameKey: 'price', codeKey: null },
  ext: { nameKey: 'extPrice', codeKey: null },
  doc_ext_price: { nameKey: 'totalExtPrice', codeKey: null },
  tax: { nameKey: 'tax', codeKey: null },
  other_charges: { nameKey: 'otherCharges', codeKey: null },
  buyer_terminal: {
    nameKey: 'buyerTerminalName',
    codeKey: 'buyerTerminalCode',
    idKey: 'buyerTerminalId',
  },
  seller_terminal: {
    nameKey: 'sellerTerminalName',
    codeKey: 'sellerTerminalCode',
    idKey: 'sellerTerminalId',
  },
}

getObjectKeyFromColumn.propTypes = {
  docType: PropTypes.oneOf(['Load', 'Invoice', 'Bill Line']).isRequired,
  columnName: PropTypes.string.isRequired,
  keyType: PropTypes.oneOf(['nameKey', 'codeKey']).isRequired,
}

import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import useTableCompanyProductGroupSubTab from './useTableCompanyProductGroupSubTab'
import {
  useQueryProductGroups,
  useModifyProductGroups,
} from '~/hooks/useQueryData'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  DialogProductGroupForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'

import _ from 'lodash'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { updateProductGroupsUi } from '~/redux/actions/CommonData/productGroupsActions'
import { apiClient } from '~/api/ApiClient'
import { ERTDisplayColumnId, EYesNo } from '~/types/enums/ECommonEnum'
import { useWindowSize } from 'react-use'
import { moduleName } from '~/utils/constants'

function CompanyProductGroupSubTab() {
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [currentRowData, setCurrentRowData] = useState()

  const { confirmation } = useConfirmationProvider()
  const windowSize = useWindowSize()

  const dispatch = useDispatch()

  const { productGroups, isLoadingProductGroups } = useQueryProductGroups()
  const { addProductGroup, updateProductGroup, deleteProductGroup } =
    useModifyProductGroups()

  const onCloseDialogForm = useCallback(() => {
    setIsOpenForm(false)
    setCurrentRowData()
  }, [])

  const handleDeleteRow = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete product group #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        dispatch(updateProductGroupsUi({ loading: true }))
        try {
          const { errors } = await apiClient.productGroups.delete(rowData.id)
          if (_.size(errors) > 0) {
            toast.error(toastMessages.deleteError)
          } else {
            toast.success(toastMessages.deleteSuccess)
            deleteProductGroup(rowData)
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.deleteError)
        } finally {
          dispatch(updateProductGroupsUi({ loading: false }))
        }
      }
    },
    [confirmation, deleteProductGroup, dispatch],
  )

  const handleOpenUpdateForm = useCallback(rowData => {
    setIsOpenForm(true)
    setCurrentRowData(rowData)
  }, [])

  const onCellEditEnd = useCallback(
    async (value, cell) => {
      try {
        const { column, row } = cell
        const columnField = column.id
        const rowId = row.original.id

        const formData = { [columnField]: value }
        const { productGroup, errors, status } =
          await apiClient.productGroups.update(rowId, formData)
        if (status === 'error') {
          const keys = Object.keys(errors)
          if (keys.length > 0) {
            const [key] = keys
            if (errors[key].length > 0) {
              const message = errors[key][0]
              toast.error(`${key} ${message}`)
            }
          }
        } else {
          toast.success(toastMessages.updateSuccess)
          updateProductGroup(productGroup)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      }
    },
    [updateProductGroup],
  )

  const { columns } = useTableCompanyProductGroupSubTab()

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          columns={columns}
          data={productGroups}
          enableTopToolbar
          tableHeight={windowSize.height - 220}
          enableCompanyView
          enableRowActions
          companyViewProps={{
            name: moduleName.company.productGroups,
            description: 'View and manage your various product groups.',
            onClickTopButton() {
              setIsOpenForm(true)
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              minSize: 85,
              maxSize: 85,
            },
          }}
          state={{
            isLoading: isLoadingProductGroups,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: () => handleOpenUpdateForm(row.original),
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              onClick: () => handleDeleteRow(row.original),
              color: 'danger',
              tooltipProps: {
                content: 'Remove',
                placement: 'top',
              },
            },
          ]}
          onCellEditEnd={onCellEditEnd}
        />
      </div>
      <DialogProductGroupForm
        isOpen={isOpenForm}
        formData={currentRowData}
        onClose={onCloseDialogForm}
        afterCreate={formData => {
          addProductGroup(formData)
          onCloseDialogForm()
        }}
        afterUpdate={formData => {
          updateProductGroup(formData)
          onCloseDialogForm()
        }}
      />
    </>
  )
}

CompanyProductGroupSubTab.propTypes = {}

export default CompanyProductGroupSubTab

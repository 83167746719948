import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'

import type { IGetUserParams, IUser } from '~/types/models/IUser'
import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { produce } from 'immer'

import type { IUserData } from './useQueryUsers'
import type { IWorker } from '~/types/models/IWorker'
import type { IPerson } from '~/types/models/IPerson'
import type { IDriverFleet } from '~/types/models/IDriverFleet'
import type { IDriver } from '~/types/models/IDriver'

const useModifyUsers = (params: Partial<IGetUserParams> = {}) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'users',
      sessionUser?.id,
      buildGetUrl(apiClient.users.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addUser = useCallback(
    (user: IUserData) => {
      queryClient.setQueryData<IUserData[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [...oldData, user]
        }
        return [user]
      })
    },
    [queryClient, queryKey],
  )

  const updateUser = useCallback(
    (id: number, user: Partial<IUserData>) => {
      queryClient.setQueryData<IUserData[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(user => user.id === id)
            if (draft[index]) {
              draft[index] = {
                ...draft[index],
                ...user,
              }
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const updateWorker = useCallback(
    (workerId: number, worker: Partial<IWorker>) => {
      queryClient.setQueryData<IUserData[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(user => user.worker?.id === workerId)
            if (draft[index]?.worker) {
              draft[index].worker = {
                ...draft[index].worker,
                ...(worker as any),
              }
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const updatePerson = useCallback(
    (personId: number, person: Partial<IPerson>) => {
      queryClient.setQueryData<IUserData[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(user => user.person?.id === personId)
            if (draft[index]?.person) {
              draft[index].person = {
                ...draft[index].person,
                ...(person as any),
              }
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const updateDriverFleet = useCallback(
    (id: number, data: Partial<IDriverFleet>) => {
      queryClient.setQueryData<IUserData[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            if (typeof data.currentTruckFleetId === 'number') {
              const indexOfAssigneeWithTruck = draft.findIndex(
                ({ driverFleet }) =>
                  driverFleet?.currentTruckFleetId === data.currentTruckFleetId,
              )
              if (indexOfAssigneeWithTruck !== -1) {
                draft[
                  indexOfAssigneeWithTruck
                ]!.driverFleet!.currentTruckFleetId = undefined
              }
            }

            if (typeof data.defaultTruckFleetId === 'number') {
              const indexOfAssigneeWithTruck = draft.findIndex(
                ({ driverFleet }) =>
                  driverFleet?.defaultTruckFleetId === data.defaultTruckFleetId,
              )
              if (indexOfAssigneeWithTruck !== -1) {
                draft[
                  indexOfAssigneeWithTruck
                ]!.driverFleet!.defaultTruckFleetId = undefined
              }
            }

            const index = draft.findIndex(user => user.driverFleet?.id === id)
            if (index !== -1) {
              draft[index].driverFleet = {
                ...draft[index].driverFleet,
                ...(data as any),
              }
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const updateDriver = useCallback(
    (id: number, data: Partial<IDriver>) => {
      queryClient.setQueryData<IUserData[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(user => user.driver?.id === id)
            if (index !== -1) {
              draft[index].driver = {
                ...draft[index].driver,
                ...(data as any),
              }
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeWorker = useCallback(
    (workerId: number) => {
      queryClient.setQueryData<IUserData[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(user => user.worker?.id === workerId)
            if (index !== -1) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const assignTruckFleetToDriverFleet = useCallback(
    (currentTruckFleetId: number, driverFleetId: number) => {
      queryClient.setQueryData<IUserData[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const userAssignedToThisTruckIndex = draft.findIndex(
              ({ driverFleet }) =>
                driverFleet?.currentTruckFleetId === currentTruckFleetId,
            )
            if (userAssignedToThisTruckIndex !== -1) {
              draft[
                userAssignedToThisTruckIndex
              ].driverFleet!.currentTruckFleetId = null
            }

            const userIndex = draft.findIndex(
              ({ driverFleet }) => driverFleet?.id === driverFleetId,
            )
            if (userIndex !== -1) {
              draft[userIndex].driverFleet!.currentTruckFleetId =
                currentTruckFleetId
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const assignDefaultTruckFleetToDriverFleet = useCallback(
    (defaultTruckFleetId: number, driverFleetId: number) => {
      queryClient.setQueryData<IUserData[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const userAssignedToThisTruckIndex = draft.findIndex(
              ({ driverFleet }) =>
                driverFleet?.defaultTruckFleetId === defaultTruckFleetId,
            )
            if (userAssignedToThisTruckIndex !== -1) {
              draft[
                userAssignedToThisTruckIndex
              ].driverFleet!.defaultTruckFleetId = null
            }

            const userIndex = draft.findIndex(
              ({ driverFleet }) => driverFleet?.id === driverFleetId,
            )
            if (userIndex !== -1) {
              draft[userIndex].driverFleet!.defaultTruckFleetId =
                defaultTruckFleetId
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addUser,
    updateUser,
    updateWorker,
    updatePerson,
    updateDriver,
    updateDriverFleet,
    removeWorker,
    assignTruckFleetToDriverFleet,
    assignDefaultTruckFleetToDriverFleet,
  }
}

export default useModifyUsers

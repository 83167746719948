import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type {
  IGetSellerProductQueryParams,
  IGetSellerProductResponse,
  ISellerProduct,
} from '~/types/models/ISellerProduct'
import type { IUser } from '~/types/models/IUser'

const useModifySellerProducts = () => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const addSellerProduct = useCallback(
    (
      sellerProduct: ISellerProduct,
      params: IGetSellerProductQueryParams = {},
    ) => {
      queryClient.setQueryData<IGetSellerProductResponse | undefined>(
        [
          'sellerProducts',
          sessionUser?.id,
          buildGetUrl('/seller_products', params),
        ],
        oldData => {
          if (oldData) {
            return {
              sellerProducts: [...oldData.sellerProducts, sellerProduct],
            }
          }

          return {
            sellerProducts: [sellerProduct],
          }
        },
      )
    },
    [queryClient, sessionUser?.id],
  )

  const updateSellerProduct = useCallback(
    (
      id: number,
      sellerProduct: Partial<ISellerProduct>,
      params: IGetSellerProductQueryParams = {},
    ) => {
      queryClient.setQueryData<IGetSellerProductResponse | undefined>(
        [
          'sellerProducts',
          sessionUser?.id,
          buildGetUrl('/seller_products', params),
        ],
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.sellerProducts.findIndex(sl => sl.id === id)
              if (index !== -1) {
                draft.sellerProducts[index] = {
                  ...draft.sellerProducts[index],
                  ...sellerProduct,
                }
              }
            }
          }),
      )
    },
    [queryClient, sessionUser?.id],
  )

  const deleteSellerProduct = useCallback(
    (id: number, params: IGetSellerProductQueryParams = {}) => {
      queryClient.setQueryData<IGetSellerProductResponse | undefined>(
        [
          'sellerProducts',
          sessionUser?.id,
          buildGetUrl('/seller_products', params),
        ],
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.sellerProducts.findIndex(sl => sl.id === id)
              if (index !== -1) {
                draft.sellerProducts.splice(index, 1)
              }
            }
          }),
      )
    },
    [queryClient, sessionUser?.id],
  )

  return { updateSellerProduct, deleteSellerProduct, addSellerProduct }
}

export default useModifySellerProducts

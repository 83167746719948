import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'
import { IGetOrderExtrasParams, IOrderExtra } from '~/types/models/IOrderExtra'
import { produce } from 'immer'

const useModifyOrderExtras = (params: IGetOrderExtrasParams = {}) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'orderExtras',
      sessionUser?.id,
      buildGetUrl(apiClient.orderExtras.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addOrderExtra = useCallback(
    (data: IOrderExtra) => {
      queryClient.setQueryData<IOrderExtra[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [...oldData, data]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateOrderExtra = useCallback(
    (id: number, data: Partial<IOrderExtra>) => {
      queryClient.setQueryData<IOrderExtra[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = {
                ...draft[index],
                ...data,
              }
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeOrderExtra = useCallback(
    (id: number) => {
      queryClient.setQueryData<IOrderExtra[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addOrderExtra,
    updateOrderExtra,
    removeOrderExtra,
  }
}

export default useModifyOrderExtras

import useTruckNumberSection from './useTruckNumberSection'

import { ToolTipOverlay } from '~/components/shared/ToolTipOverlay'

import clsx from 'clsx'

import type { ITruckNumberSectionProps } from './type'

import './styles.scss'

function TruckNumberSection(props: ITruckNumberSectionProps) {
  const {
    className,
    truckId,
    tooltipProps,
    renderTruckName = () => truck?.name,
    ...spanProps
  } = props

  const { onRightClick, truck } = useTruckNumberSection(props)

  return (
    <ToolTipOverlay
      content={`Truck: ${truck?.name}`.trim()}
      placement='top'
      {...tooltipProps}
    >
      <span
        className={clsx('TruckNumberSection__container', className)}
        onContextMenu={onRightClick}
        {...spanProps}
      >
        {renderTruckName(truck)}
      </span>
    </ToolTipOverlay>
  )
}

export default TruckNumberSection

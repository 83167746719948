import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useWorkerFormProvider } from '~/contexts'

import { selectDriverFleets } from '~/redux/selectors'

import type { IDriverAvatarProps } from './type'
import type { IDriverFleet } from '~/types/models/IDriverFleet'
import type { IWorkerFormDefaultValues } from '~/contexts/WorkerFormProvider/type'
import buildFullName from '~/utils/buildFullName'

export const useDriverAvatar = (props: IDriverAvatarProps) => {
  const { driverFleetId, isAssignable, ...infoProps } = props

  const { onOpenWorkerFormData } = useWorkerFormProvider()

  const driverFleets: Record<number, IDriverFleet> =
    useSelector(selectDriverFleets)

  const driverFleet = useMemo<IDriverFleet | undefined>(
    () => driverFleetId ? driverFleets[driverFleetId] : undefined,
    [driverFleetId, driverFleets],
  )

  const fullName = useMemo<string>(
    () => {
      if (!driverFleet?.id) return isAssignable ? '+' : '?'
      return buildFullName(driverFleet?.person, '?')},
    [driverFleet, isAssignable],
  )

  const driverFormData = useMemo<IWorkerFormDefaultValues | null>(() => {
    if (driverFleet) {
      return {
        id: driverFleet.id,
        personalDetails: driverFleet.person,
        driverDetails: driverFleet.driver,
        contactInfo: {
          email: driverFleet.user?.email || '',
          phoneNumber: driverFleet.user?.phoneNumber || '',
          allowedCommunicationChannels:
            driverFleet.user?.allowedCommunicationChannels || [],
        },
        userAccess: driverFleet.userAccess,
        worker: driverFleet.worker,
      }
    }

    return null
  }, [driverFleet])

  const onRightClickAvatar = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (driverFormData) {
        event.preventDefault()
        event.stopPropagation()
        onOpenWorkerFormData(driverFormData)
      }
    },
    [driverFormData, onOpenWorkerFormData],
  )
  const toolTipMessage= useMemo<string>(() => {
    const missingString = isAssignable ? 'Assign a Driver' : 'No Driver'
    return driverFleet?.id ? fullName : missingString
  }, [driverFleet?.id, fullName, isAssignable])

  return {
    fullName,
    toolTipMessage,
    onRightClickAvatar,
    infoProps,
  }
}

export default useDriverAvatar

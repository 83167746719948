import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import { produce } from 'immer'
import { ITestResult } from '~/types/models/ITestResult'

export const useModifyTestResults = (
  queryKey: (string | number | undefined)[],
) => {
  const queryClient = useQueryClient()

  const addTestResult = useCallback(
    (data: ITestResult) => {
      queryClient.setQueryData<ITestResult[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [...oldData, data]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateTestResult = useCallback(
    (id: number, data: ITestResult) => {
      queryClient.setQueryData<ITestResult[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeTestResult = useCallback(
    (id: number) => {
      queryClient.setQueryData<ITestResult[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const renewAllTestResults = useCallback(
    (data: ITestResult[]) => {
      queryClient.setQueryData<ITestResult[] | undefined>(
        queryKey,
        _oldData => {
          return data
        },
      )
    },
    [queryClient, queryKey],
  )

  return {
    addTestResult,
    updateTestResult,
    removeTestResult,
    renewAllTestResults,
  }
}

import { DragEvent, PropsWithChildren, useMemo } from 'react'
import type {
  RTColumnVirtualizer,
  RTHeader,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'
import { getCommonMRTCellStyles } from '../../../utils/style.utils'
import { parseFromValuesOrFunc } from '../../../utils/utils'

import './styles.scss'
import clsx from 'clsx'
import { RTTableHeadCellSortLabel } from '../RTTableHeadCellSortLabel'
import { RTTableHeadCellGrabHandle } from '../RTTableHeadCellGrabHandle'
import { RTTableHeadCellGroupHandle } from '../RTTableHeadCellGroupHandle'

export interface IRTTableHeadCellProps<TData extends IReusableTableRowData>
  extends PropsWithChildren {
  columnVirtualizer?: RTColumnVirtualizer
  header: RTHeader<TData>
  staticColumnIndex?: number
  table: ReusableTableInstance<TData>
}

const RTTableHeadCell = <TData extends IReusableTableRowData>({
  columnVirtualizer,
  header,
  staticColumnIndex,
  table,
  ...rest
}: IRTTableHeadCellProps<TData>) => {
  const {
    getState,
    options: {
      columnResizeDirection,
      columnResizeMode,
      // enableColumnActions,
      enableColumnDragging,
      enableColumnOrdering,
      enableColumnPinning,
      enableGrouping,
      enableMultiSort,
      layoutMode,
    },
    refs: { tableHeadCellRefs },
    setHoveredColumn,
  } = table
  const { columnSizingInfo, draggingColumn, hoveredColumn } = getState()
  const { column } = header
  const { columnDef } = column
  const { columnDefType } = columnDef

  const isColumnPinned =
    enableColumnPinning &&
    columnDef.columnDefType !== 'group' &&
    column.getIsPinned()

  // const showColumnActions =
  //   (enableColumnActions || columnDef.enableColumnActions) &&
  //   columnDef.enableColumnActions !== false

  const showDragHandle =
    enableColumnDragging !== false &&
    columnDef.enableColumnDragging !== false &&
    (enableColumnDragging ||
      (enableColumnOrdering && columnDef.enableColumnOrdering !== false))

  const showGroupHandle = enableGrouping || columnDef.enableGrouping

  // const headerPL = useMemo(() => {
  //   let pl = 0
  //   if (column.getCanSort()) pl += 1
  //   if (showColumnActions) pl += 1.75
  //   if (showDragHandle) pl += 1.5
  //   return pl
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showColumnActions, showDragHandle])

  const draggingBorders = useMemo(() => {
    const showResizeBorder =
      columnSizingInfo.isResizingColumn === column.id &&
      columnResizeMode === 'onChange' &&
      !header.subHeaders.length

    const borderStyle = showResizeBorder
      ? `2px solid ${'yellow'} !important`
      : draggingColumn?.id === column.id
      ? '1px dashed #e5e5e5'
      : hoveredColumn?.id === column.id
      ? '2px dashed var(--ion-color-concord)'
      : undefined

    if (showResizeBorder) {
      return columnResizeDirection === 'ltr'
        ? { borderRight: borderStyle }
        : { borderLeft: borderStyle }
    }
    const draggingBorders = borderStyle
      ? {
          borderLeft: borderStyle,
          borderRight: borderStyle,
          borderTop: borderStyle,
        }
      : undefined

    return draggingBorders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggingColumn, hoveredColumn, columnSizingInfo.isResizingColumn])

  const handleDragEnter = (_e: DragEvent) => {
    if (enableGrouping && hoveredColumn?.id === 'drop-zone') {
      setHoveredColumn(null)
    }
    if (enableColumnOrdering && draggingColumn && columnDefType !== 'group') {
      setHoveredColumn(columnDef.enableColumnOrdering !== false ? column : null)
    }
  }

  const handleDragOver = (e: DragEvent) => {
    if (columnDef.enableColumnOrdering !== false) {
      e.preventDefault()
    }
  }

  const HeaderElement =
    parseFromValuesOrFunc(columnDef.Header, {
      column,
      header,
      table,
    }) ?? columnDef.header

  const getNode: any = (node: any) => {
    if (node) {
      tableHeadCellRefs.current[column.id] = node
      if (columnDefType !== 'group') {
        columnVirtualizer?.measureElement?.(node)
      }
    }
  }

  const isPinned = column?.getIsPinned()

  const justifyContentValue = {
    center: 'center',
    left: 'flex-start',
    right: 'flex-end',
  }

  return (
    <th
      className={clsx('RTTableHeadCell__container', columnDef.align, {
        isPinned,
      })}
      colSpan={header.colSpan}
      data-index={staticColumnIndex}
      data-pinned={!!isColumnPinned || undefined}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      ref={getNode}
      {...(rest as any)}
      style={{
        flexDirection: layoutMode?.startsWith('grid') ? 'column' : undefined,
        fontWeight: 'bold',
        overflow: 'visible',
        userSelect: enableMultiSort && column.getCanSort() ? 'none' : undefined,
        verticalAlign: 'top',
        boxShadow: column
          ? isPinned === 'left' && column.getIsLastColumn(isPinned)
            ? '-4px 0 4px -4px #e5e5e5 inset'
            : isPinned === 'right' && column.getIsFirstColumn(isPinned)
            ? '4px 0 4px -4px #e5e5e5 inset'
            : undefined
          : undefined,
        ...(getCommonMRTCellStyles({
          column,
          header,
          table,
          tableCellProps: rest,
        }) as any),
        // align: columnDefType === 'group' ? 'center' : 'left',
        ...draggingBorders,
      }}
    >
      {header.isPlaceholder
        ? null
        : rest.children ?? (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: columnDef.align
                  ? justifyContentValue[columnDef.align]
                  : 'flex-start',
                position: 'relative',
                width: '100%',
              }}
            >
              <div
                // className="Mui-TableHeadCell-Content-Labels"
                onClick={column.getToggleSortingHandler()}
                style={{
                  alignItems: 'center',
                  cursor:
                    column.getCanSort() && columnDefType !== 'group'
                      ? 'pointer'
                      : undefined,
                  display: 'flex',
                  flexDirection: 'row',
                  overflow: columnDefType === 'data' ? 'hidden' : undefined,
                  // paddingLeft:
                  //   columnDef.align === 'center' ? `${headerPL}rem` : undefined,
                }}
              >
                <div
                  style={{
                    // "&:hover": {
                    //   textOverflow: "clip",
                    // },
                    minWidth: `${Math.min(columnDef.header?.length ?? 0, 4)}ch`,
                    overflow: columnDefType === 'data' ? 'hidden' : undefined,
                    textOverflow: 'ellipsis',
                    whiteSpace:
                      (columnDef.header?.length ?? 0) < 20
                        ? 'nowrap'
                        : 'normal',
                  }}
                >
                  {HeaderElement}
                </div>
                {/* {column.getCanFilter() && (
                  <RTTableHeadCellFilterLabel header={header} table={table} />
                )} */}
                {column.getCanSort() && (
                  <RTTableHeadCellSortLabel header={header} table={table} />
                )}
              </div>
              {columnDefType !== 'group' && (
                <div
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {showDragHandle && (
                    <RTTableHeadCellGrabHandle
                      column={column}
                      table={table}
                      tableHeadCellRef={{
                        current: tableHeadCellRefs.current[column.id],
                      }}
                    />
                  )}

                  {showGroupHandle && (
                    <RTTableHeadCellGroupHandle
                      column={column}
                      table={table}
                      tableHeadCellRef={{
                        current: tableHeadCellRefs.current[column.id],
                      }}
                    />
                  )}
                </div>
              )}
              {/* {column.getCanResize() && (
                <RTTableHeadCellResizeHandle header={header} table={table} />
              )} */}
            </div>
          )}
      {/* {columnFilterDisplayMode === "subheader" && column.getCanFilter() && (
        <RTTableHeadCellFilterContainer header={header} table={table} />
      )} */}
    </th>
  )
}

export default RTTableHeadCell

import { Form } from 'react-bootstrap'
import { DateRangeDropdownPicker, ConcordFormLayout } from '~/components/shared'

import clsx from 'clsx'

import './ConcordFormDateRangePicker.scss'

function ConcordFormDateRangePicker(props) {
  const {
    label,
    className,
    onChange,
    date,
    error,
    isRequired,
    isDisabled,
    isReadOnly
  } = props

  return (
    <ConcordFormLayout
      label={label}
      className={clsx('ConcordFormDateRangePicker__root', className)}
      error={error}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    >
      <DateRangeDropdownPicker date={date} onChange={onChange} />
      {error && <Form.Text>{error}</Form.Text>}
    </ConcordFormLayout>
  )
}

ConcordFormDateRangePicker.propTypes = {}

export default ConcordFormDateRangePicker

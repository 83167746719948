/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useDrag } from 'react-dnd'
import { useMeasure } from 'react-use'

import {
  ToolTipOverlay,
  Button,
  CompanyAvatar,
  TruckNumberSection,
  DropdownWithCustomChildren,
  IConcordFormOnChangeParams,
  IConcordFormDropdownOption,
} from '~/components/shared'
import {
  IonBadge,
  IonIcon,
  IonButtons,
  IonSpinner,
  IonChip,
  IonText,
} from '@ionic/react'
import ClickToDropDownSelect from '~/containers/OrderTracking/StatusCard/ClickToDropDownSelect'

import moment from 'moment'
import buildFullName from '~/utils/buildFullName'
import { addOutline } from 'ionicons/icons'
import {
  selectMyTerminalOptions,
  selectTruckFleets,
  selectDriverFleets,
  selectAllTerminals,
  selectCompanies,
} from '~/redux/selectors'
import { getLoadSectionColor, getLoadSectionIcon } from '~/utils/loadUtils'
import { EScope } from '~/types/enums/ECommonEnum'
import { updateTruckFleet } from '~/redux/actions/truckFleetsActions'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { DRIVER_TYPES } from '~/containers/OrderTracking/Utils/driverTypes'
import { apiClient } from '~/api/ApiClient'
import { driverFleetsSlice } from '~/redux/reducers/data/driverFleets'
import getDateRangeToday from '~/utils/getDateRangeToday'

import type {
  IDriverCardOnChangeParams,
  IDriverCardProps,
  IDriverDragItem,
} from './type'
import type { IDriverFleet } from '~/types/models/IDriverFleet'
import type { ITruckFleet } from '~/types/models/ITruckFleet'
import type { ITerminal } from '~/types/models/ITerminal'
import type { ICommonOption } from '~/types/models/ICommonModel'
import type { IDriver } from '~/types/models/IDriver'
import type { ICompany } from '~/types/models/ICompany'
import {
  useQueryBuyerSellers,
  useQueryOffdays,
  useQueryTerminals,
  useQueryTruckFleets,
  useQueryUsers,
} from '~/hooks/useQueryData'

const useDriverCard = (props: IDriverCardProps) => {
  const {
    driverFleetId,
    dragType = DRIVER_TYPES.UNASSIGNED,
    onDrag,
    onClose,
    isShowingDriverName,
    isDraggable = true,
    isCloseable = false,
    className,
    isHiddenStatusTime,
    onChangeNotes,
    isHiddenNotesButton = true,
    notes,
    isDriverLeft,
    index,
    indexBadgeStyle,
    extraIndexTooltipContent,
  } = props

  const [isOpenTruckDialog, setIsOpenTruckDialog] = useState(false)
  const [isUpdatingTerminal, setIsUpdatingTerminal] = useState(false)
  const [createTerminalDialogState, setCreateTerminalDialogState] = useState({
    isOpen: false,
    terminal: undefined as ITerminal | undefined,
  })
  const [isUpdatingWorkerTerminal, setIsUpdatingWorkerTerminal] =
    useState(false)

  const dispatch = useDispatch()

  const { driverFleetUsers } = useQueryUsers()
  const { truckFleetsData } = useQueryTruckFleets()
  const { terminalOptions, terminalsData } = useQueryTerminals()
  const { allCompaniesWithCurrentCompany } = useQueryBuyerSellers()
  const { offdaysData } = useQueryOffdays({
    filters: {
      startDate: {
        startDate: getDateRangeToday().startTime,
        endDate: getDateRangeToday().endTime,
      },
    },
  })

  const [measureRef, { width }] = useMeasure()

  const smallWidth = useMemo(() => width <= 80, [width])

  const user = useMemo(
    () =>
      driverFleetUsers.find(
        ({ driverFleet }) => driverFleet?.id === driverFleetId,
      ),
    [driverFleetId, driverFleetUsers],
  )

  const driverFleet = useMemo(() => user?.driverFleet, [user?.driverFleet])

  const offday = useMemo(() => {
    return offdaysData.find(({ workerId }) => workerId === user?.worker?.id)
  }, [offdaysData, user?.worker?.id])

  const hasOffday = useMemo(() => Boolean(offday), [offday])

  const loadId = useMemo(() => driverFleet?.loadId, [driverFleet?.loadId])

  const truckFleetId = useMemo(
    () => driverFleet?.currentTruckFleetId,
    [driverFleet?.currentTruckFleetId],
  )

  const truckFleet = useMemo<ITruckFleet | undefined>(
    () => truckFleetsData.find(({ id }) => id === truckFleetId),
    [truckFleetId, truckFleetsData],
  )

  const truckFleetTerminal = useMemo(() => {
    if (truckFleet?.terminalId) {
      return terminalsData.find(({ id }) => id === truckFleet.terminalId)
    }
    return null
  }, [terminalsData, truckFleet?.terminalId])

  const workerTerminal = useMemo(() => {
    if (user?.worker?.terminalId) {
      return terminalsData.find(({ id }) => id === user?.worker?.terminalId)
    }

    return null
  }, [terminalsData, user?.worker?.terminalId])

  const workerTerminalCompany = useMemo(() => {
    if (workerTerminal) {
      return allCompaniesWithCurrentCompany.find(
        ({ id }) => id === workerTerminal.companyId,
      )
    }

    return null
  }, [allCompaniesWithCurrentCompany, workerTerminal])

  const [, dragRef] = useDrag<IDriverDragItem>(
    () => ({
      type: dragType as string,
      item: {
        driverFleet: driverFleet as IDriverFleet,
        truckFleet,
      },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult()
        const dragItem: IDriverCardOnChangeParams = {
          dropResult,
          item,
        }
        onDrag && onDrag(dragItem)
      },
      collect: monitor => ({ isDragging: monitor.isDragging() }),
    }),
    [],
  )

  const fullName = useMemo(() => buildFullName(user?.person), [user?.person])

  const renderLoadStatus = useCallback(() => {
    if (driverFleet && loadId) {
      let toolTipText = `${driverFleet.loadStatus}`

      if (driverFleet.lastStatusTime) {
        toolTipText += ` @ ${moment(driverFleet.lastStatusTime).format('H:mm')}`
      }

      const formattedMinutes =
        (new Date().getTime() -
          new Date(driverFleet.lastStatusTime).getTime()) /
        1000 /
        60

      const badgeColor = getLoadSectionColor(driverFleet.loadStatus)
      const icon = getLoadSectionIcon(driverFleet.loadStatus)

      return (
        <ToolTipOverlay content={`${toolTipText}`} placement='top'>
          <h4 className='align-self-center mb-0 mx-2 statusBadge'>
            <IonBadge
              className='d-flex align-items-center shimmer-div'
              color={badgeColor}
              // key={`${driverFleet?.id}--drivers-last-update-badge--${driverFleet?.loadStatus}`}
            >
              <IonIcon icon={icon} />
              &nbsp;{parseInt(formattedMinutes.toString())}
            </IonBadge>
          </h4>
        </ToolTipOverlay>
      )
    }

    return null
  }, [driverFleet, loadId])

  const onChangeTerminal = useCallback(
    async (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      event: any,
      { value }: IConcordFormOnChangeParams<IConcordFormDropdownOption>,
    ) => {
      setIsUpdatingWorkerTerminal(true)
      try {
        const response = await apiClient.workers.update(
          driverFleet?.worker.id as number,
          {
            terminalId: value as number,
          },
        )
        dispatch(
          driverFleetsSlice.actions.update({
            ...driverFleet,
            worker: response,
          }),
        )
        toast.success(toastMessages.updateSuccess)
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      } finally {
        setIsUpdatingWorkerTerminal(false)
      }
    },
    [dispatch, driverFleet],
  )

  const onSaveNotes = useCallback(
    (notes: string) => {
      if (driverFleet) {
        onChangeNotes && onChangeNotes(driverFleet, notes)
      }
    },
    [driverFleet, onChangeNotes],
  )

  const renderWorkerTerminal = useMemo(() => {
    if (workerTerminal?.id) {
      return (
        <ToolTipOverlay
          content={`${workerTerminal?.code} - ${workerTerminal?.name}`}
          placement='top'
          allowToShow={Boolean(workerTerminal)}
        >
          <IonChip className='terminalAvatarContainer'>
            <div className='terminalAvatar'>
              <CompanyAvatar
                width={22}
                height={22}
                company={{
                  value: workerTerminal?.id as number,
                  label: workerTerminalCompany?.name as string,
                  image: workerTerminalCompany?.logo,
                }}
                companyType={EScope.fleet}
              />
            </div>
            <IonText>{workerTerminal?.code}</IonText>
          </IonChip>
        </ToolTipOverlay>
      )
    }

    return (
      <IonButtons
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginTop: 4,
        }}
      >
        <DropdownWithCustomChildren
          noHover
          options={terminalOptions}
          onChange={onChangeTerminal}
        >
          <Button
            icon={addOutline}
            size='small'
            tooltipProps={{
              content: "Add worker's terminal",
              placement: 'top',
            }}
            loading={isUpdatingWorkerTerminal}
          />
        </DropdownWithCustomChildren>
      </IonButtons>
    )
  }, [
    isUpdatingWorkerTerminal,
    onChangeTerminal,
    terminalOptions,
    workerTerminal,
    workerTerminalCompany?.logo,
    workerTerminalCompany?.name,
  ])

  const clickTerminal = useCallback((event: Event) => {
    event.stopPropagation()
  }, [])

  const onOpenTruckDialog = useCallback(
    (
      event:
        | React.MouseEvent<HTMLHeadingElement, MouseEvent>
        | React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
    ) => {
      event.stopPropagation()
      setIsOpenTruckDialog(true)
    },
    [],
  )

  const onCloseTruckDialog = useCallback(() => {
    setIsOpenTruckDialog(false)
  }, [])

  const onClickCloseIcon = useCallback(() => {
    onClose &&
      onClose({
        driver: driverFleet?.driver as IDriver,
        driverFleet: driverFleet as IDriverFleet,
        truckFleet,
      })
  }, [driverFleet, onClose, truckFleet])

  const onChangeTruckFleetTerminal = useCallback(
    async (terminalId: number) => {
      setIsUpdatingTerminal(true)
      try {
        const res = await apiClient.truckFleets.update(
          truckFleet?.id as number,
          {
            truckFleet: {
              terminalId,
            },
          },
        )
        dispatch(updateTruckFleet(res))
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateSuccess)
      } finally {
        setIsUpdatingTerminal(false)
      }
    },
    [dispatch, truckFleet?.id],
  )

  const onRightClickTerminal = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
      event.preventDefault()
      setCreateTerminalDialogState({
        isOpen: true,
        terminal: truckFleetTerminal as ITerminal,
      })
    },
    [truckFleetTerminal],
  )

  const onCloseTerminalDialog = useCallback(() => {
    setCreateTerminalDialogState({
      isOpen: false,
      terminal: undefined,
    })
  }, [])

  const renderTruck = useCallback(() => {
    if (truckFleet) {
      return (
        <>
          {isUpdatingTerminal && <IonSpinner name='lines-sharp-small' />}

          {!isUpdatingTerminal && (
            <ClickToDropDownSelect
              onSelect={onChangeTruckFleetTerminal}
              options={terminalOptions}
              value={truckFleetTerminal?.id}
              onClose={clickTerminal}
              onOpen={clickTerminal}
              className='d-flex terminalSection'
            >
              <CompanyAvatar
                company={{
                  name: truckFleetTerminal?.name || '-',
                  value: truckFleetTerminal?.id as number,
                  label: truckFleetTerminal?.name || '-',
                }}
                companyType={EScope.fleet}
                height={18}
                width={18}
                // onContextMenu={e => rightClick(e, truckFleetTerminal)}
                onContextMenu={onRightClickTerminal}
                tooltipMessage={
                  truckFleetTerminal?.id
                    ? 'Next pickup terminal: ' + truckFleetTerminal?.name
                    : 'Set next pickup terminal'
                }
              />
            </ClickToDropDownSelect>
          )}

          <h2
            className='align-self-center ms-2 mb-0 d-flex truckNumber'
            onClick={onOpenTruckDialog}
          >
            <TruckNumberSection truckId={truckFleet.truckId} />
          </h2>
        </>
      )
    }

    return (
      <IonButtons
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginTop: 4,
        }}
      >
        <ToolTipOverlay content='Set truck' placement='top'>
          <Button
            icon={addOutline}
            size='small'
            color='snowgrey'
            fill='solid'
            onClick={onOpenTruckDialog}
          />
        </ToolTipOverlay>
      </IonButtons>
    )
  }, [
    clickTerminal,
    isUpdatingTerminal,
    onChangeTruckFleetTerminal,
    onOpenTruckDialog,
    onRightClickTerminal,
    terminalOptions,
    truckFleet,
    truckFleetTerminal?.id,
    truckFleetTerminal?.name,
  ])

  return {
    renderLoadStatus,
    renderTruck,
    onCloseTruckDialog,
    setIsOpenTruckDialog,
    isOpenTruckDialog,
    driverFleet,
    createTerminalDialogState,
    onCloseTerminalDialog,
    dragRef,
    fullName,
    onClickCloseIcon,
    driverFleetId,
    isShowingDriverName,
    isDraggable,
    isCloseable,
    workerTerminal,
    workerTerminalCompany,
    className,
    isHiddenStatusTime,
    measureRef,
    width,
    smallWidth,
    renderWorkerTerminal,
    onSaveNotes,
    isHiddenNotesButton,
    notes,
    isDriverLeft,
    hasOffday,
    offday,
    index,
    indexBadgeStyle,
    extraIndexTooltipContent,
  }
}

export default useDriverCard

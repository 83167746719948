import { IonIcon } from '@ionic/react'
import Status from '~/components/routes/Status'

import { warningOutline } from 'ionicons/icons'

import './NotFoundPage.scss'
import { Unless } from 'react-if'
import { Button } from '~/components'

const NotFoundPage = props => {
  const { message, onGoBack, buttonText, isHiddenIcon, isLoading } = props
  return (
    <Status code={404}>
      <div className='NotFoundPage__container'>
        <div className='NotFoundPage__bodyContainer'>
          <Unless condition={isHiddenIcon}>
            <IonIcon icon={warningOutline} className='NotFoundPage__icon' />
          </Unless>
          <div>{message}</div>
          {onGoBack && (
            <Button onClick={onGoBack} loading={isLoading}>
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </Status>
  )
}

// NotFoundPage.propTypes = {
//   message: PropTypes.string,
//   onGoBack: PropTypes.func,
//   buttonText: PropTypes.string,
// }

NotFoundPage.defaultProps = {
  message: 'Page not found!',
  buttonText: 'Go back',
}

export default NotFoundPage

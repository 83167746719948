import React, { useMemo, useCallback, useEffect } from 'react'
import { FormProvider, useForm, UseFormProps } from 'react-hook-form'
import useFuzzy from '~/hooks/useFuzzy'
import { useDeepCompareEffect } from 'react-use'

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { IonRow, IonGrid, IonCol } from '@ionic/react'
import {
  Button,
  ConcordTextFieldWithFormControl,
  ConcordNumberFieldWithFormControl,
  ContainerSearchBar,
  ConcordDatePickerWithFormControl,
  ConcordRadioWithFormControl,
  ConcordCheckboxWithFormControl,
  ConcordFormGroupStructure,
  ConcordMultipleDropdownSelectorFormControl,
  ConcordDropdownV2WithFormControl,
  ConcordFormTagsInputWithFormControl,
  ConcordDateRangePickerWithFormControl,
  PasswordFieldWithControl,
} from '~/components/shared'

import clsx from 'clsx'
import { EFieldType } from '~/types/enums/ECommonEnum'

import type { IConcordFormField, IConcordFormStructureProps } from './type'

import './styles.scss'
import { Alert } from 'react-bootstrap'

const ConcordFormStructure = <
  Schema extends Yup.AnyObjectSchema,
  FormData extends {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any
  },
>(
  props: IConcordFormStructureProps<Schema, FormData>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
) => {
  const {
    fields,
    cancelText = 'Cancel',
    submitText = 'Submit',
    defaultValues,
    onCancel,
    schema,
    onSubmit,
    formData = {},
    isHiddenSubmitButton,
    isHiddenCancelButton,
    isLoading,
    onChange,
    submitButtonProps,
    cancelButtonProps,
    className,
    isHiddenSearch = true,
    error,
    buttons = [],
    isHideOptionalFields = false,
    hiddenFields = [],
    style,
    groupsShownByDefault,
  } = props

  const combinedFields = useMemo(() => {
    const newFields: IConcordFormField[] = []
    fields.forEach(field => {
      if (Array.isArray(field.fields)) {
        field.fields.forEach(childrenField => {
          newFields.push(childrenField)
        })
      } else {
        newFields.push(field)
      }
    })

    return newFields
  }, [fields])

  const {
    seachedList: searchedFields,
    searchValue,
    onSearch,
  } = useFuzzy(combinedFields, {
    keys: ['label'],
  })

  const labelOfSearchedFields = useMemo(
    () => searchedFields.map(({ label }) => label),
    [searchedFields],
  )

  const formParams = useMemo(() => {
    const params: UseFormProps = {
      defaultValues,
    }

    if (Yup.isSchema(schema)) {
      params.resolver = yupResolver(schema)
    }

    return params
  }, [defaultValues, schema])

  const formMethods = useForm({
    ...formParams,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = formMethods

  const watchFormValues = watch()

  const getFieldType = useCallback((type: EFieldType | undefined) => {
    switch (type) {
      case EFieldType.text: {
        return ConcordTextFieldWithFormControl
      }
      case EFieldType.number: {
        return ConcordNumberFieldWithFormControl
      }
      case EFieldType.date: {
        return ConcordDatePickerWithFormControl
      }
      case EFieldType.dateRange: {
        return ConcordDateRangePickerWithFormControl
      }
      case EFieldType.checkbox: {
        return ConcordCheckboxWithFormControl
      }
      case EFieldType.radio: {
        return ConcordRadioWithFormControl
      }

      case EFieldType.multipleSelect: {
        return ConcordMultipleDropdownSelectorFormControl
      }

      case EFieldType.singleSelect: {
        return ConcordDropdownV2WithFormControl
      }

      case EFieldType.tags: {
        return ConcordFormTagsInputWithFormControl
      }

      case EFieldType.password: {
        return PasswordFieldWithControl
      }

      default: {
        return ConcordTextFieldWithFormControl
      }
    }
  }, [])

  const handleSubmitForm = handleSubmit((formValues, event) => {
    event?.preventDefault()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit && onSubmit(formValues as any)
  })

  const renderFields = useCallback(
    (formFields: IConcordFormField[]) =>
      formFields.map(field => {
        const {
          size = 12,
          name,
          label,
          isRequired,
          type,
          fields,
          isDisabled,
          isHidden,
          autoComplete,
          removable,
          className,
          placeholder,
          isClearable,
          menuIsOpen,
          isHiddenCreateIcon,
          render,
          onClickCreateIcon,
          as,
          rows,
          isLoading,
          isReadOnly,
          onChange,
          styles,
          hint,
          dateFormat,
          getOptionLabel,
          isPhoneNumber,
          onGetTokenDisplayLabel,
          onBlur,
          containerClassName,
        } = field
        const error = errors[name]?.message as string

        const params = {
          ...field,
          errors,
          control,
          watch,
          error,
          setValue,
          handleSubmitForm,
          setError,
          clearErrors,
          getValues,
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let componentStyles: any
        if (typeof styles === 'function') {
          componentStyles = styles(params)
        } else if (typeof styles === 'object') {
          componentStyles = styles
        }

        const extraIcons =
          typeof field.extraIcons === 'function'
            ? field.extraIcons(params)
            : field.extraIcons

        const options =
          typeof field.options === 'function'
            ? field.options(params)
            : field.options

        const minDate =
          typeof field.minDate === 'function'
            ? field.minDate(params)
            : field.minDate

        const maxDate =
          typeof field.maxDate === 'function'
            ? field.maxDate(params)
            : field.maxDate

        let hintText: string | undefined = ''
        if (typeof hint === 'function') {
          hintText = hint(params)
        } else if (typeof hint === 'string') {
          hintText = hint
        }

        let isDisabledValue: boolean | undefined = false
        if (typeof isDisabled === 'function') {
          isDisabledValue = isDisabled(params)
        } else if (typeof isDisabled === 'boolean') {
          isDisabledValue = isDisabled
        }

        let isHiddenComponent: boolean | undefined = false
        if (typeof isHidden === 'function') {
          isHiddenComponent = isHidden(params)
        } else {
          isHiddenComponent = isHidden
        }

        if (isHiddenComponent || hiddenFields.includes(name)) {
          return null
        }

        if (Array.isArray(fields)) {
          const showingFields = fields.filter(({ label }) =>
            labelOfSearchedFields.includes(label),
          )

          return (
            <ConcordFormGroupStructure
              key={name}
              label={label}
              fields={fields}
              formValues={watchFormValues}
              errors={errors}
              showByDefault={groupsShownByDefault?.includes(name)}
            >
              {renderFields(showingFields)}
            </ConcordFormGroupStructure>
          )
        }

        if (labelOfSearchedFields.includes(label)) {
          const Component = getFieldType(type)

          if (typeof render === 'function') {
            return (
              <IonCol
                key={field.name}
                size={size as string}
                className={clsx(containerClassName, {
                  disabled: isDisabledValue,
                })}
              >
                {render({
                  ...field,
                  errors,
                  control,
                  watch,
                  error,
                  setValue,
                  handleSubmitForm,
                  setError,
                  clearErrors,
                  getValues,
                })}
              </IonCol>
            )
          }

          if (isHideOptionalFields && !isRequired) {
            return null
          }

          return (
            <IonCol
              key={field.name}
              size={size as string}
              className={containerClassName}
            >
              <Component
                name={name}
                label={label}
                isRequired={isRequired}
                control={control}
                type={type}
                options={options}
                autoComplete={autoComplete}
                removable={removable}
                className={className}
                maxDate={maxDate}
                minDate={minDate}
                placeholder={placeholder}
                isClearable={isClearable}
                menuIsOpen={menuIsOpen}
                isHiddenCreateIcon={isHiddenCreateIcon}
                onClickCreateIcon={onClickCreateIcon}
                as={as}
                rows={rows}
                isLoading={isLoading}
                onChange={onChange}
                styles={componentStyles}
                extraIcons={extraIcons}
                hint={hintText}
                dateFormat={dateFormat}
                getOptionLabel={getOptionLabel}
                isDisabled={isDisabledValue}
                isReadOnly={isReadOnly}
                isPhoneNumber={isPhoneNumber}
                onGetTokenDisplayLabel={onGetTokenDisplayLabel}
                onBlur={onBlur}
              />
            </IonCol>
          )
        }

        return null
      }),
    [
      errors,
      control,
      watch,
      setValue,
      handleSubmitForm,
      setError,
      clearErrors,
      getValues,
      hiddenFields,
      labelOfSearchedFields,
      watchFormValues,
      getFieldType,
      isHideOptionalFields,
      groupsShownByDefault,
    ],
  )

  const handleCancel = useCallback(
    (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
      const formValues = getValues()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onCancel && onCancel(event, { formValues } as any)
    },
    [getValues, onCancel],
  )

  const renderButtons = useMemo(
    () =>
      buttons
        .filter(({ isHidden }) => !isHidden)
        .map((buttonProps, index) => (
          <Button expand='full' {...buttonProps} key={index} />
        )),
    [buttons],
  )

  useDeepCompareEffect(() => {
    const fields = Object.keys(formData || {})
    if (fields.length > 0) {
      fields.forEach(field => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setValue(field, (formData as any)[field] as any)
      })
    }
  }, [formData])

  useEffect(() => {
    const subscription = watch(value => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange && onChange(value as any)
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch])

  useEffect(() => {
    if (typeof ref === 'function') {
      ref({
        getValues,
        setValue,
        watch,
        reset,
        handleSubmit,
      })
    } else {
      if (ref !== null) {
        ref.current = {
          ...ref.current,
          getValues,
          setValue,
          watch,
          reset,
          handleSubmit,
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, handleSubmit])

  return (
    <FormProvider {...formMethods}>
      <IonGrid className={className} style={style}>
        {error && (
          <Alert variant='danger' style={{ fontSize: 14 }}>
            {error}
          </Alert>
        )}

        {isHiddenSearch !== true && (
          <IonRow>
            <ContainerSearchBar
              searchBarValue={searchValue}
              onSearchBarChange={onSearch}
            />
          </IonRow>
        )}
        <IonRow>{renderFields(fields)}</IonRow>
        <IonRow>
          <div className='ConcordFormStructure__buttonContainer'>
            {isHiddenSubmitButton !== true && (
              <Button
                label={submitText}
                expand='full'
                color='concord'
                // type='submit'
                onClick={handleSubmitForm}
                loading={isLoading}
                {...submitButtonProps}
              />
            )}
            {renderButtons}
            {isHiddenCancelButton !== true && (
              <Button
                label={cancelText}
                expand='full'
                color='medium'
                onClick={handleCancel}
                loading={isLoading}
                {...cancelButtonProps}
              />
            )}
          </div>
        </IonRow>
      </IonGrid>
    </FormProvider>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default React.forwardRef<any, IConcordFormStructureProps<any, any>>(
  ConcordFormStructure,
)

import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'
import {
  IGetPaymentTermsParams,
  IPaymentTerm,
} from '~/types/models/IPaymentTerm'

const useModifyPaymentTerms = (
  params: Partial<IGetPaymentTermsParams> = {},
) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'paymentTerms',
      sessionUser?.id,
      buildGetUrl(apiClient.paymentTerms.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addPaymentTerm = useCallback(
    (data: IPaymentTerm) => {
      queryClient.setQueryData<IPaymentTerm[] | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [...oldData, data]
          }
          return [data]
        },
      )
    },
    [queryClient, queryKey],
  )

  const updatePaymentTerm = useCallback(
    (id: number, data: IPaymentTerm) => {
      queryClient.setQueryData<IPaymentTerm[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removePaymentTerm = useCallback(
    (id: number) => {
      queryClient.setQueryData<IPaymentTerm[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addPaymentTerm,
    updatePaymentTerm,
    removePaymentTerm,
  }
}

export default useModifyPaymentTerms

import useDriverAvatar from './useDriverAvatar'

import { CompanyInfo } from '~/components/shared'

import { EScope } from '~/types/enums/ECommonEnum'

import type { IDriverAvatarProps } from './type'

function DriverAvatar(props: IDriverAvatarProps) {
  const { fullName, onRightClickAvatar, infoProps, toolTipMessage } =
    useDriverAvatar(props)

  return (
    <CompanyInfo
      company={{ label: fullName as string, name: fullName }}
      tooltipMessage={toolTipMessage}
      onContextMenu={onRightClickAvatar}
      companyType={EScope.driver}
      {...infoProps}
    />
  )
}

export default DriverAvatar

import React, { useEffect, useCallback, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { IonHeader, IonText, IonToolbar } from '@ionic/react'
import { Document, Page, pdfjs } from 'react-pdf'
import { PdfPageSelector, ZoomButton } from '~/components/shared'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

import clsx from 'clsx'

import './ViewPDF.scss'

function ViewPDF(props) {
  const { link, className, hideHeader, ...restProps } = props

  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [scale, setScale] = useState(1)

  const onLoadPdfSuccess = useCallback(({ numPages }) => {
    setTotalPages(numPages)
    setLoading(false)
  }, [])

  const onBackPress = useCallback(() => {
    setCurrentPage(prev => prev - 1)
  }, [])

  const onForwardPress = useCallback(() => {
    setCurrentPage(prev => prev + 1)
  }, [])

  const onZoomIn = useCallback(() => {
    setScale(prev => (prev += 0.1))
  }, [])

  const onZoomOut = useCallback(() => {
    setScale(prev => (prev -= 0.1))
  }, [])

  const onInputChange = useCallback((event, data) => {
    setCurrentPage(data.currentPage)
  }, [])

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
  }, [])

  useUpdateEffect(() => {
    setCurrentPage(1)
  }, [link])

  return (
    <div className={clsx('ViewPDF__root', className)} {...restProps}>
      {hideHeader !== true && (
        <IonHeader translucent>
          <IonToolbar color='dark'>
            <div className='ViewPDF__header'>
              {loading && <IonText>Loading...</IonText>}
              {!loading && (
                <>
                  <PdfPageSelector
                    numPages={totalPages}
                    pageNumber={currentPage}
                    onBackPress={onBackPress}
                    onForwardPress={onForwardPress}
                    onInputChange={onInputChange}
                  />

                  <div className='ViewPDF__divider' />

                  <ZoomButton
                    onZoomIn={onZoomIn}
                    onZoomOut={onZoomOut}
                    value={scale.toFixed(1)}
                    zoomInButtonProps={{ disabled: scale > 2 }}
                    zoomOutButtonProps={{ disabled: scale < 0.3 }}
                  />
                </>
              )}
            </div>
          </IonToolbar>
        </IonHeader>
      )}
      <TransformWrapper
        wheel={{ disable: true, wheelDisabled: true }}
        doubleClick={{ disabled: true }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <TransformComponent>
            <Document file={link} onLoadSuccess={onLoadPdfSuccess}>
              <Page scale={scale} pageNumber={currentPage} />
            </Document>
          </TransformComponent>
        </div>
      </TransformWrapper>
    </div>
  )
}

// ViewPDF.propTypes = {}

export default ViewPDF

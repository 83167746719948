import React, { useCallback, useMemo, useRef } from 'react'

import { Form } from 'react-bootstrap'
import { ConcordFormLayout } from '~/components/shared'
import ReactSelect from 'react-select'

import PropTypes from 'prop-types'

import './ConcordFormMultipleDropdownSelector.scss'

function ConcordFormMultipleDropdownSelector(props) {
  const {
    options,
    onChange,
    label,
    error,
    value = [],
    name,
    className,
    removable = true,
    isRequired,
    isClearable = true,
    styles,
    isDisabled,
    extraIcons,
    ...selectProps
  } = props

  const inputRef = useRef()

  const mapValues = useMemo(() => {
    if (value.length) {
      if (typeof value[0] === 'object') {
        return value
      }
    }
    const values = []

    value.forEach(val => {
      const option = options.find(opt => opt.value === val)
      if (option) {
        values.push(option)
      }
    })

    return values
  }, [options, value])

  const handleSelectOption = useCallback(
    (selectedValues, event) => {
      const mapValue = selectedValues.map(({ value }) => value)
      onChange && onChange(event, { value: mapValue, name })
    },
    [name, onChange],
  )

  const isDisabledStyles = useMemo(() => {
    if (isDisabled) {
      return {
        opacity: 0.3,
        userSelect: 'none',
        pointerEvents: 'none',
      }
    }
    return {}
  }, [isDisabled])

  return (
    <ConcordFormLayout
      label={label}
      error={error}
      className={className}
      isRequired={isRequired}
      extraIcons={extraIcons}
    >
      <ReactSelect
        {...selectProps}
        options={options}
        classNamePrefix='ConcordFormMultipleDropdownSelector'
        ref={inputRef}
        name={name}
        value={mapValues}
        isMulti
        onChange={handleSelectOption}
        isClearable={isClearable}
        styles={{
          control: provided => ({
            ...provided,
            borderColor: error ? 'red !important' : provided.borderColor,
            ...isDisabledStyles,
          }),
          multiValueRemove: provided => {
            return {
              ...provided,
              display: removable ? 'flex' : 'none',
            }
          },
          clearIndicator: provided => ({
            ...provided,
            display: removable ? 'flex' : 'none',
          }),
          menu: provided => ({
            ...provided,
            zIndex: 1000,
          }),
          ...styles,
        }}
      />
      {error && <Form.Text>{error}</Form.Text>}
    </ConcordFormLayout>
  )
}

ConcordFormMultipleDropdownSelector.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.objectOf({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  removable: PropTypes.bool,
}

export default ConcordFormMultipleDropdownSelector

import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { ITruckFleet } from '~/types/models/ITruckFleet'

const useQueryTruckFleet = (
  truckFleetId: number,
  options?: Partial<UseQueryOptions<ITruckFleet>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['truckFleetId', sessionUser?.id, truckFleetId],
    queryFn() {
      return apiClient.truckFleets.getById(truckFleetId)
    },
    enabled: Boolean(sessionUser?.id && truckFleetId),
    staleTime: 60000, // one minute
    ...options,
  })

  return {
    truckFleetData: data,
    isLoadingTruckFleetData: isLoading,
  }
}

export default useQueryTruckFleet

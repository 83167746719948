import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { ICheckUsageOptionsGetResponse } from '~/types/models/ICheck'
import _ from 'lodash'

const useQueryCheckUsageOptions = (
  options?: Partial<UseQueryOptions<ICheckUsageOptionsGetResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['checkUsageOptions', sessionUser?.id],
    queryFn() {
      return apiClient.check.getUsageOptions()
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // one minute
    ...options,
  })

  const usageCategories = data?.categories || []

  const usageResourceTypes = data?.resourceTypes || []

  const usageCategoryOptions = usageCategories.map(field => ({
    value: field,
    label: _.startCase(field),
  }))

  const usageResourceTypeOptions = usageResourceTypes.map(field => ({
    value: field,
    label: _.startCase(field),
  }))

  return {
    usageCategories,
    usageResourceTypes,
    usageCategoryOptions,
    usageResourceTypeOptions,
    isCheckUsageOptionsLoading: isLoading,
  }
}

export default useQueryCheckUsageOptions

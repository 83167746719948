import * as Yup from 'yup'
import { REGEX_PHONE_NUMBERS } from '~/utils/constants'

export const personSchema = Yup.object({
  id: Yup.number().nullable(),
  firstName: Yup.string().required('First name is required!'),
  middleName: Yup.string().nullable().notRequired(),
  lastName: Yup.string().required('Last name is required!'),
  suffix: Yup.string().nullable(),
  initials: Yup.string().nullable().notRequired(),
  dob: Yup.string().nullable().notRequired(), //.required('Date of Birth is required!'),
})

export const driverSchema = Yup.object({
  id: Yup.number().nullable(),
  licenseNumber: Yup.number().nullable(),
  licenseState: Yup.string().nullable(),
  licenseExpiration: Yup.string().nullable(),
  medicalNumber: Yup.string().nullable(),
  medicalExpiration: Yup.string().nullable(),
  mvrNumber: Yup.number().nullable(),
})

export const driverFleetSchema = Yup.object({
  id: Yup.number().nullable().notRequired(),
  rank: Yup.number()
    .transform(value => (Number.isNaN(value) ? null : value))
    .nullable(),
  color: Yup.string().nullable(),
})

export const userSchema = Yup.object().shape(
  {
    id: Yup.number().nullable(),
    email: Yup.string().when('phoneNumber', {
      is: phoneNumber => !phoneNumber,
      then: schema => schema.email().required(),
      otherwise: schema => schema.email().nullable(),
    }),
    phoneNumber: Yup.string().when('email', {
      is: email => !email,
      then: schema =>
        schema
          .matches(REGEX_PHONE_NUMBERS, 'Phone number is invalid')
          .required(),
      otherwise: schema => schema.nullable(),
    }),
    allowedCommunicationChannels: Yup.array().nullable(),
  },

  ['email', 'phoneNumber'],
)

export const userAccessSchema = Yup.object({
  id: Yup.number().nullable(),
  userGroupId: Yup.number().required('User Group is required!'),
})

export const workerSchema = Yup.object({
  terminalId: Yup.number().nullable().required('Terminal is required!'),
  workerType: Yup.string().required('Worker Type is required!'),
  workPayTypeId: Yup.string().nullable(),
  taxType: Yup.string().nullable(),
  buisnessName: Yup.string().nullable(),
  ein: Yup.string().nullable(),
})

export const workerFormDataSchema = Yup.object({
  worker: workerSchema,
  person: personSchema,
  driver: driverSchema,
  driverFleet: driverFleetSchema,
  user: userSchema,
  userAccess: userAccessSchema,
})

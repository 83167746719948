import { useCallback, useMemo } from 'react'

import { ConcordFormLayout } from '~/components/shared'
import { Form } from 'react-bootstrap'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'

import './ConcordFormDatePicker.scss'

function ConcordFormDatePicker(props) {
  const {
    onChange,
    label,
    loading,
    className,
    error,
    id,
    date,
    name,
    isRequired,
    placeholder,
    isDisabled,
    minDate,
    maxDate,
    isReadOnly,
    ...datePickerProps
  } = props

  const formattedDate = useMemo(() => {
    if (typeof date === 'string' || date instanceof Date) {
      return moment(date).format('YYYY-MM-DD')
    }
    return ''
  }, [date])

  const handleChange = useCallback(
    event => {
      let newDate = event.target.value
      const newDateFormatted = moment(newDate).toISOString()
      onChange && onChange(newDateFormatted, event)
    },
    [onChange],
  )

  return (
    <ConcordFormLayout
      label={label}
      className={clsx(className, 'ConcordFormDatePicker__root', {
        disabled: loading,
        isReadOnly,
      })}
      error={error}
      loading={loading}
      isRequired={isRequired}
    >
      <Form.Control
        id={id}
        disabled={loading}
        onChange={handleChange}
        error={error}
        required={isRequired}
        className={clsx({ isDisabled })}
        type='date'
        placeholder={placeholder}
        value={formattedDate}
        min={minDate || ''}
        max={maxDate || ''}
        {...datePickerProps}
      />
      {error && <Form.Text>{error}</Form.Text>}
    </ConcordFormLayout>
  )
}

ConcordFormDatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
}

export default ConcordFormDatePicker

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useController } from 'react-hook-form'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { IonLabel } from '@ionic/react'

import { useQueryBuyerSellerProducts } from '~/hooks/useQueryData'
import { apiClient } from '~/api/ApiClient'
import { DialogSellerProductForm } from '../ConcordForm'
import { EditOption } from '../ReactSelect/components/EditOption'
import { CreateButton } from '../ReactSelect/components/CreateButton'

import clsx from 'clsx'
import '~/components/shared/FloatingForm/styles.scss'

const selectErrorStyle = {
  control: base => ({
    ...base,
    borderColor: 'red',
  }),
  placeholder: base => ({
    ...base,
    color: 'red',
  }),
}

const selectStyle = {
  control: base => ({
    ...base,
    color: 'black',
    backgroundColor: '#f7f7f7',
    borderWidth: '0px',
  }),
}

const selectWarningStyle = {
  control: base => ({
    ...base,
    borderColor: 'yellow',
  }),
}

export const ProductSelect = props => {
  const {
    buyerId,
    sellerId,
    isDisabled,
    // orderType, //memoize array before passing in
    selectComponents = {},
    name,
    control,
    onChange,
    isWarning,
    customStyles = undefined,
  } = props

  const {
    field: { onChange: onChangeField, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  useEffect(() => {
    console.log('buyerId', buyerId)
  }, [buyerId])

  const selectDisplay = error
    ? selectErrorStyle
    : isWarning
    ? selectWarningStyle
    : selectStyle

  const [isLoading, setIsLoading] = useState(false)
  const [productForm, setProductForm] = useState({ isOpen: false })

  // const orderTypesMemo = useMemo(() => orderType || [0, 1, 2], [orderType])

  const {
    isLoadingBuyerSellerProducts,
    productOptions: options,
    isLoadingSellerProducts,
    addBuyerSellerProduct,
    refetchBuyerSellerProduct,
    refetchSellerProductsData,
    sellerProducts,
  } = useQueryBuyerSellerProducts(
    {
      buyerId: buyerId,
      sellerId: sellerId,
      filters: {
        joinsSellerProduct: true,
        // orderTypes: orderTypesMemo,
      },
    },
    { enabled: Boolean(buyerId && sellerId) },
  )

  useEffect(() => {
    console.log('buyerId', buyerId)
  }, [buyerId])

  const selectedValue = useMemo(() => {
    if (options) {
      return (
        options[0]?.options.find(option => option.value === value) ||
        options[1]?.options.find(option => option.value === value)
      )
    }
    return null
  }, [options, value])

  const onChangeSellerProduct = async sellerProductId => {
    setIsLoading(true)
    try {
      const buyerSellerProduct = await apiClient.buyerSellerProducts.create(
        {
          buyerId,
          sellerId,
          buyerSellerProduct: {
            sellerProductId,
          },
        },
        {
          query: { sellerProduct: true },
        },
      )

      const errors = buyerSellerProduct.errors
      if (errors.length > 0) {
        toast.error(errors.join('\n'))
      } else {
        // dispatch(updateBuyerSellerProduct(buyerSellerProduct)) //not touching useQuery
        const { name: sellerProductName, code: sellerProductCode } =
          buyerSellerProduct.sellerProduct
        addBuyerSellerProduct({
          ...buyerSellerProduct,
          sellerProductCode,
          sellerProductName,
        })
        onChangeField(buyerSellerProduct.id) //normal select action
        onChange && onChange({ field: name, value: buyerSellerProduct.id })
        toast.success('Product added')
      }
    } catch (e) {
      toast.error(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onClickCreateProduct = useCallback(
    (name = null) =>
      setProductForm({
        isOpen: true,
        defaultValues: { sellerId, name },
        shouldHideOptionalFields: true,
      }),
    [sellerId],
  )

  const onClickEditSellerProduct = useCallback(
    sellerProductId => {
      setProductForm({
        isOpen: true,
        defaultValues: sellerProducts[sellerProductId],
      })
    },
    [sellerProducts],
  )

  const onCreateSellerProduct = useCallback(
    async sp => {
      setProductForm({ show: false })
      await onChangeSellerProduct(sp.id)
      refetchBuyerSellerProduct()
      refetchSellerProductsData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeSellerProduct],
  )

  return (
    <div className={clsx('FloatingSelectV2_root', { isDisabled })}>
      <IonLabel className='FloatingSelectV2_text'>Product</IonLabel>

      <Select
        className='d-flex'
        options={options}
        placeholder='Select product'
        components={{
          ...selectComponents,
          MenuList: CreateButton(onClickCreateProduct),
          Option: EditOption(onClickEditSellerProduct),
          IndicatorSeparator: null,
        }}
        styles={customStyles || selectDisplay}
        onBlur={onBlur}
        isSearchable
        isDisabled={!buyerId || !sellerId}
        onChange={e => {
          if (e.isSellerProduct) {
            onChangeSellerProduct(e.value)
          } else {
            onChange
              ? onChange({ field: name, value: e.value })
              : onChangeField(e.value)
          }
        }}
        value={selectedValue} //if react-hook-form's value DNE, clear the select
        isLoading={
          isLoading || isLoadingBuyerSellerProducts || isLoadingSellerProducts
        }
        {...props}
      />

      <DialogSellerProductForm
        isOpen={productForm?.isOpen}
        onClose={() => setProductForm({ isOpen: false })}
        afterCreate={onCreateSellerProduct}
        formData={{ sellerId, ...productForm.defaultValues }}
        shouldHideOptionalFields={productForm.shouldHideOptionalFields}
      />
    </div>
  )
}

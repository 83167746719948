import { RTTable } from '../RTTable'
import { RTTableLoadingOverlay } from '../RTTableLoadingOverlay'
import type {
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'
import { RTCellActionMenu } from '../../menus/RTCellActionMenu'

import './styles.scss'
import { RTHorizontalScroll } from '../RTHorizontalScroll'

export interface IRTTableContainerProps<TData extends IReusableTableRowData> {
  table: ReusableTableInstance<TData>
}

const RTTableContainer = <TData extends IReusableTableRowData>({
  table,
  ...rest
}: IRTTableContainerProps<TData>) => {
  const {
    getState,
    options: { tableHeight },
    refs: { tableContainerRef },
  } = table
  const { actionCell, isLoading, showLoadingOverlay } = getState()

  const isSomeColumnsPinned = table.getIsSomeColumnsPinned()

  const loading =
    showLoadingOverlay !== false && (isLoading || showLoadingOverlay)

  return (
    <RTHorizontalScroll isHidden={isSomeColumnsPinned}>
      {({ setRef, onScroll, scrollXPosition }: any) => (
        <div
          aria-busy={loading}
          aria-describedby={loading ? 'mrt-progress' : undefined}
          {...rest}
          onScroll={onScroll}
          className='RTTableContainer__container'
          ref={(node: HTMLDivElement) => {
            if (node) {
              tableContainerRef.current = node
              tableContainerRef.current!.scrollLeft = scrollXPosition
              setRef(node)
              if ((rest as any)?.ref) {
                //@ts-ignore
                rest.ref.current = node
              }
            }
          }}
          style={{
            maxHeight: tableHeight,
            minHeight: tableHeight,
            maxWidth: '100%',
            overflow: 'auto',
            position: 'relative',
            ...(rest as any)?.style,
          }}
        >
          {loading ? <RTTableLoadingOverlay table={table} /> : null}
          <RTTable table={table} />
          {actionCell ? <RTCellActionMenu table={table} /> : null}
        </div>
      )}
    </RTHorizontalScroll>
  )
}

export default RTTableContainer

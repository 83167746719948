import { useCallback, useMemo, useState } from 'react'
import {
  useQueryCompanies,
  useQueryScheduleLoads,
  useQuerySchedules,
  useQueryTerminals,
} from '~/hooks/useQueryData'

import { CommonDialogV2, ScheduleLoadsTable } from '~/components/shared'
import LineChart from '~/components/shared/LineChart/LineChart'

import _ from 'lodash'
import moment from 'moment'

import { Dropdown } from 'react-bootstrap'
import { SchedulesTable } from '~/components/shared/SchedulesTable'

import clsx from 'clsx'
import { Form } from 'react-bootstrap'
import { SCHEDULE_COLORS } from '~/utils/constants'
// import { colord } from 'colord'

import type { IGraphChartOrderTrackingDetailDialogProps } from './type'
import type { IScheduleLoad } from '~/types/models/IScheduleLoad'
import type { ISchedule } from '~/types/models/ISchedule'
import type { ILineChartProps } from '~/components/shared/LineChart/type'

import './styles.scss'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { EFieldType } from '~/types/enums/ECommonEnum'

const GraphChartOrderTrackingDetailDialog = (
  props: IGraphChartOrderTrackingDetailDialogProps,
) => {
  const {
    orderIds,
    isHiddenOrderNumColumn,
    scheduleIds: scheduleIdsProp,
    isOpen,
    ...dialogProps
  } = props

  const [intervalTime, setIntervalTime] = useState(1)
  const [isStacked, setIsStacked] = useState(true)
  const [scheduleLoadsFilterData, setScheduleLoadsFilterData] = useState({
    beginSellerTerminalId: [] as number[],
    ticketTime: {} as any,
    fleetId: [] as number[],
  })

  const scheduleLoadsColumnFilters = useMemo(() => {
    return Object.entries(scheduleLoadsFilterData).map(([key, value]) => ({
      id: key,
      value,
    }))
  }, [scheduleLoadsFilterData])

  const {
    schedulesData,
    updateSchedule,
    refetchSchedulesData,
    isLoadingSchedulesData,
    findScheduleById,
  } = useQuerySchedules(
    {
      filters: {
        orderId: orderIds,
      },
    },
    { enabled: Boolean((orderIds || [])?.length > 0) && isOpen },
  )

  const { sellerTerminalOptions } = useQueryTerminals()

  const { fleetCompanyOptions } = useQueryCompanies({})

  const scheduleIds = scheduleIdsProp || schedulesData.map(({ id }) => id)

  const {
    scheduleLoadsData,
    updateScheduleLoad,
    refetchScheduleLoadsData,
    isLoadingScheduleLoads,
  } = useQueryScheduleLoads(
    {
      filters: {
        scheduleId: scheduleIds,
        ...scheduleLoadsFilterData,
      },
    },
    { enabled: scheduleIds.length > 0 },
  )

  const minDate = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const date = scheduleLoadsData.reduce((minDate: any, { ticketTime }) => {
      return minDate < ticketTime ? minDate : ticketTime
    }, null)
    const minutes = moment(date).get('minutes') / 10
    return moment(date)
      .set({ minutes: Math.floor(minutes) * 10 })
      .toISOString()
  }, [scheduleLoadsData])

  const maxDate = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scheduleLoadsData.reduce((minDate: any, { arriveSellerTime }) => {
        return minDate > arriveSellerTime ? minDate : arriveSellerTime
      }, null),
    [scheduleLoadsData],
  )

  const onSelectIntervalue = useCallback(
    (value: number) => () => {
      setIntervalTime(value)
    },
    [],
  )

  const dateRange = useMemo(() => {
    const range = [minDate] // string[]
    if (minDate && maxDate) {
      let comparedDate = null
      while (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        new Date(comparedDate as any).getTime() < new Date(maxDate).getTime() ||
        comparedDate === null
      ) {
        const lastDate = _.last(range)
        comparedDate = moment(lastDate)
          .add({ minutes: intervalTime })
          .toISOString()
        range.push(comparedDate)
      }
    }
    return range
  }, [intervalTime, maxDate, minDate])

  const dateRangeWithExtraXAxis = useMemo(() => {
    if (dateRange.length === 0) {
      return []
    }

    const [firstDate] = dateRange
    const extraDate = moment(firstDate)
      .subtract({
        minutes: intervalTime,
      })
      .toISOString()
    return [extraDate, ...dateRange]
  }, [dateRange, intervalTime])

  const intervalOptions = useMemo(
    () => [
      {
        label: '1 minute',
        value: 1,
      },
      {
        label: '2 minutes',
        value: 2,
      },
      {
        label: '5 minutes',
        value: 5,
      },
      {
        label: '10 minutes',
        value: 10,
      },
      {
        label: '20 minutes',
        value: 20,
      },
    ],
    [],
  )

  const grouppedScheduleLoad = useMemo(
    () => _.groupBy(scheduleLoadsData, 'scheduleId'),
    [scheduleLoadsData],
  )

  const countSchedulesInRange = useMemo(() => {
    const result: ILineChartProps['datasets'] = []
    Object.keys(grouppedScheduleLoad)
      .filter(key => scheduleIds.includes(parseInt(key)))
      .forEach(key => {
        const values = grouppedScheduleLoad[key]
        const firstItem = values[0]
        const lineData = dateRange.map(date => {
          const today = new Date(date).getTime()
          const dataInRange = values.filter(
            ({ ticketTime, arriveSellerTime }) => {
              const from = new Date(ticketTime).getTime()
              const to = new Date(arriveSellerTime).getTime()
              return today >= from && today <= to
            },
          )

          return dataInRange.length
        })
        result.push({
          data: [0, ...lineData],
          borderColor: firstItem?.color as string,
        })
      })
    return result
  }, [dateRange, grouppedScheduleLoad, scheduleIds])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getIndexColor = useCallback<any>((currentIndex: number) => {
    const MAX_INDEX = SCHEDULE_COLORS.length
    const result = currentIndex - MAX_INDEX
    if (result <= 0) {
      return currentIndex
    }
    if (result <= MAX_INDEX) {
      return result
    }
    return getIndexColor(result)
  }, [])

  const afterUpdateSchedule = useCallback((newSchedule: ISchedule) => {
    updateSchedule(newSchedule.id, newSchedule)
    toast.success(toastMessages.updateSuccess)
    refetchScheduleLoadsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const afterUpdateScheduleLoad = useCallback((scheduleLoad: IScheduleLoad) => {
    updateScheduleLoad(scheduleLoad.id, scheduleLoad)
    toast.success(toastMessages.updateSuccess)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CommonDialogV2
      {...dialogProps}
      isOpen={isOpen}
      fullscreen
      isHiddenOkButton
      title='Truck Demand'
      className='GraphChartOrderTrackingDetailDialog_root'
    >
      <SchedulesTable
        schedules={schedulesData.filter(({ id }) => scheduleIds.includes(id))}
        scheduleLoads={scheduleLoadsData}
        isHiddenActionColumn
        isHiddenOrderNumColumn={isHiddenOrderNumColumn}
        tableHeight={200}
        state={{
          isLoading: isLoadingSchedulesData,
        }}
        // extraColumns={[
        //   {
        //     Header: 'Action',
        //     accessor: 'action',
        //     cellRenderer({ rowData }) {
        //       const scheduleId = rowData.id
        //       const isChecked = showingScheduleIds.includes(scheduleId)
        //       const content = isChecked
        //         ? 'Show this schedule on chart'
        //         : 'Hide this schedule on chart'
        //       return (
        //         <Form>
        //           <ToolTipOverlay content={content} placement='top'>
        //             <Form.Check
        //               type='switch'
        //               checked={isChecked}
        //               onChange={() => {
        //                 setShowingScheduleIds(prev =>
        //                   produce(prev, draft => {
        //                     const index = draft.indexOf(scheduleId)
        //                     if (index === -1) {
        //                       draft.push(scheduleId)
        //                     } else {
        //                       draft.splice(index, 1)
        //                     }
        //                   }),
        //                 )
        //               }}
        //             />
        //           </ToolTipOverlay>
        //         </Form>
        //       )
        //     },
        //   },
        // ]}
        // dataGridProps={{
        //   loading,
        //   className: 'schedulesDataGrid',
        //   tableHeight: 200,
        //   getRowStyles({ rowIndex }) {
        //     const indexColor = getIndexColor(rowIndex)
        //     const color = SCHEDULE_COLORS[indexColor]
        //     const isDarkColor = colord(color || 'rgb(192, 192, 192)').isDark()
        //     return {
        //       backgroundColor: color,
        //       color: isDarkColor ? '#fff' : '#000',
        //     }
        //   },
        // }}
        afterUpdateScheduleLoad={afterUpdateScheduleLoad}
        afterUpdateSchedule={afterUpdateSchedule}
        afterUpdateOrder={() => {
          refetchSchedulesData()
          refetchScheduleLoadsData()
        }}
      />

      <div
        style={{ marginTop: 8, border: '1px solid #e5e5e5', borderRadius: 8 }}
      >
        <h3 style={{ padding: 8 }}>Schedule Loads</h3>
        <ScheduleLoadsTable
          scheduleLoads={scheduleLoadsData}
          tableHeight={300}
          enableTopToolbar
          manualFiltering
          companyViewProps={{
            name: 'schedule_loads',
          }}
          extraColumns={[
            {
              header: 'Schedule Num',
              id: 'scheduleNum',
              accessorFn: row => findScheduleById(row.scheduleId)?.order?.num,
              minSize: 150,
              maxSize: 150,
              align: 'center',

              Cell: ({ row }) => {
                const rowData = row.original
                const schedule = findScheduleById(rowData.scheduleId)
                if (schedule) {
                  return `${schedule.order.num} - ${schedule.scheduleNumber}`
                }
                return '-'
              },
            },
          ]}
          filterOptions={[
            {
              label: 'Seller Terminal',
              field: 'beginSellerTerminalId',
              type: EFieldType.multipleSelect,
              options: sellerTerminalOptions,
            },
            {
              label: 'Ticket Time',
              field: 'ticketTime',
              type: EFieldType.dateRange,
            },
            {
              label: 'Fleet',
              field: 'fleetId',
              type: EFieldType.multipleSelect,
              options: fleetCompanyOptions,
            },
          ]}
          state={{
            columnOrder: ['scheduleNum'],
            isLoading: isLoadingSchedulesData || isLoadingScheduleLoads,
            columnFilters: scheduleLoadsColumnFilters,
          }}
          onColumnFiltersChange={(callback: any) => {
            const newFilters = callback(scheduleLoadsColumnFilters)
            const filtersConverted = newFilters.reduce(
              (acc: any, { id, value }: any) => {
                acc[id] = value
                return acc
              },
              {},
            )
            setScheduleLoadsFilterData(filtersConverted)
          }}
        />
      </div>

      <div className='optionsHeader__container'>
        <Dropdown>
          <Dropdown.Toggle style={{ fontSize: 12 }}>
            Interval: {intervalTime} minute{intervalTime > 1 ? 's' : ''}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {intervalOptions.map(({ label, value }) => (
              <Dropdown.Item
                className={clsx({ isSelected: value === intervalTime })}
                key={value}
                onClick={onSelectIntervalue(value)}
              >
                {label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Form className='stackCheckbox'>
          <div style={{ marginRight: 8 }}>
            {isStacked ? 'Stacked' : 'Not stacked'}
          </div>
          <Form.Check
            type='switch'
            checked={isStacked}
            onChange={() => {
              setIsStacked(prev => !prev)
            }}
          />
        </Form>
      </div>

      <LineChart
        className='lineChartContainer'
        labels={dateRangeWithExtraXAxis.map(date =>
          moment(date).format('HH:mm'),
        )}
        datasets={countSchedulesInRange}
        isStacked={isStacked}
      />
    </CommonDialogV2>
  )
}

export default GraphChartOrderTrackingDetailDialog

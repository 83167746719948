import { useCallback, memo, useEffect, useState } from 'react'
import { useRouter } from '~/hooks/useRouter'
import useTableCompanyUserSubTab from './useTableCompanyUserSubTab'

import {
  DialogUserForm,
  DialogUserAccessForm,
  ReusableTable,
  EditIcon,
  CompanyIcon,
  ViewMapIcon,
} from '~/components/shared'
import DialogUserTerminals from './DialogUserTerminals'

import _ from 'lodash'
import { produce } from 'immer'
import { toast } from 'react-toastify'
import { moduleName } from '~/utils/constants'
import { toastMessages } from '~/constants/toast-status-text'
import buildObjWithValue from '~/utils/buildObjWithValue'

import './CompanyUserSubTab.scss'
import { apiClient } from '~/api/ApiClient'
import { useWindowSize } from 'react-use'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'

function CompanyUserSubTab() {
  const windowSize = useWindowSize()

  const router = useRouter()

  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState([])
  const [userTerminalsModal, setUserTerminalsModal] = useState({
    open: false,
    userAccessId: null,
  })
  const [dialogUserState, setDialogUserState] = useState({
    isOpen: false,
    formData: null,
  })
  const [dialogUserAccessState, setDialogUserAccessState] = useState({
    isOpen: false,
    formData: null,
  })

  const getCompanyUsers = useCallback(async () => {
    setLoading(true)
    try {
      const response = await apiClient.users.get({
        userableId: router.params.id,
        userableType: 'Company',
      })
      setUserData(response)
    } catch (error) {
      console.log('error', error)
      toast.error('An error has occurred.')
    } finally {
      setLoading(false)
    }
  }, [router.params.id])

  const onOpenUserCreateForm = () => {
    setDialogUserState({
      isOpen: true,
      formData: null,
    })
  }

  const handleOpenUserTerminalModal = useCallback(user => {
    setUserTerminalsModal({
      open: true,
      userAccessId: user.userAccess.id,
    })
  }, [])

  const handleOpenEditUserForm = useCallback(user => {
    setDialogUserState({
      isOpen: true,
      formData: user,
    })
  }, [])

  const handleOpenEditAccess = useCallback(user => {
    setDialogUserAccessState({
      isOpen: true,
      formData: user.userAccess,
    })
  }, [])

  const { columns } = useTableCompanyUserSubTab()

  const handleCloseUserTerminalsModal = useCallback(() => {
    setUserTerminalsModal({
      open: false,
      userAccessId: null,
    })
  }, [])

  const updateUser = useCallback(async ({ id, ...formValues }) => {
    const { user, errors } = await apiClient.users.update(id, {
      user: formValues,
    })
    if (_.size(errors) > 0) {
      toast.error('An error has occured while updating the user.')
    } else {
      toast.success(toastMessages.updateSuccess)
      setUserData(prev =>
        produce(prev, draft => {
          const index = _.findIndex(draft, { id: user.id })
          if (index !== -1) {
            draft[index] = {
              ...draft[index],
              ...user,
            }
          }
        }),
      )
    }
  }, [])

  const afterUpdateUserAccess = useCallback(() => {
    setDialogUserAccessState({
      isOpen: false,
      formData: null,
    })
    getCompanyUsers()
  }, [getCompanyUsers])

  const hanldeChangeCellValue = useCallback(
    async (value, cell) => {
      const { column, row } = cell

      try {
        if (column.id === 'title') {
          console.log('//update worker title', row.original)

          return
        }

        const obj = buildObjWithValue(column.id, value)
        await updateUser({ id: row.original.id, ...obj })
      } catch (error) {
        console.log('error', error)
        toast.error('An error has occurred while updating the user.')
      }
    },
    [updateUser],
  )

  const afterCreateCompanyUser = useCallback(async response => {
    setUserData(prev => [response, ...prev])
    setDialogUserState({
      isOpen: false,
      formData: null,
    })
  }, [])

  const afterUpdateCompanyUser = useCallback(async response => {
    setUserData(prev =>
      produce(prev, draft => {
        const index = prev.findIndex(({ id }) => id === response.id)
        if (index !== -1) {
          draft[index] = {
            ...draft[index],
            ...response,
          }
        }
      }),
    )
    setDialogUserState({
      isOpen: false,
      formData: null,
    })
  }, [])

  useEffect(() => {
    getCompanyUsers()
  }, [getCompanyUsers])

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          columns={columns}
          data={userData}
          enableRowActions
          enableCompanyView
          enableTopToolbar
          tableHeight={windowSize.height - 220}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 105,
            },
          }}
          companyViewProps={{
            description: "Mange your company's users.",
            name: moduleName.company.users,
            onClickTopButton: onOpenUserCreateForm,
          }}
          state={{
            isLoading: loading,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: () => handleOpenEditUserForm(row.original),
              tooltipProps: {
                content: 'Edit User',
                placement: 'top',
              },
            },
            {
              icon: <CompanyIcon color='white' />,
              onClick: () => handleOpenEditAccess(row.original),
              tooltipProps: {
                content: 'Edit User Access',
                placement: 'top',
              },
            },
            {
              icon: <ViewMapIcon color='white' />,
              onClick: () => handleOpenUserTerminalModal(row.original),
              tooltipProps: {
                content: 'View Terminals',
                placement: 'top',
              },
            },
          ]}
          onCellEditEnd={hanldeChangeCellValue}
        />
      </div>
      <DialogUserTerminals
        {...userTerminalsModal}
        onClose={handleCloseUserTerminalsModal}
      />
      <DialogUserForm
        isOpen={dialogUserState.isOpen}
        formData={dialogUserState.formData}
        onClose={() => {
          setDialogUserState({
            isOpen: false,
            formData: null,
          })
        }}
        afterCreate={afterCreateCompanyUser}
        afterUpdate={afterUpdateCompanyUser}
      />

      <DialogUserAccessForm
        isOpen={dialogUserAccessState.isOpen}
        formData={dialogUserAccessState.formData}
        onClose={() => {
          setDialogUserAccessState({
            isOpen: false,
            formData: null,
          })
        }}
        afterUpdate={afterUpdateUserAccess}
      />
    </>
  )
}

CompanyUserSubTab.propTypes = {}

export default memo(CompanyUserSubTab)

import { useState, useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useDocumentMenu from '~/hooks/useDocumentMenu'
import { useRouter } from '~/hooks/useRouter'
import {
  IonIcon,
  IonButton,
  IonChip,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonText,
  IonRouterLink,
} from '@ionic/react'
import {
  peopleCircleOutline,
  calendarOutline,
  receiptOutline,
  documentTextOutline,
  helpOutline,
  pinOutline,
} from 'ionicons/icons'
import DocumentName from './DocumentName'
import {
  SelectSearchDropdown,
  ConcordCompanyDropdown,
  ContextMenu,
  CompanyAvatar,
  PdfViewer,
  ImageViewer,
  ToolTipOverlay,
  VerticalThreedotsIcon,
  ViewMapIcon,
} from '~/components/shared'
import DialogCreateLoad from '~/components/company/DocumentList/DialogCreateLoad'
import DialogEmailContent from '~/components/company/DocumentList/DialogEmailContent'
import DialogSplitInvoice from '~/components/company/DocumentList/TableView/DialogSplitInvoice'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
import { getDocumentURL } from '~/utils/documentUtils'
import { docType } from '~/components/company/DocumentList/helpers'
import {
  selectSellerTerminalOptions,
  selectBuyerTerminalOptions,
  selectMyCurrentCompany,
} from '~/redux/selectors'
import { toast } from 'react-toastify'
import { updateCompanyCheckForSplit } from '~/redux/actions/sessionActions'
import { statusColor } from '~/utils/utils'

import './DocumentItem.scss'
import { apiClient } from '~/api/ApiClient'

const DocumentItem = props => {
  const router = useRouter()

  const {
    document,
    onUpdate,
    afterCreateLoad,
    afterDeleteDocument,
    setDocuments,
  } = props

  const [openDialogType, setOpenDialogType] = useState(null)
  const [loadingSplitInvoiceDialog, setLoadingSplitInvoiceDialog] =
    useState(false)

  const [documentUrl, setDocumentUrl] = useState(document.url)
  const [showDocumentModal, setShowDocumentModal] = useState({
    isOpen: false,
    src: '',
  })
  const filetype = document.fileType
  const [_document, setDocument] = useState(document)

  const { renderDocumentMenu } = useDocumentMenu()

  const dispatch = useDispatch()
  const buyerTerminalsOptions = useSelector(selectBuyerTerminalOptions)
  const sellerTerminalOptions = useSelector(selectSellerTerminalOptions)
  const myCurrentCompany = useSelector(selectMyCurrentCompany)

  const buyerTerminal = useMemo(() => {
    const { buyerTerminalId } = _document
    const option = buyerTerminalsOptions.find(
      buyer => buyer.value === buyerTerminalId,
    )

    return option
  }, [_document, buyerTerminalsOptions])

  const sellerTerminal = useMemo(() => {
    const { sellerTerminalId } = _document
    const option = sellerTerminalOptions.find(
      seller => seller.value === sellerTerminalId,
    )

    return option
  }, [_document, sellerTerminalOptions])

  useEffect(() => {
    setDocument(document)
  }, [document])

  useEffect(() => {
    onLoadDocumentUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseDialog = useCallback(() => {
    setOpenDialogType(null)
  }, [])

  const onOpenCreateLoadDialog = useCallback(() => {
    setOpenDialogType('create-load')
  }, [])

  const onOpenPdfReview = useCallback(() => {
    setOpenDialogType('pdf-review')
  }, [])

  const onOpenEmailContent = useCallback(async () => {
    setOpenDialogType('email-content')
  }, [])

  const onSplitInvoice = useCallback(() => {
    setOpenDialogType('split-invoice')
  }, [])

  const menuEvent = useMemo(
    () => ({
      afterCreateLoad,
      afterDeleteDocument,
      onOpenCreateLoadDialog,
      onOpenPdfReview,
      onOpenEmailContent,
      onSplitInvoice,
    }),
    [
      afterCreateLoad,
      afterDeleteDocument,
      onOpenCreateLoadDialog,
      onOpenEmailContent,
      onOpenPdfReview,
      onSplitInvoice,
    ],
  )

  const contextMenu = useMemo(() => {
    return renderDocumentMenu(document, menuEvent)
  }, [document, menuEvent, renderDocumentMenu])

  const onOpenNewWindow = useCallback(async () => {
    const url = await getDocumentURL(_document)
    window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes')
  }, [_document])

  const onOpenNewTab = useCallback(
    async event => {
      event.stopPropagation()
      const url = await getDocumentURL(_document)
      window.open(url, '_blank')
    },
    [_document],
  )

  const onLoadDocumentUrl = useCallback(async () => {
    const url = await getDocumentURL(_document)
    setDocumentUrl(url)
  }, [_document])

  const onDocumentHideModal = () => {
    setShowDocumentModal({
      isOpen: false,
      src: '',
    })
  }

  const handleSubmitSplitInvoice = useCallback(
    async formValues => {
      const invoice = {
        documentId: _document.id,
        fileName: _document.displayName || _document.name,
        fileType: _document.fileType,
        buyerId: _document.buyerId,
        sellerId: _document.seller.id,
        pageRanges: formValues,
      }
      setLoadingSplitInvoiceDialog(true)
      try {
        const { documents } =
          await apiClient.invoice.createInvoiceFromDocuments({
            invoice,
          })
        setDocuments && setDocuments(prev => [...documents, ...prev])
        handleCloseDialog()
        toast.success('Split invoices successfully!')
      } catch (error) {
        toast.error('An error has occurred.')
      } finally {
        setLoadingSplitInvoiceDialog(false)
      }
    },
    [_document, handleCloseDialog, setDocuments],
  )

  const onUpdateSellerValue = async e => {
    const sellerId = e.value
    const sellerName = e.label
    const sellerImage = e.image
    const result = await apiClient.documents
      .update(_document.id, {
        sellerId,
      })
      .catch(err => {
        alert(err.message)
      })
    if (result) {
      setDocument(prev => ({
        ...prev,
        sellerId,
        sellerName,
        sellerImage,
      }))
    }
  }

  const onUpdateSellerEmailParser = (concordDocument, selectedSeller) => {
    onUpdateSellerValue(selectedSeller)
  }

  const onUpdateDoctypeValue = async e => {
    const result = await apiClient.documents
      .update(_document.id, {
        docType: e.value,
      })
      .catch(err => {
        alert(err.message)
      })
    if (result) {
      setDocument({ ..._document, docType: e.value })
    }
  }

  const onUpdateBuyerTerminal = async ({ value }) => {
    try {
      await apiClient.documents.update(_document.id, { buyerTerminalId: value })
      setDocument(prev => ({ ...prev, buyerTerminalId: value }))
    } catch (error) {
      alert(error.message)
    }
  }

  const onUpdateSellerTerminal = useCallback(
    async ({ value }) => {
      await apiClient.documents.update(_document.id, {
        sellerTerminalId: value,
      })
      setDocument(prev => ({ ...prev, sellerTerminalId: value }))
    },
    [_document.id],
  )

  const onUpdateCompany = useCallback(
    updatedCompany => {
      dispatch(updateCompanyCheckForSplit(updatedCompany.checkForSplit))
    },
    [dispatch],
  )

  const moreThanOnePage = _document.totalPages > 1

  const colorChip = {
    Load: 'danger',
    Invoice: 'tertiary',
  }

  const handleChangeDocumentName = (event, document) => {
    onUpdate && onUpdate(document)
  }

  const handleRedirectSeller = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      router.push(`/companies/${document.sellerId}/edit`)
    },
    [document.sellerId, router],
  )

  const getStyles = () => {
    return clsx('DocumentItem__container', {
      highlighted: document.highlight,
    })
  }

  const handleRerunEmail = useCallback(({ documents }) => {
    console.log('rerun email', documents)
  }, [])

  return (
    <ContextMenu menu={contextMenu} type='rightClick'>
      <IonCard className={getStyles()} style={{ margin: 0 }}>
        <IonCardHeader
          className='DocumentItem__headerContainer'
          onClick={onOpenPdfReview}
        >
          <div className='DocumentItem__pdfContainer'>
            <embed src={_document.url} />
          </div>

          <div className='DocumentItem__headerTitleContainer'>
            <DocumentName
              document={_document}
              onChange={handleChangeDocumentName}
            />

            <IonChip
              className='DocumentItem__headerStatus'
              color={statusColor(_document.status)}
            >
              <IonText
                className='DocumentItem__dotStatus'
                style={{
                  backgroundColor: `var(--ion-color-${statusColor(
                    _document.status,
                  )})`,
                }}
              />
              <span>{_document.status}</span>
            </IonChip>
          </div>
        </IonCardHeader>
        <div className='DocumentItem__contentContainer'>
          <div className='DocumentItem__contentHeader'>
            <div className='d-flex align-items-center'>
              <ConcordCompanyDropdown
                className='remove-border-from-common-seller-dropdown'
                buyerId={_document.buyerId}
                value={{
                  value: _document.sellerId,
                  label: _document.sellerName,
                  image: _document.sellerImage,
                }}
                onChange={onUpdateSellerValue}
                formProps={{
                  docType: _document.docType,
                  documentId: _document.id,
                }}
                getOptionLabel={option => (
                  <CompanyAvatar
                    companyType='seller'
                    company={option}
                    tooltipMessage={option?.label || 'Click to select seller'}
                  />
                )}
              />

              {/* Seller name */}
              <IonText>
                <IonRouterLink
                  href={`/companies/${document.sellerId}`}
                  className='underline DocumentItem__userLink'
                  onClick={handleRedirectSeller}
                >
                  {_document.sellerName}
                </IonRouterLink>
              </IonText>
            </div>

            <ContextMenu menu={contextMenu}>
              <h1 className='document-card-menu-btn'>
                <VerticalThreedotsIcon />
              </h1>
            </ContextMenu>
          </div>
          <div className='DocumentItem__contentInfoContainer'>
            <div className='DocumentItem__contentInfoItem'>
              <p className='DocumentItem__contentLabelWrapper'>
                <IonIcon
                  icon={peopleCircleOutline}
                  className='DocumentItem__contentIcon'
                />
                {_document.user && _document.user.email}
              </p>

              <p className='DocumentItem__contentLabelWrapper'>
                <IonIcon
                  icon={calendarOutline}
                  className='DocumentItem__contentIcon'
                />
                {dateFormat(_document.createdAt, 'ddd, mmm dd h:MM:ss TT')}
              </p>

              <p className='DocumentItem__contentLabelWrapper'>
                <IonIcon
                  icon={receiptOutline}
                  className='DocumentItem__contentIcon'
                />
                {/* {_document.docType} */}
                <SelectSearchDropdown
                  subComponent={
                    <ToolTipOverlay content='Document Type' placement='bottom'>
                      <IonChip
                        style={{ margin: 0 }}
                        color={
                          _document.docType
                            ? colorChip[_document.docType]
                            : 'primary'
                        }
                      >
                        <IonLabel>{_document.docType}</IonLabel>
                      </IonChip>
                    </ToolTipOverlay>
                  }
                  value={docType}
                  currentValue={{
                    label: _document.docType,
                    value: _document.docType,
                  }}
                  onChange={onUpdateDoctypeValue}
                />
              </p>

              <p className='DocumentItem__contentLabelWrapper'>
                <IonIcon
                  icon={documentTextOutline}
                  className='DocumentItem__contentIcon'
                />
                {_document.totalPages} {moreThanOnePage ? 'pages' : 'page'}
              </p>
            </div>

            <div>
              <p className='DocumentItem__contentLabelWrapper'>
                {/* Buyer terminal */}
                <ViewMapIcon className='DocumentItem__contentIcon' />
                <SelectSearchDropdown
                  subComponent={
                    <ToolTipOverlay
                      content={
                        buyerTerminal
                          ? 'Buyer Terminal'
                          : 'Buyer Terminal not set yet.  Click to view options and set'
                      }
                      placement='bottom'
                    >
                      <IonChip style={{ margin: 0 }} color='concord'>
                        {buyerTerminal?.label && (
                          <IonLabel>{buyerTerminal?.label}</IonLabel>
                        )}
                        {!buyerTerminal?.label && (
                          <IonIcon
                            icon={helpOutline}
                            style={{ marginLeft: 0 }}
                          />
                        )}
                      </IonChip>
                    </ToolTipOverlay>
                  }
                  value={buyerTerminalsOptions}
                  currentValue={buyerTerminal}
                  onChange={onUpdateBuyerTerminal}
                />
              </p>

              <p className='DocumentItem__contentLabelWrapper'>
                {/* Seller terminal */}
                <IonIcon
                  className='DocumentItem__contentIcon'
                  icon={pinOutline}
                />
                {/* <BsFillPinMapFill className="DocumentItem__contentIcon" /> */}
                <SelectSearchDropdown
                  subComponent={
                    <ToolTipOverlay
                      content={
                        sellerTerminal
                          ? 'Seller Terminal'
                          : 'Seller Terminal not set yet. Click to view options and set'
                      }
                      placement='bottom'
                    >
                      <IonChip style={{ margin: 0 }} color='secondary'>
                        {sellerTerminal?.label && (
                          <IonLabel>{sellerTerminal?.label}</IonLabel>
                        )}
                        {!sellerTerminal?.label && (
                          <IonIcon
                            icon={helpOutline}
                            style={{ marginLeft: 0 }}
                          />
                        )}
                      </IonChip>
                    </ToolTipOverlay>
                  }
                  value={sellerTerminalOptions}
                  currentValue={sellerTerminal}
                  onChange={onUpdateSellerTerminal}
                />
              </p>

              <IonButton
                onClick={onOpenNewTab}
                className='DocumentItem__viewDocument'
              >
                View Document
              </IonButton>
            </div>
          </div>
        </div>

        <PdfViewer
          value={filetype == 'pdf'}
          document={documentUrl}
          showPopOver={openDialogType === 'pdf-review'}
          setShowPopOver={handleCloseDialog}
          onOpenNewTab={onOpenNewWindow}
          onOpenNewWindow={onOpenNewWindow}
        />

        <DialogCreateLoad
          document={_document}
          isOpen={openDialogType === 'create-load'}
          onClose={handleCloseDialog}
          onDidDismiss={handleCloseDialog}
          afterCreateLoad={afterCreateLoad}
        />

        <ImageViewer
          isOpen={showDocumentModal.isOpen}
          onHidePreview={onDocumentHideModal}
          image={showDocumentModal.src}
        />

        <DialogEmailContent
          isOpen={openDialogType === 'email-content'}
          document={{
            ..._document,
            seller: {
              image: _document.sellerImage,
              label: _document.sellerName,
              id: _document.sellerId,
              value: _document.sellerId,
            },
          }}
          onClose={handleCloseDialog}
          onRerunEmail={handleRerunEmail}
          onUpdateSeller={onUpdateSellerEmailParser}
        />
        <DialogSplitInvoice
          document={_document}
          isOpen={openDialogType === 'split-invoice'}
          onClose={handleCloseDialog}
          onSubmit={handleSubmitSplitInvoice}
          loading={loadingSplitInvoiceDialog}
          currentCompany={myCurrentCompany}
          onUpdateCompany={onUpdateCompany}
        />
      </IonCard>
    </ContextMenu>
  )
}

DocumentItem.propTypes = {
  document: PropTypes.object,
  onUpdate: PropTypes.func,
  setDocuments: PropTypes.func,
  afterCreateLoad: PropTypes.func,
  afterDeleteDocument: PropTypes.func,
}

export default DocumentItem

import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useSelector } from 'react-redux'

import { Alert, Col, Container, Row } from 'react-bootstrap'
import {
  ConcordFormStructure,
  IConcordFormField,
  ConcordTextFieldWithFormControl,
} from '~/components/shared'
import { DateRangePicker, RangeKeyDict } from 'react-date-range'

import { toast } from 'react-toastify'
import { EFieldType } from '~/types/enums/ECommonEnum'
import {
  OFF_DAY_STATUS_OPTIONS,
  REQUEST_OFF_DAY_TYPE_OPTIONS,
} from '~/utils/constants'
import * as Yup from 'yup'
import { EOffDayStatus, EOffdayRequestType } from '~/types/enums/EOffday'
import { selectCurrentScope, selectWorkerDetails } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'
import moment from 'moment'

import type { IOffDayFormProps } from './type'
import type { IOffdayFormValues } from '~/types/models/IOffday'
import type { IDateRange } from '~/types/models/ICommonModel'

import './styles.scss'
import { apiClient } from '~/api/ApiClient'
import { useQueryUsers } from '~/hooks/useQueryData'

function OffDayFormData(props: IOffDayFormProps) {
  const {
    isHiddenSubmitButton,
    offdaysData = [],
    afterCreate,
    afterUpdate,
  } = props

  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: new Date(),
    endDate: new Date(),
  })
  const [selectedWorkerId, setSelectedWorkerId] = useState<number | null>(null)
  const [backendError, setBackendError] = useState('')

  const { workerOptions, isLoadingUsersData } = useQueryUsers()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const currentScope: EScope = useSelector(selectCurrentScope)
  const workerDetail = useSelector(selectWorkerDetails)

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'workerId',
        label: 'Worker',
        options: workerOptions,
        isLoading: isLoadingUsersData,
        type: EFieldType.singleSelect,
        isHidden: currentScope === EScope.driverFleet,
        isRequired: true,
        isDisabled: Boolean(props.defaultValues?.id),
      },
      {
        name: 'requestType',
        label: 'Request type',
        type: EFieldType.singleSelect,
        options: REQUEST_OFF_DAY_TYPE_OPTIONS,
        isRequired: true,
      },
      {
        name: 'reason',
        label: 'Reason',
        as: 'textarea',
        rows: 3,
        render({ control, label, name, watch }) {
          const requestType = watch('requestType', '')
          const isRequired = requestType === EOffdayRequestType.other

          return (
            <ConcordTextFieldWithFormControl
              control={control}
              name={name}
              as='textarea'
              label={label}
              isRequired={isRequired}
            />
          )
        },
      },
      {
        name: 'status',
        label: 'Status',
        isHidden: currentScope === EScope.driverFleet,
        options: OFF_DAY_STATUS_OPTIONS,
        type: EFieldType.singleSelect,
      },
    ],
    [currentScope, isLoadingUsersData, props.defaultValues?.id, workerOptions],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        requestType: Yup.string()
          .typeError('Request type is required!')
          .required('Request type is required!'),
        reason: Yup.string().when('requestType', {
          is: EOffdayRequestType.other,
          then: () => Yup.string().required('Reason is required!'),
        }),
        workerId: Yup.number().required('Worker is required!').nullable(),
      }),
    [],
  )

  const defaultValues = useMemo<IOffdayFormValues>(
    () => ({
      workerId: null,
      reason: '',
      requestType: null,
      status: EOffDayStatus.requested,
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
    }),
    [],
  )

  const getDaysArray = useCallback(
    (startDate: string | Date, endDate: string | Date, steps = 1) => {
      const dateArray = []
      let currentDate = new Date(startDate)

      while (currentDate <= new Date(endDate)) {
        dateArray.push(new Date(currentDate))
        // Use UTC date to prevent problems with time zones and DST
        currentDate.setUTCDate(currentDate.getUTCDate() + steps)
      }

      return dateArray
    },
    [],
  )

  const disabledDates = useMemo(() => {
    let date: Date[] = []
    offdaysData
      .filter(({ id }) => id !== props.defaultValues?.id)
      .filter(({ workerId }) => {
        return workerId === selectedWorkerId
      })
      .forEach(offday => {
        const days = getDaysArray(offday.startDate, offday.endDate)
        date = [...date, ...days]
      })
    return date
  }, [offdaysData, props.defaultValues?.id, selectedWorkerId, getDaysArray])

  const editOffday = useCallback(
    async (formValues: IOffdayFormValues) => {
      if (formValues.id) {
        const payload = {
          startDate: moment(formValues.startDate).format('YYYY-MM-DD'),
          endDate: moment(formValues.endDate).format('YYYY-MM-DD'),
          reason: formValues.reason,
          status: formValues.status,
          requestType: formValues.requestType,
        }
        const { errors, ...response } = await apiClient.offdays.update(
          formValues.id,
          {
            offday: payload,
          },
        )
        if (errors.length > 0) {
          setBackendError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
        }
      } else {
        setBackendError('No id was found!')
      }
    },
    [afterUpdate],
  )

  const createOffday = useCallback(
    async (formValues: IOffdayFormValues) => {
      const payload = {
        startDate: moment(formValues.startDate).format('YYYY-MM-DD'),
        endDate: moment(formValues.endDate).format('YYYY-MM-DD'),
        reason: formValues.reason,
        status: formValues.status,
        workerId: formValues.workerId,
        requestType: formValues.requestType,
      }
      const { errors, ...response } = await apiClient.offdays.create({
        offday: payload,
      })
      if (errors.length > 0) {
        setBackendError(errors[0])
      } else {
        afterCreate && afterCreate(response)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const onSubmit = useCallback(
    (formValues: IOffdayFormValues) => {
      setBackendError('')
      if (dateRange.startDate && dateRange.endDate) {
        const values = {
          ...formValues,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }
        if (formValues?.id) {
          editOffday(values)
        } else {
          createOffday(values)
        }
      } else {
        toast.error('Date is required!')
      }
    },
    [createOffday, dateRange.endDate, dateRange.startDate, editOffday],
  )

  const onChangeDateRange = useCallback(({ range1 }: RangeKeyDict) => {
    setDateRange(range1)
  }, [])

  useEffect(() => {
    if (props.defaultValues) {
      const { startDate, endDate } = props.defaultValues
      setDateRange({
        startDate: moment(startDate).toDate(),
        endDate: moment(endDate).toDate(),
      })
      setSelectedWorkerId(props.defaultValues.workerId)
    } else if (currentScope === EScope.driverFleet) {
      formRef.current?.setValue('workerId', workerDetail?.id)
      setSelectedWorkerId(workerDetail?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.defaultValues?.startDate,
    props.defaultValues?.endDate,
    currentScope,
    workerDetail?.id,
  ])

  useEffect(() => {
    const subscription = formRef.current?.watch((value: IOffdayFormValues) => {
      setSelectedWorkerId(value.workerId)
    })
    return () => subscription.unsubscribe()
  }, [])

  return (
    <Container>
      {backendError && (
        <Alert variant='danger' style={{ margin: '8px 0', fontSize: 12 }}>
          {backendError}
        </Alert>
      )}
      <Row>
        <Col xs={12} md={6} className='iconItem'>
          <DateRangePicker
            ranges={[dateRange]}
            className='dateRangeContainer'
            onChange={onChangeDateRange}
            disabledDates={disabledDates}
          />
        </Col>
        <Col xs={12} md={6} className='iconItem'>
          <ConcordFormStructure
            {...props}
            ref={formRef}
            fields={fields}
            isHiddenCancelButton
            onSubmit={onSubmit}
            schema={schema}
            defaultValues={props.defaultValues || defaultValues}
            isHiddenSearch
            isHiddenSubmitButton={isHiddenSubmitButton}
          />
        </Col>
      </Row>
    </Container>
  )
}
export default OffDayFormData

import { useCallback, useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import useModifyGlCode from './useModifyGlCode'
import { IGlCode } from '~/types/models/IGlCode'

const useQueryGlCodes = (options?: Partial<UseQueryOptions<IGlCode[]>>) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['glCodes', sessionUser?.id],
    async queryFn() {
      const response = await apiClient.glCodes.get()
      return response.glCodes
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 600000, // 10 minutes
    ...options,
  })

  const { addGlCode, updateGlCode, removeGlCode } = useModifyGlCode()

  const glCodesData = useMemo(() => data || [], [data])

  const glCodeOptions = useMemo(
    () =>
      glCodesData.map(({ id, name, glCode }) => ({
        value: id,
        label: `${glCode} - ${name}`,
      })),
    [glCodesData],
  )

  const findGlCodeById = useCallback(
    (id: number) => glCodesData.find(item => item.id === id),
    [glCodesData],
  )

  return {
    glCodesData,
    isLoadingGlCodes: isLoading,
    glCodeOptions,
    addGlCode,
    updateGlCode,
    removeGlCode,
    findGlCodeById,
  }
}

export default useQueryGlCodes

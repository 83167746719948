import { useState } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button'
import { Form } from 'react-bootstrap'
import clsx from 'clsx'

export const ScheduleLoadsTableInputQty = ({
  index,
  schedules,
  updateField,
  isReadOnly,
}) => {
  const [showAlert, setShowAlert] = useState(false)
  const [newQty, setNewQty] = useState(parseFloat(schedules[index].qty))

  const qty = parseFloat(schedules[index].qty)
  const othersSum = schedules
    .filter((_, i) => i === index) //remove current row
    .reduce((acc, curr) => acc + parseFloat(curr.qty), 0)

  const isQtyValid = newQty => qty + othersSum >= newQty

  const tryUpdate = ({ target: { value } }) => {
    isQtyValid(parseFloat(value)) ? submitUpdate() : setShowAlert(true)
  }

  const submitUpdate = () => {
    updateField({ row: index, field: 'qty', value: newQty })
    setShowAlert(false)
  }

  const resetField = () => {
    setNewQty(qty)
    setShowAlert(false)
  }

  const popover = (
    <Popover>
      <Popover.Header as='h3'>QTY too large</Popover.Header>
      <Popover.Body>
        <h4>New QTY exceeds total order QTY</h4>
        <div className='d-flex flex-row-reverse'>
          <Button
            variant='secondary'
            onClick={() => resetField()}
            className='mx-2'
          >
            Cancel
          </Button>
          <Button variant='primary' onClick={() => submitUpdate()}>
            Update QTY
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger placement='right' overlay={popover} show={showAlert}>
      <Form.Control
        value={newQty}
        onChange={e => setNewQty(e.target.value)}
        type='number'
        min={0}
        onBlur={tryUpdate}
        style={{ width: 44 }}
        className={clsx({ isReadOnly })}
      />
    </OverlayTrigger>
  )
}

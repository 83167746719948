import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'

const useQueryStripeAccount = () => {
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const currentScope: EScope = useSelector(selectCurrentScope)

  const { data, isLoading, isFetched } = useQuery({
    queryKey: ['stripeAccount', currentCompany.stripeConnectAccountUid],
    async queryFn() {
      const response = await apiClient.stripe.getStripeAccount()
      return response.stripeAccount
    },
    enabled: Boolean(
      currentCompany.stripeConnectAccountUid && currentScope === EScope.seller,
    ),
  })

  return {
    stripeAccount: data,
    isStripeAccountLoading: isLoading,
    isStripeAccountFetched: isFetched,
  }
}

export default useQueryStripeAccount

import useSchedulesTable from './useSchedulesTable'

import {
  GraphIcon,
  ReusableTable,
  RTActionNoteButton,
  ScheduleLoadsTable,
} from '~/components/shared'
import { GraphChartOrderTrackingDetailDialog } from '~/containers/OrderTracking/OrderTrackingDetailColumn/GraphChartOrderTrackingDetailDialog'
import { OrderFormDialog } from './OrderFormDialog'

import type { ISchedulesTableProps } from './type'
import { IDriverFleet } from '~/types/models/IDriverFleet'
import { ITruckFleet } from '~/types/models/ITruckFleet'

import './styles.scss'
import { ENoteableType } from '~/types/enums/ENote'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import { DRIVER_TYPES } from '~/containers/OrderTracking/Utils/driverTypes'
import clsx from 'clsx'
import { ORDER_STATUSES } from '~/constants/orders/constants'
import { OrderExtrasDialog } from './OrderExtrasDialog'

export interface IDndDriverCard {
  driverFleet: IDriverFleet
  truckFleet: ITruckFleet
}

function SchedulesTable(props: ISchedulesTableProps) {
  const {
    columns,
    lineGraphState,
    isOpenOrderForm,
    selectedSchedule,
    defaultTab,
    notesData,
    filterOptions,
    tableHeight,
    state,
    enableTopToolbar,
    manualFiltering,
    toolbarProps,
    scheduleLoads,
    schedules,
    orderExtrasDialog,
    onColumnFiltersChange,
    afterUpdateOrder,
    getCellClassName,
    onCellEditEnd,
    onCloseScheduleLineGraph,
    onCloseOrderForm,
    onOpenScheduleLineGraph,
    afterCreateNote,
    afterUpdateNote,
    afterDeleteNote,
    afterUpdateScheduleLoad,
    renderDetailPanel,
    onSortingChange,
    onCloseOrderExtrasDialog,
  } = useSchedulesTable(props)

  return (
    <>
      <ReusableTable
        columns={columns}
        data={schedules}
        tableHeight={tableHeight}
        enableRowActions
        enableColumnPinning
        enableOuterDropping
        enableTopToolbar={enableTopToolbar}
        manualFiltering={manualFiltering}
        toolbarProps={toolbarProps}
        getCellClassName={getCellClassName}
        getRowClassName={({ row }) =>
          clsx(
            'SchedulesTable__row',
            (ORDER_STATUSES as any)[row.original.status]?.color,
          )
        }
        filterOptions={filterOptions}
        displayColumnDefOptions={{
          [ERTDisplayColumnId.actions]: {
            size: 80,
          },
        }}
        state={state}
        initialState={{
          columnPinning: {
            left: [
              ERTDisplayColumnId.actions,
              ERTDisplayColumnId.expand,
              'order.num',
            ],
          },
          outerDroppingTypesAccepted: [DRIVER_TYPES.WORKING],
          sorting: [
            {
              id: 'nextTicketTime',
              desc: true,
            },
          ],
        }}
        renderRowActions={({ row }) => {
          const rowData = row.original
          const notes = notesData.filter(
            ({ noteableId }) => noteableId === rowData.id,
          )

          return [
            {
              icon: <GraphIcon color='white' />,
              onClick: onOpenScheduleLineGraph(rowData),
            },

            {
              render: () => (
                <RTActionNoteButton
                  notesData={notes}
                  noteableId={rowData.id}
                  noteableType={ENoteableType.Schedule}
                  afterUpdateNote={afterUpdateNote}
                  afterCreateNote={afterCreateNote}
                  afterDeleteNote={afterDeleteNote}
                />
              ),
            },
          ]
        }}
        renderDetailPanel={params => {
          if (typeof renderDetailPanel === 'function') {
            return renderDetailPanel(params)
          }
          const filteredScheduleLoads = scheduleLoads.filter(
            ({ scheduleId }) => params.row.original.id === scheduleId,
          )

          return (
            <ScheduleLoadsTable
              scheduleLoads={filteredScheduleLoads}
              afterUpdate={afterUpdateScheduleLoad}
            />
          )
        }}
        onCellEditEnd={onCellEditEnd}
        onColumnFiltersChange={onColumnFiltersChange}
        onSortingChange={onSortingChange}
      />
      <GraphChartOrderTrackingDetailDialog
        {...lineGraphState}
        onClose={onCloseScheduleLineGraph}
      />
      <OrderFormDialog
        isOpen={isOpenOrderForm}
        onClose={onCloseOrderForm}
        orderData={selectedSchedule?.order}
        defaultTab={defaultTab}
        afterUpdate={afterUpdateOrder}
      />
      <OrderExtrasDialog
        isOpen={orderExtrasDialog.isOpen}
        onClose={onCloseOrderExtrasDialog}
        orderId={orderExtrasDialog.orderId}
      />
    </>
  )
}
export default SchedulesTable

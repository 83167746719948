import { useMemo } from 'react'

import {
  CheckMarkIcon,
  CloseIcon,
  CompanyInfo,
  IRTColumnDef,
  ToolTipOverlay,
} from '~/components/shared'
import { ISellerProduct } from '~/types/models/ISellerProduct'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import {
  PRODUCT_MATERIAL_TYPE_OPTIONS,
  PRODUCT_QTY_TYPE_OPTIONS,
  PRODUCT_USAGE_OPTIONS,
} from '~/utils/constants'
import {
  EQtyType,
  ESellerProductMaterialType,
} from '~/types/enums/ESellerProduct'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { ISellerTerminalProduct } from '~/types/models/ISellerTerminalProduct'
import { Badge } from 'react-bootstrap'
import { ITerminal } from '~/types/models/ITerminal'

type IProps = {
  sellerTerminalProductsData: ISellerTerminalProduct[]
  terminalsData: ITerminal[]
}

const useTableCompanySellerProductSubTab = (props: IProps) => {
  const { sellerTerminalProductsData, terminalsData } = props

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const columns = useMemo<IRTColumnDef<ISellerProduct>[]>(
    () => [
      {
        header: 'Seller',
        accessorKey: 'sellerId',
        size: 250,
        filterVariant:
          currentScope === EScope.buyer ? EFieldType.multipleSelect : undefined,
        Cell() {
          return (
            <CompanyInfo
              companyType={EScope.seller}
              defaultCompanyCode='Seller'
              company={{
                name: currentCompany?.name,
                code: currentCompany?.code,
                value: currentCompany?.id,
                image: currentCompany?.logo,
                label: currentCompany
                  ? `${currentCompany.code} - ${currentCompany.name}`
                  : 'Unknown',
              }}
            />
          )
        },
      },
      {
        header: 'Name',
        accessorKey: 'name',
        size: 250,
        enableEditing: true,
      },
      {
        header: 'Code',
        accessorKey: 'code',
        enableEditing: true,
      },
      {
        header: 'Description',
        accessorKey: 'description',
        enableEditing: true,
        size: 350,
      },
      {
        header: 'Product',
        id: 'productId',
        size: 200,
        Cell({ cell, table }) {
          const { data } = table.options
          const value = cell.getValue<number>()

          const sellerProduct = data.find(({ id }) => id === value)
          return [sellerProduct?.code, sellerProduct?.name]
            .filter(text => text)
            .join(' - ')
        },
      },
      {
        header: 'Material Type',
        accessorKey: 'materialType',
        filterSelectOptions: PRODUCT_MATERIAL_TYPE_OPTIONS,
        filterVariant: EFieldType.multipleSelect,
        Cell({ cell }) {
          const cellValue = cell.getValue<ESellerProductMaterialType>()
          const item = PRODUCT_MATERIAL_TYPE_OPTIONS.find(
            ({ value }) => value === cellValue,
          )
          return item?.label
        },
      },
      {
        header: 'Qty Type',
        accessorKey: 'qtyType',
        filterSelectOptions: PRODUCT_QTY_TYPE_OPTIONS,
        filterVariant: EFieldType.multipleSelect,
        Cell({ cell }) {
          const cellValue = cell.getValue<EQtyType>()

          const item = PRODUCT_QTY_TYPE_OPTIONS.find(
            ({ value }) => value === cellValue,
          )
          return item?.label
        },
      },
      {
        header: 'Product Usage',
        accessorKey: 'productUsage',
        filterSelectOptions: PRODUCT_USAGE_OPTIONS,
        filterVariant: EFieldType.multipleSelect,
        size: 150,
        Cell({ cell }) {
          const cellValue = cell.getValue<string>()
          const item = PRODUCT_USAGE_OPTIONS.find(
            ({ value }) => value === cellValue,
          )
          return item?.label
        },
      },
      {
        header: 'Standard Product',
        accessorKey: 'standardProduct',
        size: 180,
        Cell({ cell }) {
          const value = cell.getValue<boolean | null>()
          if (value) {
            return (
              <CheckMarkIcon size='14px' color='var(--ion-color-success)' />
            )
          }
          return <CloseIcon size='14px' color='var(--ion-color-danger)' />
        },
      },
      {
        header: 'Terminals',
        accessorKey: 'allowFromAllTerminals',
        size: 200,
        Cell({ cell, row }) {
          const value = cell.getValue<boolean | null>()
          const sTerminals = sellerTerminalProductsData.filter(
            ({ sellerProductId }) => sellerProductId === row.original.id,
          )
          const elements = sTerminals.map(item => {
            const terminal = terminalsData.find(
              ({ id }) => id === item.terminalId,
            )
            return (
              <ToolTipOverlay
                content={terminal?.name}
                placement='top'
                key={item.id}
              >
                <Badge bg='secondary' className='SellerProducts__terminalBadge'>
                  {terminal?.code}
                </Badge>
              </ToolTipOverlay>
            )
          })

          return (
            <div>
              {value && (
                <Badge bg='danger' className='SellerProducts__terminalBadge'>
                  All
                </Badge>
              )}
              {sTerminals.length > 0 ? (
                <Badge bg='warning' className='SellerProducts__terminalBadge'>
                  Total: {sTerminals.length}
                </Badge>
              ) : null}
              {elements}
            </div>
          )
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 100,
      },
    ],
    [currentCompany, currentScope, sellerTerminalProductsData, terminalsData],
  )

  return { columns }
}

export default useTableCompanySellerProductSubTab

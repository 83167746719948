import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  useQueryAddresses,
  useQueryDriversNew,
  useQueryTerminals,
} from '~/hooks/useQueryData'

import {
  ConcordFormStructure,
  CommonDialogV2,
  ConcordFormAddressInput,
  AddressForm,
  ConcordFormDropdownV2,
  ConcordFormRadio,
  ConcordDropdownV2WithFormControl,
} from '~/components/shared'
import { Alert } from 'react-bootstrap'

import { toast } from 'react-toastify'
import * as Yup from 'yup'
import moment from 'moment'
import _ from 'lodash'
import { toastMessages } from '~/constants/toast-status-text'
import clsx from 'clsx'
import { apiClient } from '~/api/ApiClient'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { ADDRESSABLE_TYPE, isStartWithEmp } from '~/utils/constants'

import './DialogCreateCheckEmployee.scss'
import { EWorkerTaxType } from '~/types/enums/EWorker'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { produce } from 'immer'
import areAddressesEqual from '~/utils/areAddressesEqual'
import { EAddressableType } from '~/types/enums/EAddress'
import { closeOutline, pencilOutline } from 'ionicons/icons'
import buildFullAddress from '~/utils/buildFullAddress'
import getAddressTypesText from '~/utils/getAddressTypes'

function DialogCreateCheckEmployee(props) {
  const { userData, afterCreateCheckEmployee, ...dialogProps } = props

  const worker = userData?.worker

  const [isCreatingAddress, setIsCreatingAddress] = useState(false)
  const [selectedCurrentAddressId, setSelectedCurrentAddressId] = useState(null)
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false)

  const formRef = useRef()

  const isEmployee = useMemo(
    () => isStartWithEmp(userData?.worker?.checkUid),
    [userData?.worker?.checkUid],
  )

  const currentCompany = useSelector(selectMyCurrentCompany)

  const { terminalsHavingCheckUid } = useQueryTerminals({
    filters: {
      companyId: currentCompany?.id,
    },
  })

  const workplaceOptions = useMemo(
    () =>
      terminalsHavingCheckUid.map(({ code, name, checkUid }) => ({
        value: checkUid,
        label: `${code} - ${name}`,
      })),
    [terminalsHavingCheckUid],
  )

  const personData = useMemo(() => userData?.person, [userData?.person])

  const {
    addressesData,
    addressOptions,
    residenceAddress,
    addAddress,
    refetchQueryAddresses,
  } = useQueryAddresses(
    {
      filters: {
        addressableType: EAddressableType.person,
        addressableId: personData?.id,
      },
    },
    { enabled: Boolean(personData?.id) },
  )

  const { stateOptions, stringStatesData } = useQueryDriversNew()

  const selectedCurrentAddress = useMemo(() => {
    if (selectedCurrentAddressId) {
      return addressesData.find(({ id }) => selectedCurrentAddressId === id)
    }
    return undefined
  }, [addressesData, selectedCurrentAddressId])

  const workerTaxType = useMemo(
    () => userData?.worker?.taxType || EWorkerTaxType.employee,
    [userData?.worker?.taxType],
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const defaultValues = useMemo(
    () => ({
      company: '',
      dob: null,
      firstName: '',
      lastName: '',
      residence: {},
      startDate: null,
      workplaces: [],
      primaryWorkplace: null,
      email: '',
    }),
    [],
  )
  const [taxType, setTaxType] = useState(workerTaxType)

  const IS_WORKER_CONTRACTOR = useMemo(
    () => workerTaxType === EWorkerTaxType.contractor,
    [workerTaxType],
  )

  const isContractor = useMemo(
    () => taxType === EWorkerTaxType.contractor,
    [taxType],
  )

  const isUpdating = useMemo(
    () => Boolean(userData?.worker?.checkUid),
    [userData?.worker?.checkUid],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        firstName: Yup.string().required('This field is required!'),
        lastName: Yup.string().required('This field is required!'),
        dob: Yup.string()
          .required('This field is required!')
          .transform(value => {
            return value ? moment(value).format('YYYY-MM-DD') : ''
          }),
        startDate: Yup.string()
          .required('This field is required!')
          .transform(value => {
            return value ? moment(value).format('YYYY-MM-DD') : ''
          }),
        terminationDate: Yup.string()
          .transform(value => {
            return value ? moment(value).format('YYYY-MM-DD') : null
          })
          .nullable(),
        residence: Yup.object({
          line1: Yup.string().required('This field is required!'),
          line2: Yup.string().nullable(),
          city: Yup.string().required('This field is required!'),
          state: Yup.lazy(val =>
            Yup.string()
              .required('This field is required!')
              .oneOf(stringStatesData, `${val} is not a valid option`),
          ),
          postalCode: Yup.string().required('This field is required!'),
          country: Yup.string().required('This field is required!'),
        }),
        businessName: Yup.lazy(() => {
          // if (isContractor) {
          //   return Yup.string()
          //     .required('This field is required!')
          //     .typeError('This field is required!')
          // }
          return Yup.string().nullable()
        }),
        ein: Yup.lazy(() => {
          // if (isContractor) {
          //   return Yup.string()
          //     .required('This field is required!')
          //     .typeError('This field is required!')
          // }
          return Yup.string().nullable()
        }),
        workplaces: Yup.array().min(1, 'At least one workplace is present!'),
        primaryWorkplace: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        email: Yup.string().email().nullable(),
      }),
    [stringStatesData],
  )

  const onToggleCreateAddress = useCallback(() => {
    setIsCreatingAddress(prev => !prev)
  }, [])

  const onChangeTaxType = useCallback((event, value) => {
    setTaxType(value)
  }, [])

  const fields = useMemo(
    () => [
      {
        label: 'Tax Type: ',
        name: 'taxType',
        isHidden: isUpdating,
        render({ label, name }) {
          return (
            <ConcordFormRadio
              label={label}
              name={name}
              value={taxType}
              onChange={onChangeTaxType}
              options={[
                {
                  label: 'Employee',
                  value: EWorkerTaxType.employee,
                },
                {
                  label: 'Contractor',
                  value: EWorkerTaxType.contractor,
                },
              ]}
            />
          )
        },
      },
      {
        label: 'First name',
        name: 'firstName',
        size: 6,
        isRequired: true,
      },
      {
        label: 'Last name',
        name: 'lastName',
        size: 6,
        isRequired: true,
      },
      {
        label: 'Email',
        name: 'email',
        size: 6,
      },
      {
        label: 'Date of birth',
        name: 'dob',
        size: 6,
        type: EFieldType.date,
        isRequired: true,
        maxDate: moment().format('YYYY-MM-DD'),
      },
      {
        label: 'EIN',
        name: 'ein',
        size: 6,
        isHidden: !isContractor,
      },
      {
        label: 'Business name',
        name: 'businessName',
        size: 6,
        isHidden: !isContractor,
      },
      {
        label: 'Start date',
        name: 'startDate',
        size: 6,
        isRequired: true,
        type: EFieldType.date,
        minDate: moment().format('YYYY-MM-DD'),
      },
      {
        label: 'End date',
        name: 'terminationDate',
        size: 6,
        type: EFieldType.date,
      },
      {
        label: 'Primary Workplace',
        name: 'primaryWorkplace',
        size: 12,
        isRequired: true,
        type: EFieldType.singleSelect,
        options: workplaceOptions,
        render({ label, control, name, watch, setValue }) {
          const workplaces = watch('workplaces', [])
          return (
            <ConcordDropdownV2WithFormControl
              isRequired
              label={label}
              control={control}
              name={name}
              options={workplaceOptions}
              onChange={({ value }) => {
                const newWorkplaces = produce(workplaces, draft => {
                  const index = draft.indexOf(value)
                  if (index === -1) {
                    draft.push(value)
                  }
                })
                setValue('workplaces', newWorkplaces)
                setValue('primaryWorkplace', value)
              }}
            />
          )
        },
      },
      {
        label: 'Workplaces',
        name: 'workplaces',
        size: 12,
        isRequired: true,
        type: EFieldType.multipleSelect,
        options: workplaceOptions,
        isClearable: false,
        styles: ({ watch }) => {
          const primaryWorkplace = watch('primaryWorkplace', '')
          return {
            multiValueRemove: (provided, { data }) => {
              const isDisabled =
                data.value === primaryWorkplace ? 'none' : 'block'
              return { ...provided, display: isDisabled }
            },
          }
        },
      },
      {
        label: "Worker's addresses",
        name: 'currentAddress',
        size: 12,
        isHidden: isUpdatingAddress,
        render({ label, setValue }) {
          return (
            <ConcordFormDropdownV2
              label={label}
              isHiddenCreateIcon={false}
              onClickCreateIcon={onToggleCreateAddress}
              options={addressOptions}
              value={selectedCurrentAddressId}
              hint={
                selectedCurrentAddress
                  ? `Type: ${getAddressTypesText(selectedCurrentAddress)}`
                  : ''
              }
              extraIcons={[
                {
                  icon: isUpdatingAddress ? closeOutline : pencilOutline,
                  color: isUpdatingAddress ? 'danger' : 'fleet',
                  isHidden: addressesData.length === 0,
                  tooltipProps: {
                    content: isUpdatingAddress ? 'Close' : 'Edit address',
                    placement: 'top',
                  },
                  onClick() {
                    setIsUpdatingAddress(true)
                  },
                },
              ]}
              onChange={(event, { selectedOption, value }) => {
                const { address } = selectedOption
                setSelectedCurrentAddressId(value)
                setIsUpdatingAddress(false)
                console.log('address', address)
                setValue('residence', {
                  line1: address.street,
                  city: address.city,
                  state: address.state,
                  postalCode: address.zip,
                  country: address.country,
                })
              }}
            />
          )
        },
      },
      {
        label: 'Search address',
        name: 'searchAddress',
        size: 12,
        isHidden: !isUpdatingAddress,
        render({ label, setValue }) {
          return (
            <ConcordFormAddressInput
              label={label}
              extraIcons={[
                {
                  icon: closeOutline,
                  color: 'danger',
                  onClick() {
                    if (selectedCurrentAddress) {
                      formRef.current?.setValue('residence', {
                        line1: selectedCurrentAddress.street,
                        city: selectedCurrentAddress.city,
                        state: selectedCurrentAddress.state,
                        postalCode: selectedCurrentAddress.zip,
                        country: selectedCurrentAddress.country,
                      })
                    }
                    setIsUpdatingAddress(false)
                  },
                },
              ]}
              onChange={(event, { address }) => {
                const { city, country, postalCode, state, street } = address
                setValue('residence', {
                  line1: street,
                  city,
                  state,
                  postalCode,
                  country,
                })
              }}
            />
          )
        },
      },
      {
        label: '',
        name: 'createNewAddress',
        isHidden: !isCreatingAddress,
        render({ setValue }) {
          return (
            <div className='CreateCheckEmployeeDialog__addressForm'>
              <AddressForm
                isHiddenSearch
                submitText='Create'
                cancelText='Close'
                addressableType={ADDRESSABLE_TYPE.driver}
                addressableId={personData?.id}
                addressData={addressesData}
                onCancel={onToggleCreateAddress}
                afterCreate={address => {
                  setIsCreatingAddress(false)
                  addAddress(address)
                  console.log('address', address)
                  setSelectedCurrentAddressId(address.id)
                  setValue('residence', {
                    line1: address.street,
                    city: address.city,
                    state: address.state,
                    postalCode: address.zip,
                    country: address.country,
                  })
                  setIsUpdatingAddress(false)
                }}
              />
            </div>
          )
        },
      },
      {
        label: 'Line 1',
        name: 'residence.line1',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
      },
      {
        label: 'Line 2',
        name: 'residence.line2',
        size: 6,
        isDisabled: !isUpdatingAddress,
      },
      {
        label: 'City',
        name: 'residence.city',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
      },
      {
        label: 'State',
        name: 'residence.state',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
        type: EFieldType.singleSelect,
        options: stateOptions,
      },
      {
        label: 'Postal Code',
        name: 'residence.postalCode',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
      },
      {
        label: 'Country',
        name: 'residence.country',
        size: 6,
        isRequired: true,
        isDisabled: !isUpdatingAddress,
      },
    ],
    [
      isUpdating,
      isContractor,
      workplaceOptions,
      isUpdatingAddress,
      isCreatingAddress,
      stateOptions,
      taxType,
      onChangeTaxType,
      onToggleCreateAddress,
      addressOptions,
      selectedCurrentAddressId,
      selectedCurrentAddress,
      addressesData,
      personData?.id,
      addAddress,
    ],
  )

  const fetchSetupEmployee = useCallback(async () => {
    setLoading(true)
    try {
      const response = await apiClient.check.setupWorker({
        workerId: worker?.id,
      })
      const payload = _.pick(response, [
        'dob',
        'firstName',
        'lastName',
        'startDate',
        'company',
        'email',
        'terminationDate',
      ])
      if (residenceAddress) {
        payload.residence = {
          city: residenceAddress.city,
          country: residenceAddress.country,
          line1: residenceAddress.street,
          postalCode: residenceAddress.zip,
          state: residenceAddress.state,
        }
        setSelectedCurrentAddressId(residenceAddress.id)
        setIsUpdatingAddress(false)
      }
      if (response.workplaces?.length === 1) {
        payload.primaryWorkplace = response.workplaces[0]
        payload.workplaces = response.workplaces
      }
      setTaxType(workerTaxType)
      if (IS_WORKER_CONTRACTOR) {
        payload.businessName = worker.businessName
        payload.ein = worker.ein
      }
      formRef.current?.reset(payload)
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }, [
    IS_WORKER_CONTRACTOR,
    worker?.businessName,
    worker?.ein,
    worker?.id,
    workerTaxType,
    residenceAddress,
  ])

  const updateCheckEmployee = useCallback(
    async formValues => {
      let res
      if (isEmployee) {
        res = await apiClient.check.updateWorker(worker.checkUid, {
          worker: formValues,
        })
      } else {
        const payload = _.omit(formValues, 'residence')
        payload.address = formValues.residence
        const type =
          formValues.businessName && formValues.ein ? 'business' : 'individual'
        res = await apiClient.check.updateContractor(worker.checkUid, {
          contractor: {
            ...payload,
            type,
          },
          workerId: worker.id,
        })
      }
      if (res.id) {
        afterCreateCheckEmployee && afterCreateCheckEmployee({ worker: res })
      } else {
        const error =
          _.get(res.error, 'inputErrors[0].message') || res.error?.message
        setError(error)
      }
    },
    [afterCreateCheckEmployee, isEmployee, worker?.checkUid, worker?.id],
  )

  const createContractor = useCallback(
    async formValues => {
      const type =
        formValues.businessName && formValues.ein ? 'business' : 'individual'
      const response = await apiClient.check.createContractor({
        workerId: worker?.id,
        contractor: {
          ...formValues,
          type,
          address: formValues.residence,
          workerId: worker?.id,
        },
      })
      if (response.id) {
        afterCreateCheckEmployee &&
          afterCreateCheckEmployee({
            worker: {
              ...worker,
              checkUid: response.id,
              taxType: EWorkerTaxType.contractor,
            },
          })
      } else {
        const error =
          _.get(response.error, 'inputErrors[0].message') ||
          response.error?.message
        setError(error)
      }
    },
    [afterCreateCheckEmployee, worker],
  )

  const createEmployee = useCallback(
    async formValues => {
      const payload = _.omit(formValues, ['businessName', 'ein'])
      const response = await apiClient.check.createWorker({
        workerId: worker?.id,
        worker: {
          ...payload,
        },
      })

      if (response.id) {
        afterCreateCheckEmployee &&
          afterCreateCheckEmployee({
            worker: { ...response, taxType: EWorkerTaxType.employee },
          })
      } else {
        const error =
          _.get(response.error, 'inputErrors[0].message') ||
          response.error?.message
        setError(error)
      }
    },
    [afterCreateCheckEmployee, worker?.id],
  )

  const createCheckEmployee = useCallback(
    async formValues => {
      setLoading(true)
      try {
        if (isContractor) {
          await createContractor(formValues)
        } else {
          await createEmployee(formValues)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.createError)
      } finally {
        setLoading(false)
      }
    },
    [createContractor, createEmployee, isContractor],
  )

  const createResidenceAddress = useCallback(
    formValues => {
      const { residence } = formValues
      const payload = {
        street: residence.line1,
        city: residence.city,
        state: residence.state,
        zip: residence.postalCode,
        country: residence.country,
        addressTypes: ['residence'],
        addressableType: EAddressableType.person,
        addressableId: personData.id,
      }
      return apiClient.addresses.create(payload)
    },
    [personData?.id],
  )

  const updateAddress = useCallback(
    formValues => {
      if (selectedCurrentAddressId) {
        const { residence } = formValues
        return apiClient.addresses.update(selectedCurrentAddressId, {
          street: residence.line1,
          city: residence.city,
          state: residence.state,
          zip: residence.postalCode,
          country: residence.country,
        })
      }
      return Promise.resolve()
    },
    [selectedCurrentAddressId],
  )

  const createOrUpdateResidenceAddress = useCallback(
    async formValues => {
      if (selectedCurrentAddress) {
        if (
          !areAddressesEqual(
            {
              ...selectedCurrentAddress,
              postalCode: selectedCurrentAddress.zip,
              line1: selectedCurrentAddress.street,
            },
            formValues.residence,
          )
        ) {
          await updateAddress(formValues)
        }
      } else {
        await createResidenceAddress(formValues)
      }
      refetchQueryAddresses()
    },
    [
      createResidenceAddress,
      refetchQueryAddresses,
      selectedCurrentAddress,
      updateAddress,
    ],
  )

  const updateWorker = useCallback(
    formValues => {
      const {
        startDate,
        terminationDate,
        dob,
        email,
        firstName,
        lastName,
        primaryWorkplace,
        businessName,
        ein,
      } = formValues
      const workerPayload = [
        {
          name: 'startDate',
          value: startDate,
        },
        {
          name: 'endDate',
          value: terminationDate
            ? moment(terminationDate).format('YYYY-MM-DD')
            : undefined,
        },
        {
          name: 'terminalId',
          value: terminalsHavingCheckUid.find(
            ({ checkUid }) => checkUid === primaryWorkplace,
          )?.id,
        },
        {
          name: 'businessName',
          value: businessName,
        },
        {
          name: 'ein',
          value: ein,
        },
        {
          name: 'taxType',
          value: taxType,
        },
      ]
      const personPayload = [
        {
          name: 'dob',
          value: dob,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'firstName',
          value: firstName,
        },
        {
          name: 'lastName',
          value: lastName,
        },
      ]
      const mapWorkerPayload = {}
      const mapPersonPayload = {}
      workerPayload.forEach(({ name, value }) => {
        mapWorkerPayload[name] = value
      })
      personPayload.forEach(({ name, value }) => {
        mapPersonPayload[name] = value
      })
      return Promise.all([
        apiClient.workers.update(worker.id, mapWorkerPayload),
        apiClient.people.update(userData?.person?.id, mapPersonPayload),
      ])
    },
    [taxType, terminalsHavingCheckUid, worker?.id, userData?.person?.id],
  )

  const handleSubmitForm = useCallback(
    async formValues => {
      setLoading(true)
      try {
        setError('')
        await createOrUpdateResidenceAddress(formValues)
        await updateWorker(formValues)
        if (isUpdating) {
          await updateCheckEmployee(formValues)
        } else {
          await createCheckEmployee(formValues)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    },
    [
      createCheckEmployee,
      createOrUpdateResidenceAddress,
      isUpdating,
      updateCheckEmployee,
      updateWorker,
    ],
  )

  const retrieveWorker = useCallback(async () => {
    setLoading(true)
    try {
      let res
      if (isEmployee) {
        res = await apiClient.check.getWorkerByUid(worker?.checkUid)
      } else {
        res = await apiClient.check.getContractorByUid(worker?.checkUid)
      }
      const payload = _.pick(res, [
        'lastName',
        'firstName',
        'dob',
        'startDate',
        'residence',
        'company',
        'address',
        'primaryWorkplace',
        'workplaces',
        'email',
        'terminationDate',
      ])
      if (!isEmployee) {
        payload.residence = payload.address
      }

      const fullEmployeeResidence = buildFullAddress(payload.residence)

      const findWorkerAddress = addressesData.find(address => {
        const fullWorkerAddress = buildFullAddress(address)
        return fullEmployeeResidence === fullWorkerAddress
      })
      if (findWorkerAddress) {
        setSelectedCurrentAddressId(findWorkerAddress.id)
      }

      setTaxType(workerTaxType)
      if (IS_WORKER_CONTRACTOR) {
        payload.businessName = res.businessName
        payload.ein = res.ein
      }
      formRef.current?.reset(payload)
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }, [
    isEmployee,
    addressesData,
    workerTaxType,
    IS_WORKER_CONTRACTOR,
    worker?.checkUid,
  ])

  useEffect(() => {
    if (dialogProps.isOpen) {
      if (worker?.checkUid) {
        retrieveWorker()
      } else {
        fetchSetupEmployee()
      }
    } else {
      formRef.current?.reset()
      setTaxType(workerTaxType)
      setSelectedCurrentAddressId(null)
      setIsCreatingAddress(false)
      setIsUpdatingAddress(false)
      setError('')
    }
  }, [
    dialogProps.isOpen,
    fetchSetupEmployee,
    retrieveWorker,
    worker?.checkUid,
    workerTaxType,
  ])

  return (
    <CommonDialogV2
      {...dialogProps}
      isLoading={loading}
      title='Connect Worker to Check Payroll'
      className={clsx('DialogCreateCheckEmployee__root', { error })}
      isHiddenOkButton
    >
      {error && (
        <Alert style={{ margin: 8, fontSize: 16 }} variant='danger'>
          {error}
        </Alert>
      )}
      <ConcordFormStructure
        isHiddenSearch
        isHiddenCancelButton
        fields={fields}
        schema={schema}
        onSubmit={handleSubmitForm}
        isLoading={loading}
        defaultValues={defaultValues}
        ref={formRef}
      />
    </CommonDialogV2>
  )
}

export default DialogCreateCheckEmployee

import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetWorkPayRulesQueryParams,
  IWorkPayRule,
} from '~/types/models/IWorkPayRule'

const useModifyWorkPayRules = (params: IGetWorkPayRulesQueryParams = {}) => {
  const queryClient = useQueryClient()
  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'workPayRules',
      buildGetUrl(apiClient.workPayRules.endpoint, params),
      sessionUser.id,
    ],
    [params, sessionUser.id],
  )

  const createWorkPayRule = useCallback(
    (data: IWorkPayRule) => {
      queryClient.setQueryData<IWorkPayRule[] | undefined>(
        queryKey,
        oldData => {
          if (oldData) {
            return [...oldData, data]
          }
          return [data]
        },
      )
    },
    [queryClient, queryKey],
  )

  const updateWorkPayRule = useCallback(
    (id: number, data: Partial<IWorkPayRule>) => {
      queryClient.setQueryData<IWorkPayRule[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (index !== -1) {
              draft[index] = {
                ...draft[index],
                ...data,
              }
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const deleteWorkPayRule = useCallback(
    (id: number) => {
      queryClient.setQueryData<IWorkPayRule[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            draft.splice(index, 1)
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return { createWorkPayRule, updateWorkPayRule, deleteWorkPayRule }
}

export default useModifyWorkPayRules

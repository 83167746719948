import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { IAutoExtra, IGetAutoExtrasParams } from '~/types/models/IAutoExtra'
import useModifyAutoExtras from './useModifyAutoExtras'

const useQueryAutoExtras = (
  params: IGetAutoExtrasParams = {},
  options?: Partial<UseQueryOptions<IAutoExtra[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'autoExtras',
      sessionUser?.id,
      buildGetUrl(apiClient.autoExtras.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.autoExtras.get(params)
      return response.autoExtras
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // 1 min
    ...options,
  })

  const autoExtras = useMemo(() => data || [], [data])

  const getAutoExtraLabel = (autoExtra: IAutoExtra | undefined) => {
    if (autoExtra) {
      return `${autoExtra.startTime || '...'} → ${autoExtra.endTime || '...'}`
    }

    return ''
  }

  const autoExtraOptions = autoExtras.map(item => ({
    value: item.id,
    label: getAutoExtraLabel(item),
    sellerProductId: item.sellerProductId,
  }))

  const findAutoExtraById = (id: number) => autoExtras.find(i => i.id === id)

  const { addAutoExtra, updateAutoExtra, removeAutoExtra } =
    useModifyAutoExtras(params)

  return {
    autoExtras,
    isLoadingAutoExtras: isLoading,
    autoExtraOptions,
    addAutoExtra,
    updateAutoExtra,
    removeAutoExtra,
    findAutoExtraById,
    getAutoExtraLabel,
  }
}

export default useQueryAutoExtras

import { DragEvent, memo, useMemo, useRef } from 'react'
import type { VirtualItem } from '@tanstack/react-virtual'
import { RTTableBodyCell, MemoRTTableBodyCell } from '../RTTableBodyCell'
import { RTTableDetailPanel } from '../RTTableDetailPanel'
import type {
  RTCell,
  RTColumnVirtualizer,
  RTRow,
  IReusableTableRowData,
  RTRowVirtualizer,
  ReusableTableInstance,
  RTVirtualItem,
} from '../../../types'
import { getIsRowSelected } from '../../../utils/row.utils'

import './styles.scss'
import clsx from 'clsx'
import { DropTargetMonitor, useDrop } from 'react-dnd'

export interface IRTTableBodyRowProps<TData extends IReusableTableRowData> {
  columnVirtualizer?: RTColumnVirtualizer
  numRows?: number
  pinnedRowIds?: string[]
  row: RTRow<TData>
  rowVirtualizer?: RTRowVirtualizer
  staticRowIndex: number
  table: ReusableTableInstance<TData>
  virtualRow?: VirtualItem<any>
}

const RTTableBodyRow = <TData extends IReusableTableRowData>({
  columnVirtualizer,
  numRows,
  pinnedRowIds,
  row,
  rowVirtualizer,
  staticRowIndex,
  table,
  virtualRow,
  ...rest
}: IRTTableBodyRowProps<TData>) => {
  const {
    getState,
    options: {
      enableRowOrdering,
      enableRowPinning,
      enableStickyFooter,
      enableStickyHeader,
      enableOuterDropping,
      layoutMode,
      memoMode,
      renderDetailPanel,
      rowPinningDisplayMode,
      getRowClassName,
    },
    refs: { tableFooterRef, tableHeadRef },
    setHoveredRow,
  } = table
  const {
    density,
    draggingColumn,
    draggingRow,
    editingCell,
    editingRow,
    hoveredRow,
    isFullScreen,
    rowPinning,
    outerDroppingTypesAccepted,
  } = getState()

  const visibleCells = row.getVisibleCells()

  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: outerDroppingTypesAccepted || [],
    collect: (monitor: DropTargetMonitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: () => ({ row }),
    canDrop: () =>
      typeof enableOuterDropping === 'function'
        ? enableOuterDropping(row)
        : Boolean(enableOuterDropping),
  })

  const border = useMemo(() => {
    if (canDrop && isOver) return '2px dashed #2dd36f'
    if (canDrop) return '2px dashed #e5e5e5'
  }, [canDrop, isOver])

  const { virtualColumns, virtualPaddingLeft, virtualPaddingRight } =
    columnVirtualizer ?? {}

  const isRowSelected = getIsRowSelected({ row, table })
  const isRowPinned = enableRowPinning && row.getIsPinned()
  const isDraggingRow = draggingRow?.id === row.id
  const isHoveredRow = hoveredRow?.id === row.id

  const rowClassName =
    typeof getRowClassName === 'function'
      ? getRowClassName({
          row,
          table,
        })
      : ''

  const [bottomPinnedIndex, topPinnedIndex] = useMemo(() => {
    if (
      !enableRowPinning ||
      !rowPinningDisplayMode?.includes('sticky') ||
      !pinnedRowIds ||
      !row.getIsPinned()
    )
      return []
    return [
      [...pinnedRowIds].reverse().indexOf(row.id),
      pinnedRowIds.indexOf(row.id),
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinnedRowIds, rowPinning])

  const tableHeadHeight =
    ((enableStickyHeader || isFullScreen) &&
      tableHeadRef.current?.clientHeight) ||
    0
  const tableFooterHeight =
    (enableStickyFooter && tableFooterRef.current?.clientHeight) || 0

  const defaultRowHeight =
    density === 'compact' ? 37 : density === 'comfortable' ? 53 : 69

  const customRowHeight =
    parseInt((rest as any)?.style?.height, 10) || undefined

  const rowHeight = customRowHeight || defaultRowHeight

  const handleDragEnter = (_e: DragEvent) => {
    if (enableRowOrdering && draggingRow) {
      setHoveredRow(row)
    }
  }

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault()
  }

  const rowRef = useRef<HTMLTableRowElement | null>(null)

  return (
    <>
      <tr
        data-index={renderDetailPanel ? staticRowIndex * 2 : staticRowIndex}
        data-pinned={!!isRowPinned || undefined}
        data-selected={isRowSelected || undefined}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        ref={(node: HTMLTableRowElement) => {
          if (node) {
            rowRef.current = node
            rowVirtualizer?.measureElement(node)
          }
          dropRef(node)
        }}
        {...rest}
        style={{
          backgroundColor: `${'green'} !important`,
          bottom:
            !virtualRow && bottomPinnedIndex !== undefined && isRowPinned
              ? `${
                  bottomPinnedIndex * rowHeight +
                  (enableStickyFooter ? tableFooterHeight - 1 : 0)
                }px`
              : undefined,
          boxSizing: 'border-box',
          display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
          opacity: isRowPinned ? 0.97 : isDraggingRow || isHoveredRow ? 0.5 : 1,
          border,
          position: virtualRow
            ? 'absolute'
            : rowPinningDisplayMode?.includes('sticky') && isRowPinned
            ? 'sticky'
            : 'relative',
          // td: {
          //   ...getCommonPinnedCellStyles({ table }),
          // },
          // "td:after": cellHighlightColor
          //   ? {
          //       backgroundColor: cellHighlightColor,
          //       ...commonCellBeforeAfterStyles,
          //     }
          //   : undefined,
          top: virtualRow
            ? 0
            : topPinnedIndex !== undefined && isRowPinned
            ? `${
                topPinnedIndex * rowHeight +
                (enableStickyHeader || isFullScreen ? tableHeadHeight - 1 : 0)
              }px`
            : undefined,
          transition: virtualRow ? 'none' : 'all 150ms ease-in-out',
          width: '100%',
          zIndex:
            rowPinningDisplayMode?.includes('sticky') && isRowPinned ? 2 : 0,

          transform: virtualRow
            ? `translateY(${virtualRow.start}px)`
            : undefined,
          ...(rest as any)?.style,
        }}
        className={clsx('RTTableBodyRow__container', rowClassName, {
          isRowSelected,
        })}
      >
        {virtualPaddingLeft ? (
          <td style={{ display: 'flex', width: virtualPaddingLeft }} />
        ) : null}
        {(virtualColumns ?? visibleCells).map(
          (cellOrVirtualCell, staticColumnIndex) => {
            let cell = cellOrVirtualCell as RTCell<TData>
            if (columnVirtualizer) {
              staticColumnIndex = (cellOrVirtualCell as RTVirtualItem).index
              cell = visibleCells[staticColumnIndex]
            }
            const props = {
              cell,
              numRows,
              rowRef,
              staticColumnIndex,
              staticRowIndex,
              table,
            }
            return cell ? (
              memoMode === 'cells' &&
              cell.column.columnDef.columnDefType === 'data' &&
              !draggingColumn &&
              !draggingRow &&
              editingCell?.id !== cell.id &&
              editingRow?.id !== row.id ? (
                <MemoRTTableBodyCell key={cell.id} {...props} />
              ) : (
                <RTTableBodyCell key={cell.id} {...props} />
              )
            ) : null
          },
        )}
        {virtualPaddingRight ? (
          <td style={{ display: 'flex', width: virtualPaddingRight }} />
        ) : null}
      </tr>
      {renderDetailPanel && !row.getIsGrouped() && (
        <RTTableDetailPanel
          parentRowRef={rowRef}
          row={row}
          rowVirtualizer={rowVirtualizer}
          staticRowIndex={staticRowIndex}
          table={table}
          virtualRow={virtualRow}
        />
      )}
    </>
  )
}

export const MemoRTTableBodyRow = memo(
  RTTableBodyRow,
  (prev, next) =>
    prev.row === next.row && prev.staticRowIndex === next.staticRowIndex,
) as typeof RTTableBodyRow

export default RTTableBodyRow

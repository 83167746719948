import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { ListGroup } from 'react-bootstrap'
import { IonIcon } from '@ionic/react'

import { getStatusesList, codedStatus } from '~/utils/loadUtils'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { selectUsersDriverFleet } from '~/redux/selectors'

import type { ILoadStatusModalProps } from './type'
import type { IDriverFleet } from '~/types/models/IDriverFleet'
import useGeolocation from '~/hooks/useGeolocation'
import { ILoadStatus } from '~/types/models/ILoad'

const useLoadStatusModal = (props: ILoadStatusModalProps) => {
  const { isOpen, onClose, loadData, onChangeLoadStatus } = props

  const driverFleet: IDriverFleet = useSelector(selectUsersDriverFleet)

  const { locationAttributes } = useGeolocation()

  const loadStatusSection = useMemo(
    () => getStatusesList(loadData.status),
    [loadData.status],
  )

  const getRankOfStatus = useCallback((statusName: string) => {
    const statusRanks = Object.values(codedStatus)
    return statusRanks.find(status => status.name === statusName)?.rank
  }, [])

  const onClickStatusItem = useCallback(
    (status: string) => () => {
      const loadStatusRowId = getRankOfStatus(status) as number
      apiClient.loadTimes.create({
        loadTime: {
          loadId: loadData.id,
          loadStatusRowId,
          time: new Date().toISOString(),
          breadcrumbLoadAttributes: {
            loadId: loadData.id,
            driverFleetId: driverFleet.id,
            truckFleetId:
              loadData.truckFleetId ||
              (driverFleet.currentTruckFleetId as number),
            locationAttributes: {
              eventType: status,
              source: 'Concord',
              lat: locationAttributes?.lat as number,
              lng: locationAttributes?.lng as number,
              time: locationAttributes?.time as string,
            },
          },
        },
      })
      onClose && onClose()
      onChangeLoadStatus &&
        onChangeLoadStatus(
          { ...loadData, status: status as ILoadStatus },
          status,
        )
      toast.success(toastMessages.updateSuccess)
    },
    [
      driverFleet?.currentTruckFleetId,
      driverFleet?.id,
      getRankOfStatus,
      loadData,
      locationAttributes?.lat,
      locationAttributes?.lng,
      locationAttributes?.time,
      onChangeLoadStatus,
      onClose,
    ],
  )

  const renderStatuses = useMemo(() => {
    if (loadStatusSection) {
      const { statuses, header } = loadStatusSection

      return (statuses as string[]).map((status, index) => (
        <ListGroup.Item
          key={index}
          action
          className='LoadStatusModal__statusItem'
          active={loadData.status === status}
          onClick={onClickStatusItem(status)}
        >
          <span className='icon'>
            <IonIcon icon={header.icon} />
          </span>
          <span>{status}</span>
        </ListGroup.Item>
      ))
    }
    return null
  }, [loadData.status, loadStatusSection, onClickStatusItem])

  return {
    isOpen,
    onClose,
    renderStatuses,
  }
}

export default useLoadStatusModal

import { useMemo, useEffect, useCallback, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  useModifySellerProducts,
  useQueryProductGroups,
  useQuerySellerProducts,
} from '~/hooks/useQueryData'

import {
  ConcordFormStructure,
  IConcordFormField,
  DialogSellerProductForm,
} from '~/components/shared'

import * as Yup from 'yup'
import { EFieldType, EStatus, EScope } from '~/types/enums/ECommonEnum'
import {
  selectCommonSellerOptions,
  selectCommonBuyerOptions,
  selectCurrentScope,
  // selectSellerProducts,
} from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'

import type { ICommonOption } from '~/types/models/ICommonModel'
import type { IBuyerSellerProductFormProps } from './type'
import type { ISellerProduct } from '~/types/models/ISellerProduct'
import type { IBuyerSellerProductFormValues } from '~/types/models/IBuyerSellerProduct'

import './styles.scss'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

const DEFAULT_VALUES: IBuyerSellerProductFormValues = {
  name: '',
  code: '',
  buyerSellerId: null,
  sellerProductId: null,
  productGroupId: null,
  price: null,
  status: EStatus.Active.toLowerCase() as Lowercase<EStatus>,
}

const BuyerSellerProductForm = (props: IBuyerSellerProductFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const [isOpenSellerProductDialog, setIsOpenSellerProductDialog] =
    useState(false)
  const [buyerSellerId, setBuyerSellerId] = useState<null | number>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const currentScope: EScope = useSelector(selectCurrentScope)
  const companySellerOptions: ICommonOption[] = useSelector(
    selectCommonSellerOptions,
  )
  const companyBuyerOptions: ICommonOption[] = useSelector(
    selectCommonBuyerOptions,
  )
  const companyOptions = useMemo(
    () =>
      currentScope === EScope.buyer
        ? companySellerOptions
        : companyBuyerOptions,
    [companyBuyerOptions, companySellerOptions, currentScope],
  )

  const { productGroupOptions } = useQueryProductGroups()
  const { sellerProductOptions, isLoadingSellerProducts } =
    useQuerySellerProducts(
      {
        filters: { buyerSellerId: [buyerSellerId as number] },
      },
      { enabled: Boolean(buyerSellerId) },
    )
  const { addSellerProduct } = useModifySellerProducts()

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'buyerSellerId',
        label: currentScope === EScope.buyer ? 'Seller' : 'Buyer',
        isRequired: true,
        type: EFieldType.singleSelect,
        options: companyOptions,
      },
      {
        name: 'sellerProductId',
        label: 'Seller product',
        isRequired: true,
        type: EFieldType.singleSelect,
        options: sellerProductOptions,
        isHiddenCreateIcon: !buyerSellerId,
        isLoading: isLoadingSellerProducts,
        onClickCreateIcon() {
          setIsOpenSellerProductDialog(true)
        },
      },
      {
        name: 'name',
        label: 'Name',
        size: 6,
      },
      {
        name: 'code',
        label: 'Code',
        size: 6,
      },
      {
        name: 'productGroupId',
        label: 'Product group',
        type: EFieldType.singleSelect,
        options: productGroupOptions,
      },
      {
        name: 'status',
        label: 'Status',
        isRequired: true,
        type: EFieldType.radio,
        options: [
          { label: 'Active', value: EStatus.Active.toLowerCase() },
          { label: 'Inactive', value: EStatus.Inactive.toLowerCase() },
        ],
      },
    ],
    [
      currentScope,
      companyOptions,
      sellerProductOptions,
      buyerSellerId,
      isLoadingSellerProducts,
      productGroupOptions,
    ],
  )

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const schema = useMemo(
    () =>
      Yup.object({
        buyerSellerId: Yup.number()
          .required('Buyer seller is required!')
          .typeError('Buyer seller is required!'),
        sellerProductId: Yup.number()
          .required('Seller product is required!')
          .typeError('Seller product is required!'),
        productGroupId: Yup.number().nullable(),
        status: Yup.string()
          .oneOf([EStatus.Active.toLowerCase(), EStatus.Inactive.toLowerCase()])
          .required('Status is required!'),
      }),
    [],
  )

  const onCloseSellerProductDialog = useCallback(() => {
    setIsOpenSellerProductDialog(false)
  }, [])

  const afterCreateSellerProduct = useCallback(
    (sellerProduct: ISellerProduct) => {
      if (buyerSellerId) {
        addSellerProduct(sellerProduct, {
          filters: { buyerSellerId: [buyerSellerId] },
        })
        formRef.current?.setValue('sellerProductId', sellerProduct.id)
        onCloseSellerProductDialog()
      }
    },
    [addSellerProduct, buyerSellerId, onCloseSellerProductDialog],
  )

  const onCreate = useCallback(
    async (formValues: IBuyerSellerProductFormValues) => {
      const { errors, ...response } =
        await apiClient.buyerSellerProducts.create({
          buyerSellerProduct: formValues,
        })
      if (errors.length) {
        setBackendError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IBuyerSellerProductFormValues) => {
      const { errors, ...response } =
        await apiClient.buyerSellerProducts.update(
          formData?.id as number,
          formValues,
        )
      if (errors.length) {
        setBackendError(errors[0])
      } else {
        afterUpdate && afterUpdate(response)
        toast.success(toastMessages.updateSuccess)
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: IBuyerSellerProductFormValues) => {
      setIsLoading(true)
      setBackendError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    const subscription = formRef.current?.watch(
      (value: IBuyerSellerProductFormValues) => {
        setBuyerSellerId(value.buyerSellerId)
      },
    )
    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    if (formData) {
      setBuyerSellerId(formData.buyerSellerId)
    }
  }, [formData])

  return (
    <>
      <ConcordFormStructure
        {...formProps}
        ref={formRef}
        fields={fields}
        defaultValues={DEFAULT_VALUES}
        formData={formData}
        cancelText='Close'
        submitText={isUpdating ? 'Update' : 'Create'}
        onSubmit={handleSubmit}
        schema={schema}
        isLoading={isLoading}
        error={backendError}
      />
      <DialogSellerProductForm
        isOpen={isOpenSellerProductDialog}
        onClose={onCloseSellerProductDialog}
        afterCreate={afterCreateSellerProduct}
      />
    </>
  )
}

export default BuyerSellerProductForm

import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { WorkerForm, CommonDialogV2 } from '~/components/shared'

import { toastMessages } from '~/constants/toast-status-text'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'

import './WorkerForm.scss'
import { Alert } from 'react-bootstrap'
import { useQueryUsers } from '~/hooks/useQueryData'

function DialogWorkerForm(props) {
  const {
    defaultValues,
    afterCreateWorker,
    afterUpdateWorker,
    isOpen,
    ...dialogaProps
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')

  const { addUser, updateUser } = useQueryUsers()

  const isUpdating = useMemo(() => defaultValues?.user?.id, [defaultValues])

  const currentCompany = useSelector(selectMyCurrentCompany)

  const patchDriverFleetDriverId = useCallback(
    async (driverFleetId, driverId) => {
      const { errors, ...response } = await apiClient.driverFleets.update(
        driverFleetId,
        {
          driverId,
        },
      )
      if (errors?.length > 0) {
        errors.forEach(error => toast.error(error.message))
      } else {
        return response
      }
    },
    [],
  )

  const createWorker = useCallback(
    async formData => {
      const {
        user: { errors, ...user },
      } = await apiClient.user.create({ user: formData, showFullUser: true })

      if (errors?.length > 0) {
        setBackendError(errors[0])
      } else {
        toast.success(toastMessages.createSuccess)

        if (!user.driverFleet?.driverId) {
          const df = await patchDriverFleetDriverId(
            user.driverFleet.id,
            user.driver.id,
          )
          addUser({
            ...user,
            driverFleet: df,
          })
        } else {
          addUser(user)
        }

        afterCreateWorker && afterCreateWorker(user)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [afterCreateWorker, patchDriverFleetDriverId],
  )

  const updateWorker = useCallback(
    async formData => {
      const response = await apiClient.users.update(formData.id, {
        user: formData,
        showFullUser: true,
      })
      const { errors, ...user } = response.user
      if (errors?.length > 0) {
        setBackendError(errors[0])
      } else {
        toast.success(toastMessages.updateSuccess)
        afterUpdateWorker && afterUpdateWorker(response)
        updateUser(user.id, user)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [afterUpdateWorker],
  )

  const handleSubmit = useCallback(
    async formData => {
      const payload = {
        id: defaultValues?.user?.id,
        ...formData.user,
        personAttributes: {
          ...formData.person,
          driverAttributes: formData.driver,
        },

        userAccessesAttributes: [
          {
            companyId: currentCompany?.id,
            ...formData.userAccess,
            workerAttributes: {
              ...formData.worker,
              id: defaultValues?.worker?.id,
              companyId: currentCompany?.id,
              driverFleetAttributes: {
                ...formData.driverFleet,
                fleetId: currentCompany?.id,
              },
            },
          },
        ],
      }

      payload.phoneNumber = payload.phoneNumber || undefined
      payload.email = payload.email || undefined

      setIsLoading(true)
      setBackendError('')

      try {
        if (isUpdating) {
          await updateWorker(payload)
        } else {
          await createWorker(payload)
        }
      } catch (error) {
        console.log(error)
        setBackendError(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [createWorker, currentCompany.id, defaultValues, isUpdating, updateWorker],
  )

  return (
    <CommonDialogV2
      {...dialogaProps}
      isOpen={isOpen}
      title={isUpdating ? 'Update Worker' : 'Create Worker'}
      isLoading={isLoading}
      isHiddenOkButton
      className='DialogWorkerForm__container'
    >
      {backendError && (
        <Alert variant='danger' style={{ fontSize: 13 }}>
          {backendError}
        </Alert>
      )}
      {isOpen && (
        <WorkerForm
          defaultValues={defaultValues}
          isLoading={isLoading}
          onSubmit={handleSubmit}
        />
      )}
    </CommonDialogV2>
  )
}

export default DialogWorkerForm

import { ConcordFormDropdownV2, ToolTipOverlay } from '~/components/shared'

import { IonButtons } from '@ionic/react'
import { Button } from '~/components/shared'
import { components } from 'react-select'

import { brush } from 'ionicons/icons'
import { START_TIME_COLOR_OPTIONS } from '~/utils/constants'

import type { IDropdownStartTimeColorsProps } from './type'

import './styles.scss'

function DropdownTerminalOptions(props: IDropdownStartTimeColorsProps) {
  const { color, onChange } = props

  return (
    <ConcordFormDropdownV2
      className='DropdownTerminalOptions__container'
      options={START_TIME_COLOR_OPTIONS}
      value={color}
      onChange={(event, { value }) => {
        onChange && onChange(value)
      }}
      styles={{
        container: provider => ({
          ...provider,
          maxWidth: 60,
        }),
        control: provider => ({
          ...provider,
          border: 'none',
          boxShadow: 'none',
        }),
        singleValue: provider => ({
          ...provider,
          display: 'none',
        }),
        indicatorsContainer: provider => ({
          ...provider,
          display: 'none',
        }),
        menu: provider => ({
          ...provider,
          minWidth: 220,
        }),
        input: provider => ({
          ...provider,
          opacity: 0,
          width: 0,
        }),
      }}
      components={{
        Input: props => {
          return (
            <ToolTipOverlay
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              content={`Color: ${(props.selectProps.value as any).label}`}
              placement='top'
            >
              <IonButtons>
                <Button icon={brush} color={color} />
                <components.Input {...props} />
              </IonButtons>
            </ToolTipOverlay>
          )
        },
      }}
    />
  )
}

export default DropdownTerminalOptions

import { useCallback, useState, useEffect, useMemo } from 'react'
import usePdfPageSelector from '~/hooks/usePdfPageSelector'
import {
  IonIcon,
  IonRow,
  IonCol,
  IonButton,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonCheckbox,
  IonLabel,
} from '@ionic/react'
import { checkboxOutline } from 'ionicons/icons'
import PropTypes from 'prop-types'
import { Document, Page, pdfjs } from 'react-pdf'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { isExpiredAWSLink } from '~/utils/utils'
import PdfPageSelector from '~/components/shared/PdfPageSelector'
import { ToolTipOverlay, ZoomButton } from '~/components/shared'
import { Button } from '~/components/shared'
import queryString from 'query-string'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import './DocumentView.scss'

const DocumentView = ({
  document,
  extraButtons,
  onReloadData,
  ratio = 1,
  noHover,
  selectionButton,
  onselectionButtonClick,
  selected,
  contentHeight,
  mapButtons,
  contentWidth,
  style,
  menu,
}) => {
  const {
    setPageNumber,
    setNumPages,
    numPages,
    pageNumber,
    onBlur,
    onChange,
    onKeyPress,
    incrementPage,
    decrementPage,
    submittedPage,
  } = usePdfPageSelector()

  const [scale, setScale] = useState(ratio)

  const isPdf = useMemo(() => {
    if (!document) {
      return true
    }

    const url = queryString.parseUrl(document)
    if (url.query) {
      const responseType = url.query['response-content-type']

      return responseType === 'application/pdf'
    }

    return true
  }, [document])

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }) => {
      setNumPages(numPages)
    },
    [setNumPages],
  )

  const renderMapButtons = useCallback(() => {
    return mapButtons.map((button, index) => {
      const {
        icon,
        label,
        onClick,
        type,
        buttonProps,
        tooltipProps,
        iconProps,
        ...inputProps
      } = button

      if (type === 'checkbox') {
        return (
          <div
            className='d-flex align-items-center ion-margin-start'
            key={index}
          >
            <IonCheckbox
              onClick={onClick}
              style={{ marginRight: 8 }}
              {...inputProps}
            />
            <IonLabel style={{ fontSize: 14 }}>{label}</IonLabel>
          </div>
        )
      }

      return (
        <Button
          key={label || icon}
          buttonProps={buttonProps}
          tooltipProps={tooltipProps}
          onClick={onClick}
          label={label}
          icon={icon}
          iconProps={iconProps}
        />
      )
    })
  }, [mapButtons])

  useEffect(() => {
    if (isExpiredAWSLink(document)) onReloadData()
    setPageNumber(1)
  }, [document, onReloadData, setPageNumber])

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
  }, [])

  return (
    <div
      style={style}
      onDoubleClick={event => {
        event.stopPropagation()
      }}
    >
      <IonHeader translucent style={{ width: contentWidth }}>
        <IonToolbar color='dark'>
          <IonRow
            className={`ion-align-items-center ${noHover ? 'document-header-no-hover' : ''
              }`}
          >
            <IonCol
              className='DocumentView__controlPdfContainer'
              sizeLg='6'
              sizeXl='5'
              sizeMd='12'
              sizeSm='12'
              sizeXs='12'
            >
              {isPdf && (
                <>
                  <PdfPageSelector
                    numPages={numPages}
                    pageNumber={pageNumber}
                    onBackPress={decrementPage}
                    onForwardPress={incrementPage}
                    onKeyPress={onKeyPress}
                    onInputBlur={onBlur}
                    onInputChange={onChange}
                  />

                  <div className='DocumentView__divider' />
                </>
              )}

              <ZoomButton
                onZoomIn={() => setScale(scale + 0.1)}
                onZoomOut={() => setScale(scale - 0.1)}
                value={scale.toFixed(1)}
                zoomInButtonProps={{ disabled: scale > 2 }}
                zoomOutButtonProps={{ disabled: scale < 0.5 }}
              />
            </IonCol>

            <IonCol
              className='DocumentView__extraButtons'
              sizeLg='6'
              sizeXl='7'
              sizeMd='12'
              sizeSm='12'
              sizeXs='12'
            >
              {selectionButton && (
                <IonButton
                  fill='solid'
                  size='small'
                  color={selected ? 'success' : 'primary'}
                  onClick={onselectionButtonClick}
                >
                  <IonIcon
                    icon={checkboxOutline}
                    tooltip
                    title='Click to make this seller level processing configuration.'
                  />
                </IonButton>
              )}
              {extraButtons && (
                <IonButtons style={{ float: 'right', marginRight: '30px' }}>
                  {menu?.length > 0 &&
                    menu.map((item, index) => (
                      <ToolTipOverlay content={item.label} key={index}>
                        <IonButton
                          fill='solid'
                          color={item.color || 'primary'}
                          onClick={item.onClick}
                          disabled={item.disabled}
                        >
                          {item.icon.props.color ? (
                            <IonIcon icon={item.icon.props.icon} />
                          ) : (
                            item.icon
                          )}
                        </IonButton>
                      </ToolTipOverlay>
                    ))}
                </IonButtons>
              )}

              {mapButtons && mapButtons.length > 0 && (
                <IonButtons>{renderMapButtons()}</IonButtons>
              )}
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <TransformWrapper
        wheel={{ disable: true, wheelDisabled: true }}
        doubleClick={{ disabled: true }}
      >
        <IonContent
          scrollX={true}
          scrollY={true}
          style={{ height: contentHeight, width: contentWidth }}
        >
          {isPdf && (
            <div className='PdfViewerDisplay'>
              <TransformComponent>
                <Document file={document} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page scale={scale} pageNumber={submittedPage} />
                </Document>
              </TransformComponent>
            </div>
          )}
          {!isPdf && (
            <TransformComponent
              wrapperStyle={{ width: '100%', height: '100%' }}
              contentStyle={{ width: '100%', height: '100%' }}
            >
              <div className='DocumentView__wrapImageViewer'>
                <div className='DocumentView__imageViewer'>
                  <img
                    src={document}
                    style={{ transform: `scale(${scale})` }}
                    alt='document_image'
                  />
                </div>
              </div>
            </TransformComponent>
          )}
        </IonContent>
      </TransformWrapper>
    </div>
  )
}

DocumentView.propTypes = {
  mapButtons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
}

DocumentView.defaultProps = {
  mapButtons: [],
  contentHeight: 400,
  contentWidth: null,
}

export default DocumentView

import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import {
  selectCurrentScope,
  selectMyCurrentCompany,
  selectSessionUser,
} from '~/redux/selectors'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'
import { IGetSettingNewValuesResponse } from '~/types/models/ISetting'

import type { IUser } from '~/types/models/IUser'
import { useQuerySellerProducts } from '../useQuerySellerProducts'

const useQueryRetrieveSettingValues = (
  options?: Partial<UseQueryOptions<IGetSettingNewValuesResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['retrieveSettings', sessionUser?.id],
    queryFn() {
      return apiClient.settings.new()
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 600000, // 10 minutes
    retry: 2,
    ...options,
  })

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { sellerProductOptions } = useQuerySellerProducts({
    filters: {
      sellerId:
        currentScope === EScope.seller ? [currentCompany.id] : undefined,
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const settingNewValuesData = useMemo(() => data, [data])

  const settingFieldOptions = useMemo(() => {
    return Object.keys(settingNewValuesData || {}).map(key => ({
      label: _.startCase(key),
      value: _.snakeCase(key),
    }))
  }, [settingNewValuesData])

  const buildOptionsFromArrayStr = useCallback((opts: string | string[]) => {
    if (typeof opts === 'string') {
      return []
    }
    return opts.map(str => ({
      label: _.startCase(str),
      value: str,
    }))
  }, [])

  const getSettingFieldTypeFromValues = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string, values: any) => {
      if (Array.isArray(values)) {
        if (
          values.length &&
          (values.includes('true') || values.includes('false'))
        ) {
          return EFieldType.radio
        }
        return EFieldType.singleSelect
      }
      if (values === '1..Infinity') {
        if (key.includes('seller_product')) {
          return EFieldType.singleSelect
        }
      }
      return EFieldType.text
    },
    [],
  )

  const getSettingOptionsFromValues = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string, values: any) => {
      const type = getSettingFieldTypeFromValues(key, values)
      if (type === EFieldType.singleSelect && key.includes('seller_product')) {
        return sellerProductOptions
      }

      if (type === EFieldType.singleSelect) {
        return buildOptionsFromArrayStr(values)
      }

      if (type === EFieldType.radio) {
        return [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ]
      }

      return []
    },
    [
      buildOptionsFromArrayStr,
      getSettingFieldTypeFromValues,
      sellerProductOptions,
    ],
  )

  return {
    // settingNewValues: data,
    settingNewValuesData,
    settingFieldOptions,
    isLoadingSettingNewValues: isLoading,
    getSettingOptionsFromValues,
    getSettingFieldTypeFromValues,
  }
}

export default useQueryRetrieveSettingValues

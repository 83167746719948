import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import {
  IGetSalesProposalsParams,
  ISalesProposal,
} from '~/types/models/ISalesProposal'

const useModifySellerToBuyerTerminals = (
  params: IGetSalesProposalsParams = {},
) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'salesProposals',
      sessionUser?.id,
      buildGetUrl(apiClient.salesProposals.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addSalesProposal = useCallback(
    (data: ISalesProposal) => {
      queryClient.setQueryData<ISalesProposal[]>(queryKey, oldData => {
        if (oldData) {
          return [...oldData, data]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateSalesProposal = useCallback(
    (id: number, data: ISalesProposal) => {
      queryClient.setQueryData<ISalesProposal[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(g => g.id === id)
              draft[index] = data
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const removeSalesProposal = useCallback(
    (id: number) => {
      queryClient.setQueryData<ISalesProposal[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(item => item.id === id)
              if (draft[index]) {
                draft.splice(index, 1)
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )
  return {
    addSalesProposal,
    updateSalesProposal,
    removeSalesProposal,
  }
}

export default useModifySellerToBuyerTerminals

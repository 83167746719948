import { useMemo, useState, useCallback } from 'react'
import { useRouter } from '~/hooks/useRouter'
import useTableCompanySellerBuyerSubTab from './useTableCompanySellerBuyerSubTab'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  DialogBuyerSellerForm,
  DialogCompanyForm,
  EditIcon,
  OpenIcon,
  ReusableTable,
} from '~/components/shared'

import { moduleName } from '~/utils/constants'

import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { ERTDisplayColumnId, EYesNo } from '~/types/enums/ECommonEnum'
import { useWindowSize } from 'react-use'
import { useQueryBuyerSellers } from '~/hooks/useQueryData'
import singularize from '~/utils/singularize'

function CompanySellerBuyerSubTab({ description, subTab }) {
  const router = useRouter()
  const windowSize = useWindowSize()
  const { confirmation } = useConfirmationProvider()

  const [formState, setFormState] = useState({
    isOpen: false,
    formData: {},
  })
  const [companyFormState, setCompanyFormState] = useState({
    isOpen: false,
    formData: {},
  })

  const {
    isLoadingBuyerSellers,
    buyerSellersData,
    addBuyerSeller,
    removeBuyerSeller,
    updateBuyerSellerByCompany,
    buyerCompanies,
    sellerCompanies,
    fleetCompanies,
  } = useQueryBuyerSellers()

  const isTableLoading = isLoadingBuyerSellers

  const data = useMemo(() => {
    switch (subTab) {
      case moduleName.company.fleets:
        return fleetCompanies
      case moduleName.company.sellers:
        return sellerCompanies
      case moduleName.company.buyers:
        return buyerCompanies

      default:
        return []
    }
  }, [subTab, fleetCompanies, sellerCompanies, buyerCompanies])

  const handleDeleteRow = useCallback(
    async rowData => {
      try {
        const result = await confirmation({
          message: 'Are you sure you want to delete this company',
        })
        if (result === EYesNo.Yes) {
          const name = singularize(subTab)
          const relationship = buyerSellersData.find(
            ({ buyerId, sellerId }) => {
              const id = name === 'buyer' ? buyerId : sellerId
              return rowData.id === id
            },
          )
          if (relationship) {
            apiClient.buyerSellers.delete(relationship.id)
            removeBuyerSeller(relationship.id)
          } else {
            toast.error("Can't remove this company for some reasons")
          }
        }
      } catch (error) {
        toast.error(toastMessages.deleteError)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buyerSellersData, subTab],
  )

  const { columns } = useTableCompanySellerBuyerSubTab({
    subTab,
  })

  const onCloseCompanyForm = () => {
    setFormState({
      isOpen: false,
      formData: {},
    })
  }

  const afterCreateCompany = (newCompany, { buyerSeller }) => {
    if (buyerSeller?.id) {
      addBuyerSeller(buyerSeller)
    } else {
      toast.error(toastMessages.createError)
    }
    onCloseCompanyForm()
  }

  const afterUpdateCompany = company => {
    updateBuyerSellerByCompany(company)
    setCompanyFormState({
      isOpen: false,
      formData: {},
    })
  }

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          enableCompanyView
          enableTopToolbar
          enableRowActions
          companyViewProps={{
            name: subTab,
            description,
            onClickTopButton() {
              setFormState({
                isOpen: true,
                formData: {
                  [singularize(subTab)]: true,
                },
              })
            },
          }}
          columns={columns}
          data={data}
          tableHeight={windowSize.height - 220}
          renderRowActions={({ row }) => {
            return [
              {
                icon: <EditIcon color='white' />,
                onClick() {
                  setCompanyFormState({
                    isOpen: true,
                    formData: row.original,
                  })
                },
              },
              {
                icon: <DeleteIcon color='white' />,
                color: 'danger',
                onClick() {
                  handleDeleteRow(row.original)
                },
                tooltipProps: {
                  content: 'Delete',
                  placement: 'top',
                },
              },
              {
                icon: <OpenIcon color='white' />,
                onClick() {
                  router.push(`/companies/${row.original.id}/edit`)
                },
                color: 'primary',
                tooltipProps: {
                  content: 'View Company',
                  placement: 'top',
                },
              },
            ]
          }}
          state={{
            isLoading: isTableLoading,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
            sorting: [
              {
                id: 'name',
                desc: false,
              },
            ],
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              minSize: 105,
              maxSize: 105,
            },
          }}
        />
      </div>
      <DialogBuyerSellerForm
        isOpen={formState.isOpen}
        onClose={onCloseCompanyForm}
        formData={formState.formData}
        afterCreate={afterCreateCompany}
        hiddenFields={['seller', 'buyer', 'fleet']}
      />
      <DialogCompanyForm
        isOpen={companyFormState.isOpen}
        onClose={() => {
          setCompanyFormState({
            isOpen: false,
            formData: {},
          })
        }}
        formData={companyFormState.formData}
        afterUpdate={afterUpdateCompany}
        hiddenFields={['seller', 'buyer', 'fleet']}
      />
    </>
  )
}

export default CompanySellerBuyerSubTab

import { useCallback, useMemo, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'

import { toast } from 'react-toastify'
import { ITruckFleet } from '~/types/models/ITruckFleet'

import { EFieldType, EStatus } from '~/types/enums/ECommonEnum'
import { apiClient } from '~/api/ApiClient'

import type { ITruckFleetFormProps } from './type'

function TruckFleetFormData(props: ITruckFleetFormProps) {
  const { truckFleet, afterUpdateTruckFleet, isHiddenCancelButton } = props

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = useCallback(
    async (formData: ITruckFleet) => {
      setIsLoading(true)
      try {
        const response = await apiClient.truckFleets.update(truckFleet.id, {
          truckFleet: formData,
        })
        if ((response.errors as string[])?.length > 0) {
          toast.error((response.errors as string[])[0])
        } else {
          afterUpdateTruckFleet &&
            afterUpdateTruckFleet({ updatedTruckFleet: response, truckFleet })
        }
      } catch (error) {
        console.log('error', error)
        toast.error((error as Error).message)
      } finally {
        setIsLoading(false)
      }
    },
    [afterUpdateTruckFleet, truckFleet],
  )

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Label',
        name: 'label',
        size: 12,
        type: EFieldType.text,
      },
      {
        label: 'Status Reason',
        name: 'statusReason',
        size: 6,
        type: EFieldType.text,
      },
      {
        label: 'Notes',
        name: 'dispatchNotes',
        size: 6,
        type: EFieldType.text,
      },
      {
        label: 'Color',
        name: 'color',
        type: EFieldType.color,
        size: 6,
      },
      {
        label: 'Expected Return',
        name: 'expectedReturn',
        type: EFieldType.date,
        size: 6,
      },
      {
        label: 'Auto Status',
        name: 'autoStatus',
        type: EFieldType.checkbox,
        size: 6,
      },
      {
        label: 'Record Breadcrumbs',
        name: 'recordBreadcrumbs',
        type: EFieldType.checkbox,
        size: 6,
      },
      {
        label: 'Status',
        name: 'status',
        options: [
          { label: 'Active', value: EStatus.Active.toLowerCase() },
          { label: 'Pending', value: EStatus.Pending.toLowerCase() },
          { label: 'Inactive', value: EStatus.Inactive.toLowerCase() },
        ],
        type: EFieldType.radio,
        size: 12,
      },
    ],
    [],
  )

  const defaultValues = useMemo<Partial<ITruckFleet>>(
    () => ({
      label: '',
      statusReason: '',
      dispatchNotes: '',
      color: '',
      expectedReturn: null,
      autoStatus: true,
      recordBreadcrumbs: false,
      status: 'active',
    }),
    [],
  )

  const formData = useMemo(() => {
    const { status, ...truckData } = truckFleet
    return {
      ...truckData,
      status: status || 'active',
    }
  }, [truckFleet])

  return (
    <ConcordFormStructure
      fields={fields}
      defaultValues={defaultValues}
      formData={formData}
      onSubmit={onSubmit}
      isLoading={isLoading}
      isHiddenCancelButton={isHiddenCancelButton}
    />
  )
}

export default TruckFleetFormData

import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'
import { IBillLine, IGetBillLinesParams } from '~/types/models/IBillLine'

const useModifyBillLines = (params: Partial<IGetBillLinesParams>) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'billLines',
      sessionUser?.id,
      buildGetUrl(apiClient.billLines.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addBillLine = useCallback(
    (data: IBillLine) => {
      queryClient.setQueryData<IBillLine[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [...oldData, data]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateBillLine = useCallback(
    (id: number, data: IBillLine) => {
      queryClient.setQueryData<IBillLine[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeBillLine = useCallback(
    (id: number) => {
      queryClient.setQueryData<IBillLine[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addBillLine,
    updateBillLine,
    removeBillLine,
  }
}

export default useModifyBillLines

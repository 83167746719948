import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyPrizes from './useModifyPrizes'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IGetPrizesParams, IPrize } from '~/types/models/IPrize'

const useQueryPrizes = (
  params: IGetPrizesParams,
  options?: Partial<UseQueryOptions<IPrize[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'prizes',
      sessionUser?.id,
      buildGetUrl(apiClient.prizes.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.prizes.get(params)
      return response.prizes
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // 1 min
    ...options,
  })

  const prizesData = useMemo(() => data || [], [data])

  const { addPrize, updatePrize, removePrize } = useModifyPrizes(params)

  return {
    prizesData,
    isLoadingPrizes: isLoading,
    addPrize,
    updatePrize,
    removePrize,
  }
}

export default useQueryPrizes

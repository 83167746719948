import { Button, Collapse } from 'react-bootstrap'
import { useFieldArray, useWatch } from 'react-hook-form'
import { ExtrasFormRow } from './ExtrasFormRow'
import { useEffect } from 'react'
import _ from 'lodash'
import { PlusIcon, ToolTipOverlay } from '~/components/shared'

export const ExtrasFormFieldArray = ({
  prefix = '',
  control,
  autoExtras,
  buyerId,
  sellerId,
  orderTypesMemo,
  loading,
  name,
  show,
  hideHeader,
  ...props
}) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `${prefix}orderExtrasAttributes`,
  })
  const watch = useWatch({ control, name: `${prefix}orderExtrasAttributes` })

  const appendExtra = () => {
    append({
      buyerSellerProductId: null,
      orderExtrasRangesAttributes: [{ qty: null, startLoad: 1, endLoad: null }],
    })
  }

  useEffect(() => {
    if (autoExtras.length) {
      const uniqueSPs = [...new Set(autoExtras.map(ae => ae.sellerProductId))]

      const sortedExtras = uniqueSPs.map(sp => {
        const ranges = autoExtras
          .filter(ae => ae.sellerProductId === sp)
          .map(range => {
            const [start, end] = range.applicationRange.split('...')
            return {
              qty: range.qty,
              startLoad: _.isNumber(start) ? start : 1,
              endLoad: _.isNumber(end) ? end : null,
            }
          })

        return {
          sellerProductId: sp,
          orderExtraRangesAttributes: ranges,
        }
      })
      sortedExtras.forEach(extra => {
        const existingExtra = watch.findIndex(
          ({ sellerProductId }) => sellerProductId === extra.sellerProductId,
        )

        if (existingExtra === -1) {
          const nullExtra = watch.findIndex(
            ({ sellerProductId }) => sellerProductId === null,
          )
          if (nullExtra !== -1) {
            update(nullExtra, extra)
          } else {
            append(extra)
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [append, autoExtras, update])

  return (
    <Collapse in={show}>
      <div>
        {!hideHeader && (
          <h3 className='d-flex align-items-center'>
            {_.startCase(name)} Extras:{' '}
            <ToolTipOverlay content='Add extra'>
              <Button onClick={appendExtra}>
                <PlusIcon color='#fff' />
              </Button>
            </ToolTipOverlay>
          </h3>
        )}

        <div>
          {fields.map((field, index) => (
            <ExtrasFormRow
              key={field.id}
              index={index}
              remove={remove}
              prefix={prefix}
              control={control}
              buyerId={buyerId}
              sellerId={sellerId}
              productOrderTypes={orderTypesMemo}
              {...props}
            />
          ))}
        </div>
      </div>
    </Collapse>
  )
}

import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { IGetDriversNewResponse } from '~/types/models/IDriver'
import { useCallback, useMemo } from 'react'

const useQueryDriversNew = (
  options?: Partial<UseQueryOptions<IGetDriversNewResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['driversNew', sessionUser?.id],
    queryFn() {
      return apiClient.drivers.new()
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 600000, // 10 minutes
    ...options,
  })

  const stateOptions = useMemo(() => {
    if (data?.states) {
      return Object.keys(data.states).map(key => {
        const value = key.toUpperCase()
        return {
          value,
          label: `${value} - ${data.states[key]}`,
        }
      })
    }
    return []
  }, [data?.states])

  const stringStatesData = useMemo(
    () => stateOptions.map(({ value }) => value),
    [stateOptions],
  )

  const isValidStateOption = useCallback(
    (value: string) => {
      return stringStatesData.includes(value)
    },
    [stringStatesData],
  )

  return {
    stateOptions,
    stringStatesData,
    isValidStateOption,
    isLoadingDriversNew: isLoading,
  }
}

export default useQueryDriversNew

import queryString from 'query-string'
import qs from 'qs'
import _ from 'lodash'
import humps from 'humps'
import moment from 'moment'

export const replaceUrl = (originalUrl, params) => {
  const { url, query } = queryString.parseUrl(originalUrl)
  const newQuery = { ...query, ...params }

  return queryString.stringifyUrl(
    {
      url,
      query: newQuery,
    },
    { skipEmptyString: true, skipNull: true, sort: false },
  )
}

export const formatOrderParams = orders => {
  const params = {}

  orders
    .filter(({ sorted }) => sorted)
    .forEach(({ value, isAsc, field }) => {
      params[humps.decamelize(value || field || '')] = isAsc ? 'asc' : 'desc'
    })

  return params
}

/**
 * @param {object} params
 * @param {qs.IStringifyOptions} options
 * @returns {string}
 */
export const stringifyParams = (params, options) => {
  //keep an eye on skipnulls
  const DEFAULT_OPTIONS = {
    encodeValuesOnly: true,
    arrayFormat: 'brackets',
    addQueryPrefix: true,
    filter(prefix, value) {
      if (typeof value === 'string') {
        if (prefix === 'filters[active]') {
          return value
        }

        if (value?.length === 0) {
          return
        }
      }
      if (
        _.has(value, ['start_date']) &&
        _.has(value, ['end_date']) &&
        Object.keys(value).length <= 3
      ) {
        if (value.start_date && value.end_date) {
          return `${moment(value.start_date)
            .startOf('day')
            .toISOString()}...${moment(value.end_date)
            .endOf('day')
            .toISOString()}`
        }
        if (value.is_not_set) {
          return 'null'
        }
        return
      }

      if (_.has(value, ['lower']) || _.has(value, ['upper'])) {
        if (value.lower && value.upper) {
          return `${value.lower}...${value.upper}`
        }
        if (value.lower) {
          return `${value.lower}...${Infinity}`
        }

        if (value.upper) {
          return `${Infinity}...${value.upper}`
        }

        return
      }

      if (value instanceof Date) {
        return moment(value).format('YYYY-MM-DD')
      }

      return value
    },
  }
  const decamelizeKeys = humps.decamelizeKeys(params)
  const mapOptions = _.merge(DEFAULT_OPTIONS, options)

  return qs.stringify(decamelizeKeys, mapOptions)
}

/**
 * @returns {string}
 */
export const buildGetUrl = (url, params, options = {}) => {
  const query = stringifyParams(params, {
    ...options,
    encode: false,
  })
  if (!query) {
    return url
  }

  return `${url}${query}`
}

import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyContests from './useModifyContests'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IContest, IGetContestsParams } from '~/types/models/IContest'

const useQueryContests = (
  params: IGetContestsParams,
  options?: Partial<UseQueryOptions<IContest[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'contests',
      sessionUser?.id,
      buildGetUrl(apiClient.contests.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.contests.get(params)
      return response.contests
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // 1 min
    retry: false,
    ...options,
  })

  const contestsData = useMemo(() => data || [], [data])

  const { addContest, updateContest, removeContest } = useModifyContests(params)

  return {
    contestsData,
    isLoadingContests: isLoading,
    addContest,
    updateContest,
    removeContest,
  }
}

export default useQueryContests

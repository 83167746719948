import {
  checkmarkCircleOutline,
  arrowRedoOutline,
  pushOutline,
  arrowRedoCircleOutline,
  downloadOutline,
  helpCircleOutline,
  reloadOutline,
} from 'ionicons/icons'

export const filterTrucksWithStatuses = (trucks = [], statuses = []) =>
  trucks.filter(truck => statuses.includes(truck.loadStatus))

export const loadSections = {
  to_seller: {
    header: {
      icon: arrowRedoOutline,
      color: 'medium',
      opacity: 0.7,
      colorHex: '#92949c'
    },
    statuses: ['Ticketed', 'Dead Head'],
    code: 'TKT',
  },
  at_seller: {
    header: {
      icon: pushOutline,
      color: 'dark',
      colorHex: 'black'
    },
    statuses: [
      'At Plant',
      'Check In',
      'On Scale - Empty',
      'To Loading Area',
      'Start Load',
      'End Load',
      'On Scale - Full',
      'Exiting Plant',
      'Check Out',
    ],
    code: 'ATP',
  },
  to_buyer: {
    header: {
      icon: arrowRedoCircleOutline,
      color: 'secondary',
      colorHex: '#3dc2ff'
    },
    statuses: ['To Job'],
    code: 'TOD',
  },
  at_buyer: {
    header: {
      icon: downloadOutline,
      color: 'primary',
      colorHex: '#3880ff'
    },
    statuses: [
      'Arrived Job',
      'Job Scale - Full',
      'To Discharge',
      'Discharging',
      'Discharge Complete',
      'Job Scale - Empty',
      'Exiting Job',
    ],
    code: 'ATD',
  },
  returning: {
    header: {
      icon: reloadOutline,
      color: 'success',
      colorHex: '#2dd36f'
    },
    statuses: ['Returning'],
    code: 'RTN',
  },
  deliver_complete: {
    header: {
      icon: checkmarkCircleOutline,
      color: 'medium',
    },
    statuses: ['Delivery Complete', 'Awaiting Ticket', 'Complete'],
    code: 'DCO',
  },
}

export const isInLoadStatus = (key, currentStatus) => {
  const statuses = loadSections[key]?.statuses || []
  return statuses.includes(currentStatus)
}

export const codedStatus = {
  TKT: {
    time_name: 'Ticket Generated',
    name: 'Ticketed',
    rank: 1,
    code: 'TKT',
  },
  DHD: {
    time_name: 'Start Dead Head',
    name: 'Dead Head',
    rank: 2,
    code: 'DHD',
  },
  ATP: {
    time_name: 'Arrived Plant',
    name: 'At Plant',
    rank: 3,
    code: 'ATP',
    required: true,
  },
  CKI: { time_name: 'Start Check In', name: 'Check In', rank: 4, code: 'CKI' },
  SEW: {
    time_name: 'Start Empty Weigh',
    name: 'On Scale - Empty',
    rank: 5,
    code: 'SEW',
  },
  TEW: {
    time_name: 'End Empty Weigh',
    name: 'To Loading Area',
    rank: 6,
    code: 'TEW',
  },
  SLD: { time_name: 'Start Load', name: 'Start Load', rank: 7, code: 'SLD' },
  ELD: { time_name: 'End Load', name: 'End Load', rank: 8, code: 'ELD' },
  SFW: {
    time_name: 'Start Full Weigh',
    name: 'On Scale - Full',
    rank: 9,
    code: 'SFW',
  },
  EFW: {
    time_name: 'End Full Weigh',
    name: 'Exiting Plant',
    rank: 10,
    code: 'EFW',
  },
  CKO: { time_name: 'Check Out', name: 'Check Out', rank: 11, code: 'CKO' },
  TOD: {
    time_name: 'To Job',
    name: 'To Job',
    rank: 12,
    code: 'TOD',
    required: true,
  },
  ATD: {
    time_name: 'Arrived Job',
    name: 'Arrived Job',
    rank: 13,
    code: 'ATD',
    required: true,
  },
  JFW: {
    time_name: 'Start Job Full Weigh',
    name: 'Job Scale - Full',
    rank: 14,
    code: 'JFW',
  },
  JFWE: {
    time_name: 'End Job Full Weigh',
    name: 'To Discharge',
    rank: 15,
    code: 'JFWE',
  },
  SDC: {
    time_name: 'Start Discharge',
    name: 'Discharging',
    rank: 16,
    code: 'SDC',
  },
  EDC: {
    time_name: 'End Discharge',
    name: 'Discharge Complete',
    rank: 17,
    required: true,
    code: 'EDC',
  },
  JEW: {
    time_name: 'Start Job Empty Weigh',
    name: 'Job Scale - Empty',
    rank: 18,
    code: 'JEW',
  },
  JEWE: {
    time_name: 'End Job Empty Weigh',
    name: 'Exiting Job',
    rank: 19,
    code: 'JEWE',
  },
  DCO: {
    time_name: 'Delivery Complete',
    name: 'Delivery Complete',
    rank: 20,
    code: 'DCO',
  },
}

export const getLoadSectionIcon = (status = '') => {
  const loadSection = Object.values(loadSections).find(section =>
    section.statuses.includes(status),
  )
  return loadSection ? loadSection.header.icon : helpCircleOutline
}

export const getLoadSectionColor = (status = '') => {
  const loadSection = Object.values(loadSections).find(section =>
    section.statuses.includes(status),
  )
  return loadSection ? loadSection.header.color : 'primary'
}

export const getNextLoadStatus = loadStatus => {
  const statuses = Object.keys(loadSections)
  let currentKey = ''
  for (const [key, value] of Object.entries(loadSections)) {
    if (value.statuses.includes(loadStatus)) currentKey = key
  }
  return statuses[statuses.indexOf(currentKey) + 1]
}

export const getStatusesList = loadStatus => {
  const loadStatuses = Object.keys(loadSections)

  const findLoadStatusesKey = loadStatuses.find((key) => {
    const { statuses } = loadSections[key]
    return statuses.includes(loadStatus)
  })

  const findLoadStatuses = loadSections[findLoadStatusesKey]

  if (findLoadStatuses) {
    const index = findLoadStatuses.statuses.indexOf(loadStatus)

    if (index === findLoadStatuses.statuses.length - 1) {
      const key = getNextLoadStatus(loadStatus)
      return loadSections[key]
    }

    return findLoadStatuses
  }

  return null

}

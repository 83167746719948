import React from 'react'
import useDriverCard from './useDriverCard'

import { IonIcon } from '@ionic/react'
import { Badge, Modal } from 'react-bootstrap'
import {
  CloseIcon,
  CommonDialogV2,
  DragIcon,
  DriverAvatar,
  ToolTipOverlay,
} from '~/components/shared'
import AssignTruck from '~/components/load/AssignLoads/AssignTruck'
import { TerminalForm } from '~/components/onboarding'
import { Unless, When } from 'react-if'

import { calendar } from 'ionicons/icons'
import clsx from 'clsx'
import { showOffDayRange } from '~/utils/offdayFormattedDays'

import type { IDriverCardProps } from './type'

import './styles.scss'
import { NoteButton } from '~/components/fleet/StartTimes/StartTimesTable/NoteButton'

const DriverCard = React.forwardRef<HTMLDivElement, IDriverCardProps>(
  (props: IDriverCardProps, ref) => {
    const {
      isOpenTruckDialog,
      driverFleet,
      createTerminalDialogState,
      renderLoadStatus,
      renderTruck,
      onCloseTruckDialog,
      setIsOpenTruckDialog,
      onCloseTerminalDialog,
      dragRef,
      fullName,
      onClickCloseIcon,
      driverFleetId,
      isDraggable,
      isCloseable,
      className,
      isHiddenStatusTime,
      measureRef,
      renderWorkerTerminal,
      onSaveNotes,
      isHiddenNotesButton,
      notes,
      hasOffday,
      offday,
      index,
      indexBadgeStyle,
      extraIndexTooltipContent,
    } = useDriverCard(props)

    return (
      <>
        <div
          className={clsx('DriverCard__container', className, {
            isDraggable,
            isDisabled: hasOffday,
          })}
          ref={node => {
            dragRef(node)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref && (ref as any)(node)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            measureRef(node as any)
          }}
        >
          <When condition={typeof index === 'number'}>
            <ToolTipOverlay
              content={
                <div>
                  <div>Driver Rank: {driverFleet?.rank || '-'}</div>
                  {extraIndexTooltipContent}
                </div>
              }
              placement='right'
            >
              <div className='indexBadge' style={indexBadgeStyle}>
                #{index}
              </div>
            </ToolTipOverlay>
          </When>
          {isCloseable && (
            <span className='closeIcon' onClick={onClickCloseIcon}>
              <CloseIcon color='var(--ion-color-danger)' />
            </span>
          )}
          <div className='bodyContainer'>
            {isDraggable && (
              <span>
                <DragIcon className='fs-2 flex-shrink-0' />
              </span>
            )}
            <div className='driverAvatar'>
              <DriverAvatar driverFleetId={driverFleetId} isHiddenInfo />
              <span className='driverFullName'>{fullName}</span>
            </div>
            {renderTruck()}

            {renderWorkerTerminal}
            <Unless condition={isHiddenStatusTime}>{renderLoadStatus()}</Unless>
            <Unless condition={isHiddenNotesButton}>
              <NoteButton note={notes} onSave={onSaveNotes} />
            </Unless>

            <When condition={hasOffday}>
              <Badge style={{ marginLeft: 4 }} bg='info'>
                <span style={{ verticalAlign: 'middle' }}>
                  <IonIcon icon={calendar} />
                </span>
                <span style={{ verticalAlign: 'middle', marginLeft: 4 }}>
                  {showOffDayRange(offday)}
                </span>
              </Badge>
            </When>
          </div>
        </div>
        <CommonDialogV2
          isOpen={isOpenTruckDialog}
          onClose={onCloseTruckDialog}
          isHiddenHeader
        >
          <AssignTruck
            assignedDriverTruck={driverFleet?.currentTruckFleetId}
            driverFleetId={driverFleet?.id}
            setShow={setIsOpenTruckDialog}
          />
        </CommonDialogV2>
        <Modal
          show={createTerminalDialogState.isOpen}
          onHide={onCloseTerminalDialog}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <TerminalForm currentRowData={createTerminalDialogState.terminal} />
          </Modal.Body>
        </Modal>
      </>
    )
  },
)

export default DriverCard

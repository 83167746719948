import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { CompanyHomeContainer } from '~/containers/my-company/CompanyHomeContainer'

import _ from 'lodash'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

import {
  MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  moduleName,
} from '~/utils/constants'
import {
  selectMyCurrentCompany,
  selectUsersDriverFleet,
  selectIsScopeDriver,
  //selectCurrentScope,
  selectUserMenuOptions,
  selectCurrentScope,
} from '~/redux/selectors'
import {
  alertOutline,
  cashOutline,
  speedometerOutline,
  lockClosedOutline,
  cogOutline,
  todayOutline,
  cardOutline,
  documentsOutline,
  carOutline,
  albumsOutline,
  readerOutline,
  businessOutline,
  pieChartOutline,
  cameraOutline,
  apertureOutline,
  personCircleOutline,
  busOutline,
  reloadOutline,
  mapOutline,
  alarmOutline,
  addOutline,
  chatbox,
  peopleOutline,
  storefrontOutline,
  pricetagsOutline,
  // document,
  mailUnread,
  bagCheckOutline,
  trailSign,
  construct,
  cash,
  checkboxOutline,
  listCircleOutline,
  duplicateOutline,
  documentTextOutline,
  receiptOutline,
  gitNetworkOutline,
  peopleCircleOutline,
  bookmarksOutline,
  idCardOutline,
  homeOutline,
  callOutline,
  mailOutline,
  flagOutline,
  calendarOutline,
  cardSharp,
  earOutline,
  starOutline,
  wallet,
  cashSharp,
  card,
  giftOutline,
} from 'ionicons/icons'
import {
  documentRoutesPaths,
  companyRoutesPaths,
  invoicesPaths,
  ordersRoutesPaths,
  userDashboardPaths,
  loadsCreatePaths,
  driverRoutesPaths,
  fleetRoutesPaths,
  chatRoutesPaths,
  salesProposalPaths,
  routesPaths,
  adminRoutesPaths,
} from '~/constants/routesPaths'
import ShiftsSubTab from '~/containers/CompanySubTabs/Shifts'
import {
  DialogEmailParserForm,
  DialogUserForm,
  DialogSellerProductForm,
  DialogTerminalForm,
  DialogBuyerTerminalReferenceForm,
  DialogBuyerSellerProductForm,
  DialogProductGroupForm,
  DialogTagForm,
  DialogAddressForm,
  DialogEmailForm,
  DialogPhoneNumberForm,
  DialogBankAccountForm,
  DialogFlagableConfigurationsForm,
  DialogPayScheduleForm,
  DialogEarningCodeForm,
  DialogEarningRateForm,
  DialogAllowedHierarchyForm,
  DialogGlCodeForm,
  DialogUserGroupForm,
  DialogParserColumnMappingForm,
  DialogWorkerForm,
  DialogPaymentTypeForm,
  DialogHierarchyForm,
  DialogHierarchyRowForm,
  DialogProductGlCodeForm,
  DialogTaxForm,
  DialogProductTaxOverrideForm,
  DialogPaymentTermForm,
  DialogSettingForm,
  DialogFixedPriceForm,
  DialogDataCorrectionMethodForm,
  DialogReferenceNameForm,
  DialogDataParserForm,
  DialogBtfstpForm,
  DialogCampaignForm,
  DialogBuyerSellerForm,
  DialogTruckFormData,
} from '~/components/shared'
import DialogCreateEditPayroll from '~/containers/CompanySubTabs/Payroll/DialogCreateEditPayroll'
import DialogOffDayForm from '~/components/shared/ConcordForm/FormData/OffDayForm/DialogOffDayForm'
import { DialogWorkPayTypeForm } from '~/components/shared/ConcordForm/FormData/WorkPayTypeForm'
import { DialogProductForm } from '~/components/shared/ConcordForm/FormData/ProductForm'
import WorkersContainerTable from '~/containers/WorkersContainer/WorkersContainerTable'
import { PaymentTypesTable } from '~/containers/PaymentTypesContainer/PaymentTypesTable'
import { EScope } from '~/types/enums/ECommonEnum'
import TrucksContainerTable from '~/containers/TrucksContainer/TrucksContainerTable'

const AllowedHierarchiesContainer = loadable(() =>
  import('~/containers/AllowedHierarchiesContainer'),
)
const GlCodesContainer = loadable(() => import('~/containers/GlCodesContainer'))
const HierarchiesTable = loadable(() =>
  import('~/containers/HierarchiesContainer/HierarchiesTable/HierarchiesTable'),
)
const HierarchyRowsTable = loadable(() =>
  import(
    '~/containers/HierarchyRowsContainer/HierarchyRowsTable/HierarchyRowsTable'
  ),
)
const ProductGlCodesTable = loadable(() =>
  import(
    '~/containers/ProductGlCodesContainer/ProductGlCodesTable/ProductGlCodesTable'
  ),
)
const TaxesTable = loadable(() =>
  import('~/containers/TaxesContainer/TaxesTable/TaxesTable'),
)
const ProductTaxOverrideTable = loadable(() =>
  import(
    '~/containers/ProductTaxOverrideContainer/ProductTaxOverrideTable/ProductTaxOverrideTable'
  ),
)
const PaymentTermsTable = loadable(() =>
  import(
    '~/containers/PaymentTermsContainer/PaymentTermsTable/PaymentTermsTable'
  ),
)
const SettingsTable = loadable(() =>
  import('~/containers/SettingsContainer/SettingsTable/SettingsTable'),
)
const CampaignsTable = loadable(() =>
  import('~/containers/CampaignsContainer/CampaignsTable/CampaignsTable'),
)

const DocumentsContainer = loadable(() =>
  pMinDelay(
    import('~/containers/documents/DocumentsContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const RewardsContainer = loadable(() =>
  pMinDelay(
    import('~/containers/RewardsContainer/RewardsContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const LoadsContainer = loadable(() =>
  pMinDelay(
    import('~/containers/loads/LoadsContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const InvoicesContainer = loadable(() =>
  pMinDelay(
    import('~/containers/invoices/InvoicesContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const InvoiceContainer = loadable(() =>
  pMinDelay(
    import('~/containers/invoices/InvoiceContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const TerminalContainer = loadable(() =>
  pMinDelay(
    import('~/containers/TerminalContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const DocumentContainer = loadable(() =>
  pMinDelay(
    import('~/containers/documents/DocumentContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const OrderDetailsContainer = loadable(() =>
  pMinDelay(
    import('~/containers/OrderDetailsContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const ParsedLoadContainer = loadable(() =>
  pMinDelay(
    import('~/containers/ParsedLoadContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const ProcessDocumentContainer = loadable(() =>
  pMinDelay(
    import('~/containers/ProcessDocumentContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const LoadDetailsContainer = loadable(() =>
  pMinDelay(
    import('~/containers/loads/LoadDetailsContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const SalesProposalViewContainer = loadable(() =>
  pMinDelay(
    import('~/containers/SalesProposals/SalesProposalDetailsContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const EditCompanyContainer = loadable(() =>
  pMinDelay(
    import('~/containers/EditCompanyContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const PayrollShowContainer = loadable(() =>
  pMinDelay(
    import('~/containers/PayrollShowContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const FlagableConfigurationDetailContainer = loadable(() =>
  pMinDelay(
    import(
      '~/containers/CompanySubTabs/FlagableConfigurations/FlagableConfigurationDetail'
    ),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const DashboardContainer = loadable(() =>
  pMinDelay(
    import('~/containers/DashboardContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const ProductsContainer = loadable(() =>
  pMinDelay(
    import('~/containers/ProductsContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const PreProcessingTests = loadable(() =>
  pMinDelay(
    import('~/containers/PreProcessingTests'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const OrdersContainer = loadable(() =>
  pMinDelay(
    import('~/containers/OrdersContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const AccessTableContainer = loadable(() =>
  pMinDelay(
    import('~/containers/AccessTableContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const CompaniesShowContainer = loadable(() =>
  pMinDelay(
    import('~/containers/my-company/CompaniesShowContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const LoadCaptureContainer = loadable(() =>
  pMinDelay(
    import('~/containers/loads/LoadCaptureContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const DriverHomePage = loadable(() =>
  pMinDelay(
    import('~/containers/DriverHomePage'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const PastLoadContainer = loadable(() =>
  pMinDelay(
    import('~/containers/PastLoadContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const DriverProfilePage = loadable(() =>
  pMinDelay(
    import('~/containers/DriverProfilePage'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const TruckProfilePage = loadable(() =>
  pMinDelay(
    import('~/containers/TruckProfilePage'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const ChatContainer = loadable(() =>
  pMinDelay(
    import('~/containers/ChatContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const OrderTrackingContainer = loadable(() =>
  pMinDelay(
    import('~/containers/OrderTracking'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const SalesProposalContainer = loadable(() =>
  pMinDelay(
    import('~/containers/SalesProposals/SalesProposalContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const WorkersContainer = loadable(() =>
  pMinDelay(
    import('~/containers/WorkersContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const TrucksContainer = loadable(() =>
  pMinDelay(
    import('~/containers/TrucksContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const FleetsOrderContainer = loadable(() =>
  pMinDelay(
    import('~/containers/fleets/FleetsCreateOrderContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const StartTimesContainer = loadable(() =>
  pMinDelay(
    import('~/containers/StartTimesContainer/StartTimesContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const MapContainer = loadable(() =>
  pMinDelay(
    import('~/containers/MapContainer/MapContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const AutoExtrasContainer = loadable(() =>
  pMinDelay(
    import('~/containers/AutoExtrasContainer/AutoExtrasContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const AutoChargesContainer = loadable(() =>
  pMinDelay(
    import('~/containers/AutoChargesContainer/AutoChargesContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

// public routes
const LoginPageContainer = loadable(() =>
  pMinDelay(
    import('~/containers/LoginPageContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const OnBoardingContainer = loadable(() =>
  pMinDelay(
    import('~/containers/OnboardingContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const ForgotPassword = loadable(() =>
  pMinDelay(
    import('~/containers/ForgotPassword'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const BuyerCompanyComponent = loadable(() =>
  pMinDelay(
    import('~/containers/BuyerCompany'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const PaymentsComponent = loadable(() =>
  pMinDelay(
    import('~/containers/Payments'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

// common private route
const ProfileContainer = loadable(() =>
  pMinDelay(
    import('~/containers/ProfileContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyUserSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/User'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyTagSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/Tag'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanySellerSetupSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/SellerSetup'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanySellerBuyerSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/SellerBuyer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyEmailParserSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/EmailParser'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyParserColumnMappingSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/ParserColumnMapping'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyProductSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/Product'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyFixedPriceSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/FixedPrice'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanySellerProductSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/SellerProduct'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyTerminalSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/Terminal'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyBuyerTerminalReferenceSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/BuyerTerminalReference'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyBuyerSellerProductSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/BuyerSellerProduct'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyProductGroupSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/ProductGroup'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyReferenceNameSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/ReferenceName'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyBTFSTPSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/BTFSTP'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyDataCorrectionMethodSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/DataCorrectionMethod'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyCostCodeSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/CostCode'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyUserGroupSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/UserGroup'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyWorkPayTypeSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/WorkPayType'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyAddressSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/Address'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyPhoneNumberSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/PhoneNumber'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyEmailSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/Email'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyBankAccounts = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/BankAccounts'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyPayroll = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/Payroll'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyDataParser = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/Parser'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyFlagableConfigurations = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/FlagableConfigurations'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const PaySchedulesSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/PaySchedules'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const PayAttemptsSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/PayAttempts'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const EarningCodesSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/EarningCodes'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const EarningRatesSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/EarningRates'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)
const OffdaysSubTab = loadable(() =>
  pMinDelay(
    import('~/containers/CompanySubTabs/Offday'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const AccountsReceivableContainer = loadable(() =>
  pMinDelay(
    import(
      '~/containers/AccountsReceivableContainer/AccountsReceivableContainer'
    ),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const GroupsContainer = loadable(() =>
  pMinDelay(
    import('~/containers/GroupsContainer/GroupsContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const SalesBacklogContainer = loadable(() =>
  pMinDelay(
    import('~/containers/SalesBacklogContainer/SalesBacklogContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const OffdaysContainer = loadable(() =>
  pMinDelay(
    import('~/containers/OffdaysContainer/OffdaysContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CompanyContainer = loadable(() =>
  pMinDelay(
    import('~/containers/CompanyContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const PayrollsContainer = loadable(() =>
  pMinDelay(
    import('~/containers/PayrollsContainer/PayrollsContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const BarcodeContainer = loadable(() =>
  pMinDelay(
    import('~/containers/BarcodeContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const PaymentTypesContainer = loadable(() =>
  pMinDelay(
    import('~/containers/PaymentTypesContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const CardsContainer = loadable(() =>
  pMinDelay(
    import('~/containers/CardsContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const PaymentMethodsContainer = loadable(() =>
  pMinDelay(
    import('~/containers/PaymentMethodsContainer/PaymentMethodsContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const TestingContainer = loadable(() =>
  pMinDelay(
    import('~/containers/TestingContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const EmbeddedPaymentsContainer = loadable(() =>
  pMinDelay(
    import('~/containers/EmbeddedPaymentsContainer/EmbeddedPaymentsContainer'),
    MILLISECONDS_WAIT_TO_LOAD_COMPONENT,
  ),
)

const useNavigatedMenuItems = () => {
  const currentCompany = useSelector(selectMyCurrentCompany)
  const userMenuOptions = useSelector(selectUserMenuOptions)
  const driverFleet = useSelector(selectUsersDriverFleet)
  const isDriver = useSelector(selectIsScopeDriver)
  const currentScope = useSelector(selectCurrentScope)

  // for company users
  const documentsIndexMenu = useMemo(
    () => ({
      icon: documentsOutline,
      path: documentRoutesPaths.documents,
      label: 'Documents',
      value: 'documents',
      exact: true,
      component: DocumentsContainer,
    }),
    [],
  )

  const loadsIndexMenu = useMemo(
    () => ({
      icon: readerOutline,
      path: '/loads',
      label: 'Loads',
      value: 'loads',
      exact: true,
      component: LoadsContainer,
    }),
    [],
  )

  const invoicesIndexMenu = useMemo(
    () => ({
      icon: cardOutline,
      path: invoicesPaths.index,
      label: 'Invoices',
      value: 'invoices',
      exact: true,
      component: InvoicesContainer,
    }),
    [],
  )

  const dashboardMenu = useMemo(
    () => ({
      icon: pieChartOutline,
      path: userDashboardPaths.dashboard,
      label: 'Dashboard',
      value: 'dashboard',
      component: DashboardContainer,
    }),
    [],
  )

  const productsIndexMenu = useMemo(
    () => ({
      icon: albumsOutline,
      path: companyRoutesPaths.products,
      label: 'Products',
      value: 'products',
      component: ProductsContainer,
    }),
    [],
  )

  const documentProcessingMenu = useMemo(
    () => ({
      icon: apertureOutline,
      path: '/preprocessing_tests',
      label: 'Document Processing',
      value: 'document_processing',
      component: PreProcessingTests,
    }),
    [],
  )

  const ordersIndexMenu = useMemo(
    () => ({
      icon: todayOutline,
      path: '/orders',
      label: 'Orders',
      value: 'orders',
      component: OrdersContainer,
      exact: true,
    }),
    [],
  )

  const accountsMenu = useMemo(
    () => ({
      icon: lockClosedOutline,
      path: '/accesses',
      label: 'Accounts',
      value: 'accounts',
      component: AccessTableContainer,
    }),
    [],
  )

  const settingsMenu = {
    icon: cogOutline,
    path: routesPaths.profile,
    label: 'Settings',
    value: 'settings',
  }

  const orderRemindersMenu = useMemo(
    () => ({
      icon: alertOutline,
      path: '/order_reminders',
      label: 'Order Reminders',
      value: 'order_reminders',
      component: CompanyContainer,
    }),
    [],
  )

  const adminMenu = [
    {
      icon: speedometerOutline,
      path: adminRoutesPaths.index,
      label: 'Admin dashboard',
      value: 'admin_dashboard',
    },
  ]

  const payrollsMenu = useMemo(
    () => ({
      icon: wallet,
      path: '/payrolls',
      label: 'Payroll',
      value: 'payrolls',
      component: PayrollsContainer,
    }),
    [],
  )

  const myCompanyMenu = useMemo(
    () => ({
      icon: businessOutline,
      alias: `/companies/${currentCompany?.id}`,
      path: '/companies/:id',
      label: 'My Company',
      value: 'my_company',
      exact: true,
      component: CompaniesShowContainer,
      props: {
        companyId: currentCompany?.id,
      },
      subtabs: [
        {
          label: 'Home',
          value: moduleName.company.home,
          isHiddenOnHomePage: true,
          component: CompanyHomeContainer,
          props: {
            companyId: currentCompany?.id,
          },
        },
        {
          label: 'Users',
          value: moduleName.company.users,
          description: "Mange your company's users.",
          icon: peopleOutline,
          component: CompanyUserSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogUserForm {...props} />,
        },
        {
          label: 'User Group',
          value: moduleName.company.userGroups,
          description: 'View and control the various user groups.',
          icon: peopleCircleOutline,
          component: CompanyUserGroupSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogUserGroupForm {...props} />,
        },
        {
          label: 'Sellers',
          value: moduleName.company.sellers,
          description: 'Manage the companies you purchase from.',
          icon: storefrontOutline,
          component: CompanySellerBuyerSubTab,
          isHidden: currentScope === EScope.seller,
          props: {
            companyId: currentCompany?.id,
            description: 'Manage the companies you purchase from.',
            subTab: moduleName.company.sellers,
          },
          DialogComponent: props => (
            <DialogBuyerSellerForm
              hiddenFields={['seller', 'buyer', 'fleet']}
              {...props}
            />
          ),
        },
        {
          label: 'Buyers',
          value: moduleName.company.buyers,
          isHidden: currentScope === EScope.buyer,
          description: 'Manage the companies that buy from you.',
          icon: pricetagsOutline,
          component: CompanySellerBuyerSubTab,
          props: {
            companyId: currentCompany?.id,
            description: 'Manage the companies that buy from you.',
            subTab: moduleName.company.buyers,
          },
          DialogComponent: props => (
            <DialogBuyerSellerForm
              hiddenFields={['seller', 'buyer', 'fleet']}
              {...props}
            />
          ),
        },
        {
          label: 'Fleets',
          value: moduleName.company.fleets,
          description: 'Manage the fleets that provide hauling services.',
          icon: carOutline,
          component: CompanySellerBuyerSubTab,
          isHidden: currentScope === EScope.seller,
          props: {
            companyId: currentCompany?.id,
            description: 'Manage the fleets that provide hauling services.',
            subTab: moduleName.company.fleets,
          },
          DialogComponent: props => (
            <DialogBuyerSellerForm
              hiddenFields={['seller', 'buyer', 'fleet']}
              {...props}
            />
          ),
        },
        {
          label: 'Email Parsers',
          value: moduleName.company.emailParsers,
          description:
            'Manage the rules we use to extract data from document emails.',
          icon: mailUnread,
          component: CompanyEmailParserSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogEmailParserForm {...props} />,
        },
        {
          label: 'Parser Column Mappings',
          value: moduleName.company.parserColumnMappings,
          description:
            "Manage the rules we use to map data columns on invoices to Concord's invoices.",
          icon: mapOutline,
          component: CompanyParserColumnMappingSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => (
            <DialogParserColumnMappingForm {...props} />
          ),
        },
        {
          label: 'Products',
          value: moduleName.company.products,
          description: 'Manage the products you purchase.',
          icon: bagCheckOutline,
          component: CompanyProductSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogProductForm {...props} />,
        },
        {
          label: 'BTF STPs',
          value: moduleName.company.btfstps,
          description:
            'Manage the individual terminal-to-terminal product purchases.',
          icon: trailSign,
          component: CompanyBTFSTPSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogBtfstpForm {...props} />,
        },
        {
          label: 'Data Correction Methods',
          value: moduleName.company.dataCorrectionMethods,
          description:
            'Manage rules for correcting incorrectly parsed data from physical documents.',
          icon: construct,
          component: CompanyDataCorrectionMethodSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => (
            <DialogDataCorrectionMethodForm {...props} />
          ),
        },
        {
          label: 'Fixed Prices',
          value: moduleName.company.fixedPrices,
          description: 'Manage prices paid for materials and hauling.',
          icon: cash,
          component: CompanyFixedPriceSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogFixedPriceForm {...props} />,
        },
        {
          label: 'Seller Products',
          value: moduleName.company.sellerProducts,
          description: 'View products that sellers are selling to buyers.',
          icon: checkboxOutline,
          component: CompanySellerProductSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogSellerProductForm {...props} />,
        },
        {
          label: 'Terminals',
          value: moduleName.company.terminals,
          description: 'Locations where products are brought from or sold to.',
          icon: listCircleOutline,
          component: CompanyTerminalSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogTerminalForm {...props} />,
        },
        {
          label: 'Buyer Terminal References',
          value: moduleName.company.buyerTerminalReferences,
          description:
            'View and manage the terminal names and code of your buyers.',
          icon: documentTextOutline,
          component: CompanyBuyerTerminalReferenceSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => (
            <DialogBuyerTerminalReferenceForm {...props} />
          ),
        },
        {
          label: 'Cost Codes',
          value: moduleName.company.costCodes,
          description: 'View, edit, and delete company cost codes.',
          icon: receiptOutline,
          component: CompanyCostCodeSubTab,
          props: {
            companyId: currentCompany?.id,
          },
        },
        {
          label: 'Buyer Seller Products',
          value: moduleName.company.buyerSellerProducts,
          description:
            'View and manage products bought or sold between buyers and sellers.',
          icon: duplicateOutline,
          component: CompanyBuyerSellerProductSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogBuyerSellerProductForm {...props} />,
        },
        {
          label: 'Product Groups',
          value: moduleName.company.productGroups,
          description: 'View and manage your various product groups.',
          icon: albumsOutline,
          component: CompanyProductGroupSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogProductGroupForm {...props} />,
        },
        {
          label: 'Reference Names',
          value: moduleName.company.referenceNames,
          description:
            'Map names from your buyer or seller that reference your data (e.g. terminals, products, etc)',
          icon: gitNetworkOutline,
          component: CompanyReferenceNameSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogReferenceNameForm {...props} />,
        },
        {
          label: 'Tags',
          value: moduleName.company.tags,
          description: 'Create and manage your company tags.',
          icon: bookmarksOutline,
          component: CompanyTagSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogTagForm {...props} />,
        },
        {
          label: 'Work Pay Types',
          value: moduleName.company.workPayTypes,
          description: 'Payroll rules for sets of employees',
          icon: idCardOutline,
          component: CompanyWorkPayTypeSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogWorkPayTypeForm {...props} />,
        },
        {
          label: 'Seller Setup',
          value: moduleName.company.sellerSetup,
          icon: documentTextOutline,
          component: CompanySellerSetupSubTab,
          props: {
            companyId: currentCompany?.id,
          },
        },
        {
          label: 'Addresses',
          value: moduleName.company.addresses,
          icon: homeOutline,
          component: CompanyAddressSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogAddressForm {...props} />,
        },
        {
          label: 'Phone Numbers',
          value: moduleName.company.phoneNumbers,
          icon: callOutline,
          component: CompanyPhoneNumberSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogPhoneNumberForm {...props} />,
        },
        {
          label: 'Emails',
          value: moduleName.company.emails,
          icon: mailOutline,
          component: CompanyEmailSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogEmailForm {...props} />,
        },
        {
          label: 'Bank Accounts',
          value: moduleName.company.bankAccounts,
          icon: idCardOutline,
          component: CompanyBankAccounts,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogBankAccountForm {...props} />,
        },
        {
          label: 'Payrolls',
          value: moduleName.company.payrolls,
          icon: wallet,
          component: CompanyPayroll,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogCreateEditPayroll {...props} />,
        },
        {
          label: 'Flagable Configuration',
          value: moduleName.company.flagableConfigurations,
          icon: flagOutline,
          component: CompanyFlagableConfigurations,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => (
            <DialogFlagableConfigurationsForm {...props} />
          ),
        },
        {
          label: 'Pay Schedules',
          value: moduleName.company.paySchedules,
          icon: calendarOutline,
          component: PaySchedulesSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogPayScheduleForm {...props} />,
        },
        {
          label: 'Pay Attempts',
          value: moduleName.company.payAttempts,
          icon: cardSharp,
          component: PayAttemptsSubTab,
          props: {
            companyId: currentCompany?.id,
          },
        },
        {
          label: 'Earning Codes',
          value: moduleName.company.earningCodes,
          icon: earOutline,
          component: EarningCodesSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogEarningCodeForm {...props} />,
        },
        {
          label: 'Earning Rates',
          value: moduleName.company.earningRates,
          icon: starOutline,
          component: EarningRatesSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogEarningRateForm {...props} />,
        },
        {
          label: 'Offdays',
          value: moduleName.company.offdays,
          icon: calendarOutline,
          component: OffdaysSubTab,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogOffDayForm {...props} />,
        },
        {
          label: 'Data Parsers',
          value: moduleName.company.parsers,
          icon: receiptOutline,
          component: CompanyDataParser,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogDataParserForm {...props} />,
        },
        {
          label: 'Shifts',
          value: moduleName.company.shifts,
          icon: receiptOutline,
          component: ShiftsSubTab,
          props: {
            companyId: currentCompany?.id,
          },
        },
        {
          label: 'Allowed Hierarchies',
          value: moduleName.company.allowedHierarchies,
          icon: receiptOutline,
          component: AllowedHierarchiesContainer,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogAllowedHierarchyForm {...props} />,
        },
        {
          label: 'GL Codes',
          value: moduleName.company.glCodes,
          icon: receiptOutline,
          component: GlCodesContainer,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogGlCodeForm {...props} />,
        },
        {
          label: 'Workers',
          value: moduleName.company.workers,
          icon: personCircleOutline,
          component: WorkersContainerTable,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogWorkerForm {...props} />,
        },
        {
          label: 'Payment Types',
          value: moduleName.company.paymentTypes,
          icon: receiptOutline,
          component: PaymentTypesTable,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogPaymentTypeForm {...props} />,
        },
        {
          label: 'Hierarchies',
          value: moduleName.company.hierarchies,
          icon: receiptOutline,
          component: HierarchiesTable,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogHierarchyForm {...props} />,
        },
        {
          label: 'Hierarchy Rows',
          value: moduleName.company.hierarchyRows,
          icon: receiptOutline,
          component: HierarchyRowsTable,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogHierarchyRowForm {...props} />,
        },
        {
          label: 'Product GL Code',
          value: moduleName.company.productGlCodes,
          icon: receiptOutline,
          component: ProductGlCodesTable,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogProductGlCodeForm {...props} />,
        },
        {
          label: 'Taxes',
          value: moduleName.company.taxes,
          icon: receiptOutline,
          component: TaxesTable,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogTaxForm {...props} />,
        },
        {
          label: 'Product Tax Override',
          value: moduleName.company.productTaxOverride,
          icon: receiptOutline,
          component: ProductTaxOverrideTable,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogProductTaxOverrideForm {...props} />,
        },
        {
          label: 'Payment Terms',
          value: moduleName.company.paymentTerms,
          icon: receiptOutline,
          component: PaymentTermsTable,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogPaymentTermForm {...props} />,
        },
        {
          label: 'Settings',
          value: moduleName.company.settings,
          icon: receiptOutline,
          component: SettingsTable,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogSettingForm {...props} />,
        },
        {
          label: 'Campaigns',
          value: moduleName.company.campaigns,
          icon: receiptOutline,
          component: CampaignsTable,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogCampaignForm {...props} />,
        },
        {
          label: 'Trucks',
          value: moduleName.company.trucks,
          icon: busOutline,
          component: TrucksContainerTable,
          props: {
            companyId: currentCompany?.id,
          },
          DialogComponent: props => <DialogTruckFormData {...props} />,
        },
      ],
    }),
    [currentCompany?.id, currentScope],
  )

  const rewardsMenu = useMemo(
    () => ({
      icon: giftOutline,
      path: '/rewards',
      alias: '/rewards',
      label: 'Rewards',
      value: 'rewards',
      exact: true,
      component: RewardsContainer,
    }),
    [],
  )

  const invoiceShowMenu = useMemo(
    () => ({
      icon: cardOutline,
      path: invoicesPaths.detail,
      alias: invoicesPaths.detail,
      label: 'Invoice show page',
      value: 'invoice_show_page',
      isHiddenOnMenu: true,
      exact: true,
      component: InvoiceContainer,
    }),
    [],
  )

  const terminalShowMenu = useMemo(
    () => ({
      icon: cardOutline,
      path: companyRoutesPaths.terminal,
      alias: companyRoutesPaths.terminal,
      label: 'Terminal show page',
      value: 'terminal_show_page',
      isHiddenOnMenu: true,
      exact: true,
      component: TerminalContainer,
    }),
    [],
  )

  const documentShowMenu = useMemo(
    () => ({
      icon: cardOutline,
      path: documentRoutesPaths.detail,
      alias: documentRoutesPaths.detail,
      label: 'Document show page',
      value: 'document_show_page',
      isHiddenOnMenu: true,
      exact: true,
      component: DocumentContainer,
    }),
    [],
  )

  const orderShowMenu = useMemo(
    () => ({
      icon: cardOutline,
      path: ordersRoutesPaths.detail,
      alias: ordersRoutesPaths.detail,
      label: 'Order show page',
      value: 'order_show_page',
      isHiddenOnMenu: true,
      exact: true,
      component: OrderDetailsContainer,
    }),
    [],
  )

  const loadShowMenu = useMemo(
    () => ({
      icon: cardOutline,
      path: companyRoutesPaths.loadsDetail,
      alias: companyRoutesPaths.loadsDetail,
      label: 'Load show page',
      value: 'load_show_page',
      isHiddenOnMenu: true,
      exact: true,
      component: ParsedLoadContainer,
    }),
    [],
  )

  const processDocumentShowMenu = useMemo(
    () => ({
      icon: cardOutline,
      path: '/process_document/:id',
      alias: '/process_document/:id',
      label: 'Process document show page',
      value: 'process_document_show_page',
      isHiddenOnMenu: true,
      exact: true,
      component: ProcessDocumentContainer,
    }),
    [],
  )

  const ticketShowMenu = useMemo(
    () => ({
      icon: cardOutline,
      path: '/tickets/:id',
      alias: '/tickets/:id',
      label: 'Ticket document show page',
      value: 'ticket_document_show_page',
      isHiddenOnMenu: true,
      exact: true,
      component: LoadDetailsContainer,
    }),
    [],
  )

  const salesProposalShowMenu = useMemo(
    () => ({
      icon: cardOutline,
      path: salesProposalPaths.detail,
      alias: salesProposalPaths.detail,
      label: 'Sales Proposal document show page',
      value: 'sales_proposal_show_page',
      isHiddenOnMenu: true,
      exact: true,
      component: SalesProposalViewContainer,
    }),
    [],
  )

  const editCompanyMenu = useMemo(
    () => ({
      icon: cardOutline,
      path: companyRoutesPaths.edit,
      alias: companyRoutesPaths.edit,
      label: 'Edit company',
      value: 'edit_company',
      isHiddenOnMenu: true,
      exact: true,
      component: EditCompanyContainer,
    }),
    [],
  )

  const payrollDetailsMenu = useMemo(
    () => ({
      icon: cardOutline,
      path: companyRoutesPaths.payrollsDetails,
      alias: companyRoutesPaths.payrollsDetails,
      label: 'Payroll details',
      value: 'payroll_details',
      isHiddenOnMenu: true,
      exact: true,
      component: PayrollShowContainer,
    }),
    [],
  )

  const flagableConfigDetails = useMemo(
    () => ({
      icon: cardOutline,
      path: companyRoutesPaths.flagableConfigurationsDetail,
      alias: companyRoutesPaths.flagableConfigurationsDetail,
      label: 'Flagable Configuration',
      value: 'flagable_configuration',
      isHiddenOnMenu: true,
      exact: true,
      component: FlagableConfigurationDetailContainer,
    }),
    [],
  )

  // ----------

  const alphabetCompanySubtabs = useMemo(
    () => _.orderBy(myCompanyMenu.subtabs, ['label', 'asc']),
    [myCompanyMenu.subtabs],
  )

  const loadsCaptureMenu = useMemo(
    () => ({
      icon: cameraOutline,
      path: loadsCreatePaths.loadsCapture,
      label: 'Load Capture',
      value: 'loads_create',
      component: LoadCaptureContainer,
    }),
    [],
  )

  // const orderTrackingMenu = useMemo(() => ({
  //   icon: mapOutline,
  //   path: routesPaths.orderTracking,
  //   label: 'Order Tracking',
  //   name: 'order_tracking',
  //   value: 'order_tracking',
  // }), [])

  // for driver users
  // const itineraryMenu = useMemo(() => ({
  //   icon: readerOutline,
  //   path: driverRoutesPaths.itinerary,
  //   label: 'Itinerary',
  //   name: 'itinerary',
  //   value: 'itinerary',
  // }), [])

  const currentLoadMenu = useMemo(
    () => ({
      icon: readerOutline,
      path: driverRoutesPaths.index,
      label: 'Current Load',
      value: 'current_load',
      component: DriverHomePage,
    }),
    [],
  )

  const mapMenu = useMemo(
    () => ({
      icon: mapOutline,
      path: driverRoutesPaths.map,
      label: 'Map',
      value: 'map',
      component: MapContainer,
    }),
    [],
  )

  const pastLoadsMenu = useMemo(
    () => ({
      icon: reloadOutline,
      path: driverRoutesPaths.pastLoad,
      label: 'Past Loads',
      value: 'past_loads',
      component: PastLoadContainer,
      exact: true,
    }),
    [],
  )

  const driverProfileMenu = useMemo(
    () => ({
      icon: personCircleOutline,
      path: driverRoutesPaths.driverProfile,
      label: 'Driver Profile',
      value: 'driver_profile',
      component: DriverProfilePage,
    }),
    [],
  )

  const driverTruckProfileMenu = useMemo(
    () => ({
      icon: busOutline,
      path: driverRoutesPaths.truckProfile,
      label: 'Truck Profile',
      value: 'truck_profile',
      component: TruckProfilePage,
    }),
    [],
  )

  // const scanTicketsMenu = useMemo(() => ({
  //   icon: todayOutline,
  //   path: driverRoutesPaths.scanTicket,
  //   label: 'Scan Tickets',
  //   name: 'scan_ticket',
  //   value: 'scan_ticket',
  // }), [])

  const startTimesIndexMenu = useMemo(
    () => ({
      icon: alarmOutline,
      path: driverRoutesPaths.startTimes,
      label: 'Start Times',
      value: 'start_times',
      component: StartTimesContainer,
    }),
    [],
  )

  const chatMenu = useMemo(
    () => ({
      icon: chatbox,
      path: chatRoutesPaths.chats,
      label: 'Chats',
      value: 'chats',
      component: ChatContainer,
    }),
    [],
  )

  // fleet
  const dispatchMenu = useMemo(
    () => ({
      icon: speedometerOutline,
      path: fleetRoutesPaths.dispatchDashboard,
      label: 'Dashboard',
      value: 'dispatch_dashboard',
      component: OrderTrackingContainer,
    }),
    [],
  )

  const salesProposalsMenu = useMemo(
    () => ({
      icon: cashOutline,
      path: salesProposalPaths.index,
      label: 'Sales Proposals',
      value: 'sales_proposals',
      component: SalesProposalContainer,
    }),
    [],
  )

  const workersIndexMenu = useMemo(
    () => ({
      icon: personCircleOutline,
      path: fleetRoutesPaths.workers,
      label: 'Workers',
      value: 'workers',
      component: WorkersContainer,
    }),
    [],
  )

  const trucksIndexMenu = useMemo(
    () => ({
      icon: busOutline,
      path: fleetRoutesPaths.trucks,
      label: 'Trucks',
      value: 'trucks',
      component: TrucksContainer,
    }),
    [],
  )

  // const channelsMenu = useMemo(() => ({
  //   icon: chatbubbleEllipsesOutline,
  //   path: chatRoutesPaths.channels,
  //   label: 'Channels',
  //   name: 'channels',
  //   value: 'channels',
  // }), [])

  const groupsMenu = useMemo(
    () => ({
      icon: mapOutline,
      path: '/groups',
      label: 'Groups',
      value: 'groups',
      component: GroupsContainer,
    }),
    [],
  )

  const createOrderMenu = useMemo(
    () => ({
      icon: addOutline,
      path: fleetRoutesPaths.createOrder,
      label: 'Create Order',
      value: 'create_order',
      component: FleetsOrderContainer,
      exact: true,
    }),
    [],
  )
  const accountsReceivableMenu = useMemo(
    () => ({
      icon: speedometerOutline,
      path: routesPaths.accountsReceivable,
      label: 'AR',
      value: 'ar',
      component: AccountsReceivableContainer,
    }),
    [],
  )

  const salesBacklogMenu = useMemo(
    () => ({
      icon: speedometerOutline,
      path: '/sales_backlog',
      label: 'Sales Backlog',
      value: 'sales_backlog',
      component: SalesBacklogContainer,
    }),
    [],
  )

  const offDaysMenu = useMemo(
    () => ({
      icon: calendarOutline,
      path: '/offdays',
      label: 'Offdays',
      value: 'offdays',
      component: OffdaysContainer,
    }),
    [],
  )

  const paymentsMenu = useMemo(
    () => ({
      path: '/payments',
      component: PaymentsComponent,
      exact: true,
      icon: cashSharp,
      label: 'Payments',
      value: 'payments',
    }),
    [],
  )

  const barCodeMenu = useMemo(
    () => ({
      icon: calendarOutline,
      path: '/barcode_scanner',
      label: 'Barcode Scanner',
      value: 'barcode_scanner',
      component: BarcodeContainer,
    }),
    [],
  )

  const paymentTypesMenu = useMemo(
    () => ({
      icon: receiptOutline,
      path: '/payment_types',
      label: 'Payment Types',
      value: 'payment_types',
      component: PaymentTypesContainer,
    }),
    [],
  )

  const autoExtrasIndex = useMemo(
    () => ({
      icon: cashOutline,
      path: '/auto_extras',
      label: 'Auto Extras',
      value: 'auto_extras',
      component: AutoExtrasContainer,
    }),
    [],
  )

  const autoChargesIndex = useMemo(
    () => ({
      icon: cashOutline,
      path: '/auto_charges',
      label: 'Auto Charges',
      value: 'auto_charges',
      component: AutoChargesContainer,
    }),
    [],
  )

  const cardsMenu = useMemo(
    () => ({
      icon: card,
      path: '/cards',
      label: 'Cards',
      value: 'cards',
      component: CardsContainer,
    }),
    [],
  )

  const paymentMethodsMenu = {
    icon: card,
    path: '/payment_methods',
    label: 'Payment Methods',
    value: 'payment_methods',
    component: PaymentMethodsContainer,
  }

  const testingMenu = {
    icon: card,
    path: '/testing',
    label: 'Testing',
    value: 'testing',
    component: TestingContainer,
  }

  const embeddedPaymentsMenu = {
    icon: card,
    path: '/embedded_payments',
    label: 'Embedded Payments',
    value: 'embedded_ayments',
    component: EmbeddedPaymentsContainer,
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allPrivateMenuOptions = [
    dashboardMenu,
    productsIndexMenu,
    documentProcessingMenu,
    accountsMenu,
    loadsCaptureMenu,

    pastLoadsMenu,
    loadShowMenu,

    isDriver || driverFleet ? currentLoadMenu : loadsIndexMenu,
    //currentLoadMenu,
    //loadsIndexMenu,
    mapMenu,
    barCodeMenu,

    driverProfileMenu,
    driverTruckProfileMenu,
    startTimesIndexMenu,
    chatMenu,
    dispatchMenu,
    accountsReceivableMenu,
    salesProposalsMenu,
    workersIndexMenu,
    trucksIndexMenu,

    groupsMenu,

    createOrderMenu,
    orderShowMenu,
    ordersIndexMenu,

    invoiceShowMenu,
    invoicesIndexMenu,

    terminalShowMenu,

    documentShowMenu,
    documentsIndexMenu,

    processDocumentShowMenu,

    ticketShowMenu,

    salesProposalShowMenu,
    salesProposalsMenu,

    editCompanyMenu,
    myCompanyMenu,

    payrollDetailsMenu,
    payrollsMenu,

    flagableConfigDetails,

    salesBacklogMenu,

    offDaysMenu,

    orderRemindersMenu,
    paymentsMenu,

    paymentTypesMenu,
    cardsMenu,

    autoExtrasIndex,
    autoChargesIndex,
    rewardsMenu,

    paymentMethodsMenu,

    testingMenu,
    embeddedPaymentsMenu,
  ]

  const publicRoutes = useMemo(
    () => [
      {
        path: routesPaths.login,
        component: LoginPageContainer,
      },
      {
        path: routesPaths.index,
        component: LoginPageContainer,
        exact: true,
      },
      {
        path: routesPaths.signUp,
        component: OnBoardingContainer,
        exact: true,
      },
      {
        path: routesPaths.signUpCompany,
        component: OnBoardingContainer,
        exact: true,
      },
      {
        path: '/api/v1/users/password/edit',
        component: ForgotPassword,
        exact: true,
      },
      {
        path: '/credit_app',
        component: BuyerCompanyComponent,
        exact: true,
      },
    ],
    [],
  )

  const commonPrivateRoutes = useMemo(
    () => [
      {
        path: routesPaths.profile,
        component: ProfileContainer,
        private: true,
        name: 'profile',
      },
    ],
    [],
  )

  const currentUserMenuItems = useMemo(() => {
    const menuOptions = []
    userMenuOptions.forEach(menu => {
      const findMenu = allPrivateMenuOptions.find(({ value }) => value === menu)
      if (findMenu) {
        menuOptions.push({
          ...findMenu,
          private: true,
        })
      }
    })

    if (isDriver && !driverFleet) {
      return menuOptions.map(menu => {
        if (menu.value === 'driver_profile') {
          return menu
        }

        return { ...menu, isDisabled: true }
      })
    }

    return menuOptions
  }, [allPrivateMenuOptions, driverFleet, isDriver, userMenuOptions])

  return {
    currentUserMenuItems,
    publicRoutes,
    commonPrivateRoutes,
    myCompanyMenu,
    alphabetCompanySubtabs,
    allPrivateMenuOptions,
    settingsMenu,
    orderRemindersMenu,
    adminMenu,
  }
}

export default useNavigatedMenuItems

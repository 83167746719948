import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { IGetPaymentsParams, IPayment } from '~/types/models/IPayment'

const useModifyPaymentTypes = (query: IGetPaymentsParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => ['payments', sessionUser?.id, buildGetUrl('/payments', query)],
    [query, sessionUser?.id],
  )

  const addPayment = useCallback(
    (data: IPayment) => {
      queryClient.setQueryData<IPayment[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [...oldData, data]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updatePayment = useCallback(
    (id: number, data: IPayment) => {
      queryClient.setQueryData<IPayment[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = data
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removePayment = useCallback(
    (id: number) => {
      queryClient.setQueryData<IPayment[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addPayment,
    updatePayment,
    removePayment,
  }
}

export default useModifyPaymentTypes

import { EFieldType } from '~/types/enums/ECommonEnum'
import { ConcordFormStructure, IConcordFormField } from '../../FormStructure'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { ITestResult, ITestResultTest } from '~/types/models/ITestResult'

import * as Yup from 'yup'
import { useQuerySellerProducts } from '~/hooks/useQueryData'
import useQueryTestResults from '~/hooks/useQueryData/useQueryTestResults/useQueryTestResults'

interface Props {
  sellerProductId: number
  onUpdateTestResult?: (testResult: ITestResult) => void
}
interface FormValues {
  sellerProductId: number
  name: string
  value: number
}

export const TestResultForm = ({
  sellerProductId,
  onUpdateTestResult,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [tests, setTests] = useState<ITestResultTest[]>([])

  const { sellerProducts, sellerProductOptions } = useQuerySellerProducts()
  const formRef = useRef<any>()
  const companyId = useMemo(
    () =>
      sellerProducts.find(({ id }) => id === sellerProductId)?.sellerId ||
      undefined,
    [sellerProductId, sellerProducts],
  )

  const { testResults, updateTestResult, renewAllTestResults } =
    useQueryTestResults(
      { filters: { sellerProductId } },
      { enabled: !!sellerProductId },
    )

  const id = useMemo(() => testResults[0]?.id, [testResults])

  const nameOptions = useMemo(
    () =>
      tests.map(test => ({
        label: `${test.name} - ${test.test}`,
        value: test.name,
      })),
    [tests],
  )

  useEffect(() => {
    const fetch = async () => {
      try {
        const { tests } = await apiClient.testResults.new()
        setTests(tests)
      } catch (error) {
        console.error(error)
      }
    }
    fetch()
  }, [])

  const defaultValues = useMemo<FormValues>(
    () => ({
      sellerProductId: sellerProductId,
      name: testResults[0]?.name || '',
      value: testResults[0]?.value || 0,
    }),
    [sellerProductId, testResults],
  )

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'sellerProductId',
        label: 'Seller Product',
        type: EFieldType.singleSelect,
        isRequired: true,
        isReadOnly: sellerProductId ? true : false,
        options: sellerProductOptions,
      },
      {
        name: 'name',
        label: 'Test Result Name',
        type: EFieldType.singleSelect,
        isRequired: true,
        options: nameOptions,
      },
      {
        name: 'value',
        label: 'Value',
        type: EFieldType.number,
        isRequired: true,
      },
    ],
    [nameOptions, sellerProductId, sellerProductOptions],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        sellerProductId: Yup.number().required('This field is required'),
        name: Yup.string().required('This field is required'),
        value: Yup.number()
          .required('This field is required')
          .test(
            'is-within-tests',
            d => `${d.path} must be within the tests`,
            (value, { createError, path, parent: { name } }) => {
              const currentTest = tests.find(test => test.name === name)
              if (currentTest) {
                const [lower, upper] = currentTest.values.split('..')
                if (value < parseFloat(lower) || value > parseFloat(upper)) {
                  return createError({
                    path,
                    message: `${value} is not within the range ${currentTest.values} of ${currentTest.name}`,
                  })
                }
              }
              return true
            },
          ),
      }),
    [tests],
  )

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setValue('sellerProductId', sellerProductId)
    }
  }, [sellerProductId])

  useEffect(() => {
    if (formRef.current && testResults[0]?.name && testResults[0]?.value) {
      console.log('inside useEffect')
      formRef.current.setValue('name', testResults[0]?.name)
      formRef.current.setValue(
        'value',
        parseFloat(testResults[0]?.value.toString()),
      )
    }
  }, [testResults])

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      setIsLoading(true)
      const testResultPayload = {
        ...formValues,
        companyId,
        id,
      }
      try {
        if (id) {
          const { errors, ...testResult } = await apiClient.testResults.update(
            testResultPayload,
          )
          updateTestResult(testResult.id, testResult)

          onUpdateTestResult && onUpdateTestResult(testResult)
          if (errors && errors.length > 0) {
            toast.error(errors.join(', '))
          } else {
            toast.success('Test Result updated successfully')
          }
        } else {
          const { errors, ...testResult } = await apiClient.testResults.create(
            testResultPayload,
          )
          renewAllTestResults([testResult])
          onUpdateTestResult && onUpdateTestResult(testResult)
          if (errors && errors.length > 0) {
            toast.error(errors.join(', '))
          } else {
            toast.success('Test Result created successfully')
          }
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [companyId, id, onUpdateTestResult, renewAllTestResults, updateTestResult],
  )
  console.log('testResults', testResults)
  return (
    <ConcordFormStructure
      isLoading={isLoading}
      fields={fields}
      defaultValues={defaultValues}
      schema={schema}
      ref={formRef}
      isHiddenCancelButton
      isHiddenSearch
      onSubmit={handleSubmit}
      submitText={id ? 'Update' : 'Create'}
    />
  )
}

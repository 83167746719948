import useNoteButton from './useNoteButton'

import Tooltip from 'rc-tooltip'

import type { INoteButtonProps } from './type'

import { Badge, Button } from 'react-bootstrap'
import { NoteIcon } from '~/components/shared'

import './styles.scss'

function RTActionNoteButton(props: INoteButtonProps) {
  const { Overlay, tooltipProps, notesData } = useNoteButton(props)

  return (
    <Tooltip
      placement='top'
      overlay={Overlay}
      overlayClassName='RTActionNoteButton__tooltip'
      destroyTooltipOnHide
      {...tooltipProps}
    >
      <Button
        className='RTActionNoteButton__button'
        variant='secondary'
        style={{ fontSize: 13 }}
      >
        <NoteIcon color='white' />
        {notesData?.length ? (
          <Badge bg='danger' className='badgeButton'>
            {notesData.length}
          </Badge>
        ) : null}
      </Button>
    </Tooltip>
  )
}
export default RTActionNoteButton

import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type {
  IAddressGetNewListParams,
  IAddressGetNewListResponse,
} from '~/types/models/IAddress'

const useQueryNewAddresses = (
  params: IAddressGetNewListParams,
  options?: Partial<UseQueryOptions<IAddressGetNewListResponse['data']>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey: [
      'newAddresses',
      sessionUser?.id,
      buildGetUrl(`${apiClient.addresses.endpoint}/new`, params),
    ],
    async queryFn() {
      const response = await apiClient.addresses.new(params)

      return response.data
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 600000, // 10 minute
    ...options,
  })

  const newAddressesDataOptions = (data?.addressComponents || []).map(item => ({
    label: item.shortName,
    value: item.shortName,
    isNew: true,
  }))

  return {
    newAddressesData: data,
    newAddressesDataOptions,
    isNewAddressesDataLoading: isLoading,
    isNewAddressesDataFetched: isFetched,
    refetchNewAddressesData: refetch,
  }
}

export default useQueryNewAddresses

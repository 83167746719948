import { REGEX_SPLIT_REGEX_TO_STRING } from '~/utils/constants'

export const getRegex = regex => {
  if (!regex) {
    return new RegExp('')
  }

  console.log('regex', regex)

  const [, , regexStr, flags] = regex.match(REGEX_SPLIT_REGEX_TO_STRING)

  console.log('flags', flags)

  return new RegExp(regexStr, flags)
}

import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import type { IUser } from '~/types/models/IUser'
import useModifyWorkTypes from './useModifyWorkTypes'
import { IWorkType } from '~/types/models/IWorkType'

const useQueryWorkTypes = (
  _params = {},
  options?: Partial<UseQueryOptions<IWorkType[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(() => ['workTypes', sessionUser?.id], [sessionUser])

  const { addWorkType, updateWorkType, removeWorkType } = useModifyWorkTypes()

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const { workTypes } = await apiClient.workTypes.get()
      return workTypes
    },
    enabled: Boolean(sessionUser?.id),
    ...options,
  })

  const workTypesData = useMemo(() => data || [], [data])

  const workTypeOptions = useMemo(
    () =>
      workTypesData.map(({ id, name, code }) => ({
        value: id,
        label: `${code} - ${name}`,
      })),
    [workTypesData],
  )

  return {
    workTypesData,
    workTypeOptions,
    isWorkTypesLoading: isLoading,
    addWorkType,
    updateWorkType,
    removeWorkType,
  }
}

export default useQueryWorkTypes

import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyHierarchyRow from './useModifyHierarchyRow'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetHierarchyRowsParams,
  IHierarchyRow,
} from '~/types/models/IHierarchyRow'

const useQueryHierarchyRows = (
  params: Partial<IGetHierarchyRowsParams> = {},
  options?: Partial<UseQueryOptions<IHierarchyRow[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'hierarchyRows',
      sessionUser?.id,
      buildGetUrl(apiClient.hierarchyRows.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.hierarchyRows.get(params)
      return response.hierarchyRows
    },
    enabled: Boolean(sessionUser?.id),
    ...options,
  })

  const {
    addHierarchyRow,
    updateHierarchyRow,
    removeHierarchyRow,
    renewAllHierarchyRows,
  } = useModifyHierarchyRow(params)

  const hierarchyRowsData = useMemo(() => data || [], [data])

  return {
    hierarchyRowsData,
    isLoadingHierarchyRows: isLoading,
    addHierarchyRow,
    updateHierarchyRow,
    removeHierarchyRow,
    renewAllHierarchyRows,
  }
}

export default useQueryHierarchyRows

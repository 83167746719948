import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import useModifyDataParsers from './useModifyDataParsers'
import { IDataParser, IGetDataParsersParams } from '~/types/models/IDataParser'

const useQueryDataParsers = (
  params: Partial<IGetDataParsersParams> = {},
  options?: Partial<UseQueryOptions<IDataParser[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'dataParsers',
      sessionUser?.id,
      buildGetUrl(apiClient.dataParsers.endpoint, params),
    ],
    async queryFn() {
      const res = await apiClient.dataParsers.get(params)
      return res
    },
    enabled: Boolean(sessionUser?.id),
    ...options,
  })

  const { addDataParser, updateDataParser, removeDataParser } =
    useModifyDataParsers(params)

  const dataParsers = useMemo(() => data || [], [data])

  return {
    dataParsers,
    isLoadingDataParsers: isLoading,
    addDataParser,
    updateDataParser,
    removeDataParser,
  }
}

export default useQueryDataParsers

import { useWatch } from 'react-hook-form'
import { ExtrasFormRangesRow } from './ExtrasFormRangesRow'
import { produce } from 'immer'

export const ExtrasFormRangesFieldArray = ({
  name,
  control,
  sellerProductId,
  hideRange,
  setValue,
}) => {
  const watchOrderExtraRangesAttributes = useWatch({
    control,
    name,
  })

  if (sellerProductId)
    return (
      <div>
        {watchOrderExtraRangesAttributes.map((field, rangeIndex) => (
          <ExtrasFormRangesRow
            key={field.id}
            index={rangeIndex}
            control={control}
            name={name}
            hideRange={hideRange}
            setValue={setValue}
            onAdd={() => {
              setValue(name, [
                ...watchOrderExtraRangesAttributes,
                { qty: null, startLoad: 1, endLoad: null },
              ])
            }}
            onRemove={index => {
              setValue(
                name,
                produce(watchOrderExtraRangesAttributes, draft => {
                  draft.splice(index, 1)
                }),
              )
            }}
          />
        ))}
      </div>
    )

  return null
}

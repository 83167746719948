import React, { PropsWithChildren } from 'react'

import { IonSpinner } from '@ionic/react'
import { ConcordFormDropdownV2 } from '~/components/shared'
import { InputProps, components } from 'react-select'

import clsx from 'clsx'

import type { IDropdownWithCustomChildrenProps } from './type'

import './styles.scss'

const Input = React.memo<
  PropsWithChildren<InputProps & { isLoading?: boolean }>
>(props => {
  const { selectProps } = props
  return (
    <div style={{ cursor: 'pointer' }} className='wrapChildren'>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {(selectProps as any).childrenComponent}
      {selectProps.isLoading && (
        <IonSpinner name='lines-sharp-small' className='avatarSpinner' />
      )}
      {!selectProps.isLoading && <components.Input {...props} />}
    </div>
  )
})

function DropdownWithCustomChildren(props: IDropdownWithCustomChildrenProps) {
  const {
    children,
    isLoading,
    className,
    noHover,
    isReadOnly,
    styles,
    ...dropdownProps
  } = props

  return (
    <ConcordFormDropdownV2
      {...dropdownProps}
      className={clsx('DropdownWithCustomChildren__container', className, {
        noHover,
        isReadOnly,
      })}
      styles={{
        container: provider => ({
          ...provider,
          maxWidth: 250,
          height: '100%',
        }),
        control: provider => ({
          ...provider,
          border: 'none',
          boxShadow: 'none',
        }),
        singleValue: provider => ({
          ...provider,
          display: 'none',
        }),
        indicatorsContainer: provider => ({
          ...provider,
          display: 'none',
        }),
        menu: provider => ({
          ...provider,
          minWidth: 220,
        }),
        input: provider => ({
          ...provider,
          opacity: 0,
        }),
        ...styles,
      }}
      isLoading={isLoading}
      childrenComponent={children}
      components={{
        ...dropdownProps.components,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Input: Input as any,
      }}
    />
  )
}
export default React.memo(DropdownWithCustomChildren)

import { createSelector } from 'reselect'
import { selectDriverFleets, selectDriverFleetsList } from './selectDrivers'
import buildFullName from '~/utils/buildFullName'

export const selectTrucks = state => state.data.trucks
const getTrucksUi = state => state.ui.trucks
export const selectTruckFleets = state => state.data.truckFleets
const getId = (_state, id) => id

export const selectTrucksList = createSelector(selectTrucks, state =>
  Object.values(state),
)

export const selectTruckFleetsCompactList = createSelector(
  selectTruckFleets,
  truckFleets => Object.values(truckFleets) || [],
)

export const selectTruckFleetsFullList = createSelector(
  selectTruckFleets,
  selectTrucks,
  (truckFleets, trucks) =>
    Object.values(truckFleets).map(truckFleet => {
      const truck = trucks[truckFleet.truckId]
      return {
        ...truckFleet,
        truck,
      }
    }),
)

export const selectTruckFromTruckFleetId = createSelector(
  [selectTruckFleets, selectTrucks, getId],
  (truckFleets, trucks, id) => trucks[truckFleets[id]?.truckId] || null,
)

export const selectTrucksForDriverOptions = createSelector(
  selectTrucksList,
  trucks => (trucks || []).map(({ id, name }) => ({ value: id, label: name })),
)

export const selectTrucksForDriverOptionsWithNotSet = createSelector(
  selectTrucksForDriverOptions,
  options => [
    {
      label: 'Not Set',
      value: null,
    },
    ...options,
  ],
)

export const selectTrucksForDriverFullOptions = createSelector(
  selectTruckFleetsFullList,
  truckFleets =>
    (truckFleets || []).map(({ id, truck }) => ({
      value: id,
      label: truck?.name,
    })),
)

export const selectTrucksForDriverFullOptionsWithNotSet = createSelector(
  selectTrucksForDriverFullOptions,
  options => [
    {
      label: 'Not Set',
      value: null,
    },
    ...options,
  ],
)

export const selectTruckFleetsWithTruckNameOptions = createSelector(
  [selectTruckFleets, selectTrucks],
  (truckFleets, trucks) =>
    Object.values(truckFleets).map(truckFleet => ({
      value: truckFleet.id,
      label: trucks[truckFleet.truckId]?.name,
    })) || [],
)

export const selectAvailableTrucks = createSelector(
  [selectTrucks, selectDriverFleets, selectTruckFleets],
  (trucks, driverFleets, truckFleets) => {
    const takenTrucks = Object.values(driverFleets)
      .map(df => df.currentTruckFleetId)
      .filter(id => truckFleets[id]?.truckId)

    return Object.values(trucks).filter(t => !takenTrucks.includes(t.id))
  },
)

export const selectAvailableTruckFleets = createSelector(
  [selectTruckFleetsCompactList, selectDriverFleetsList],
  (truckFleets, driverFleets) => {
    const assignedTruckFleetIds = driverFleets.map(
      ({ currentTruckFleetId }) => currentTruckFleetId,
    )

    return truckFleets.filter(({ id }) => !assignedTruckFleetIds.includes(id))
  },
)
export const selectAssignedTruckFleets = createSelector(
  [selectTruckFleetsCompactList, selectDriverFleetsList],
  (truckFleets, driverFleets) => {
    const assignedTruckFleetIds = driverFleets.map(
      ({ currentTruckFleetId }) => currentTruckFleetId,
    )

    return truckFleets
      .filter(({ id }) => assignedTruckFleetIds.includes(id))
      .map(truckFleet => {
        const findDriverFleet = driverFleets.find(
          ({ currentTruckFleetId }) => currentTruckFleetId === truckFleet.id,
        )

        return {
          ...truckFleet,
          driverFleet: findDriverFleet,
        }
      })
  },
)

export const selectAvailableTruckFleetOptions = createSelector(
  selectAvailableTruckFleets,
  truckFleets =>
    truckFleets.map(({ id, truck }) => ({
      value: id,
      label: truck.name,
    })),
)
export const selectAssignedTruckFleetOptions = createSelector(
  selectAssignedTruckFleets,
  truckFleets =>
    truckFleets.map(({ id, truck, driverFleet }) => ({
      value: id,
      label: `${truck.name} - ${buildFullName(driverFleet?.person)}`,
    })),
)

export const selectTruckLoading = createSelector(
  getTrucksUi,
  state => state.loading,
)

export const selectTrucksViewMode = createSelector(
  getTrucksUi,
  state => state.viewMode,
)

export const selectTrucksLastUpdatedAt = createSelector(
  getTrucksUi,
  state => state.lastUpdatedAt,
)

export const selectFirstTruck = createSelector(
  selectTrucks,
  trucks => Object.values(trucks)[0] || {},
)

export const selectTruckFleetFromIdFull = createSelector(
  [selectTruckFleets, selectTrucks, getId],
  (truckFleets, trucks, id) => {
    const truckFleet = truckFleets[id]
    const truck = trucks[truckFleet?.truckId]
    return {
      ...truckFleet,
      truck,
    }
  },
)

export const selectTruckFromDriverFleetId = createSelector(
  [selectDriverFleets, selectTrucks, selectTruckFleets, getId],
  (driverFleets, trucks, truckFleets, id) => {
    trucks[truckFleets[driverFleets[id]?.truckFleetId]?.truckId] || {}
  },
)

import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyTaxes from './useModifyTaxes'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { IGetTaxesParams, ITax } from '~/types/models/ITax'

const useQueryTaxes = (
  params: Partial<IGetTaxesParams> = {},
  options?: Partial<UseQueryOptions<ITax[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'taxes',
      sessionUser?.id,
      buildGetUrl(apiClient.taxes.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.taxes.get(params)
      return response.taxes
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // 1 min
    ...options,
  })

  const taxesData = useMemo(() => data || [], [data])

  const taxOptions = useMemo(
    () =>
      taxesData.map(({ id, name, taxType, taxValue, taxNumber }) => {
        const label = [name, taxType, taxValue, taxNumber].filter(text => text)
        return {
          label: label.join(' - '),
          value: id,
        }
      }),
    [taxesData],
  )

  const { addTax, updateTax, removeTax } = useModifyTaxes(params)

  return {
    taxesData,
    isLoadingTaxes: isLoading,
    taxOptions,
    addTax,
    updateTax,
    removeTax,
  }
}

export default useQueryTaxes

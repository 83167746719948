import { produce } from 'immer'
import { useCallback, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/ApiClient'

import { selectSessionUser } from '~/redux/selectors'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IGetWorkersParams } from '~/types/models/IWorker'
import buildFullName from '~/utils/buildFullName'
import { buildGetUrl } from '~/utils/buildUrl'
/**
 *
 * @param {IGetWorkersParams} queryParams
 */
const useQueryWorkers = (queryParams = {}, options = {}) => {
  const sessionUser = useSelector(selectSessionUser)

  const queryClient = useQueryClient()

  const key = useMemo(
    () => ['workers', sessionUser.id, buildGetUrl('/workers', queryParams)],
    [queryParams, sessionUser.id],
  )

  const { data = [] } = useQuery({
    queryKey: key,
    async queryFn() {
      const data = await apiClient.workers.get({
        includePerson: true,
        ...queryParams,
      })

      return data.workers
    },
    enabled: Boolean(sessionUser.id),
    staleTime: 60000, // one minute
    ...options,
  })

  const workerOptions = useMemo(
    () =>
      data.map(({ person, id }) => ({
        value: id,
        label: buildFullName(person),
      })),
    [data],
  )

  const updateWorker = useCallback(
    (id, worker) => {
      queryClient.setQueryData(key, oldData =>
        produce(oldData, draft => {
          const index = draft.findIndex(worker => worker.id === id)
          if (index !== -1) {
            draft[index] = {
              ...draft[index],
              ...worker,
            }
          }
        }),
      )
    },
    [key, queryClient],
  )

  const availableWorkers = useMemo(
    () => data.filter(({ checkUid }) => checkUid),
    [data],
  )

  const workerOptionsWithCheckUid = useMemo(
    () =>
      data.map(({ person, id, checkUid }) => ({
        label: `${person?.firstName} ${person?.lastName}`,
        value: id,
        isDisabled: !checkUid,
        checkUid,
      })),
    [data],
  )

  const findWorkerById = useCallback(
    workerId => {
      return data.find(({ id }) => workerId === id)
    },
    [data],
  )

  const findWorkerByCheckUid = useCallback(
    checkUid => {
      return data.find(worker => checkUid === worker.checkUid)
    },
    [data],
  )

  const findWorkerByUserAccessId = useCallback(
    userAccessId => {
      if (userAccessId === sessionUser.userAccess?.id) {
        return {
          companyId: sessionUser.company.id,
          userAccessId: sessionUser.userAccess.id,
          workerType: null,
          person: sessionUser.person,
        }
      }

      return data.find(worker => worker.userAccessId === userAccessId)
    },
    [data, sessionUser],
  )

  return {
    workerData: data,
    workerOptions,
    availableWorkers,
    workerOptionsWithCheckUid,
    updateWorker,
    findWorkerById,
    findWorkerByCheckUid,
    findWorkerByUserAccessId,
  }
}

export default useQueryWorkers

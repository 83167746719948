import _ from 'lodash'

const diffObjects = function (obj1, obj2) {
  try {
    const result = _.reduce(obj1, function (result, value, key) {
      if (key === 'id') {
        result.id = value
      }
      if (_.isPlainObject(value)) {
        result[key] = diffObjects(value, obj2[key])
      } else if (!_.isEqual(value, obj2[key])) {
        result[key] = value
      }
      return result
    }, {})
    return result
  } catch (error) {
    return {}
  }
}

export default diffObjects

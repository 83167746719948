import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyPriceEscalations from './useModifyPriceEscalations'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import {
  IGetPriceEscalationsParams,
  IPriceEscalation,
} from '~/types/models/IPriceEscalation'
// import sleep from '~/utils/sleep'

const useQueryPriceEscalations = (
  params: IGetPriceEscalationsParams,
  options?: Partial<UseQueryOptions<IPriceEscalation[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { addPriceEscalation, updatePriceEscalation, removePriceEscalation } =
    useModifyPriceEscalations(params)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'priceEscalations',
      sessionUser?.id,
      buildGetUrl(apiClient.priceEscalations.endpoint, params),
    ],
    async queryFn() {
      // await sleep(1000)
      // return [
      //   {
      //     id: 1,
      //     amount: '123',
      //     date: '2024-03-01',
      //     escalationCadence: 'annual' as any,
      //     escalationType: 'dollar' as any,
      //     salesProposalId: 1,
      //     senderCompanyId: 362,
      //   },
      //   {
      //     id: 2,
      //     amount: '222',
      //     date: '2024-10-10',
      //     escalationCadence: 'one_time' as any,
      //     escalationType: 'percent' as any,
      //     salesProposalId: 1,
      //     senderCompanyId: 362,
      //   },
      // ]
      const response = await apiClient.priceEscalations.get(params)
      return response.priceEscalations
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // one minute
    ...options,
  })

  const priceEscalationsData = data || []

  return {
    priceEscalationsData,
    isPriceEscalationsLoading: isLoading,
    refetchPriceEscalations: refetch,
    addPriceEscalation,
    updatePriceEscalation,
    removePriceEscalation,
  }
}

export default useQueryPriceEscalations

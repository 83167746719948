import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetCheckSignatoriesParams,
  IGetCheckSignatoriesResponse,
} from '~/types/models/ICheck'
import { useQueryUsers } from '../useQueryUsers'
import useModifyCheckSignatories from './useModifyCheckSignatories'

const useQueryCheckSignatories = (
  params: IGetCheckSignatoriesParams,
  options?: Partial<UseQueryOptions<IGetCheckSignatoriesResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { addCheckSignatory } = useModifyCheckSignatories(params)

  const { data, isLoading } = useQuery({
    queryKey: [
      'checkSignatories',
      sessionUser?.id,
      buildGetUrl('/check_signatories', params),
    ],
    async queryFn() {
      const response = await apiClient.check.getSignatories(params)
      return response
    },
    enabled: Boolean(sessionUser?.id),
    staleTime: 60000, // one minute
    ...options,
  })

  const checkSignatoriesData = useMemo(
    () => data?.checkSignatories || [],
    [data],
  )

  const { userOptions, usersData, updatePerson, updateUser, updateWorker } =
    useQueryUsers({
      filters: {
        companyId: params.filters.companyId,
      },
    })

  const signatoryUserAccessIds = useMemo(
    () => checkSignatoriesData.map(({ userAccessId }) => userAccessId),
    [checkSignatoriesData],
  )

  const availableUsersAsSigner = useMemo(
    () =>
      userOptions.filter(
        ({ userAccessId }) =>
          signatoryUserAccessIds.includes(userAccessId) &&
          sessionUser?.userAccess?.id !== userAccessId,
      ),
    [sessionUser?.userAccess?.id, signatoryUserAccessIds, userOptions],
  )

  const unavailableUserAsSigner = useMemo(
    () =>
      userOptions.filter(
        ({ userAccessId }) => !signatoryUserAccessIds.includes(userAccessId),
      ),
    [signatoryUserAccessIds, userOptions],
  )

  const checkSignatoryOptions = useMemo(() => {
    if (checkSignatoriesData.length) {
      return [
        {
          label: 'Signers',
          options: availableUsersAsSigner,
        },
        {
          label: 'Users',
          options: unavailableUserAsSigner,
        },
      ]
    }

    return userOptions
  }, [
    availableUsersAsSigner,
    checkSignatoriesData.length,
    unavailableUserAsSigner,
    userOptions,
  ])

  return {
    checkSignatoriesData,
    isLoadingCheckSignatoriesData: isLoading,
    checkSignatoryOptions,
    availableUsersAsSigner,
    unavailableUserAsSigner,
    userOptions,
    usersData,
    addCheckSignatory,
    updateUser,
    updateWorker,
    updatePerson,
  }
}

export default useQueryCheckSignatories

import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import {
  IGetPriceEscalationsParams,
  IPriceEscalation,
} from '~/types/models/IPriceEscalation'

const useModifyPriceEscalations = (params: IGetPriceEscalationsParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'priceEscalations',
      sessionUser?.id,
      buildGetUrl(apiClient.priceEscalations.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addPriceEscalation = useCallback(
    (data: IPriceEscalation) => {
      queryClient.setQueryData<IPriceEscalation[]>(queryKey, oldData => {
        if (oldData) {
          return [...oldData, data]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updatePriceEscalation = useCallback(
    (id: number, data: IPriceEscalation) => {
      queryClient.setQueryData<IPriceEscalation[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(g => g.id === id)
              draft[index] = data
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const removePriceEscalation = useCallback(
    (id: number) => {
      queryClient.setQueryData<IPriceEscalation[] | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.findIndex(item => item.id === id)
              if (draft[index]) {
                draft.splice(index, 1)
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )
  return {
    addPriceEscalation,
    updatePriceEscalation,
    removePriceEscalation,
  }
}

export default useModifyPriceEscalations
